import { DEFAULT_VALUE_0 } from 'common/config';
import { EInventoryActionStatus } from 'common/const/inventory.enum';
import { ISubdivisionShort, IUserShort } from 'common/models';
import { mapInventoryActionToInventoryActionShort } from 'entities/Inventory/Inventory.helper';
import {
  IInventory,
  IInventoryAction,
  IInventoryActionListState,
  IInventoryActionState,
  IInventoryDetailed,
  IInventoryListState,
  IInventoryState,
} from 'entities/Inventory/Inventory.models';
import { IUser } from 'entities/User/User.models';

export const inventoryListStateReducers = {
  setInventoryList: (state: IInventoryListState, payload: IInventory[]) => ({ ...state, data: payload }),
  setInventoryListPart: (state: IInventoryListState, payload: IInventory[]) => ({ ...state, data: [...state.data, ...payload] }),
  setCount: (state: IInventoryListState, payload: number) => ({ ...state, count: payload }),
  setLoading: (state: IInventoryListState, payload: boolean) => ({ ...state, loading: payload }),
  setSubdivisionList: (state: IInventoryListState, payload: ISubdivisionShort[]) => ({
    ...state,
    subdivisionList: payload,
  }),
  setUserList: (state: IInventoryListState, payload: IUserShort[]) => ({ ...state, userList: payload }),
  addAction: (state: IInventoryListState, payload: IInventoryAction) => {
    return {
      ...state,
      data: state.data.map((inventory) => {
        if (inventory.id === payload.inventoryId) {
          return {
            ...inventory,
            actions:
              payload.status === EInventoryActionStatus.New
                ? [...inventory.actions, mapInventoryActionToInventoryActionShort(payload)]
                : inventory.actions,
            count: payload.status === EInventoryActionStatus.New ? inventory.count : inventory.count - payload.count,
          };
        }

        return inventory;
      }),
    };
  },
  filterActions: (state: IInventoryListState, payload: { inventoryId: number; id: number }) => {
    return {
      ...state,
      data: state.data.map((inventory) => {
        if (inventory.id === payload.inventoryId) {
          return {
            ...inventory,
            actions: inventory.actions.filter((action) => action.id !== payload.id),
          };
        }

        return inventory;
      }),
    };
  },
  filterListByCount: (state: IInventoryListState) => {
    const ids = state.data.filter((inventory) => inventory.count === DEFAULT_VALUE_0).map((inventory) => inventory.id);

    return {
      ...state,
      data: state.data.filter((inventory) => !ids.includes(inventory.id)),
      count: state.count - ids.length,
    };
  },
};

export const inventoryStateReducers = {
  setInventory: (state: IInventoryState, payload: IInventoryDetailed | null) => ({ ...state, data: payload }),
  setLoading: (state: IInventoryState, payload: boolean) => ({ ...state, loading: payload }),
  setUserList: (state: IInventoryState, payload: IUser[]) => ({ ...state, userList: payload }),
  filterActions: (state: IInventoryState, payload: number) => {
    return {
      ...state,
      data: state.data
        ? {
            ...state.data,
            actions: state.data.actions.filter((action) => action.id !== payload),
          }
        : null,
    };
  },
};

export const inventoryActionListStateReducers = {
  setInventoryActionList: (state: IInventoryActionListState, payload: IInventoryAction[]) => ({
    ...state,
    data: payload,
  }),
  setInventoryActionListPart: (state: IInventoryActionListState, payload: IInventoryAction[]) => ({
    ...state,
    data: [...state.data, ...payload],
  }),
  setCount: (state: IInventoryActionListState, payload: number) => ({ ...state, count: payload }),
  setLoading: (state: IInventoryActionListState, payload: boolean) => ({ ...state, loading: payload }),
  setSubdivisionList: (state: IInventoryActionListState, payload: ISubdivisionShort[]) => ({
    ...state,
    subdivisionList: payload,
  }),
  setUserList: (state: IInventoryActionListState, payload: IUserShort[]) => ({ ...state, userList: payload }),
  filterList: (state: IInventoryActionListState, payload: IInventoryAction[]) => {
    return {
      ...state,
      data: state.data.filter((action) => !payload.some((item) => item.id === action.id)),
      count: state.count - payload.length,
    };
  },
};

export const inventoryActionStateReducers = {
  setInventoryAction: (state: IInventoryActionState, payload: IInventoryAction | null) => ({ ...state, data: payload }),
  setLoading: (state: IInventoryActionState, payload: boolean) => ({ ...state, loading: payload }),
};
