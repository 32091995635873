import axios from 'axios';
import {
  IBuyerCityCollectionDto,
  IBuyerCityListParams,
  IDelivery,
  IDeliveryCollectionDto,
  IDeliveryListParams,
  IDeliveryCreatePayload,
  IDeliveryDeletePayload,
  IDeliveryUpdatePayload,
} from 'entities/Delivery/Delivery.models';

const basePath = 'delivery';

export const deliveryTransport = {
  getBuyerCityList: (params: IBuyerCityListParams): Promise<IBuyerCityCollectionDto> =>
    axios.get(`${basePath}/buyer-cities`, { params }),
  getDeliveryList: (params: IDeliveryListParams): Promise<IDeliveryCollectionDto> => axios.get(`${basePath}`, { params }),
  createDelivery: (payload: IDeliveryCreatePayload): Promise<IDelivery> => axios.post(`${basePath}`, payload),
  updateDelivery: (payload: IDeliveryUpdatePayload): Promise<IDelivery> => axios.patch(`${basePath}`, payload),
  deleteDelivery: (payload: IDeliveryDeletePayload): Promise<IDelivery> => axios.delete(`${basePath}`, { data: payload }),
};
