import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { IBuyerCityListState, IDeliveryListState, IDeliveryState } from 'entities/Delivery/Delivery.models';
import {
  buyerCityListStateReducers,
  deliveryListStateReducers,
  deliveryStateReducers,
} from 'entities/Delivery/Delivery.reducers';
import { buyerCityListStateEffects, deliveryListStateEffects, deliveryStateEffects } from 'entities/Delivery/Delivery.effects';

export const buyerCityListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IBuyerCityListState,
  reducers: buyerCityListStateReducers,
  effects: buyerCityListStateEffects,
});

export const deliveryListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IDeliveryListState,
  reducers: deliveryListStateReducers,
  effects: deliveryListStateEffects,
});

export const deliveryState = createModel<IRootModel>()({
  state: {
    loading: false,
  } as IDeliveryState,
  reducers: deliveryStateReducers,
  effects: deliveryStateEffects,
});
