import axios from 'axios';
import {
  ISubdivisionCollectionDto,
  ISubdivisionListParams,
  ISubdivision,
  ISubdivisionCreatePayload,
  ISubdivisionDeletePayload,
  ISubdivisionUpdatePayload,
  ISubdivisionParams,
} from 'entities/Subdivision/Subdivision.models';

const basePath = 'subdivision';

export const subdivisionTransport = {
  getSubdivisionList: (params: ISubdivisionListParams): Promise<ISubdivisionCollectionDto> =>
    axios.get(`${basePath}`, { params }),
  getSubdivisionById: ({ id }: ISubdivisionParams): Promise<ISubdivision> => axios.get(`${basePath}/${id}`),
  createSubdivision: (payload: ISubdivisionCreatePayload): Promise<ISubdivision> => axios.post(`${basePath}`, payload),
  updateSubdivision: (payload: ISubdivisionUpdatePayload): Promise<ISubdivision> => axios.patch(`${basePath}`, payload),
  deleteSubdivision: (payload: ISubdivisionDeletePayload): Promise<ISubdivision> =>
    axios.delete(`${basePath}`, { data: payload }),
};
