import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { destroyMessage, showLoadingMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  ICatalogCreatePayload,
  ICatalogDeletePayload,
  ICatalogListParams,
  ICatalogUpdatePayload,
} from 'entities/Catalog/Catalog.models';
import { catalogTransport } from 'entities/Catalog/Catalog.transport';

export const catalogListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getCatalogList(params: ICatalogListParams) {
    dispatch.catalogListState.setLoading(true);

    try {
      const response = await catalogTransport.getCatalogList(params);
      dispatch.catalogListState.seList(response.data);
      dispatch.catalogListState.seCount(response.count);
      return response.data;
    } catch (error) {
      axiosErrorHandler(error);
      return null;
    } finally {
      dispatch.catalogListState.setLoading(false);
    }
  },
});

export const catalogStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getCatalogById(id: number) {
    dispatch.catalogState.seLoading(true);

    try {
      const response = await catalogTransport.getCatalogById(id);
      dispatch.catalogState.setCatalog(response);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.catalogState.seLoading(false);
    }
  },
  async createCatalog(payload: ICatalogCreatePayload) {
    dispatch.catalogState.seLoading(true);

    try {
      const response = await catalogTransport.createCatalog(payload);
      dispatch.catalogListState.addCatalog(response);
      dispatch.catalogState.setCatalog(response);
      dispatch.catalogState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.catalogState.seLoading(false);
    }
  },
  async updateCatalog(payload: ICatalogUpdatePayload) {
    dispatch.catalogState.seLoading(true);

    showLoadingMessage(0);

    try {
      const response = await catalogTransport.updateCatalog(payload);
      dispatch.catalogListState.updateCatalog(response);
      dispatch.catalogState.setCatalog(response);
      dispatch.catalogState.onSuccess(payload);
      showSuccessMessage();
    } catch (error) {
      destroyMessage();
      axiosErrorHandler(error);
    } finally {
      dispatch.catalogState.seLoading(false);
    }
  },
  async deleteCatalog(payload: ICatalogDeletePayload) {
    dispatch.catalogState.seLoading(true);

    try {
      const response = await catalogTransport.deleteCatalog(payload);
      dispatch.catalogListState.deleteCatalog(response.id);
      dispatch.catalogState.setCatalog(response);
      dispatch.catalogState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.catalogState.seLoading(false);
    }
  },
});
