import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Input, Modal } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { DEFAULT_VALUE_0 } from 'common/config';
import { IOption } from 'common/models';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  options: IOption[];
  defaultValue?: string[];
  onClose: () => void;
  onSave: (value: string[], onSuccess: () => void) => void;
}

export const ContractBrandsModal: FC<IComponentProps> = (props) => {
  const { open, loading, options, defaultValue, onClose, onSave } = props;

  const [search, setSearch] = useState<string>('');
  const [value, setValue] = useState<CheckboxValueType[]>([]);

  const checkAll = options.length === value.length;
  const indeterminate = value.length > DEFAULT_VALUE_0 && value.length < options.length;
  const optionsForView = useMemo(() => {
    return options.filter((option) => (option.label as string).toLowerCase().includes(search.toLowerCase()));
  }, [options, search]);

  const onCheckAllChange = (checked: boolean) => {
    checked ? setValue(options.map((option) => option.value)) : setValue([]);
  };

  const handleClose = () => {
    if (loading) {
      return;
    }

    setSearch('');
    onClose();
  };

  const handleSave = () => {
    onSave(value as string[], handleClose);
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    } else {
      setValue(options.map((option) => option.value));
    }
  }, [defaultValue, options]);

  return (
    <Modal
      rootClassName="redesign modal contract-brands-modal"
      width={800}
      centered
      destroyOnClose
      closeIcon={false}
      open={open}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" className="button-sm secondary" onClick={handleClose} disabled={loading}>
          Отмена
        </Button>,
        <Button key="save" className="button-sm primary" onClick={handleSave} loading={loading}>
          Сохранить
        </Button>,
      ]}
    >
      <Input
        className="mb-20"
        placeholder={EPlaceholder.EnterBrandName}
        allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <div className="mb-10 contract-brands-modal__check-all">
        <Checkbox
          className="mb-10"
          checked={checkAll}
          indeterminate={indeterminate}
          onChange={(e) => onCheckAllChange(e.target.checked)}
        >
          Все бренды
        </Checkbox>
      </div>

      <div className="mb-20 scrollbar contract-brands-modal__scroll-container">
        <Checkbox.Group options={optionsForView} value={value} onChange={setValue} />
      </div>
    </Modal>
  );
};
