import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Drawer, Form, Input, InputNumber, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Image } from 'common/components/Image';
import { InventoryCount } from 'common/components/InventoryCount';
import { SuccessCard } from 'common/components/SuccessCard';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { EInventoryActionType } from 'common/const/inventory.enum';
import { rules } from 'common/helpers/form.helper';
import { useInventoryContext } from 'common/hooks/useInventoryContext';
import { DEFAULT_VALUE_0, DRAWER_Z_INDEX_1 } from 'common/config';
import { IFormValue } from 'common/models';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import {
  filterInventoryTransferCardUserList,
  groupInventoryTransferCardUserOptions,
  inventoryCount,
} from 'entities/Inventory/Inventory.helper';
import { getUserName } from 'entities/User/User.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = (props) => {
  const {
    // state
    currentUser,
    inventory,
    inventoryActionLoading,
    userList,
    // dispatch
    filterInventoryListByCount,
    createInventoryAction,
    getUserInventoryStatistics,
  } = props;

  const [userSearch, setUserSearch] = useState<string>('');
  const [receiverId, setReceiverId] = useState<number | null>(null);
  const [count, setCount] = useState<number | null>(null);
  const [openSuccessCard, setOpenSuccessCard] = useState<boolean>(false);
  const [form] = useForm();
  const { isNestedCard, setIsNestedCard, setOpenInventoryCard, openInventoryTransferCard, setOpenInventoryTransferCard } =
    useInventoryContext();

  const availableCount = inventoryCount.withoutAllRequestsCount(inventory);
  const filteredUserList = filterInventoryTransferCardUserList(userList, userSearch, inventory?.user?.id, currentUser?.id);
  const userOptions = groupInventoryTransferCardUserOptions(filteredUserList);
  const selectedUser = userList.find((user) => user.id === receiverId);

  const onClose = () => {
    if (inventoryActionLoading) {
      return;
    }

    setOpenInventoryTransferCard(false);

    if (isNestedCard) {
      setOpenInventoryCard(true);
    }

    setIsNestedCard(false);
    form.resetFields();
    setUserSearch('');
  };

  const onSuccessCardClose = () => {
    setOpenSuccessCard(false);
    form.resetFields();
    setUserSearch('');
  };

  const onSubmit = (values: IFormValue) => {
    const { receiverId, count } = values;

    if (inventory) {
      createInventoryAction({
        inventoryId: inventory.id,
        type: EInventoryActionType.Transfer,
        receiverId,
        count,
        onSuccess: () => {
          setOpenInventoryTransferCard(false);
          setOpenSuccessCard(true);
          filterInventoryListByCount();
          getUserInventoryStatistics();
        },
      });
    }
  };

  if (!inventory) {
    return null;
  }

  return (
    <>
      <Drawer
        rootClassName="redesign drawer"
        open={openInventoryTransferCard}
        onClose={onClose}
        width={800}
        destroyOnClose
        zIndex={DRAWER_Z_INDEX_1}
      >
        <div className="drawer__body">
          <div className="text-h1-drawers mb-52">Передача оборудования другому сотруднику</div>

          <div className="inventory-card__good-card mb-52">
            <Image size={80} src={inventory.good.image} />

            <div style={{ flex: 1 }}>
              <div className="text-h4 mb-12">{inventory.good.name}</div>

              <div className="inventory-card__good-card-footer">
                <div>
                  {inventory.good.sellerCode && (
                    <span className="text-tag-accent color-dark-grey" style={{ marginRight: 20 }}>
                      {inventory.good.sellerCode}
                    </span>
                  )}

                  {inventory.good.buyerCode && (
                    <span className="text-tag-accent color-bright-green">{inventory.good.buyerCode}</span>
                  )}
                </div>
              </div>

              <div style={{ marginTop: 12 }}>
                <InventoryCount count={inventory.count} />
              </div>
            </div>
          </div>

          <Form layout="vertical" requiredMark={false} form={form} onFinish={onSubmit}>
            <Form.Item className="mb-32" label="Новый ответственный*" name="receiverId" rules={[rules.required()]}>
              <Select
                rootClassName="redesign"
                options={userOptions}
                placeholder={EPlaceholder.SelectEmployee}
                virtual={false}
                suffixIcon={<ArrowDownShortIcon />}
                notFoundContent="В списке нет вариантов."
                onChange={setReceiverId}
                dropdownRender={(menu) => {
                  return (
                    <>
                      <Input
                        className="mb-20"
                        allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
                        value={userSearch}
                        onChange={(e) => setUserSearch(e.target.value)}
                      />

                      {menu}
                    </>
                  );
                }}
              />
            </Form.Item>

            <Form.Item
              className="mb-32"
              label="Количество оборудования*"
              name="count"
              rules={[
                rules.required(),
                () => ({
                  validator(_, value) {
                    if (value > availableCount || value <= DEFAULT_VALUE_0) {
                      return Promise.reject(new Error('Невозможно указать введённое количество.'));
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber controls={false} suffix="шт" onChange={(value) => setCount(value as number)} />
            </Form.Item>
          </Form>
        </div>

        <div className="drawer__footer">
          <Button className="button-lg primary" loading={inventoryActionLoading} onClick={form.submit}>
            Передать оборудование
          </Button>

          <Button className="button-lg secondary" onClick={onClose} loading={inventoryActionLoading}>
            Отмена
          </Button>
        </div>
      </Drawer>

      <SuccessCard
        open={openSuccessCard}
        content={`Запрос о передаче оборудования сотруднику ${getUserName.fullLastNameAndInitials(
          selectedUser?.lastName,
          selectedUser?.firstName,
          selectedUser?.patronymic,
        )} в количестве ${count} шт успешно отправлен`}
        subtitle="Готово!"
        btnTitle="Назад к списку"
        onClose={onSuccessCardClose}
        onConfirm={onSuccessCardClose}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  currentUser: state.userState.currentUser,
  inventory: state.inventoryState.data,
  inventoryActionLoading: state.inventoryActionState.loading,
  userList: state.inventoryState.userList,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  filterInventoryListByCount: dispatch.inventoryListState.filterListByCount,
  createInventoryAction: dispatch.inventoryActionState.createInventoryAction,
  getUserInventoryStatistics: dispatch.userInventoryStatisticsState.getUserInventoryStatistics,
});

export const InventoryTransferCard = connect(mapState, mapDispatch)(Component);
