import React, { FC } from 'react';
import { Spin } from 'antd';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { Notification } from 'common/components/Notification';
import { ESupplyCorrectionLabel } from 'common/const/supply.enum';
import { ENeedHistoryItemType } from 'common/const/need.enum';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { SupplyCardMin } from 'entities/Supply/components/SupplyCardMin';
import { INeedHistoryItem, INeedHistorySupplyCorrectionChanges, INeedHistorySupplyPriceChanges } from 'entities/Need/Need.models';
import { ISupply } from 'entities/Supply/Supply.models';

interface IComponentProps {
  supplyList: ISupply[];
  historyList: INeedHistoryItem[];
  loading: boolean;
  onSupplyClick: (id: number) => void;
}

export const NeedCardSupplies: FC<IComponentProps> = (props) => {
  const { supplyList, historyList, loading, onSupplyClick } = props;

  return (
    <div className="need-card-supplies">
      <Spin wrapperClassName="need-card-supplies__spin" spinning={loading} indicator={<SpinIndicator />}>
        {!supplyList.length ? (
          <div className="text-body color-dark-grey need-card-supplies__empty">Поставки по данной заявке не запланированы.</div>
        ) : (
          <>
            {supplyList.map((supply) => {
              const supplyHistory = historyList.filter((history) => {
                return (
                  (history.changes as INeedHistorySupplyCorrectionChanges).supplyId &&
                  (history.changes as INeedHistorySupplyCorrectionChanges).supplyId === supply.id
                );
              });
              const lastHistoryItem = supplyHistory[0];
              const supplyOnCorrection = lastHistoryItem?.type === ENeedHistoryItemType.CreateSupplyCorrection;
              const supplyPriceIsChanged = lastHistoryItem?.type === ENeedHistoryItemType.ChangeSupplyPrice;

              return (
                <div key={supply.id}>
                  <SupplyCardMin supply={supply} onSupplyClick={onSupplyClick} />

                  {supplyOnCorrection && (
                    <div className="mb-32">
                      <Notification
                        type="warning"
                        title="Запрошена корректировка поставки"
                        description={`Причина: «${
                          ESupplyCorrectionLabel[(lastHistoryItem?.changes as INeedHistorySupplyCorrectionChanges).reason]
                        }». Комментарий: «${(lastHistoryItem?.changes as INeedHistorySupplyCorrectionChanges).comment}»`}
                      />
                    </div>
                  )}

                  {supplyPriceIsChanged && (
                    <div className="mb-32">
                      <Notification
                        type="warning"
                        title="Произведена корректировка стоимости товара"
                        description={`Старая цена поставщика: ${priceFormatter(
                          (lastHistoryItem?.changes as INeedHistorySupplyPriceChanges).oldPrice,
                        )}; новая цена поставщика ${priceFormatter(
                          (lastHistoryItem?.changes as INeedHistorySupplyPriceChanges).newPrice,
                        )}`}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </>
        )}
      </Spin>
    </div>
  );
};
