import { createModel } from '@rematch/core';
import { DEFAULT_VALUE_0 } from 'common/config';
import { IRootModel } from 'app/store';
import { IBrandListState } from 'entities/Brand/Brand.models';
import { brandListStateReducers } from 'entities/Brand/Brand.reducers';
import { brandListStateEffects } from 'entities/Brand/Brand.effects';

export const brandListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: DEFAULT_VALUE_0,
    loading: false,
  } as IBrandListState,
  reducers: brandListStateReducers,
  effects: brandListStateEffects,
});
