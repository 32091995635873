import React from 'react';
import { Form, FormInstance, Spin } from 'antd';
import { Store } from 'antd/es/form/interface';

interface IComponentProps {
  form: FormInstance;
  initialValues?: Store;
  loading: boolean;
  body: React.ReactNode;
  footer: React.ReactNode;
  onSubmit: (values: any) => void;
}

export const AuthCard: React.FC<IComponentProps> = ({ form, initialValues, loading, body, footer, onSubmit }) => (
  <Form
    layout="vertical"
    className="auth-card"
    requiredMark={false}
    form={form}
    initialValues={initialValues}
    onFinish={onSubmit}
    style={{ width: '100%', padding: 0 }}
  >
    <Spin spinning={loading}>
      <div className="auth-card__body">{body}</div>

      <div className="auth-card__footer">{footer}</div>
    </Spin>
  </Form>
);
