import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { WarningCard } from 'common/components/WarningCard';
import { EFormFieldMessage } from 'common/const/form.enum';
import { EMode } from 'common/const/common.enum';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { rules } from 'common/helpers/form.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { useLegalEntityContext } from 'common/hooks/useLegalEntityContext';
import { LEGAL_INN_LENGTH, LEGAL_KPP_LENGTH } from 'common/config';
import { IFormValue } from 'common/models';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { RootDispatch, RootState } from 'app/store';
import { ILegalEntityCreatePayload, ILegalEntityUpdatePayload } from 'entities/LegalEntity/LegalEntity.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ legalEntity, legalEntityLoading, createLegalEntity, updateLegalEntity }) => {
  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
  const [payload, setPayload] = useState<ILegalEntityUpdatePayload | null>(null);
  const [form] = useForm();
  const { editLegalEntityCardMode, openEditLegalEntityCard, setOpenEditLegalEntityCard } = useLegalEntityContext();

  const isEditMode = editLegalEntityCardMode === EMode.Edit;

  const onClose = () => {
    setFormIsChanged(false);
    setOpenWarningCard(false);
    setOpenEditLegalEntityCard(false);
  };

  const handleClose = () => {
    if (legalEntityLoading) {
      return;
    }

    if (formIsChanged) {
      setOpenEditLegalEntityCard(false);
      setOpenWarningCard(true);
      return;
    }

    onClose();
  };

  const onFormValuesChange = (value: IFormValue) => {
    setFormIsChanged(true);
    setPayload((prev) => (prev ? { ...prev, ...value } : null));
  };

  const onCreateSubmit = (value: ILegalEntityCreatePayload) => {
    createLegalEntity({
      ...value,
      onSuccess: () => {
        showSuccessMessage('Новое юридическое лицо добавлено в аккаунт.');
        onClose();
      },
    });
  };

  const onUpdateSubmit = () => {
    if (payload) {
      updateLegalEntity({
        ...payload,
        onSuccess: () => {
          showSuccessMessage('Реквизиты юридического лица успешно изменены.');
          onClose();
        },
      });
    }
  };

  useEffect(() => {
    if (openEditLegalEntityCard) {
      if (isEditMode && legalEntity) {
        setPayload({ id: legalEntity.id });
        form.setFieldsValue({
          name: legalEntity.name,
          inn: legalEntity.inn,
          kpp: legalEntity.kpp,
        });
      } else {
        form.resetFields();
      }
    }
  }, [openEditLegalEntityCard, isEditMode, legalEntity]);

  return (
    <>
      <Drawer
        rootClassName="redesign drawer edit-legal-entity-card"
        destroyOnClose
        width={720}
        open={openEditLegalEntityCard}
        onClose={handleClose}
        footer={
          isEditMode ? (
            <Button className="button-lg primary" loading={legalEntityLoading} onClick={form.submit}>
              Сохранить изменения
            </Button>
          ) : (
            <Button className="button-lg primary" loading={legalEntityLoading} onClick={form.submit}>
              Добавить юр. лицо
            </Button>
          )
        }
      >
        <div className="drawer__body">
          <div className="drawer__title mb-52">{isEditMode ? 'Редактировать юр. лицо' : 'Новое юридическое лицо'}</div>

          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={isEditMode ? onUpdateSubmit : onCreateSubmit}
            onValuesChange={onFormValuesChange}
          >
            <Form.Item className="mb-32" label="Название*" name="name" rules={[rules.required()]}>
              <Input
                placeholder={EPlaceholder.EnterName}
                allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
              />
            </Form.Item>

            <Form.Item
              className="mb-32"
              label="ИНН*"
              name="inn"
              rules={[
                rules.required(),
                rules.min(LEGAL_INN_LENGTH, EFormFieldMessage.LegalInnLength),
                rules.max(LEGAL_INN_LENGTH, EFormFieldMessage.LegalInnLength),
              ]}
            >
              <Input placeholder={EPlaceholder.EnterINN} allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }} />
            </Form.Item>

            <Form.Item
              className="mb-32"
              label="КПП*"
              name="kpp"
              rules={[
                rules.required(),
                rules.min(LEGAL_KPP_LENGTH, EFormFieldMessage.LegalKppLength),
                rules.max(LEGAL_KPP_LENGTH, EFormFieldMessage.LegalKppLength),
              ]}
            >
              <Input placeholder={EPlaceholder.EnterKPP} allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }} />
            </Form.Item>
          </Form>
        </div>
      </Drawer>

      <WarningCard
        open={openWarningCard}
        content="Вы уверены, что не хотите сохранить изменения?"
        subtitle="Введенные данные будут потеряны."
        confirmBtnTitle="Не сохранять"
        onConfirm={onClose}
        onClose={() => {
          setOpenWarningCard(false);
          setOpenEditLegalEntityCard(true);
        }}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  legalEntity: state.legalEntityState.data,
  legalEntityLoading: state.legalEntityState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  createLegalEntity: dispatch.legalEntityState.createLegalEntity,
  updateLegalEntity: dispatch.legalEntityState.updateLegalEntity,
});

export const EditLegalEntityCard = connect(mapState, mapDispatch)(Component);
