/* eslint-disable sonarjs/no-duplicate-string */
import React, { Key } from 'react';
import { Button, Dropdown, Tooltip, type MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { UserRoleChip } from 'common/components/UserRoleChip';
import { IconBackground } from 'common/components/IconBackground';
import { EDateFormat } from 'common/const/date.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { EUserFilterOption, EUserFilterOptionLabel, EUserRole, EUserStatus } from 'common/const/user.enum';
import { ESettingsMenuTab } from 'common/const/settings.enum';
import { EColor } from 'common/const/color.enum';
import { getSettingsPath } from 'common/helpers/router.helper';
import { generateTreeNodeKeyAndSort } from 'common/helpers/tree.helper';
import { ITreeDataNode, IUserShort } from 'common/models';
import { ReactComponent as SuccessIcon } from 'app/assets/images/success.svg';
import { ReactComponent as ErrorIcon } from 'app/assets/images/error.svg';
import { ReactComponent as InfoYellowIcon } from 'app/assets/images/redesign/info-yellow.svg';
import { ReactComponent as InfoRedIcon } from 'app/assets/images/redesign/info-red.svg';
import { ReactComponent as LegalEntityIcon } from 'app/assets/images/redesign/legal-entity.svg';
import { ReactComponent as OptionsIcon } from 'app/assets/images/redesign/options.svg';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';
import { ReactComponent as DeliveryIcon } from 'app/assets/images/redesign/delivery.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { ReactComponent as DiscountIcon } from 'app/assets/images/redesign/discount.svg';
import { IUser } from 'entities/User/User.models';
import { ICatalog } from 'entities/Catalog/Catalog.models';
import { getUserName, getUserRole } from 'entities/User/User.helper';
import { IInvitationCreatePayload } from 'entities/Invitation/Invitation.models';
import { ILegalEntity } from 'entities/LegalEntity/LegalEntity.models';
import { ISubdivision } from 'entities/Subdivision/Subdivision.models';
import { IAddress } from 'entities/Address/Address.models';
import { IStock } from 'entities/Stock/Stock.models';
import { ICategory } from 'entities/Category/Category.models';
import { IBrandDiscount } from 'entities/Discount/Discount.models';

export const getSettingsNavItems = (roles?: string[], isSeller?: boolean) => {
  const { isSuperAdmin, isAccountAdmin } = getUserRole(roles);
  const navItems: MenuProps['items'] = [];

  if (isSuperAdmin) {
    navItems.push({
      key: ESettingsMenuTab.AccountList,
      label: <Link to={getSettingsPath(ESettingsMenuTab.AccountList)}>Список аккаунтов</Link>,
    });
  }

  if (isAccountAdmin) {
    navItems.push(
      {
        key: ESettingsMenuTab.Account,
        label: <Link to={getSettingsPath(ESettingsMenuTab.Account)}>Настройки аккаунта</Link>,
      },
      {
        key: ESettingsMenuTab.UserList,
        label: <Link to={getSettingsPath(ESettingsMenuTab.UserList)}>Пользователи</Link>,
      },
    );

    if (isSeller) {
      navItems.push(
        {
          key: ESettingsMenuTab.Stocks,
          label: <Link to={getSettingsPath(ESettingsMenuTab.Stocks)}>Склады отгрузки</Link>,
        },
        {
          key: ESettingsMenuTab.Discounts,
          label: <Link to={getSettingsPath(ESettingsMenuTab.Discounts)}>Скидки</Link>,
        },
        {
          key: ESettingsMenuTab.Integration,
          label: <Link to={getSettingsPath(ESettingsMenuTab.Integration)}>Интеграции</Link>,
        },
      );
    } else {
      navItems.push(
        {
          key: ESettingsMenuTab.Subdivisions,
          label: <Link to={getSettingsPath(ESettingsMenuTab.Subdivisions)}>Подразделения</Link>,
        },
        {
          key: ESettingsMenuTab.LegalEntityList,
          label: <Link to={getSettingsPath(ESettingsMenuTab.LegalEntityList)}>Юр.лица</Link>,
        },
      );
    }
  }

  return navItems.map((item) => ({ ...item, className: 'workplace-nav__link' }));
};

export const renderUsersSettingsRecords = (
  setSearchParams: (key: ESearchParams, value: string | number) => void,
  onInviteUser: (payload: IInvitationCreatePayload) => void,
) => {
  return [
    {
      onCell: () => ({ className: `users__list_item-cell user-status` }),
      key: 'status',
      render: (record) => (
        <div className="users__list_item-status">
          {record?.status === EUserStatus.Active ? <SuccessIcon className="success" /> : <ErrorIcon />}
        </div>
      ),
    },
    {
      onCell: () => ({ className: 'users__list_item-cell' }),
      key: 'firstName',
      render: (record) => (
        <Button type="link" onClick={() => setSearchParams(ESearchParams.UserId, record.id)}>
          {getUserName.fullName(record.lastName, record.firstName, record.patronymic)}
        </Button>
      ),
      ellipsis: true,
    },
    {
      onCell: () => ({ className: `users__list_item-cell role` }),
      key: 'role',
      render: (record) => {
        const { isAccountAdmin } = getUserRole(record.roles);

        return isAccountAdmin ? <span>Администратор</span> : null;
      },
    },
    {
      onCell: () => ({ className: `users__list_item-cell email` }),
      key: 'email',
      render: (record) => <div title={record.email}>{record.email}</div>,
      ellipsis: true,
    },
    {
      key: 'invite',
      render: (_, record) => {
        if (record.status === EUserStatus.Active) {
          return null;
        } else {
          return (
            <ThreeDotMenu
              items={[
                {
                  key: 'invite',
                  label: (
                    <Button
                      onClick={() =>
                        onInviteUser({
                          email: record.email,
                          firstName: record.firstName,
                          lastName: record.lastName,
                          patronymic: record.patronymic,
                          subdivisionId: record.subdivisionId,
                        })
                      }
                    >
                      Пригласить повторно
                    </Button>
                  ),
                },
              ]}
            />
          );
        }
      },
      width: 64,
    },
  ] as ColumnsType<IUser>;
};

export const getUserSettingsFilterOptions = () => {
  return Object.values(EUserFilterOption).map((item) => ({ label: EUserFilterOptionLabel[item], value: item }));
};

export const toUserSettingsSearchParams = (currentUser: IUser | null, searchValue: string, filterList: EUserFilterOption[]) => {
  return {
    accountId: currentUser?.accountId,
    search: searchValue,
    withoutSubdivision: filterList.includes(EUserFilterOption.WithoutSubdivision) ? true : undefined,
    status: filterList.includes(EUserFilterOption.NotActive) ? EUserStatus.NotActive : undefined,
    limit: 0,
  };
};

export const renderCatalogListSettingsRecords = (
  handleUpdateCatalogClick: (record: ICatalog) => void,
  handleDeleteCatalogClick: (catalogId: number) => void,
) => {
  return [
    {
      title: 'Название каталога',
      dataIndex: 'name',
    },
    {
      title: 'Создан',
      dataIndex: 'createdAt',
      render: (text) => dayjs(text).locale('ru').format(EDateFormat.FullDate),
      width: 200,
    },
    {
      title: 'Изменен',
      dataIndex: 'updatedAt',
      render: (text) => dayjs(text).locale('ru').format(EDateFormat.FullDate),
      width: 200,
    },
    {
      title: 'Автор',
    },
    {
      title: '',
      render: (_, record) => (
        <ThreeDotMenu
          items={[
            {
              key: 'edit',
              label: (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUpdateCatalogClick(record);
                  }}
                >
                  Редактировать
                </Button>
              ),
              className: 'edit',
            },
            {
              key: 'delete',
              label: (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteCatalogClick(record.id);
                  }}
                >
                  Удалить
                </Button>
              ),
              className: 'delete',
            },
          ]}
        />
      ),
      width: 64,
    },
  ] as ColumnsType<ICatalog>;
};

export const mapSubdivisionListToTreeData = (
  subdivisionList: ISubdivision[],
  onCreateClick: (key: Key) => Promise<void>,
  onTransferClick: (key: Key) => void,
  onDeleteClick: (id: Key) => void,
): ITreeDataNode[] => {
  const map = new Map<number, ITreeDataNode>();
  const roots: ITreeDataNode[] = [];

  subdivisionList.forEach(({ id, name }) => {
    map.set(id, {
      key: String(id),
      title: name,
      children: [],
      icon: (props) => {
        return (
          <div className="settings-sidebar__subdivision-tree-item-container">
            <Dropdown
              overlayClassName="redesign"
              menu={{
                items: [
                  {
                    key: 'transfer',
                    label: 'Перенести в...',
                    onClick: ({ domEvent }) => {
                      domEvent.stopPropagation();
                      onTransferClick(props.data.key);
                    },
                  },
                  { type: 'divider' },
                  {
                    key: 'delete',
                    label: 'Удалить подразделение',
                    className: 'red',
                    onClick: ({ domEvent }) => {
                      domEvent.stopPropagation();
                      onDeleteClick(props.data.key);
                    },
                  },
                ],
              }}
            >
              <Button className="button-icon" icon={<OptionsIcon className="icon-options-dark-grey" />} />
            </Dropdown>

            <Tooltip title="Дочернее подразделение" placement="bottom">
              <Button
                className="button-icon"
                icon={<PlusIcon className="icon-plus-dark-grey" />}
                onClick={(e) => {
                  e.stopPropagation();
                  onCreateClick(props.data.key);
                }}
              />
            </Tooltip>
          </div>
        );
      },
    });
  });

  subdivisionList.forEach(({ id, parentId }) => {
    const node = map.get(id);

    if (node) {
      if (parentId !== undefined && map.has(parentId)) {
        const parent = map.get(parentId);

        parent?.children?.push(node);
      } else {
        roots.push(node);
      }
    }
  });

  generateTreeNodeKeyAndSort(roots);

  return roots;
};

export const mapCategoryListToTreeData = (categoryList: ICategory[]): ITreeDataNode[] => {
  const map = new Map<number, ITreeDataNode>();
  const roots: ITreeDataNode[] = [];

  categoryList.forEach(({ id, displayName }) => {
    map.set(id, {
      key: String(id),
      title: displayName,
      children: [],
    });
  });

  categoryList.forEach(({ id, parentId }) => {
    const node = map.get(id);

    if (node) {
      if (parentId !== undefined && map.has(parentId)) {
        const parent = map.get(parentId);

        parent?.children?.push(node);
      } else {
        roots.push(node);
      }
    }
  });

  generateTreeNodeKeyAndSort(roots);

  return roots;
};

export const buildTreeKeysFromCategoryList = (categoryList: ICategory[], parentId: number | null, prefix: string = ''): Key[] => {
  const result: Key[] = [];

  categoryList.forEach((category) => {
    if (category.parentId === parentId) {
      const key = prefix ? `${prefix}-${category.id}` : `${category.id}`;

      result.push(key);
      result.push(...buildTreeKeysFromCategoryList(categoryList, category.id, key));
    }
  });

  return result;
};

export const getSubdivisionTreeKeysBySubdivisionId = (subdivisionList: ISubdivision[], id?: string): string[] => {
  if (!id || isNaN(Number(id))) {
    return [];
  }

  const dataMap = new Map<number, ISubdivision>(subdivisionList.map((item) => [item.id, item]));
  const result: number[] = [];

  let current: ISubdivision | undefined = dataMap.get(Number(id));

  while (current) {
    result.unshift(current.id);
    current = current.parentId ? dataMap.get(current.parentId) : undefined;
  }

  return [result.join('-')];
};

export const getSubdivisionSettingsSubdivisionLegalEntityOptions = (legalEntityList: ILegalEntity[]) => {
  return legalEntityList.map((legalEntity) => ({ label: legalEntity.name, value: legalEntity.id }));
};

export const getSubdivisionSettingsSubdivisionHeadOptions = (userList: IUser[]) => {
  return userList.map((user) => ({
    label: getUserName.fullName(user.lastName, user.firstName, user.patronymic),
    value: user.id,
  }));
};

export const getSubdivisionSettingsManagerOptions = (userList: IUser[]) => {
  return userList
    .filter((user) => user.roles.includes(EUserRole.Manager))
    .map((user) => ({ label: getUserName.fullName(user.lastName, user.firstName, user.patronymic), value: user.id }));
};

export const getSubdivisionSettingsBreadcrumbItems = (
  subdivisionList: ISubdivision[],
  selectedTreeKeys: string[],
  onClick?: (id: number) => void,
) => {
  const subdivisionIds = selectedTreeKeys[0]?.split('-').map(Number);

  if (!subdivisionIds) {
    return [];
  }

  return subdivisionIds
    .map((id) => subdivisionList.find((item) => item.id === id) as ISubdivision)
    .map((item) => ({
      title: item?.name,
      onClick: () => onClick?.(item?.id),
    }));
};

export const getAccountSettingsMenuItems = (isSeller?: boolean) => {
  return isSeller
    ? [
        { key: ESettingsMenuTab.UserList, label: 'Пользователи' },
        { key: ESettingsMenuTab.StockList, label: 'Склады отгрузки' },
        { key: ESettingsMenuTab.Integrations, label: 'Интеграции' },
      ]
    : [
        { key: ESettingsMenuTab.Contracts, label: 'Контракты' },
        { key: ESettingsMenuTab.UserList, label: 'Пользователи' },
        { key: ESettingsMenuTab.LegalEntityList, label: 'Юридические лица' },
      ];
};

export const renderUserListSettingsRecords = (resendInvite?: (payload: IInvitationCreatePayload) => void, isSeller?: boolean) => {
  return [
    {
      key: 'abbreviation',
      render: (_, { firstName, lastName, status }) => {
        if (status === EUserStatus.NotActive) {
          return <IconBackground content={<InfoRedIcon className="icon-info-red" />} color={EColor.PaleRed} />;
        }

        return (
          <IconBackground
            content={<span className="text-profile-icon color-bright-green">{getUserName.initials(lastName, firstName)}</span>}
            color={EColor.PaleGreen}
          />
        );
      },
      width: 40,
    },
    {
      key: 'name',
      render: (_, { firstName, lastName, patronymic, email }) => {
        return (
          <div>
            <div className="text-controls-accent mb-4">{getUserName.fullName(lastName, firstName, patronymic)}</div>

            <span className="text-tag color-dark-grey">{email}</span>
          </div>
        );
      },
    },
    {
      key: 'role',
      render: (_, { roles }) => {
        const { isAccountAdmin } = getUserRole(roles);

        if (!isAccountAdmin) {
          return null;
        }

        return <UserRoleChip color={EColor.PalePurple} name="Администратор аккаунта" />;
      },
    },
    {
      key: 'subdivision',
      render: (_, { email, firstName, lastName, patronymic, subdivision, status }) => {
        if (status === EUserStatus.NotActive && resendInvite) {
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                className="button-sm secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  resendInvite({ email, firstName, lastName, patronymic });
                }}
              >
                Пригласить повторно
              </Button>
            </div>
          );
        }

        if (isSeller || subdivision) {
          return null;
        }

        return (
          <div className="account-user-list-settings__table-item-subdivision">
            <InfoYellowIcon className="icon-info-yellow" />

            <span className="text-tag-accent">Подразделение не назначено</span>
          </div>
        );
      },
      width: 239,
    },
  ] as ColumnsType<IUser>;
};

export const renderLegalEntityListSettingsRecords = (
  onEditClick: (id: number) => void,
  onDeleteClick: (record: ILegalEntity) => void,
) => {
  return [
    {
      key: 'name',
      render: (_, { name, inn }) => {
        return (
          <div className="account-legal-entity-list-settings__table-item-name">
            <IconBackground content={<LegalEntityIcon className="icon-legal-entity-bright-green" />} color={EColor.PaleGreen} />

            <div>
              <div className="text-controls-accent mb-4">{name}</div>

              <span className="text-tag color-dark-grey">{inn}</span>
            </div>
          </div>
        );
      },
    },
    {
      key: 'actions',
      render: (_, record) => {
        return (
          <Dropdown
            overlayClassName="redesign"
            menu={{
              items: [
                {
                  key: 'edit',
                  label: 'Редактировать',
                  onClick: ({ domEvent }) => {
                    domEvent.stopPropagation();
                    onEditClick(record.id);
                  },
                },
                { type: 'divider' },
                {
                  key: 'delete',
                  label: 'Удалить',
                  className: 'red',
                  onClick: ({ domEvent }) => {
                    domEvent.stopPropagation();
                    onDeleteClick(record);
                  },
                },
              ],
            }}
            placement="bottomRight"
          >
            <Button
              className="button-icon"
              icon={<OptionsIcon className="icon-options-dark-grey" style={{ transform: 'rotate(90deg)' }} />}
            />
          </Dropdown>
        );
      },
      width: 28,
    },
  ] as ColumnsType<ILegalEntity>;
};

export const renderAddressListSettingsRecord = (
  onEdit: (record: IAddress) => void,
  onReassign: (record: IAddress) => void,
  onDelete: (record: IAddress) => void,
) => {
  return [
    {
      key: 'icon',
      render: () => {
        return <IconBackground content={<DeliveryIcon className="icon-delivery-bright-green" />} color={EColor.PaleGreen} />;
      },
      width: 40,
    },
    {
      key: 'name',
      render: (_, { name, isMain }) => {
        return (
          <div>
            <div className="text-controls-accent mb-4">{name}</div>

            {isMain && <span className="text-tag color-dark-grey">Основной адрес</span>}
          </div>
        );
      },
    },
    {
      key: 'actions',
      render: (_, record) => {
        return (
          <Dropdown
            overlayClassName="redesign"
            menu={{
              items: [
                {
                  key: 'edit',
                  label: 'Редактировать',
                  onClick: ({ domEvent }) => {
                    domEvent.stopPropagation();
                    onEdit(record);
                  },
                },
                { type: 'divider' },
                {
                  key: 'reassign',
                  label: 'Сделать основным',
                  onClick: ({ domEvent }) => {
                    domEvent.stopPropagation();
                    onReassign(record);
                  },
                },
                { type: 'divider' },
                {
                  key: 'delete',
                  label: 'Удалить',
                  className: 'red',
                  onClick: ({ domEvent }) => {
                    domEvent.stopPropagation();
                    onDelete(record);
                  },
                },
              ],
            }}
            placement="bottomRight"
          >
            <Button
              className="button-icon"
              icon={<OptionsIcon className="icon-options-dark-grey" style={{ transform: 'rotate(90deg)' }} />}
            />
          </Dropdown>
        );
      },
      width: 28,
    },
  ] as ColumnsType<IAddress>;
};

export const renderStockListSettingsRecords = (onEdit: (id: number) => void, onDelete: (id: number) => void) => {
  return [
    {
      key: 'icon',
      render: () => {
        return <IconBackground content={<DeliveryIcon className="icon-delivery-bright-green" />} color={EColor.PaleGreen} />;
      },
      width: 40,
    },
    {
      key: 'name',
      render: (_, { name, address }) => {
        return (
          <div>
            <div className="text-controls-accent mb-4">{name}</div>

            <span className="text-tag color-dark-grey">{address}</span>
          </div>
        );
      },
    },
    {
      key: 'actions',
      render: (_, { id }) => {
        return (
          <Dropdown
            overlayClassName="redesign"
            menu={{
              items: [
                {
                  key: 'edit',
                  label: 'Редактировать',
                  onClick: ({ domEvent }) => {
                    domEvent.stopPropagation();
                    onEdit(id);
                  },
                },
                { type: 'divider' },
                {
                  key: 'delete',
                  label: 'Удалить склад',
                  className: 'red',
                  onClick: ({ domEvent }) => {
                    domEvent.stopPropagation();
                    onDelete(id);
                  },
                },
              ],
            }}
            placement="bottomRight"
          >
            <Button
              className="button-icon"
              icon={<OptionsIcon className="icon-options-dark-grey" style={{ transform: 'rotate(90deg)' }} />}
            />
          </Dropdown>
        );
      },
      width: 28,
    },
  ] as ColumnsType<IStock>;
};

export const renderContractGeneralSettingsUserListRecords = (onDelete: (id: number) => void) => {
  return [
    {
      key: 'name',
      render: (_, { lastName, firstName, patronymic, email }) => {
        return (
          <div className="contract-general-settings__table-item-name">
            <IconBackground
              content={<span className="text-profile-icon color-dark-grey">{getUserName.initials(lastName, firstName)}</span>}
              color={EColor.LightGrey}
            />

            <div>
              <div className="text-controls-accent mb-4">{getUserName.fullName(lastName, firstName, patronymic)}</div>

              <span className="text-tag color-dark-grey">{email}</span>
            </div>
          </div>
        );
      },
    },
    {
      key: 'action',
      render: (_, { id }) => {
        return <Button className="button-icon" icon={<BinIcon className="icon-bin-dark-grey" />} onClick={() => onDelete(id)} />;
      },
      width: 28,
    },
  ] as ColumnsType<IUserShort>;
};

export const renderBrandDiscountListRecords = (onEdit: (record: IBrandDiscount) => void, onDelete: (id: number) => void) => {
  return [
    {
      key: 'name',
      render: (_, { brand, discountValue }) => {
        return (
          <div className="contract-discount-list-settings__table-item-name">
            <IconBackground content={<DiscountIcon className="icon-discount-bright-green" />} color={EColor.PaleGreen} />

            <div className="text-controls-accent">{`${brand} ${discountValue}%`}</div>
          </div>
        );
      },
    },
    {
      key: 'actions',
      render: (_, record) => {
        return (
          <Dropdown
            overlayClassName="redesign"
            menu={{
              items: [
                {
                  key: 'edit',
                  label: 'Настроить',
                  onClick: () => onEdit(record),
                },
                { type: 'divider' },
                {
                  key: 'delete',
                  label: 'Удалить скидку',
                  className: 'red',
                  onClick: () => onDelete(record.id),
                },
              ],
            }}
            placement="bottomRight"
          >
            <Button
              className="button-icon"
              icon={<OptionsIcon className="icon-options-dark-grey" style={{ transform: 'rotate(90deg)' }} />}
            />
          </Dropdown>
        );
      },
      width: 28,
    },
  ] as ColumnsType<IBrandDiscount>;
};
