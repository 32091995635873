import { IUserShort } from 'common/models';
import { IContract, IContractListState, IContractState } from 'entities/Contract/Contract.models';

export const contractListStateReducers = {
  setList: (state: IContractListState, payload: IContract[]) => ({ ...state, data: payload }),
  setCount: (state: IContractListState, payload: number) => ({ ...state, count: payload }),
  setLoading: (state: IContractListState, payload: boolean) => ({ ...state, loading: payload }),
  addContract: (state: IContractListState, payload: IContract) => ({ ...state, data: [...state.data, payload] }),
  updateContract: (state: IContractListState, payload: IContract) => ({
    ...state,
    data: state.data.map((contract) => (contract.id === payload.id ? payload : contract)),
  }),
  deleteContract: (state: IContractListState, payload: number) => ({
    ...state,
    data: state.data.filter((contract) => contract.id !== payload),
  }),
};

export const contractStateReducers = {
  setContract: (state: IContractState, payload: IContract) => ({ ...state, data: payload }),
  setLoading: (state: IContractState, payload: boolean) => ({ ...state, loading: payload }),
  setUserList: (state: IContractState, payload: IUserShort[]) => ({ ...state, userList: payload }),
  addUser: (state: IContractState, payload: IUserShort) => ({ ...state, userList: [...state.userList, payload] }),
  deleteUser: (state: IContractState, payload: IUserShort) => {
    return {
      ...state,
      userList: state.userList.filter((user) => user.id !== payload.id),
    };
  },
};
