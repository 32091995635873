import React from 'react';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { IFormValue } from 'common/models';
import { ILegalEntity, ILegalEntityCustomerOld } from 'entities/LegalEntity/LegalEntity.models';
import { ISeller } from 'entities/Sellers/Sellers.models';

export const renderLegalEntityListRecords = (onEditClick: (id: number) => void, onDeleteClick: (id: number) => void) => {
  return [
    {
      key: 'name',
      render: (_, record) => {
        return (
          <div>
            <div>{record.name}</div>

            <div className="legal-entity-list__table-item-inn">{`ИНН ${record.inn}`}</div>
          </div>
        );
      },
    },
    {
      key: 'menu',
      render: (_, record) => {
        return (
          <ThreeDotMenu
            items={[
              {
                key: 'edit',
                label: <Button onClick={() => onEditClick(record.id)}>Редактировать</Button>,
                className: 'edit',
              },
              {
                key: 'delete',
                label: <Button onClick={() => onDeleteClick(record.id)}>Удалить</Button>,
                className: 'delete',
              },
            ]}
          />
        );
      },
      width: 64,
    },
  ] as ColumnsType<ILegalEntity>;
};

export const legalCollectionDataToSelectOptions = (data: ILegalEntity[] | null) =>
  data ? data.map((legal) => ({ label: legal.name, value: legal.id })) : [];

export const renderLegalCustomerListRecords = (deleteCustomer: (id: number) => void) => {
  return [
    {
      key: 'customerId',
      dataIndex: 'customerId',
    },
    {
      key: 'divider',
      render: () => '-',
    },
    {
      key: 'sellerName',
      dataIndex: 'sellerName',
    },
    {
      key: 'delete',
      render: (_, record) => {
        return (
          <Button className="btn btn-icon" onClick={() => deleteCustomer(record.id)}>
            x
          </Button>
        );
      },
      width: 38,
    },
  ] as ColumnsType<ILegalEntityCustomerOld>;
};

export const getLegalSellerOptions = (sellerList: ISeller[], customerList: ILegalEntityCustomerOld[]) => {
  return sellerList
    .filter((seller) => !customerList.some((customer) => customer.sellerId === seller.id))
    .map((seller) => ({ label: seller.name, value: seller.id }));
};

export const getLegalPayload = (formValues: IFormValue, customerList: ILegalEntityCustomerOld[]) => ({
  name: formValues.name,
  inn: formValues.inn,
  kpp: formValues.kpp,
  customers: customerList.map((customer) => ({ customerId: customer.customerId, sellerId: customer.sellerId })),
});
