import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { TabNavigation } from 'common/components/TabNavigation';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { ERequestsNavigationTab } from 'common/const/request.enum';
import { ERoute } from 'common/const/route.enum';
import { RootDispatch, RootState } from 'app/store';
import { CreatedNeedListForUser } from 'entities/Need/components/User/CreatedNeedListForUser';
import { CancelledNeedListForUser } from 'entities/Need/components/User/CancelledNeedListForUser';
import { SupplyListForUser } from 'entities/Supply/components/User/SupplyListForUser';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ auth, getCurrentBasket }) => {
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const { tab } = useParams();

  const basketId = auth?.access.basketId;
  const isCancelledNeedList = tab === ERequestsNavigationTab.Cancelled;
  const isSupplyList = tab === ERequestsNavigationTab.SupplyList;
  const header = <div className="text-body color-dark-grey need-list__subtitle">Мои заявки</div>;
  const navigation = (
    <TabNavigation
      items={[
        { label: 'Все заявки', path: ERoute.RequestsForUser },
        { label: 'Отмененные заявки', path: `${ERoute.RequestsForUser}/${ERequestsNavigationTab.Cancelled}` },
        { label: 'Поставки', path: `${ERoute.RequestsForUser}/${ERequestsNavigationTab.SupplyList}` },
      ]}
    />
  );

  useEffect(() => {
    const fetch = async () => {
      if (basketId) {
        await getCurrentBasket(basketId);
      }

      setFetchLoading(false);
    };

    fetch();
  }, [basketId]);

  if (fetchLoading) {
    return <Spin indicator={<SpinIndicator />} />;
  }

  return isCancelledNeedList ? (
    <CancelledNeedListForUser header={header} navigation={navigation} />
  ) : isSupplyList ? (
    <SupplyListForUser header={header} navigation={navigation} />
  ) : (
    <CreatedNeedListForUser header={header} navigation={navigation} />
  );
};

const mapState = (state: RootState) => ({
  auth: state.authState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCurrentBasket: dispatch.basketState.getCurrentBasket,
});

export const RequestsForUser = connect(mapState, mapDispatch)(Component);
