import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { uploadExcelFile } from 'common/helpers/file';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import { basketTransport } from 'entities/Basket/Basket.transport';
import { IBasketListParams, IBasketRequestCreatePayload, IBasketUpdatePayload } from 'entities/Basket/Basket.models';

export const basketListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getBasketList(params: IBasketListParams) {
    dispatch.basketListState.setLoading(true);

    try {
      const response = await basketTransport.getBasketList(params);
      dispatch.basketListState.setList(response.data);
      dispatch.basketListState.setCount(response.count);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.basketListState.setLoading(false);
    }
  },
  async getBasketListPart(params: IBasketListParams) {
    dispatch.basketListState.setLoading(true);

    try {
      const response = await basketTransport.getBasketList(params);
      dispatch.basketListState.setListPart(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.basketListState.setLoading(false);
    }
  },
  async getBasketListSubdivisions() {
    dispatch.basketListState.setLoading(true);

    try {
      const response = await basketTransport.getBasketListSubdivisions();
      dispatch.basketListState.setSubdivisionList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.basketListState.setLoading(false);
    }
  },
});

export const basketStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getCurrentBasket(id: number) {
    dispatch.basketState.setLoading(true);

    try {
      const response = await basketTransport.getBasketById(id);
      dispatch.basketState.setCurrentBasket(response);
      return response;
    } catch (error) {
      axiosErrorHandler(error);
      return null;
    } finally {
      dispatch.basketState.setLoading(false);
    }
  },
  async getBasketById(id: number) {
    dispatch.basketState.setLoading(true);

    try {
      const response = await basketTransport.getBasketById(id);
      dispatch.basketState.setBasket(response);
      return response;
    } catch (error) {
      axiosErrorHandler(error);
      return null;
    } finally {
      dispatch.basketState.setLoading(false);
    }
  },
  async updateBasket(payload: IBasketUpdatePayload) {
    dispatch.basketState.setLoading(true);

    try {
      const response = await basketTransport.updateBasket(payload);
      dispatch.basketState.setBasket(response);
      dispatch.basketState.onSuccess(payload);
      return response;
    } catch (error) {
      axiosErrorHandler(error);
      return null;
    } finally {
      dispatch.basketState.setLoading(false);
    }
  },
  async createBasketRequest(payload: IBasketRequestCreatePayload) {
    dispatch.basketState.setLoading(true);

    try {
      const response = await basketTransport.createBasketRequest(payload);
      dispatch.basketState.setBasket(response);
      dispatch.basketState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.basketState.setLoading(false);
    }
  },
  async uploadBasketExcel(id: number) {
    dispatch.basketState.setLoading(true);

    try {
      const response = await basketTransport.uploadBasketExcel(id);
      uploadExcelFile(response, `basket_${id}`);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.basketState.setLoading(false);
    }
  },
});
