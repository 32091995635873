import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Drawer, Form, InputNumber } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { SelectWithSearch } from 'common/components/SelectWithSearch';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { rules } from 'common/helpers/form.helper';
import { IFormValue } from 'common/models';
import { ReactComponent as PercentageIcon } from 'app/assets/images/redesign/percentage.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { IBrandDiscount, IBrandDiscountUpdatePayload } from 'entities/Discount/Discount.models';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  brands: string[];
  brandDiscount: IBrandDiscount | null;
  onClose: () => void;
  onCreate: (brand: string, discountValue: number, onSuccess: () => void) => void;
  onUpdate: (payload: IBrandDiscountUpdatePayload) => void;
  onDelete: (id: number, onSuccess: () => void) => void;
}

export const BrandDiscountCreateCard: FC<IComponentProps> = (props) => {
  const { open, loading, brands, brandDiscount, onClose, onCreate, onUpdate, onDelete } = props;

  const [payload, setPayload] = useState<IBrandDiscountUpdatePayload | null>(null);
  const [form] = useForm();

  const isEditMode = !!brandDiscount?.id;
  const brandOptions = useMemo(() => brands.map((brand) => ({ label: brand, value: brand })), [brands]);

  const handleClose = () => {
    if (loading) {
      return;
    }

    form.resetFields();
    onClose();
  };

  const handleDelete = () => {
    if (brandDiscount) {
      onDelete(brandDiscount.id, handleClose);
    }
  };

  const onFormValuesChange = (value: IFormValue) => {
    setPayload((prev) => (prev ? { ...prev, ...value } : null));
  };

  const onCreateSubmit = ({ brand, discountValue }: IFormValue) => {
    onCreate(brand, discountValue, handleClose);
  };

  const onUpdateSubmit = () => {
    if (payload) {
      onUpdate({ ...payload, onSuccess: handleClose });
    }
  };

  useEffect(() => {
    if (brandDiscount) {
      setPayload({ id: brandDiscount.id });
      form.setFieldsValue({
        brand: brandDiscount.brand,
        discountValue: brandDiscount.discountValue,
      });
    }
  }, [brandDiscount]);

  return (
    <Drawer
      rootClassName="redesign drawer brand-discount-create-card"
      destroyOnClose
      width={720}
      open={open}
      onClose={handleClose}
      footer={[
        <Button key="edit" className="button-lg primary" loading={loading} onClick={form.submit}>
          Сохранить изменения
        </Button>,
        isEditMode ? (
          <Button
            key="delete"
            className="button-lg deleting"
            icon={<BinIcon className="icon-bin-pale-red" />}
            loading={loading}
            onClick={handleDelete}
          >
            Удалить
          </Button>
        ) : null,
      ]}
    >
      <div className="drawer__body">
        <div className="drawer__title mb-52">Настроить скидку</div>

        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={isEditMode ? onUpdateSubmit : onCreateSubmit}
          onValuesChange={onFormValuesChange}
        >
          <Form.Item className="mb-32" label="Бренд*" name="brand" rules={[rules.required()]}>
            <SelectWithSearch placeholder={EPlaceholder.SelectBrand} options={brandOptions} />
          </Form.Item>

          <Form.Item className="mb-32" label="Скидка на товары*" name="discountValue" rules={[rules.required()]}>
            <InputNumber placeholder="0" suffix={<PercentageIcon className="icon-percentage-dark-grey" />} controls={false} />
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
};
