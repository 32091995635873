import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { EFormFieldMessage } from 'common/const/form.enum';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { rules } from 'common/helpers/form.helper';
import { ReactComponent as EyeOnIcon } from 'app/assets/images/redesign/eyeOn.svg';
import { ReactComponent as EyeOffIcon } from 'app/assets/images/redesign/eyeOff.svg';

interface IComponentProps {
  className?: string;
  label: string;
  newPasswordName: string;
}

export const NewPasswordConfirmFormField: FC<IComponentProps> = ({ className, label, newPasswordName }) => {
  return (
    <Form.Item
      className={className}
      label={label}
      name="passwordConfirm"
      dependencies={[newPasswordName]}
      rules={[
        rules.required(),
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue(newPasswordName) === value) {
              return Promise.resolve();
            }

            return Promise.reject(new Error(EFormFieldMessage.PasswordMismatch));
          },
        }),
      ]}
    >
      <Input.Password
        placeholder={EPlaceholder.RepeatNewPassword}
        iconRender={(visible) => (visible ? <EyeOnIcon /> : <EyeOffIcon />)}
      />
    </Form.Item>
  );
};
