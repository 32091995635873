import React, { FC } from 'react';
import { Chip } from 'common/components/Chip';
import { EColor } from 'common/const/color.enum';
import { IUserListParams } from 'entities/User/User.models';

interface IComponentProps {
  params: IUserListParams;
  onParamsChange: (params: IUserListParams) => void;
}

export const AccountUserListSettingsFilterChips: FC<IComponentProps> = ({ params, onParamsChange }) => {
  const show = params.status || params.withoutSubdivision;

  if (!show) {
    return null;
  }

  return (
    <div className="chip-list mb-20">
      {params.status && (
        <Chip
          color={EColor.BrightGreen}
          name="Пользователи"
          value="Не активированы"
          onClear={() => onParamsChange({ ...params, status: undefined })}
        />
      )}

      {params.withoutSubdivision && (
        <Chip
          color={EColor.BrightGreen}
          name="Пользователи"
          value="Не привязаны к подразделению"
          onClear={() => onParamsChange({ ...params, withoutSubdivision: undefined })}
        />
      )}

      {params.status && params.withoutSubdivision && (
        <Chip
          color={EColor.LightGrey}
          name="Очистить всё"
          onClear={() => onParamsChange({ ...params, status: undefined, withoutSubdivision: undefined })}
        />
      )}
    </div>
  );
};
