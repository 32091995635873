import { IDelivery } from 'entities/Delivery/Delivery.models';
import { IStock } from 'entities/Stock/Stock.models';

export const toDeliveryData = (deliveryCities: string[], stockCities: IDelivery[]) => {
  return deliveryCities.map((city) => ({ city, stocks: stockCities.filter((stock) => stock.city === city) }));
};

export const stockListToSelectOptions = (stockList: IStock[], stockCityList: IDelivery[]) => {
  const stockIds = stockCityList.map((stock) => stock.stockId);

  return stockList
    .filter((stock) => !stockIds.includes(stock.id))
    .map((stock) => ({ label: stock.displayName, value: stock.id }));
};
