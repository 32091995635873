import React, { FC, useMemo, useState } from 'react';
import { Button, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { connect } from 'react-redux';
import { WarningCard } from 'common/components/WarningCard';
import { InventoryCount } from 'common/components/InventoryCount';
import { Chip } from 'common/components/Chip';
import { EMode } from 'common/const/common.enum';
import { EColor } from 'common/const/color.enum';
import { showMessage } from 'common/helpers/message.helper';
import { countFormatter } from 'common/helpers/formatter.helper';
import { useWorkspaceContext } from 'common/hooks/useWorkspaceContext';
import { ReactComponent as DocumentIcon } from 'app/assets/images/redesign/document.svg';
import { ReactComponent as OptionsIcon } from 'app/assets/images/redesign/options.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { WorkspacePositionInventoryLimit } from 'entities/Workspace/components/WorkspacePositionInventoryLimit';
import { getWorkspacePositionName, mapWorkspacePositionPropertyListToChips } from 'entities/Workspace/Workspace.helper';
import { IWorkspacePosition } from 'entities/Workspace/Workspace.models';

interface IComponentProps {
  position: IWorkspacePosition;
  editable?: boolean;
  showPresets?: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    position,
    editable = true,
    showPresets = true,
    // state
    workspacePositionLoading,
    // dispatch
    getPropertyListByCategoryId,
    getGoodsList,
    getWorkspacePositionById,
    deleteWorkspacePosition,
    decreaseGroupedWorkspaceCatalogPositionsCount,
  } = props;

  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);

  const { setOpenPositionCard, setOpenEditPositionCard, setEditPositionCardMode } = useWorkspaceContext();

  const chips = useMemo(() => mapWorkspacePositionPropertyListToChips(position.properties), [position.properties]);

  const onCardClick = async () => {
    setOpenEditPositionCard(false);
    await getWorkspacePositionById(position.id);
    await getGoodsList({
      categoryId: position.categoryId,
      positions: position.properties.map((property) => {
        return {
          propertyId: property.propertyId,
          values: property.values,
          range: property.range,
        };
      }),
    });
    setOpenPositionCard(true);
  };

  const onEditClick = async () => {
    setOpenEditPositionCard(false);
    await getWorkspacePositionById(position.id);
    await getPropertyListByCategoryId(position.categoryId);
    setEditPositionCardMode(EMode.Edit);
    setOpenEditPositionCard(true);
  };

  const onCopyClick = async () => {
    setOpenEditPositionCard(false);
    await getWorkspacePositionById(position.id);
    await getPropertyListByCategoryId(position.categoryId);
    setEditPositionCardMode(EMode.Copy);
    setOpenEditPositionCard(true);
  };

  const onDeleteConfirm = () => {
    deleteWorkspacePosition({
      id: position.id,
      onSuccess: () => {
        setOpenWarningCard(false);
        setOpenEditPositionCard(false);
        decreaseGroupedWorkspaceCatalogPositionsCount(position.categoryId);
        showMessage({ content: 'Позиция удалена из карты оснащения.', icon: <BinIcon className="icon-bin-dark-grey" /> });
      },
    });
  };

  const getMenuItems = () => {
    const items: MenuProps['items'] = [
      {
        key: 'edit',
        label: 'Редактировать позицию',
        onClick: ({ domEvent }) => {
          domEvent.stopPropagation();
          onEditClick();
        },
      },
      { type: 'divider' },
      {
        key: 'copy',
        label: 'Дублировать позицию',
        onClick: ({ domEvent }) => {
          domEvent.stopPropagation();
          onCopyClick();
        },
      },
      { type: 'divider' },
      {
        key: 'delete',
        label: 'Удалить из карты',
        onClick: ({ domEvent }) => {
          domEvent.stopPropagation();
          setOpenWarningCard(true);
        },
        className: 'red',
      },
    ];

    return items;
  };

  return (
    <>
      <div className="workspace-position-card-min" onClick={onCardClick}>
        <div className="workspace-position-card-min__header">
          <DocumentIcon className="icon-document-dark-grey" />

          <span className="text-controls-accent" style={{ flex: 1 }}>
            {getWorkspacePositionName(position)}
          </span>

          <InventoryCount count={position.inventoryCount} limit={position.inventoryLimit} />

          {editable && (
            <Dropdown overlayClassName="redesign" menu={{ items: getMenuItems() }} placement="bottomRight">
              <Button
                className="button-icon"
                icon={<OptionsIcon className="icon-options-dark-grey" style={{ transform: 'rotate(90deg)' }} />}
              />
            </Dropdown>
          )}
        </div>

        <WorkspacePositionInventoryLimit
          content={`Норма хранения: ${
            position.inventoryLimit === undefined || position.inventoryLimit === null
              ? 'Не установлена'
              : countFormatter(position.inventoryLimit)
          }`}
        />

        {showPresets && (
          <div className="chip-list">
            <Chip color={EColor.PalePurple} name="Категория" value={position.categoryName} />

            {chips.map((chip) => {
              if (!chip) {
                return null;
              }

              return (
                <Chip
                  key={chip.id}
                  color={chip.isRequiredForPresets ? EColor.PaleBlue : EColor.LightGrey}
                  name={chip.name}
                  value={chip.value}
                />
              );
            })}
          </div>
        )}
      </div>

      <WarningCard
        open={openWarningCard}
        content="Вы уверены, что хотите удалить позицию из карты оснащения?"
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Удалить"
        loading={workspacePositionLoading}
        onClose={() => setOpenWarningCard(false)}
        onConfirm={onDeleteConfirm}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  workspacePositionLoading: state.workspacePositionState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getPropertyListByCategoryId: dispatch.propertyListState.getPropertyListByCategoryId,
  getGoodsList: dispatch.goodsListState.getGoodsList,
  getWorkspacePositionById: dispatch.workspacePositionState.getWorkspacePositionById,
  deleteWorkspacePosition: dispatch.workspacePositionState.deleteWorkspacePosition,
  decreaseGroupedWorkspaceCatalogPositionsCount: dispatch.groupedWorkspaceState.decreasePositionsCount,
});

export const WorkspacePositionCardMin = connect(mapState, mapDispatch)(Component);
