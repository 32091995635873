import React from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ESettingsMenuTab } from 'common/const/settings.enum';
import { RootState } from 'app/store';
import { getSettingsNavItems } from 'entities/Settings/Settings.helper';
import { AccountSettings } from 'entities/Settings/components/AccountSettings';
import { SubdivisionsSettings } from 'entities/Settings/components/SubdivisionsSettings';
import { UsersSettings } from 'entities/Settings/components/UsersSettings';
import { IntegrationSetting } from 'entities/Settings/components/IntegrationSettings';
import { LegalEntityList } from 'entities/LegalEntity/components/LegalEntityList';
import { DiscountList } from 'entities/Discount/components/DiscountList';
import { Discount } from 'entities/Discount/components/Discount';
import { StockList } from 'entities/Stock/components/StockList';

type AllType = ReturnType<typeof mapState>;

export const SettingsComponent: React.FC<AllType> = ({ authState, currentUser }) => {
  const { data: auth } = authState;

  const { tab, id } = useParams();

  const isSeller = auth?.access.isSeller;
  const roles = currentUser?.roles;
  const items = getSettingsNavItems(roles, isSeller);

  const getSettingsContent = () => {
    switch (tab) {
      case ESettingsMenuTab.Account: {
        return <AccountSettings />;
      }
      case ESettingsMenuTab.UserList: {
        return <UsersSettings />;
      }
      case ESettingsMenuTab.Subdivisions: {
        return <SubdivisionsSettings />;
      }
      case ESettingsMenuTab.Integration: {
        return <IntegrationSetting />;
      }
      case ESettingsMenuTab.LegalEntityList: {
        return <LegalEntityList />;
      }
      case ESettingsMenuTab.Discounts: {
        return id ? <Discount id={Number(id)} /> : <DiscountList />;
      }
      case ESettingsMenuTab.Stocks: {
        return <StockList />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className="settings" style={{ padding: 40 }}>
      {/* @ts-ignore */}
      <Menu className="workplace-nav" items={items} selectedKeys={[tab]} />

      <div className="settings__content" style={{ flex: 1 }}>
        {getSettingsContent()}
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  authState: state.authState,
  currentUser: state.userState.currentUser,
});

export const SettingsOld = connect(mapState)(SettingsComponent);
