import React, { FC, useEffect, useMemo, useState } from 'react';
import { Menu, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { ESettingsMenuTab, ESettingsTab } from 'common/const/settings.enum';
import { ERoute } from 'common/const/route.enum';
import { RootDispatch, RootState } from 'app/store';
import { AccountSettingsName } from 'entities/Settings/components/AccountSettings/AccountSettingsName';
import { AccountContractsSettings } from 'entities/Settings/components/AccountSettings/Contracts/AccountContractsSettings';
import { AccountUserListSettings } from 'entities/Settings/components/AccountSettings/UserList/AccountUserListSettings';
import { AccountLegalEntityListSettings } from 'entities/Settings/components/AccountSettings/LegalEntityList/AccountLegalEntityListSettings';
import { AccountStockListSettings } from 'entities/Settings/components/AccountSettings/StockList/AccountStockListSettings';
import { AccountIntegrationsSettings } from 'entities/Settings/components/AccountSettings/Integrations/AccountIntegrationsSettings';
import { getAccountSettingsMenuItems } from 'entities/Settings/Settings.helper';

interface IComponentProps {
  isSeller?: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const { isSeller, currentUser, account, accountLoading, getAccountById, updateAccount, updateAccountName } = props;

  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const { menu } = useParams();
  const navigate = useNavigate();

  const accountId = currentUser?.account?.id;

  const header = useMemo(() => {
    return (
      <>
        <div className="text-body color-dark-grey mb-20">Настройки аккаунта</div>

        <AccountSettingsName account={account} loading={accountLoading} onAccountUpdate={updateAccountName} />

        <Menu
          items={getAccountSettingsMenuItems(isSeller)}
          selectedKeys={[menu ? menu : isSeller ? ESettingsMenuTab.UserList : ESettingsMenuTab.Contracts]}
          onClick={({ key }) => navigate(`${ERoute.Settings}/${ESettingsTab.Account}/${key}`)}
          mode="horizontal"
        />
      </>
    );
  }, [account, accountLoading, menu]);

  const renderContent = () => {
    switch (menu) {
      case ESettingsMenuTab.UserList: {
        return <AccountUserListSettings header={header} account={account} isSeller={isSeller} />;
      }
      case ESettingsMenuTab.LegalEntityList: {
        return <AccountLegalEntityListSettings header={header} />;
      }
      case ESettingsMenuTab.StockList: {
        return <AccountStockListSettings header={header} />;
      }
      case ESettingsMenuTab.Integrations: {
        return <AccountIntegrationsSettings header={header} />;
      }
      default: {
        return <AccountContractsSettings header={header} account={account} onAccountUpdate={updateAccount} />;
      }
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (accountId) {
        await getAccountById(accountId);
      }

      setFetchLoading(false);
    };

    fetch();
  }, [accountId]);

  if (fetchLoading) {
    return (
      <div className="account-settings">
        <Spin indicator={<SpinIndicator />} />
      </div>
    );
  }

  return <div className="account-settings">{renderContent()}</div>;
};

const mapState = (state: RootState) => ({
  currentUser: state.userState.currentUser,
  account: state.accountState.data,
  accountLoading: state.accountState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getAccountById: dispatch.accountState.getAccountById,
  updateAccount: dispatch.accountState.updateAccount,
  updateAccountName: dispatch.accountState.updateAccountName,
});

export const AccountSettings = connect(mapState, mapDispatch)(Component);
