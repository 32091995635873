import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { rules } from 'common/helpers/form.helper';
import { EFormFieldMessage } from 'common/const/form.enum';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';
import { IInvitationCreatePayload } from 'entities/Invitation/Invitation.models';

interface IComponentProps {
  error: string | null;
  loading: boolean;
  onSendInvite: (values: IInvitationCreatePayload) => void;
}

export const AccountUserListSettingsAddUserButton: FC<IComponentProps> = ({ error, loading, onSendInvite }) => {
  const [openInviteForm, setOpenInviteForm] = useState<boolean>(false);
  const [form] = useForm();

  const onClose = () => {
    if (loading) {
      return;
    }

    setOpenInviteForm(false);
    form.resetFields();
  };

  const onSubmit = (values: IInvitationCreatePayload) => {
    onSendInvite({ ...values, onSuccess: onClose });
  };

  useEffect(() => {
    if (error) {
      form.setFields([{ name: 'email', errors: [error] }]);
    }
  }, [error]);

  return (
    <>
      <Tooltip title="Новый пользователь" placement="bottom">
        <Button
          className="button-circle primary"
          icon={<PlusIcon className="icon-plus-white" />}
          onClick={() => setOpenInviteForm(true)}
        />
      </Tooltip>

      <Drawer
        rootClassName="redesign drawer account-user-list-settings-add-user-button__card"
        open={openInviteForm}
        width={720}
        onClose={onClose}
        destroyOnClose
        footer={
          <Button className="button-lg primary" onClick={form.submit} loading={loading}>
            Отправить приглашение
          </Button>
        }
      >
        <div className="drawer__body">
          <div className="drawer__title mb-52">Пригласить пользователя</div>

          <Form form={form} layout="vertical" requiredMark={false} onFinish={onSubmit}>
            <Form.Item
              className="mb-32"
              label="Электронная почта*"
              name="email"
              rules={[rules.required(), rules.type('email', EFormFieldMessage.InvalidEmailFormat)]}
            >
              <Input placeholder={EPlaceholder.EnterEmailAddress} />
            </Form.Item>

            <Form.Item className="mb-32" label="Фамилия*" name="lastName" rules={[rules.required()]}>
              <Input placeholder={EPlaceholder.EnterLastName} />
            </Form.Item>

            <Form.Item className="mb-32" label="Имя*" name="firstName" rules={[rules.required()]}>
              <Input placeholder={EPlaceholder.EnterFirstName} />
            </Form.Item>

            <Form.Item className="mb-32" label="Отчество" name="patronymic">
              <Input placeholder={EPlaceholder.EnterPatronymic} />
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    </>
  );
};
