import React, { Key } from 'react';
import type { DataNode } from 'antd/es/tree';
import { stringToNumber } from 'common/helpers/common.helper';
import { ITreeDataNode } from 'common/models';
import { ReactComponent as SuccessIcon } from 'app/assets/images/success.svg';

export const dataToTree = <T extends { name: string; id: number; parentId?: number }>(data?: T[]) => {
  const itemMap = new Map<number, DataNode>();

  // Create a map of elements by element id
  data?.forEach((item) => {
    const transformedItem: DataNode = {
      title: item.name,
      key: item.id,
      children: [],
      icon: ({ selected }) => (selected ? <SuccessIcon /> : null),
    };

    itemMap.set(item.id, transformedItem);
  });

  const treeData: DataNode[] = [];

  // Building a tree using parentId
  data?.forEach((item) => {
    const transformedItem = itemMap.get(item.id);

    if (item.parentId === null && transformedItem) {
      // Top level element, add it to the result
      treeData.push({ ...transformedItem, className: 'root' });
    } else {
      // Find the parent and add the current element to its children
      const parentItem = itemMap.get(item.parentId as number);

      if (parentItem && transformedItem) {
        parentItem.children?.push(transformedItem);
      }
    }
  });

  return treeData;
};

export const generateTreeNodeKeyAndSort = (nodes: ITreeDataNode[] = [], parentKey = '') => {
  nodes.sort((a, b) => (a.title as string).localeCompare(b.title as string));

  nodes.forEach((node) => {
    node.key = parentKey ? `${parentKey}-${node.key}` : node.key;
    if (node.children?.length === 0) {
      delete node.children;
      node.isLeaf = true;
    } else {
      generateTreeNodeKeyAndSort(node.children, node.key);
    }
  });
};

export const filterTreeDataByKey = (nodes: ITreeDataNode[], key: Key): ITreeDataNode[] => {
  return nodes.reduce<ITreeDataNode[]>((acc, node) => {
    if (node.key === key) {
      return acc;
    }

    const children = node.children ? filterTreeDataByKey(node.children, key) : undefined;
    acc.push(children ? { ...node, children } : node);

    return acc;
  }, []);
};

export const filterTreeDataBySearch = (nodes: ITreeDataNode[], search: string): Key[] => {
  const lowerValue = search.toLowerCase();

  return nodes.reduce<Key[]>((keys, node) => {
    if ((node.title as string).toLowerCase().includes(lowerValue)) {
      keys.push(node.key);
    }

    if (node.children) {
      keys.push(...filterTreeDataBySearch(node.children, search));
    }
    return keys;
  }, []);
};

const highlightText = (text: string, search: string) => {
  if (!search) {
    return text;
  }

  const lowerSearch = search.toLowerCase();
  const regex = new RegExp(`(${lowerSearch})`, 'gi');

  return (
    <>
      {text.split(regex).map((part, i) =>
        part.toLowerCase() === lowerSearch ? (
          <span key={i} style={{ color: '#df4058' }}>
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </>
  );
};

export const highlightTreeTitle = (nodes: ITreeDataNode[], search: string): ITreeDataNode[] =>
  nodes.map((node) => {
    return {
      ...node,
      title: highlightText(node.title as string, search),
      children: node.children ? highlightTreeTitle(node.children, search) : undefined,
    };
  });

export const getTreeNodeParentKey = (key: Key, tree: ITreeDataNode[]): Key | null => {
  for (const node of tree) {
    if (node.children?.some((child) => child.key === key)) {
      return node.key;
    }

    const parentKey = getTreeNodeParentKey(key, node.children || []);

    if (parentKey) {
      return parentKey;
    }
  }
  return null;
};

export const getSelectedKeyFromTreeKey = (key: Key) => {
  return (key as string).split('-').pop() as string;
};

export const transformIdToTreeKey = (id: number, treeKeys: Key[]) => {
  const key = treeKeys.find((item) => (item as string).endsWith(`-${id}`) || item === String(id));

  return key ? key : '';
};

export const findTreeParentKeysByCheckedKeys = (checkedKeys: Key[]) => {
  const parentKeys = checkedKeys
    .flatMap((key) => {
      const parts = (key as string).split('-');
      return parts.slice(0, -1).map((_, index) => parts.slice(0, index + 1).join('-'));
    })
    .filter(Boolean);

  return [...new Set(parentKeys)] as Key[];
};

export const transformTreeKeyToId = (key: Key) => {
  return stringToNumber((key as string).split('-').pop() as string);
};
