import React, { FC, ReactNode } from 'react';
import { ReactComponent as InfoGreenIcon } from 'app/assets/images/redesign/info-green.svg';
import { ReactComponent as InfoRedIcon } from 'app/assets/images/redesign/info-red.svg';
import { ReactComponent as InfoYellowIcon } from 'app/assets/images/redesign/info-yellow.svg';

interface IComponentProps {
  type: 'success' | 'error' | 'warning';
  title?: string;
  description: string;
  footer?: ReactNode[];
}

export const Notification: FC<IComponentProps> = ({ type, title, description, footer }) => {
  return (
    <div className="notification">
      {type === 'success' && <InfoGreenIcon className="icon-info-green" />}

      {type === 'error' && <InfoRedIcon className="icon-info-red" />}

      {type === 'warning' && <InfoYellowIcon className="icon-info-yellow" />}

      <div className="notification__container">
        {title && <div className="text-h4 color-white mb-12">{title}</div>}

        <div className="text-tag color-white">{description}</div>

        {footer && <div className="notification__footer">{footer.map((item) => item)}</div>}
      </div>
    </div>
  );
};
