import React, { FC } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ERoute } from 'common/const/route.enum';
import { AuthCardLogo } from 'entities/Auth/components/AuthCardLogo';
import { AuthCardFooter } from 'entities/Auth/components/AuthCardFooter';

interface IComponentProps {
  email: string;
}

export const RestoreSuccessCard: FC<IComponentProps> = ({ email }) => {
  const navigate = useNavigate();

  return (
    <div className="auth-card">
      <AuthCardLogo />

      <div className="auth-card__content">
        <div className="auth-card__content-success-container">
          <div
            style={{ textAlign: 'center' }}
            className="text-h1-drawers"
          >{`Ссылка для восстановления пароля отправлена на почту ${email}`}</div>
        </div>
      </div>

      <AuthCardFooter
        content={
          <Button className="button-sm secondary" onClick={() => navigate(ERoute.Login)}>
            На страницу входа
          </Button>
        }
      />
    </div>
  );
};
