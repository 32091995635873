import React, { FC, useEffect } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { rules } from 'common/helpers/form.helper';
import { IFormValue } from 'common/models';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ILegalEntity } from 'entities/LegalEntity/LegalEntity.models';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  legalEntity: ILegalEntity | null;
  onClose: () => void;
  onSave: (id: number, value: string, onSuccess?: () => void) => void;
}

export const LegalEntityCustomerIdCreateModal: FC<IComponentProps> = ({ open, loading, legalEntity, onClose, onSave }) => {
  const [form] = useForm();

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const onSubmit = ({ customerId }: IFormValue) => {
    if (legalEntity) {
      onSave(legalEntity.id, customerId, handleClose);
    }
  };

  useEffect(() => {
    if (open && legalEntity?.customerId) {
      form.setFieldsValue({ customerId: legalEntity.customerId });
    }
  }, [open, legalEntity]);

  return (
    <Modal
      rootClassName="redesign modal"
      width={400}
      title={`ID покупателя «${legalEntity?.name}»`}
      centered
      open={open}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" className="button-sm secondary" onClick={handleClose} loading={loading}>
          Отмена
        </Button>,
        <Button key="save" className="button-sm primary" loading={loading} onClick={form.submit}>
          Сохранить
        </Button>,
      ]}
    >
      <Form form={form} requiredMark={false} layout="vertical" onFinish={onSubmit}>
        <Form.Item label="ID" name="customerId" rules={[rules.required()]}>
          <Input
            placeholder={EPlaceholder.AddCustomerId}
            allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
