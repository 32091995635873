import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  IAddressCreatePayload,
  IAddressDeletePayload,
  IAddressListParams,
  IAddressSuggestionListParams,
  IAddressUpdatePayload,
} from 'entities/Address/Address.models';
import { addressesTransport } from 'entities/Address/Address.transport';

export const addressSuggestionListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getAddressSuggestionList(params: IAddressSuggestionListParams) {
    dispatch.addressSuggestionListState.setLoading(true);

    try {
      const response = await addressesTransport.getAddressSuggestionList(params);
      dispatch.addressSuggestionListState.setList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.addressSuggestionListState.setLoading(false);
    }
  },
});

export const addressListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getAddressList(params: IAddressListParams) {
    dispatch.addressListState.setLoading(true);

    try {
      const response = await addressesTransport.getAddressList(params);
      dispatch.addressListState.setList(response.data);
      dispatch.addressListState.setCount(response.count);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.addressListState.setLoading(false);
    }
  },
});

export const addressStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async createAddress(payload: IAddressCreatePayload) {
    dispatch.addressState.setLoading(true);

    try {
      const response = await addressesTransport.createAddress(payload);
      dispatch.addressListState.addAddress(response);
      dispatch.addressState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.addressState.setLoading(false);
    }
  },
  async updateAddress(payload: IAddressUpdatePayload) {
    dispatch.addressState.setLoading(true);

    try {
      const response = await addressesTransport.updateAddress(payload);
      dispatch.addressListState.updateAddress(response);
      dispatch.addressState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.addressState.setLoading(false);
    }
  },
  async reassignAddress(payload: IAddressUpdatePayload) {
    dispatch.addressState.setLoading(true);

    try {
      const response = await addressesTransport.updateAddress(payload);
      dispatch.addressListState.reassignAddress(response);
      dispatch.addressState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.addressState.setLoading(false);
    }
  },
  async deleteAddress(payload: IAddressDeletePayload) {
    dispatch.addressState.setLoading(true);

    try {
      const response = await addressesTransport.deleteAddress(payload);
      dispatch.addressListState.deleteAddress(response.id);
      dispatch.addressState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.addressState.setLoading(false);
    }
  },
});
