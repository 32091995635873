import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { EMessage } from 'common/const/message.enum';
import { EModalCollectionKey } from 'common/const/modal.enum';
import { RootDispatch, RootState } from 'app/store';
import { CreateDiscountModal } from 'entities/Modal/components/CreateDiscountModal';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export const DiscountListComponent: FC<AllType> = (props) => {
  const { openCreateDiscountModal, showModal, hideModal } = props;

  const [discountName, setDiscountName] = useState<string>('');

  const onCreateNewDiscountClick = () => {
    showModal(EModalCollectionKey.CreateDiscount);
  };

  const onCreateDiscountModalCancelClick = () => {
    setDiscountName('');
    hideModal(EModalCollectionKey.CreateDiscount);
  };

  return (
    <div className="discount-list">
      <div className="discount-list__title">Скидки</div>

      <Button className="btn btn-primary" onClick={onCreateNewDiscountClick}>
        <PlusOutlined />

        <span>Новый вид скидок</span>
      </Button>

      <Table
        className="discount-list__table"
        dataSource={[]}
        columns={[]}
        showHeader={false}
        pagination={false}
        locale={{ emptyText: EMessage.DiscountListIsEmpty }}
      />

      <CreateDiscountModal
        open={openCreateDiscountModal}
        loading={false}
        name={discountName}
        changeName={setDiscountName}
        onCancelClick={onCreateDiscountModalCancelClick}
        onAddClick={onCreateDiscountModalCancelClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  openCreateDiscountModal: state.modalCollection.createDiscountModalParams.open,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const DiscountList = connect(mapState, mapDispatch)(DiscountListComponent);
