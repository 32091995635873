import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { EGroupedSupplyListKey } from 'common/const/supply.enum';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  IGroupedSupplyListPayload,
  ISupplyAddressListForSellerPayload,
  ISupplyChangeStatusPayload,
  ISupplyCorrectionPayload,
  ISupplyCreatePayload,
  ISupplyDeletePayload,
  ISupplyLegalListForSellerPayload,
  ISupplyListPayload,
  ISupplyListUpdateDeliveryDatePayload,
  ISupplyParams,
  ISupplySubdivisionListForManagerParams,
  ISupplySubdivisionListForSellerPayload,
  ISupplyUpdatePayload,
  ISupplyUserListForSellerPayload,
  ISupplyUserListParams,
} from 'entities/Supply/Supply.models';
import { supplyTransport } from 'entities/Supply/Supply.transport';

export const supplyListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getSupplyList(payload: ISupplyListPayload) {
    dispatch.supplyListState.setLoading(true);

    try {
      const response = await supplyTransport.getSupplyList(payload);
      dispatch.supplyListState.setList(response.data);
      dispatch.supplyListState.setCount(response.count);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyListState.setLoading(false);
    }
  },
  async getSupplyListPart(payload: ISupplyListPayload) {
    dispatch.supplyListState.setLoading(true);

    try {
      const response = await supplyTransport.getSupplyList(payload);
      dispatch.supplyListState.setListPart(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyListState.setLoading(false);
    }
  },
  async updateSupplyListDeliveryDate(payload: ISupplyListUpdateDeliveryDatePayload) {
    dispatch.supplyListState.setLoading(true);

    try {
      const response = await supplyTransport.updateSupplyListDeliveryDate(payload);
      dispatch.supplyListState.updateList(response.data);
      dispatch.supplyListState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyListState.setLoading(false);
    }
  },
  async changeSupplyListStatus(payload: ISupplyChangeStatusPayload) {
    dispatch.supplyListState.setLoading(true);

    try {
      const response = await supplyTransport.changeSupplyStatus(payload);
      dispatch.supplyListState.updateList(response.data);
      dispatch.supplyListState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyListState.setLoading(false);
    }
  },
  async getSupplyUserList(params: ISupplyUserListParams) {
    dispatch.supplyListState.setLoading(true);

    try {
      const response = await supplyTransport.getSupplyUserList(params);
      dispatch.supplyListState.setUserList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyListState.setLoading(false);
    }
  },
  async getSupplySubdivisionListForManager(params: ISupplySubdivisionListForManagerParams) {
    dispatch.supplyListState.setLoading(true);

    try {
      const response = await supplyTransport.getSupplySubdivisionListForManager(params);
      dispatch.supplyListState.setSubdivisionList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyListState.setLoading(false);
    }
  },
  async getSupplySubdivisionListForSeller(payload: ISupplySubdivisionListForSellerPayload) {
    dispatch.supplyListState.setLoading(true);

    try {
      const response = await supplyTransport.getSupplySubdivisionListForSeller(payload);
      dispatch.supplyListState.setSubdivisionList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyListState.setLoading(false);
    }
  },
  async getSupplyLegalListForSeller(payload: ISupplyLegalListForSellerPayload) {
    dispatch.supplyListState.setLoading(true);

    try {
      const response = await supplyTransport.getSupplyLegalListForSeller(payload);
      dispatch.supplyListState.setLegalList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyListState.setLoading(false);
    }
  },
  async getSupplyAddressListForSeller(payload: ISupplyAddressListForSellerPayload) {
    dispatch.supplyListState.setLoading(true);

    try {
      const response = await supplyTransport.getSupplyAddressListForSeller(payload);
      dispatch.supplyListState.setAddressList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyListState.setLoading(false);
    }
  },
  async getSupplyUserListForSeller(payload: ISupplyUserListForSellerPayload) {
    dispatch.supplyListState.setLoading(true);

    try {
      const response = await supplyTransport.getSupplyUserListForSeller(payload);
      dispatch.supplyListState.setUserList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyListState.setLoading(false);
    }
  },
});

export const groupedSupplyListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getGroupedSupplyList({ key, ...rest }: IGroupedSupplyListPayload) {
    dispatch.groupedSupplyListState.setLoading(true);

    try {
      if (key === EGroupedSupplyListKey.ByDeliveryDate) {
        const response = await supplyTransport.getGroupedByDeliveryDateSupplyList({ ...rest });
        dispatch.groupedSupplyListState.setList(response.data);
        dispatch.groupedSupplyListState.setCount(response.count);
      }

      if (key === EGroupedSupplyListKey.ByUpdatedAt) {
        const response = await supplyTransport.getGroupedByUpdatedAtSupplyList({ ...rest });
        dispatch.groupedSupplyListState.setList(response.data);
        dispatch.groupedSupplyListState.setCount(response.count);
      }
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.groupedSupplyListState.setLoading(false);
    }
  },
  async getGroupedSupplyListPart({ key, ...rest }: IGroupedSupplyListPayload) {
    dispatch.groupedSupplyListState.setLoading(true);

    try {
      if (key === EGroupedSupplyListKey.ByDeliveryDate) {
        const response = await supplyTransport.getGroupedByDeliveryDateSupplyList({ ...rest });
        dispatch.groupedSupplyListState.setListPart(response.data);
      }

      if (key === EGroupedSupplyListKey.ByUpdatedAt) {
        const response = await supplyTransport.getGroupedByUpdatedAtSupplyList({ ...rest });
        dispatch.groupedSupplyListState.setListPart(response.data);
      }
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.groupedSupplyListState.setLoading(false);
    }
  },
  async changeGroupedSupplyListStatus(payload: ISupplyChangeStatusPayload) {
    dispatch.groupedSupplyListState.setLoading(true);

    try {
      const response = await supplyTransport.changeSupplyStatus(payload);
      dispatch.groupedSupplyListState.updateList(response.data);
      dispatch.groupedSupplyListState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.groupedSupplyListState.setLoading(false);
    }
  },
  async updateGropedSupplyListDeliveryDate(payload: ISupplyListUpdateDeliveryDatePayload) {
    dispatch.groupedSupplyListState.setLoading(true);

    try {
      const response = await supplyTransport.updateSupplyListDeliveryDate(payload);
      dispatch.groupedSupplyListState.updateDeliveryDate(response.data);
      dispatch.groupedSupplyListState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.groupedSupplyListState.setLoading(false);
    }
  },
});

export const supplyStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getSupplyById(params: ISupplyParams) {
    dispatch.supplyState.setLoading(true);

    try {
      const response = await supplyTransport.getSupplyById(params.id);
      dispatch.supplyState.setSupply(response);
      dispatch.supplyState.onSuccess(params);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyState.setLoading(false);
    }
  },
  async createSupply(payload: ISupplyCreatePayload) {
    dispatch.supplyState.setLoading(true);

    try {
      const response = await supplyTransport.createSupply(payload);
      dispatch.groupedSupplyListState.addSupply(response.data[0]);
      dispatch.supplyListState.addSupply(response.data[0]);
      dispatch.supplyState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyState.setLoading(false);
    }
  },
  async updateSupply(payload: ISupplyUpdatePayload) {
    dispatch.supplyState.setLoading(true);

    try {
      const response = await supplyTransport.updateSupply(payload);
      dispatch.groupedSupplyListState.updateDeliveryDate([response]);
      dispatch.supplyListState.updateList([response]);
      dispatch.supplyState.setSupply(response);
      dispatch.supplyState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyState.setLoading(false);
    }
  },
  async deleteSupply(payload: ISupplyDeletePayload) {
    dispatch.supplyState.setLoading(true);

    try {
      const response = await supplyTransport.deleteSupply(payload);
      dispatch.supplyListState.deleteSupply(response.id);
      dispatch.supplyState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyState.setLoading(false);
    }
  },
  async changeSupplyStatus(payload: ISupplyChangeStatusPayload) {
    dispatch.supplyState.setLoading(true);

    try {
      const response = await supplyTransport.changeSupplyStatus(payload);
      dispatch.supplyState.setSupply(response.data[0]);
      dispatch.supplyListState.updateList(response.data);
      dispatch.supplyState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyState.setLoading(false);
    }
  },
  async sendRequestSupplyCorrection(payload: ISupplyCorrectionPayload) {
    dispatch.supplyState.setLoading(true);

    try {
      const response = await supplyTransport.sendRequestSupplyCorrection(payload);
      dispatch.supplyState.setSupply(response);
      dispatch.supplyListState.updateList([response]);
      dispatch.supplyState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.supplyState.setLoading(false);
    }
  },
});
