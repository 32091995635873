import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { ICategoryListState, ICategoryState } from 'entities/Category/Category.models';
import { categoryListStateReducers, categoryStateReducers } from 'entities/Category/Category.reducers';
import { categoryListStateEffects, categoryStateEffects } from 'entities/Category/Category.effects';

export const categoryListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
  } as ICategoryListState,
  reducers: categoryListStateReducers,
  effects: categoryListStateEffects,
});

export const categoryState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ICategoryState,
  reducers: categoryStateReducers,
  effects: categoryStateEffects,
});
