import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  IStockListParams,
  IStockCreatePayload,
  IStockDeletePayload,
  IStockUpdatePayload,
  IStockParams,
} from 'entities/Stock/Stock.models';
import { stockTransport } from 'entities/Stock/Stock.transport';

export const stockListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getStockList(params: IStockListParams) {
    dispatch.stockListState.setLoading(true);

    try {
      const response = await stockTransport.getStockList(params);
      dispatch.stockListState.setList(response.data);
      dispatch.stockListState.setCount(response.count);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.stockListState.setLoading(false);
    }
  },
});

export const stockStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getStockById(params: IStockParams) {
    dispatch.stockState.setLoading(true);

    try {
      const response = await stockTransport.getStockById(params.id);
      dispatch.stockState.setStock(response);
      dispatch.stockState.onSuccess(params);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.stockState.setLoading(false);
    }
  },
  async createStock(payload: IStockCreatePayload) {
    dispatch.stockState.setLoading(true);

    try {
      const response = await stockTransport.createStock(payload);
      dispatch.stockListState.addStock(response);
      dispatch.stockState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.stockState.setLoading(false);
    }
  },
  async updateStock(payload: IStockUpdatePayload) {
    dispatch.stockState.setLoading(true);

    try {
      const response = await stockTransport.updateStock(payload);
      dispatch.stockListState.updateStock(response);
      dispatch.stockState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.stockState.setLoading(false);
    }
  },
  async deleteStock(payload: IStockDeletePayload) {
    dispatch.stockState.setLoading(true);

    try {
      const response = await stockTransport.deleteStock(payload);
      dispatch.stockListState.deleteStock(response.id);
      dispatch.stockState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.stockState.setLoading(false);
    }
  },
});
