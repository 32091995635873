import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom';
import { EFormFieldMessage } from 'common/const/form.enum';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { ERoute } from 'common/const/route.enum';
import { rules } from 'common/helpers/form.helper';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { IFormValue } from 'common/models';
import { RootDispatch, RootState } from 'app/store';
import { RestoreSuccessCard } from 'entities/Auth/components/Restore/RestoreSuccessCard';
import { RestorePasswordCard } from 'entities/Auth/components/Restore/RestorePasswordCard';
import { AuthCardLogo } from 'entities/Auth/components/AuthCardLogo';
import { AuthCardFooter } from 'entities/Auth/components/AuthCardFooter';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ authError, authLoading, setAuthError, sendRestorePasswordEmail }) => {
  const [email, setEmail] = useState<string>('');
  const [showSuccessCard, setShowSuccessCard] = useState<boolean>(false);
  const [form] = useForm();
  const navigate = useNavigate();
  const { searchParams } = useSearchParamsHook();

  const hash = searchParams.get('hash');

  const onSubmit = (value: IFormValue) => {
    setAuthError(null);

    sendRestorePasswordEmail({
      email: value.email,
      onSuccess: () => {
        setEmail(value.email);
        setShowSuccessCard(true);
      },
    });
  };

  useEffect(() => {
    if (authError) {
      form.setFields([{ name: 'email', errors: [authError] }]);
    }
  }, [authError]);

  if (hash) {
    return <RestorePasswordCard hash={hash} />;
  }

  if (showSuccessCard) {
    return <RestoreSuccessCard email={email} />;
  }

  return (
    <div className="auth-card">
      <AuthCardLogo />

      <div className="auth-card__content">
        <Form className="auth-card__content-form" form={form} layout="vertical" requiredMark={false} onFinish={onSubmit}>
          <div className="mb-40 auth-card__content-form-title">Восстановление пароля</div>

          <Form.Item
            className="w-100 mb-40"
            label="Ваша почта*"
            name="email"
            rules={[rules.required(true, 'Обязательное поле'), rules.type('email', EFormFieldMessage.InvalidEmailFormat)]}
          >
            <Input placeholder={EPlaceholder.EnterEmailAddress} />
          </Form.Item>

          <div className="auth-card__content-btn-container">
            <Button className="button-lg secondary w-100" loading={authLoading} onClick={() => navigate(ERoute.Login)}>
              Отмена
            </Button>

            <Button className="button-lg primary w-100" loading={authLoading} htmlType="submit">
              Войти
            </Button>
          </div>
        </Form>
      </div>

      <AuthCardFooter content="СПРАУТ. Система управления закупками." />
    </div>
  );
};

const mapState = (state: RootState) => ({
  authError: state.authState.error,
  authLoading: state.authState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setAuthError: dispatch.authState.setError,
  sendRestorePasswordEmail: dispatch.authState.sendRestorePasswordEmail,
});

export const RestoreCard = connect(mapState, mapDispatch)(Component);
