import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { IUIState } from 'entities/UI/UI.models';
import { UIStateReducers } from 'entities/UI/UI.reducers';

export const UIState = createModel<IRootModel>()({
  state: {
    isSidebarOpen: false,
  } as IUIState,
  reducers: UIStateReducers,
});
