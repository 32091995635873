import React, { FC } from 'react';
import { Button } from 'antd';
import { EColor } from 'common/const/color.enum';
import { ReactComponent as CloseGreyIcon } from 'app/assets/images/redesign/close-grey.svg';

interface IComponentProps {
  color: EColor;
  name: string;
  value?: string;
  onClear?: () => void;
}

export const Chip: FC<IComponentProps> = ({ color, name, value, onClear }) => {
  return (
    <div className={`chip ${color}`}>
      <span className="chip__name">{value ? `${name}:` : name}</span>

      {value && <span className="chip__value">{value}</span>}

      {onClear && <Button className="button-icon chip__clear-btn" icon={<CloseGreyIcon />} onClick={onClear} />}
    </div>
  );
};
