import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Navigate } from 'react-router-dom';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { ERoute } from 'common/const/route.enum';
import { EFormFieldError, EFormFieldMessage } from 'common/const/form.enum';
import { rules } from 'common/helpers/form.helper';
import { AUTH_REDIRECT_TIMEOUT_MS } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as EyeOnIcon } from 'app/assets/images/redesign/eyeOn.svg';
import { ReactComponent as EyeOffIcon } from 'app/assets/images/redesign/eyeOff.svg';
import { AuthCardLogo } from 'entities/Auth/components/AuthCardLogo';
import { AuthCardFooter } from 'entities/Auth/components/AuthCardFooter';
import { LoginSuccessCard } from 'entities/Auth/components/Login/LoginSuccessCard';
import { IAuthLoginPayload } from 'entities/Auth/Auth.models';
import { getUserRole } from 'entities/User/User.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ auth, authError, authLoading, addAuth, setAuthError }) => {
  const [showSuccessCard, setShowSuccessCard] = useState<boolean>(false);
  const [canBeRedirected, setCanBeRedirected] = useState<boolean>(true);
  const [form] = useForm();

  const { isSuperAdmin } = getUserRole(auth?.access.roles);

  const onSubmit = async (value: IAuthLoginPayload) => {
    setCanBeRedirected(false);
    setAuthError(null);

    await addAuth({
      ...value,
      onSuccess: () => {
        setShowSuccessCard(true);
        setTimeout(() => setCanBeRedirected(true), AUTH_REDIRECT_TIMEOUT_MS);
      },
    });
  };

  useEffect(() => {
    if (authError) {
      form.setFields([
        {
          name: authError === EFormFieldError.IncorrectPassword ? 'password' : 'email',
          errors: [authError],
        },
      ]);
    }
  }, [authError]);

  if (auth && canBeRedirected) {
    return isSuperAdmin ? <Navigate to={ERoute.AccountList} replace /> : <Navigate to={ERoute.Profile} replace />;
  }

  if (showSuccessCard) {
    return <LoginSuccessCard firstName={auth?.access.firstName} patronymic={auth?.access.patronymic} />;
  }

  return (
    <div className="auth-card">
      <AuthCardLogo />

      <div className="auth-card__content">
        <Form className="auth-card__content-form" form={form} layout="vertical" requiredMark={false} onFinish={onSubmit}>
          <div className="mb-40 auth-card__content-form-title">Пожалуйста, введите вашу почту и пароль</div>

          <Form.Item
            className="w-100"
            label="Логин*"
            name="email"
            rules={[rules.required(), rules.type('email', EFormFieldMessage.InvalidEmailFormat)]}
          >
            <Input placeholder={EPlaceholder.EnterEmailAddress} />
          </Form.Item>

          <Form.Item className="w-100" label="Пароль*" name="password" rules={[rules.required()]}>
            <Input.Password
              placeholder={EPlaceholder.EnterPassword}
              iconRender={(visible) => (visible ? <EyeOnIcon /> : <EyeOffIcon />)}
            />
          </Form.Item>

          <div className="text-secondary-button color-dark-green mb-44">
            Забыли пароль? Восстановите по <a href={ERoute.RestorePassword}>ссылке</a>.
          </div>

          <Button className="button-lg primary w-100" loading={authLoading} htmlType="submit">
            Войти
          </Button>
        </Form>
      </div>

      <AuthCardFooter content="СПРАУТ. Система управления закупками." />
    </div>
  );
};

const mapState = (state: RootState) => ({
  auth: state.authState.data,
  authError: state.authState.error,
  authLoading: state.authState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setAuthError: dispatch.authState.setError,
  addAuth: dispatch.authState.addAuth,
});

export const LoginCard = connect(mapState, mapDispatch)(Component);
