import React, { FC, ReactNode, useMemo } from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { CountBadge } from 'common/components/CountBadge';
import { EInventoryListMenuTab } from 'common/const/inventory.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { getFilterOptions } from 'common/helpers/option.helper';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { DEFAULT_VALUE_0 } from 'common/config';
import { RootState } from 'app/store';
import { AllAvailableInventoryList } from 'entities/Inventory/components/All/AllAvailableInventoryList';
import { AllWriteOffInventoryActionListForUser } from 'entities/Inventory/components/All/AllWriteOffInventoryActionListForUser';
import { AllWriteOffInventoryList } from 'entities/Inventory/components/All/AllWriteOffInventoryList';
import { IUserInventoryStatistics } from 'entities/User/User.models';
import { getUserRole } from 'entities/User/User.helper';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  statistics: IUserInventoryStatistics;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

export const Component: FC<AllType> = (props) => {
  const { header, navigation, statistics, inventoryListSubdivisions, inventoryListUsers, currentUser } = props;

  const { getSearchParam, setSearchParam } = useSearchParamsHook();

  const { inventoryAvailableCount, inventoryWrittenOffCount, writeOffsCount } = statistics;
  const { isAccountAdmin } = getUserRole(currentUser?.roles);
  const tab = getSearchParam(ESearchParams.Tab);
  const isAllWriteOffInventoryActionListForUser = tab === EInventoryListMenuTab.WriteOffActionList;
  const isAllWriteOffInventoryList = tab === EInventoryListMenuTab.WriteOff;
  const subdivisionOptions = getFilterOptions.subdivision(inventoryListSubdivisions);
  const userOptions = getFilterOptions.userWithNull(inventoryListUsers);

  const getMenuItems = () => {
    const items = [
      {
        key: EInventoryListMenuTab.Available,
        label: (
          <>
            Инструменты в наличии
            <CountBadge count={inventoryAvailableCount} />
          </>
        ),
      },
    ];

    if (!isAccountAdmin && writeOffsCount > DEFAULT_VALUE_0) {
      items.push({
        key: EInventoryListMenuTab.WriteOffActionList,
        label: (
          <>
            Запросы о списании
            <CountBadge count={writeOffsCount} />
          </>
        ),
      });
    }

    if (inventoryWrittenOffCount > DEFAULT_VALUE_0) {
      items.push({
        key: EInventoryListMenuTab.WriteOff,
        label: (
          <>
            Списанные инструменты
            <CountBadge count={inventoryWrittenOffCount} />
          </>
        ),
      });
    }

    return items;
  };

  const menu = useMemo(() => {
    return (
      <Menu
        items={getMenuItems()}
        selectedKeys={[tab === null ? EInventoryListMenuTab.Available : tab]}
        onClick={({ key }) => setSearchParam(ESearchParams.Tab, key)}
        mode="horizontal"
      />
    );
  }, [tab, statistics]);

  return isAllWriteOffInventoryList ? (
    <AllWriteOffInventoryList
      header={header}
      navigation={navigation}
      menu={menu}
      subdivisionOptions={subdivisionOptions}
      userOptions={userOptions}
    />
  ) : isAllWriteOffInventoryActionListForUser ? (
    <AllWriteOffInventoryActionListForUser header={header} navigation={navigation} menu={menu} />
  ) : (
    <AllAvailableInventoryList
      header={header}
      navigation={navigation}
      menu={menu}
      subdivisionOptions={subdivisionOptions}
      userOptions={userOptions}
    />
  );
};

const mapState = (state: RootState) => ({
  inventoryListSubdivisions: state.inventoryListState.subdivisionList,
  inventoryListUsers: state.inventoryListState.userList,
  currentUser: state.userState.currentUser,
});

export const AllInventoryList = connect(mapState)(Component);
