import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import { IGoodsCreatePayload, IGoodsListPayload, IGoodsParams, IGoodsUpdatePayload } from 'entities/Goods/Goods.models';
import { goodsTransport } from 'entities/Goods/Goods.transport';

export const goodsListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getGoodsList(payload: IGoodsListPayload) {
    dispatch.goodsListState.setGoodsListLoading(true);

    try {
      const response = await goodsTransport.getGoodsList(payload);
      dispatch.goodsListState.setGoodsList(response.data);
      dispatch.goodsListState.setGoodsListCount(response.count);
      dispatch.goodsListState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.goodsListState.setGoodsListLoading(false);
    }
  },
  async getGoodsListPart(payload: IGoodsListPayload) {
    dispatch.goodsListState.setGoodsListLoading(true);

    try {
      const response = await goodsTransport.getGoodsList(payload);
      dispatch.goodsListState.setGoodsListPart(response.data);
      dispatch.goodsListState.setGoodsListCount(response.count);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.goodsListState.setGoodsListLoading(false);
    }
  },
  async searchGoodsByName(payload: IGoodsListPayload) {
    try {
      const response = await goodsTransport.getGoodsList(payload);
      return response.data;
    } catch (error) {
      axiosErrorHandler(error);
      return [];
    }
  },
});

export const goodsStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getGoodsById(params: IGoodsParams) {
    dispatch.goodsState.setGoodsLoading(true);

    try {
      const response = await goodsTransport.getGoodsById(params);
      dispatch.goodsState.setGoods(response);
      dispatch.goodsState.onSuccess(params);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.goodsState.setGoodsLoading(false);
    }
  },
  async createGoods(payload: IGoodsCreatePayload) {
    dispatch.goodsState.setGoodsLoading(true);

    try {
      const response = await goodsTransport.createGoods(payload);
      dispatch.goodsState.setGoods(response);
      dispatch.goodsState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.goodsState.setGoodsLoading(false);
    }
  },
  async updateGoods(payload: IGoodsUpdatePayload) {
    dispatch.goodsState.setGoodsLoading(true);

    try {
      const response = await goodsTransport.updateGoods(payload);
      dispatch.goodsListState.updateGoodsList(response);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.goodsState.setGoodsLoading(false);
    }
  },
});
