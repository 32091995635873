import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { WarningCard } from 'common/components/WarningCard';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { showMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { LIST_LIMIT_0 } from 'common/config';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { AddressCreateCard } from 'entities/Address/components/AddressCreateCard';
import { IAddress } from 'entities/Address/Address.models';
import { renderAddressListSettingsRecord } from 'entities/Settings/Settings.helper';
import { ISubdivision } from 'entities/Subdivision/Subdivision.models';

interface IComponentProps {
  header: ReactNode;
  subdivision: ISubdivision | null;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    subdivision,
    // state
    addressList,
    addressListLoading,
    addressLoading,
    // dispatch
    getAddressList,
    deleteAddress,
    reassignAddress,
  } = props;

  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const [openAddressCreateCard, setOpenAddressCreateCard] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<IAddress | null>(null);

  const onCreateAddressClick = () => {
    setSelectedAddress(null);
    setOpenAddressCreateCard(true);
  };

  const onEditAddressClick = (record: IAddress) => {
    setSelectedAddress(record);
    setOpenAddressCreateCard(true);
  };

  const onReassignAddressClick = (record: IAddress) => {
    reassignAddress({
      id: record.id,
      isMain: true,
      onSuccess: () => {
        showSuccessMessage('Основной адрес доставки успешно изменен.');
      },
    });
  };

  const onDeleteAddressClick = (record: IAddress) => {
    setSelectedAddress(record);
    setOpenWarningCard(true);
  };

  const onDeleteAddressConfirmClick = () => {
    if (selectedAddress) {
      deleteAddress({
        id: selectedAddress.id,
        onSuccess: () => {
          showMessage({
            content: `Адрес доставки «${selectedAddress.postalCode}, ${selectedAddress.city}, ${selectedAddress.name}» удален.`,
            icon: <BinIcon className="icon-bin-dark-grey" />,
          });
          setOpenWarningCard(false);
        },
      });
    }
  };

  useEffect(() => {
    if (subdivision?.id) {
      getAddressList({ subdivisionId: subdivision.id, limit: LIST_LIMIT_0 });
    }
  }, [subdivision?.id]);
  return (
    <>
      <InfiniteScrollContainer>
        {header}

        <div className="mb-20">
          <Button
            className="button-sm primary"
            icon={<PlusIcon className="icon-plus-white" />}
            onClick={() => onCreateAddressClick()}
          >
            Добавить адрес
          </Button>
        </div>

        <Table
          className={`${!addressList.length && 'table-empty'} mb-72 subdivision-address-list-settings__table`}
          showHeader={false}
          pagination={false}
          size="middle"
          dataSource={addressList.map((address) => ({ ...address, key: address.id }))}
          columns={renderAddressListSettingsRecord(onEditAddressClick, onReassignAddressClick, onDeleteAddressClick)}
          loading={{ spinning: addressListLoading || addressLoading, indicator: <SpinIndicator /> }}
          locale={{
            emptyText: <div className="text-body color-dark-grey">В подразделении ещё нет адресов.</div>,
          }}
        />
      </InfiniteScrollContainer>

      <AddressCreateCard
        open={openAddressCreateCard}
        subdivisionId={subdivision?.id}
        address={selectedAddress}
        onOpenChange={setOpenAddressCreateCard}
      />

      <WarningCard
        open={openWarningCard}
        content="Вы уверены, что хотите удалить адрес доставки?"
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Удалить"
        loading={addressLoading}
        onConfirm={onDeleteAddressConfirmClick}
        onClose={() => setOpenWarningCard(false)}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  addressList: state.addressListState.data,
  addressListLoading: state.addressListState.loading,
  addressLoading: state.addressState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getAddressList: dispatch.addressListState.getAddressList,
  deleteAddress: dispatch.addressState.deleteAddress,
  reassignAddress: dispatch.addressState.reassignAddress,
});

export const SubdivisionSettingsAddressList = connect(mapState, mapDispatch)(Component);
