import React from 'react';
import { IconBackground } from 'common/components/IconBackground';
import { EColor } from 'common/const/color.enum';
import { IOption } from 'common/models';
import { getUserName } from 'entities/User/User.helper';
import { IUser } from 'entities/User/User.models';

export const sortSelectOptions = (options: IOption[]) => {
  return options.sort((a, b) => {
    if (isNaN(Number(a.label))) {
      return (a.label as string).localeCompare(b.label as string);
    } else {
      return Number(a.label) - Number(b.label);
    }
  });
};

export const renderSelectCustomOptionWithUser = (user: IUser) => {
  return (
    <div className="select-with-search__custom-option-user">
      <IconBackground
        content={<span className="text-profile-icon color-dark-grey">{getUserName.initials(user.lastName, user.firstName)}</span>}
        color={EColor.LightGrey}
      />

      <div>
        <div className="text-controls-accent mb-4 select-with-search__custom-option-user-name">
          {getUserName.fullName(user.lastName, user.firstName, user.patronymic)}
        </div>

        <span className="text-tag color-dark-grey">{user.email}</span>
      </div>
    </div>
  );
};
