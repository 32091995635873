import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { IAuthState } from 'entities/Auth/Auth.models';
import { authStateReducers } from 'entities/Auth/Auth.reducers';
import { authStateEffects } from 'entities/Auth/Auth.effects';

export const authState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: true,
    error: null,
    superAdminAsUser: false,
    userCatalogId: null,
    xBasketId: null,
  } as IAuthState,
  reducers: authStateReducers,
  effects: authStateEffects,
});
