import React, { FC, useMemo } from 'react';
import { Chip } from 'common/components/Chip';
import { EPresetType } from 'common/const/preset.enum';
import { EColor } from 'common/const/color.enum';
import { generateUid } from 'common/helpers/common.helper';
import { usePropertyContext } from 'common/hooks/usePropertyContext';
import { mapCatalogPropertyListToChips, showCatalogFilterChipsClearAllBtn } from 'entities/Catalog/Catalog.helper';
import { setPropertyListItemHidden } from 'entities/Property/Property.helper';

export const CatalogFilterChips: FC = () => {
  const { setFilterIsChanged, propertiesForView, onPropertiesForViewChange } = usePropertyContext();

  const chips = useMemo(() => mapCatalogPropertyListToChips(propertiesForView), [propertiesForView]);
  const showClearAll = showCatalogFilterChipsClearAllBtn(propertiesForView);

  const onClear = (id: number, value: string) => {
    const newPropertiesForView = propertiesForView.map((property) => {
      if (property.id === id) {
        if (property.presetType === EPresetType.SingleValue || property.presetType === EPresetType.Range) {
          return { ...property, result: [] };
        }

        return { ...property, result: property.result?.filter((item) => item !== value) };
      }

      return property;
    });

    const mappedNewPropertiesForView = setPropertyListItemHidden(newPropertiesForView);

    onPropertiesForViewChange(mappedNewPropertiesForView);
    setFilterIsChanged(true);
  };

  const onClearAll = () => {
    const newPropertiesForView = propertiesForView.map((property) => {
      return { ...property, result: property.isCategory ? property.result : [] };
    });
    const mappedNewPropertiesForView = setPropertyListItemHidden(newPropertiesForView);

    onPropertiesForViewChange(mappedNewPropertiesForView);
    setFilterIsChanged(true);
  };

  return (
    <>
      {chips.map((chip) => {
        if (!chip) {
          return null;
        }

        return (
          <Chip
            key={generateUid()}
            color={EColor.BrightGreen}
            name={chip.name}
            value={`${chip.value} ${chip.unit}`}
            onClear={() => onClear(chip.id, chip.value)}
          />
        );
      })}

      {showClearAll && <Chip key={generateUid()} color={EColor.LightGrey} name="Очистить всё" onClear={onClearAll} />}
    </>
  );
};
