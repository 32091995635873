import React, { FC, ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Select, Table } from 'antd';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { EInventoryStatus } from 'common/const/inventory.enum';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { onInputKeyDown } from 'common/helpers/input.helper';
import { useInventoryContext } from 'common/hooks/useInventoryContext';
import {
  CHARACTER_LIMIT_MIN,
  DEFAULT_EMPTY_VALUE,
  DEFAULT_LIST_OFFSET,
  DEFAULT_PAGINATION_PAGE,
  LIST_LIMIT_20,
  SELECT_LIST_HEIGHT_320,
} from 'common/config';
import { IOption } from 'common/models';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { RootDispatch, RootState } from 'app/store';
import { InventoryCard } from 'entities/Inventory/components/InventoryCard';
import { InventoryEmptyList } from 'entities/Inventory/components/InventoryEmptyList';
import { IInventoryListPayload } from 'entities/Inventory/Inventory.models';
import { renderInventoryListRecords } from 'entities/Inventory/Inventory.helper';
import { GoodsCard } from 'entities/Goods/components/GoodsCard';
import { getUserRole } from 'entities/User/User.helper';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  menu: ReactNode;
  subdivisionOptions: IOption[];
  userOptions: IOption[];
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

export const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    menu,
    subdivisionOptions,
    userOptions,
    // state
    userListLoading,
    inventoryList,
    inventoryListCount,
    inventoryListLoading,
    currentUser,
    inventoryLoading,
    userInventoryStatisticsLoading,
    // dispatch
    setInventoryList,
    getInventoryList,
    getInventoryListPart,
    getInventoryListSubdivisions,
    getInventoryListUsers,
    getInventoryById,
    getUserInventoryStatistics,
  } = props;

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [payload, setPayload] = useState<IInventoryListPayload>({
    statuses: [EInventoryStatus.WriteOff],
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
  });
  const { page, setPage, fetchLoading, setFetchLoading, setOpenInventoryCard } = useInventoryContext();

  const { isAccountAdmin } = getUserRole(currentUser?.roles);
  const listLoading =
    userListLoading || inventoryListLoading || inventoryLoading || userInventoryStatisticsLoading || fetchLoading;

  const onSubdivisionChange = async (value: number) => {
    const newPayload = {
      ...payload,
      subdivisionIds: value !== DEFAULT_EMPTY_VALUE ? [value] : undefined,
      userId: undefined,
    };

    setPage(DEFAULT_PAGINATION_PAGE);
    setPayload(newPayload);

    await getInventoryListUsers({ statuses: [EInventoryStatus.WriteOff], subdivisionId: newPayload.subdivisionIds?.[0] });
    await getInventoryList(newPayload);
  };

  const onUserChange = (value: number) => {
    const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

    setPage(DEFAULT_PAGINATION_PAGE);
    setPayload(newPayload);
    getInventoryList(newPayload);
  };

  const onSearchChange = (value: string) => {
    value.trim().length >= CHARACTER_LIMIT_MIN ? setSearch(value) : setSearch(undefined);
  };

  const onSearchClick = (value?: string) => {
    const newPayload = { ...payload, search: value };

    setPage(DEFAULT_PAGINATION_PAGE);
    setPayload(newPayload);
    getInventoryList(newPayload);
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setInventoryList([]);
      await getUserInventoryStatistics();

      if (isAccountAdmin) {
        await getInventoryListSubdivisions({ statuses: [EInventoryStatus.WriteOff] });
        await getInventoryListUsers({ statuses: [EInventoryStatus.WriteOff] });
      }

      await getInventoryList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!inventoryListLoading && inventoryList.length < inventoryListCount}
        scrollToTopTrigger={[payload]}
        onLoad={() => {
          setPage(page + 1);
          getInventoryListPart({ ...payload, offset: LIST_LIMIT_20 * page });
        }}
      >
        {header}
        {navigation}
        {menu}

        <div className="inventory-list__filters mb-12">
          {isAccountAdmin && (
            <>
              <Select
                rootClassName="redesign"
                className="inventory-list__filters-select"
                options={subdivisionOptions}
                value={payload?.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
                onChange={onSubdivisionChange}
                placeholder={EPlaceholder.SelectCompany}
                listHeight={SELECT_LIST_HEIGHT_320}
                virtual={false}
                suffixIcon={<ArrowDownShortIcon />}
              />

              <Select
                rootClassName="redesign"
                className="inventory-list__filters-select"
                options={userOptions}
                value={payload?.userId !== undefined ? payload.userId : DEFAULT_EMPTY_VALUE}
                onChange={onUserChange}
                placeholder={EPlaceholder.SelectUser}
                listHeight={SELECT_LIST_HEIGHT_320}
                virtual={false}
                suffixIcon={<ArrowDownShortIcon />}
              />
            </>
          )}

          <Input
            style={{ flex: 1 }}
            placeholder={EPlaceholder.SearchByNameOrArticle}
            allowClear={{
              clearIcon: (
                <CloseIcon
                  className="icon-close-input"
                  onClick={() => {
                    if (payload.search?.length) {
                      onSearchClick();
                    } else {
                      setSearch('');
                    }
                  }}
                />
              ),
            }}
            value={search}
            onChange={(e) => onSearchChange(e.target.value)}
            onKeyDown={(e) => onInputKeyDown(e.key, () => onSearchClick(search))}
          />

          <Button
            className="button-circle primary"
            icon={<SearchIcon className="icon-search-white" />}
            onClick={() => onSearchClick(search)}
          />
        </div>

        <Table
          className={`
                table-hover inventory-list__table
                ${!!inventoryList.length && inventoryList.length === inventoryListCount && 'inventory-list__table-end-of-list'}
                ${!inventoryList.length && 'inventory-list__table-empty'}
              `}
          dataSource={inventoryList.map((inventory) => ({ ...inventory, key: inventory.id }))}
          columns={renderInventoryListRecords()}
          onRow={({ id }) => ({ onClick: () => getInventoryById({ id, onSuccess: () => setOpenInventoryCard(true) }) })}
          showHeader={false}
          pagination={false}
          locale={{ emptyText: <InventoryEmptyList open={!listLoading && !inventoryList.length} /> }}
          loading={{ spinning: listLoading, indicator: <SpinIndicator /> }}
        />
      </InfiniteScrollContainer>

      <InventoryCard />

      <GoodsCard />
    </>
  );
};

const mapState = (state: RootState) => ({
  userListLoading: state.userListState.loading,
  inventoryList: state.inventoryListState.data,
  inventoryListCount: state.inventoryListState.count,
  inventoryListLoading: state.inventoryListState.loading,
  currentUser: state.userState.currentUser,
  inventoryLoading: state.inventoryState.loading,
  userInventoryStatisticsLoading: state.userInventoryStatisticsState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setInventoryList: dispatch.inventoryListState.setInventoryList,
  getInventoryList: dispatch.inventoryListState.getInventoryList,
  getInventoryListPart: dispatch.inventoryListState.getInventoryListPart,
  getInventoryListSubdivisions: dispatch.inventoryListState.getInventoryListSubdivisions,
  getInventoryListUsers: dispatch.inventoryListState.getInventoryListUsers,
  getInventoryById: dispatch.inventoryState.getInventoryById,
  getUserInventoryStatistics: dispatch.userInventoryStatisticsState.getUserInventoryStatistics,
});

export const AllWriteOffInventoryList = connect(mapState, mapDispatch)(Component);
