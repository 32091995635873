import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  IBrandDiscountCreatePayload,
  IBrandDiscountDeletePayload,
  IBrandDiscountUpdatePayload,
  IBrandDiscountListParams,
} from 'entities/Discount/Discount.models';
import { discountTransport } from 'entities/Discount/Discount.transport';

export const brandDiscountListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getBrandDiscountList(params: IBrandDiscountListParams) {
    dispatch.brandDiscountListState.setLoading(true);

    try {
      const response = await discountTransport.getBrandDiscountList(params);
      dispatch.brandDiscountListState.setList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.brandDiscountListState.setLoading(false);
    }
  },
});

export const brandDiscountStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async createBrandDiscount(payload: IBrandDiscountCreatePayload) {
    dispatch.brandDiscountState.setLoading(true);

    try {
      const response = await discountTransport.createBrandDiscount(payload);
      dispatch.brandDiscountListState.addDiscount(response);
      dispatch.brandDiscountState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.brandDiscountState.setLoading(false);
    }
  },
  async updateBrandDiscount(payload: IBrandDiscountUpdatePayload) {
    dispatch.brandDiscountState.setLoading(true);

    try {
      const response = await discountTransport.updateBrandDiscount(payload);
      dispatch.brandDiscountListState.updateDiscount(response);
      dispatch.brandDiscountState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.brandDiscountState.setLoading(false);
    }
  },
  async deleteBrandDiscount(payload: IBrandDiscountDeletePayload) {
    dispatch.brandDiscountState.setLoading(true);

    try {
      const response = await discountTransport.deleteBrandDiscount(payload);
      dispatch.brandDiscountListState.deleteDiscount(response.id);
      dispatch.brandDiscountState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.brandDiscountState.setLoading(false);
    }
  },
});
