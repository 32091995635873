import React, { FC, useEffect, useMemo, useState } from 'react';
import { Menu, Spin } from 'antd';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { ERoute } from 'common/const/route.enum';
import { ESettingsMenuTab, ESettingsTab } from 'common/const/settings.enum';
import { stringToNumber } from 'common/helpers/common.helper';
import { RootDispatch, RootState } from 'app/store';
import { ContractGeneralSettings } from 'entities/Settings/components/ContractSettings/General/ContractGeneralSettings';
import { ContractAssortmentSettings } from 'entities/Settings/components/ContractSettings/Assortment/ContractAssortmentSettings';
import { ContractDiscountListSettings } from 'entities/Settings/components/ContractSettings/DiscountList/ContractDiscountListSettings';
import { ContractDeliveryTimeListSettings } from 'entities/Settings/components/ContractSettings/DeliveryTimeList/ContractDeliveryTimeListSettings';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ contract, getContractById }) => {
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const { id, menu } = useParams();
  const navigate = useNavigate();

  const header = useMemo(() => {
    return (
      <>
        <div className="text-body color-dark-grey mb-20">Настройки контракта</div>

        <div className="text-h1 mb-52">{contract?.buyerName}</div>

        <Menu
          items={[
            { key: ESettingsMenuTab.General, label: 'Управление контрактом' },
            { key: ESettingsMenuTab.Assortment, label: 'Ассортимент' },
            { key: ESettingsMenuTab.DiscountList, label: 'Скидки' },
            { key: ESettingsMenuTab.DeliveryTimeList, label: 'Сроки доставки' },
          ]}
          selectedKeys={[!menu ? ESettingsMenuTab.General : menu]}
          onClick={({ key }) => navigate(`${ERoute.Settings}/${ESettingsTab.Contract}/${contract?.id}/${key}`)}
          mode="horizontal"
        />
      </>
    );
  }, [contract, menu]);

  const renderContent = () => {
    switch (menu) {
      case ESettingsMenuTab.Assortment: {
        return <ContractAssortmentSettings header={header} contract={contract} />;
      }
      case ESettingsMenuTab.DiscountList: {
        return <ContractDiscountListSettings header={header} contract={contract} />;
      }
      case ESettingsMenuTab.DeliveryTimeList: {
        return <ContractDeliveryTimeListSettings header={header} contract={contract} />;
      }
      default: {
        return <ContractGeneralSettings header={header} contract={contract} />;
      }
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (id) {
        await getContractById(stringToNumber(id));
      }

      setFetchLoading(false);
    };

    fetch();
  }, [id]);

  if (fetchLoading) {
    return (
      <div className="contract-settings">
        <Spin indicator={<SpinIndicator />} />
      </div>
    );
  }

  return <div className="contract-settings">{renderContent()}</div>;
};

const mapState = (state: RootState) => ({
  contract: state.contractState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getContractById: dispatch.contractState.getContractById,
});

export const ContractSettings = connect(mapState, mapDispatch)(Component);
