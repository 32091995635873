import { createModel } from '@rematch/core';
import { DEFAULT_VALUE_0 } from 'common/config';
import { IRootModel } from 'app/store';
import { IAddressSuggestionListState, IAddressState, IAddressListState } from 'entities/Address/Address.models';
import {
  addressListStateReducers,
  addressStateReducers,
  addressSuggestionListStateReducers,
} from 'entities/Address/Address.reducers';
import {
  addressListStateEffects,
  addressStateEffects,
  addressSuggestionListStateEffects,
} from 'entities/Address/Address.effects';

export const addressSuggestionListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IAddressSuggestionListState,
  reducers: addressSuggestionListStateReducers,
  effects: addressSuggestionListStateEffects,
});

export const addressListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: DEFAULT_VALUE_0,
    loading: false,
  } as IAddressListState,
  reducers: addressListStateReducers,
  effects: addressListStateEffects,
});

export const addressState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IAddressState,
  reducers: addressStateReducers,
  effects: addressStateEffects,
});
