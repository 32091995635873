import { ILegalEntity, ILegalEntityListState, ILegalEntityState } from 'entities/LegalEntity/LegalEntity.models';

export const legalEntityListStateReducers = {
  setList: (state: ILegalEntityListState, payload: ILegalEntity[]) => ({ ...state, data: payload }),
  setCount: (state: ILegalEntityListState, payload: number) => ({ ...state, count: payload }),
  setLoading: (state: ILegalEntityListState, payload: boolean) => ({ ...state, loading: payload }),
  addLegalEntity: (state: ILegalEntityListState, payload: ILegalEntity) => ({ ...state, data: [...state.data, payload] }),
  updateLegalEntity: (state: ILegalEntityListState, payload: ILegalEntity) => ({
    ...state,
    data: state.data.map((legal) => (legal.id === payload.id ? payload : legal)),
  }),
  deleteLegalEntity: (state: ILegalEntityListState, payload: number) => ({
    ...state,
    data: state.data.filter((legal) => legal.id !== payload),
  }),
};

export const legalEntityStateReducers = {
  setLegalEntity: (state: ILegalEntityState, payload: ILegalEntity | null) => ({ ...state, data: payload }),
  setLoading: (state: ILegalEntityState, payload: boolean) => ({ ...state, loading: payload }),
  setError: (state: ILegalEntityState, payload: string | null) => ({ ...state, error: payload }),
};
