import { createModel } from '@rematch/core';
import { DEFAULT_VALUE_0 } from 'common/config';
import { IRootModel } from 'app/store';
import { ICatalogListState, ICatalogState } from 'entities/Catalog/Catalog.models';
import { catalogListStateReducers, catalogStateReducers } from 'entities/Catalog/Catalog.reducers';
import { catalogListStateEffects, catalogStateEffects } from 'entities/Catalog/Catalog.effects';

export const catalogListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: DEFAULT_VALUE_0,
    loading: false,
  } as ICatalogListState,
  reducers: catalogListStateReducers,
  effects: catalogListStateEffects,
});

export const catalogState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ICatalogState,
  reducers: catalogStateReducers,
  effects: catalogStateEffects,
});
