import React, { ReactNode, useMemo } from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { ERequestsMenuTab } from 'common/const/request.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { getFilterOptions } from 'common/helpers/option.helper';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { RootState } from 'app/store';
import { CreatedNeedListForManager } from 'entities/Need/components/Manager/CreatedNeedListForManager';
import { PendingNeedListForManager } from 'entities/Need/components/Manager/PendingNeedListForManager';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const Component: React.FC<AllType> = ({ header, navigation, subdivisionList, userList }) => {
  const { getSearchParam, setSearchParam } = useSearchParamsHook();

  const subdivisionOptions = getFilterOptions.subdivision(subdivisionList);
  const userOptions = getFilterOptions.user(userList);
  const tab = getSearchParam(ESearchParams.Tab);
  const isPendingNeedListPage = tab === ERequestsMenuTab.Pending;
  const menu = useMemo(() => {
    return (
      <Menu
        items={[
          { label: 'Новые заявки', key: ERequestsMenuTab.Created },
          { label: 'Заявки в работе', key: ERequestsMenuTab.Pending },
        ]}
        selectedKeys={[tab === null ? ERequestsMenuTab.Created : tab]}
        onClick={({ key }) => setSearchParam(ESearchParams.Tab, key)}
        mode="horizontal"
      />
    );
  }, [tab]);

  return isPendingNeedListPage ? (
    <PendingNeedListForManager
      header={header}
      navigation={navigation}
      menu={menu}
      subdivisionOptions={subdivisionOptions}
      userOptions={userOptions}
    />
  ) : (
    <CreatedNeedListForManager
      header={header}
      navigation={navigation}
      menu={menu}
      subdivisionOptions={subdivisionOptions}
      userOptions={userOptions}
    />
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.needListState.subdivisionList,
  userList: state.needListState.userList,
});

export const IncomingRequestsForManager = connect(mapState)(Component);
