import React, { FC } from 'react';
import { ERoute } from 'common/const/route.enum';
import { LoginCard } from 'entities/Auth/components/Login/LoginCard';
import { ActivationCard } from 'entities/Auth/components/Activation/ActivationCard';
import { RestoreCard } from 'entities/Auth/components/Restore/RestoreCard';

interface IComponentProps {
  path: ERoute;
}

export const AuthLayout: FC<IComponentProps> = ({ path }) => {
  const renderCard = () => {
    switch (path) {
      case ERoute.Activation: {
        return <ActivationCard />;
      }
      case ERoute.RestorePassword: {
        return <RestoreCard />;
      }
      default: {
        return <LoginCard />;
      }
    }
  };

  return (
    <div className="redesign auth-layout">
      <div className="auth-layout__image" />

      {renderCard()}
    </div>
  );
};
