import React, { FC, Key, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Input, Modal, Tree } from 'antd';
import { AntTreeNodeProps } from 'antd/es/tree';
import { DEFAULT_VALUE_0 } from 'common/config';
import { EPlaceholder } from 'common/const/placeholder.enum';
import {
  filterTreeDataBySearch,
  findTreeParentKeysByCheckedKeys,
  getTreeNodeParentKey,
  highlightTreeTitle,
  transformIdToTreeKey,
  transformTreeKeyToId,
} from 'common/helpers/tree.helper';
import { ITreeDataNode } from 'common/models';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as ArrowRightShortIcon } from 'app/assets/images/redesign/arrow-right-short.svg';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  defaultValue?: number[];
  treeData: ITreeDataNode[];
  allTreeKeys: Key[];
  onClose: () => void;
  onSave: (value: number[], onSuccess: () => void) => void;
}

export const ContractCategoriesModal: FC<IComponentProps> = (props) => {
  const { open, loading, defaultValue, treeData, allTreeKeys, onClose, onSave } = props;

  const [search, setSearch] = useState<string>('');
  const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

  const highlightedTreeData = useMemo(() => highlightTreeTitle(treeData, search), [treeData, search]);
  const checkAll = allTreeKeys.length > DEFAULT_VALUE_0 && checkedKeys.length === allTreeKeys.length;
  const indeterminate = checkedKeys.length > DEFAULT_VALUE_0 && checkedKeys.length < allTreeKeys.length;

  const onCheckAllChange = (checked: boolean) => {
    checked ? setCheckedKeys(allTreeKeys) : setCheckedKeys([]);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const matchedKeys = filterTreeDataBySearch(treeData, value);
    const newExpandedKeys = matchedKeys
      .map((key) => getTreeNodeParentKey(key, treeData))
      .filter((key, index, array): key is Key => key !== null && array.indexOf(key) === index);

    setSearch(value);
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(true);
  };

  const onExpand = (keys: Key[]) => {
    setExpandedKeys(keys);
    setAutoExpandParent(false);
  };

  const handleClose = () => {
    if (loading) {
      return;
    }

    setSearch('');
    onClose();
  };

  const handleSave = () => {
    const ids = checkedKeys.map((key) => transformTreeKeyToId(key));

    onSave(ids, handleClose);
  };

  useEffect(() => {
    if (defaultValue) {
      const checkedKeys = defaultValue.map((value) => transformIdToTreeKey(value, allTreeKeys));
      const expandedKeys = findTreeParentKeysByCheckedKeys(checkedKeys);

      setCheckedKeys(checkedKeys);
      setExpandedKeys(expandedKeys);
    } else {
      setCheckedKeys(allTreeKeys);
      setExpandedKeys(allTreeKeys);
    }
  }, [defaultValue, allTreeKeys]);

  return (
    <Modal
      rootClassName="redesign modal contract-categories-modal"
      width={800}
      centered
      destroyOnClose
      closeIcon={false}
      open={open}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" className="button-sm secondary" onClick={handleClose} disabled={loading}>
          Отмена
        </Button>,
        <Button key="save" className="button-sm primary" onClick={handleSave} loading={loading}>
          Сохранить
        </Button>,
      ]}
    >
      <Input
        className="mb-20"
        placeholder={EPlaceholder.EnterCategoryName}
        allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
        value={search}
        onChange={onSearchChange}
      />

      <div className="mb-10 contract-categories-modal__check-all">
        <Checkbox
          className="mb-10"
          checked={checkAll}
          indeterminate={indeterminate}
          onChange={(e) => onCheckAllChange(e.target.checked)}
        >
          Все категории
        </Checkbox>
      </div>

      <div className="mb-20 contract-categories-modal__scroll-container">
        {highlightedTreeData.length ? (
          <Tree
            height={800}
            virtual
            defaultExpandAll
            treeData={highlightedTreeData}
            showLine
            switcherIcon={(props: AntTreeNodeProps) => {
              return props.expanded ? (
                <ArrowDownShortIcon className="icon-arrow-down-short-dark-grey" />
              ) : (
                <ArrowRightShortIcon className="icon-arrow-right-short-dark-grey" />
              );
            }}
            checkable
            checkStrictly
            checkedKeys={checkedKeys}
            // @ts-ignore
            onCheck={({ checked }) => setCheckedKeys(checked)}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onExpand={onExpand}
          />
        ) : (
          <div style={{ margin: '20px 0', textAlign: 'center' }} className="text-body color-dark-grey">
            Список категорий пуст.
          </div>
        )}
      </div>
    </Modal>
  );
};
