import { ISubdivisionShort, IUserShort } from 'common/models';
import { INeed, INeedHistoryItem, INeedListState, INeedState } from 'entities/Need/Need.models';
import { ISupply } from 'entities/Supply/Supply.models';

export const needListStateReducers = {
  setList: (state: INeedListState, payload: INeed[]) => ({ ...state, data: payload }),
  updateList: (state: INeedListState, payload: INeed[]) => {
    return {
      ...state,
      data: state.data.map((need) => {
        const item = payload.find((needItem) => needItem.id === need.id);

        return item ? item : need;
      }),
    };
  },
  filterList: (state: INeedListState, payload: number[]) => {
    return {
      ...state,
      data: state.data.filter((need) => !payload.includes(need.id)),
      count: state.count - payload.length,
    };
  },
  setListPart: (state: INeedListState, payload: INeed[]) => ({ ...state, data: [...state.data, ...payload] }),
  setCount: (state: INeedListState, payload: number) => ({ ...state, count: payload }),
  setLoading: (state: INeedListState, payload: boolean) => ({ ...state, loading: payload }),
  setUserList: (state: INeedListState, payload: IUserShort[]) => ({ ...state, userList: payload }),
  setSubdivisionList: (state: INeedListState, payload: ISubdivisionShort[]) => ({
    ...state,
    subdivisionList: payload,
  }),
  setSubdivisionListLoading: (state: INeedListState, payload: boolean) => ({ ...state, subdivisionListLoading: payload }),
};

export const needStateReducers = {
  setNeed: (state: INeedState, payload: INeed) => ({ ...state, data: payload }),
  setLoading: (state: INeedState, payload: boolean) => ({ ...state, loading: payload }),
  setSupplyList: (state: INeedState, payload: ISupply[]) => ({ ...state, supplyList: payload }),
  setHistoryList: (state: INeedState, payload: INeedHistoryItem[]) => ({ ...state, historyList: payload }),
};
