/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useState } from 'react';

export interface ISubdivisionContext {
  parentId: number | undefined;
  setParentId: (value: number | undefined) => void;
  openSubdivisionCreateCard: boolean;
  setOpenSubdivisionCreateCard: (value: boolean) => void;
}

export const SubdivisionContext = createContext<ISubdivisionContext>({
  parentId: undefined,
  setParentId: () => {},
  openSubdivisionCreateCard: false,
  setOpenSubdivisionCreateCard: () => {},
});

export const useSubdivisionContext = () => useContext(SubdivisionContext);

export interface IComponentProps {
  children: React.ReactNode;
}

export const SubdivisionProvider: FC<IComponentProps> = ({ children }) => {
  const [parentId, setParentId] = useState<number | undefined>(undefined);
  const [openSubdivisionCreateCard, setOpenSubdivisionCreateCard] = useState<boolean>(false);

  return (
    <SubdivisionContext.Provider
      value={{
        parentId,
        setParentId,
        openSubdivisionCreateCard,
        setOpenSubdivisionCreateCard,
      }}
    >
      {children}
    </SubdivisionContext.Provider>
  );
};
