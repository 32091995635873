import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { EMessage } from 'common/const/message.enum';
import { ENotificationType } from 'common/const/notification.enum';
import { EModalCollectionKey } from 'common/const/modal.enum';
import { RootDispatch, RootState } from 'app/store';
import { CreateSubdivisionModal } from 'entities/Modal/components/CreateSubdivisionModal';
import { Notification } from 'entities/Modal/components/Notification';
import { ISubdivision, ISubdivisionCreatePayload } from 'entities/Subdivision/Subdivision.models';

interface IComponentProps {
  subdivision?: ISubdivision;
  changeSubdivision: (subdivisionId: number) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const SubdivisionActionsComponent: React.FC<AllType> = (props) => {
  const {
    subdivision,
    subdivisionListState,
    subdivisionState,
    createSubdivisionModalParams,
    deleteSubdivisionModalParams,
    changeSubdivision,
    createSubdivision,
    deleteSubdivision,
    showModal,
    hideModal,
  } = props;
  const { data } = subdivisionListState;
  const { loading: subdivisionLoading } = subdivisionState;
  const { open: openCreateSubdivisionModal } = createSubdivisionModalParams;
  const { open: openDeleteSubdivisionModal } = deleteSubdivisionModalParams;

  const [form] = useForm();

  const onDeleteSubdivisionCancelClick = () => {
    hideModal(EModalCollectionKey.DeleteSubdivision);
  };

  const onDeleteSubdivisionConfirmClick = () => {
    const parentSubdivision = data.find((item) => item.id === subdivision?.parentId);

    if (parentSubdivision) {
      deleteSubdivision({
        id: subdivision?.id as number,
        onSuccess: () => {
          changeSubdivision(parentSubdivision.id);
          hideModal(EModalCollectionKey.DeleteSubdivision);
        },
      });
    }
  };

  const onCreateSubdivisionCancelClick = () => {
    form.resetFields();
    hideModal(EModalCollectionKey.CreateSubdivision);
  };

  const onCreateSubdivisionSubmit = (values: ISubdivisionCreatePayload) => {
    createSubdivision({
      name: values.name,
      parentId: subdivision?.id as number,
      onSuccess: () => {
        form.resetFields();
        hideModal(EModalCollectionKey.CreateSubdivision);
      },
    });
  };

  return (
    <>
      <div className="subdivision-info__actions">
        <Button
          className="btn btn-primary"
          icon={<PlusOutlined />}
          onClick={() => showModal(EModalCollectionKey.CreateSubdivision)}
        >
          Дочернее подразделение
        </Button>

        <Button
          className="btn btn-red"
          onClick={() => showModal(EModalCollectionKey.DeleteSubdivision)}
          // Disable if the selected subdivision is the root subdivision
          disabled={!subdivision?.parentId}
        >
          Удалить подразделение
        </Button>
      </div>

      <CreateSubdivisionModal
        open={openCreateSubdivisionModal}
        form={form}
        loading={subdivisionLoading}
        onSubmit={onCreateSubdivisionSubmit}
        onCancelClick={onCreateSubdivisionCancelClick}
      />

      <Notification
        open={openDeleteSubdivisionModal}
        type={ENotificationType.Warning}
        description={EMessage.SubdivisionWillBeRemoved}
        loading={subdivisionLoading}
        onConfirmClick={onDeleteSubdivisionConfirmClick}
        onCancelClick={onDeleteSubdivisionCancelClick}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  subdivisionListState: state.subdivisionListState,
  subdivisionState: state.subdivisionState,
  createSubdivisionModalParams: state.modalCollection.createSubdivisionModalParams,
  deleteSubdivisionModalParams: state.modalCollection.deleteSubdivisionModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  createSubdivision: dispatch.subdivisionState.createSubdivision,
  deleteSubdivision: dispatch.subdivisionState.deleteSubdivision,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const SubdivisionActions = connect(mapState, mapDispatch)(SubdivisionActionsComponent);
