import axios from 'axios';
import {
  IBrandDiscount,
  IBrandDiscountCreatePayload,
  IBrandDiscountDeletePayload,
  IBrandDiscountCollectionDto,
  IBrandDiscountListParams,
  IBrandDiscountUpdatePayload,
} from 'entities/Discount/Discount.models';

const basePath = 'discount';

export const discountTransport = {
  getBrandDiscountList: (params: IBrandDiscountListParams): Promise<IBrandDiscountCollectionDto> =>
    axios.get(`${basePath}/brand`, { params }),
  createBrandDiscount: (payload: IBrandDiscountCreatePayload): Promise<IBrandDiscount> =>
    axios.post(`${basePath}/brand`, payload),
  updateBrandDiscount: (payload: IBrandDiscountUpdatePayload): Promise<IBrandDiscount> =>
    axios.patch(`${basePath}/brand`, payload),
  deleteBrandDiscount: (payload: IBrandDiscountDeletePayload): Promise<IBrandDiscount> =>
    axios.delete(`${basePath}/brand`, { data: payload }),
};
