import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Input, InputRef, Spin, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { EmptyList } from 'common/components/EmptyList';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { WarningCard } from 'common/components/WarningCard';
import { EMode } from 'common/const/common.enum';
import { showMessage } from 'common/helpers/message.helper';
import { onInputKeyDown } from 'common/helpers/input.helper';
import { LIST_LIMIT_0 } from 'common/config';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { WorkspaceListGroup } from 'entities/Workspace/components/WorkspaceListGroup';
import { WorkspaceListSubdivisions } from 'entities/Workspace/components/WorkspaceListSubdivisions';
import { CreateWorkspaceCard } from 'entities/Workspace/components/CreateWorkspaceCard';
import { filterGroupedWorkspaceList, groupWorkspaceList } from 'entities/Workspace/Workspace.helper';
import { IWorkspaceListItem } from 'entities/Workspace/Workspace.models';
import { ISubdivision } from 'entities/Subdivision/Subdivision.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = (props) => {
  const {
    // state
    workspaceList,
    workspaceListLoading,
    subdivisionList,
    userListLoading,
    auth,
    workspaceLoading,
    // dispatch
    getWorkspaceList,
    getSubdivisionList,
    getUserList,
    deleteWorkspace,
  } = props;

  const [search, setSearch] = useState<string>('');
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [openCreateWorkspaceCard, setOpenCreateWorkspaceCard] = useState<boolean>(false);
  const [openDeleteWorkspaceDrawer, setOpenDeleteWorkspaceDrawer] = useState<boolean>(false);
  const [createWorkspaceCardMode, setCreateWorkspaceCardMode] = useState<EMode>(EMode.Create);
  const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkspaceListItem | null>(null);
  const [selectedSubdivision, setSelectedSubdivision] = useState<ISubdivision | null>(null);
  const searchRef = useRef<InputRef | null>(null);

  const accountId = auth?.access.accountId;
  const groupedWorkspaceList = groupWorkspaceList(workspaceList);
  const filteredGroupedWorkspaceList = filterGroupedWorkspaceList(groupedWorkspaceList, search, selectedSubdivision?.id);

  const onSearchClick = () => {
    setSearch(searchRef.current?.input?.value || '');
  };

  const onCreateWorkspaceClick = async () => {
    await getUserList({ accountId, limit: LIST_LIMIT_0 });
    setCreateWorkspaceCardMode(EMode.Create);
    setOpenCreateWorkspaceCard(true);
  };

  const onCopyWorkspaceClick = async (value: IWorkspaceListItem) => {
    await getUserList({ accountId, limit: LIST_LIMIT_0 });
    setSelectedWorkspace(value);
    setCreateWorkspaceCardMode(EMode.Copy);
    setOpenCreateWorkspaceCard(true);
  };

  const onEditWorkspaceClick = async (value: IWorkspaceListItem) => {
    await getUserList({ accountId, limit: LIST_LIMIT_0 });
    setSelectedWorkspace(value);
    setCreateWorkspaceCardMode(EMode.Edit);
    setOpenCreateWorkspaceCard(true);
  };

  const onDeleteWorkspaceClick = (value: IWorkspaceListItem) => {
    setSelectedWorkspace(value);
    setOpenDeleteWorkspaceDrawer(true);
  };

  const onDeleteWorkspaceConfirm = () => {
    if (selectedWorkspace) {
      deleteWorkspace({
        id: selectedWorkspace.id,
        onSuccess: () => {
          setOpenDeleteWorkspaceDrawer(false);
          setSelectedWorkspace(null);
          showMessage({ content: 'Карта оснащения удалена из подразделения.', icon: <BinIcon className="icon-bin-dark-grey" /> });
        },
      });
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await getWorkspaceList({ limit: LIST_LIMIT_0 });
      await getSubdivisionList({ limit: LIST_LIMIT_0 });
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <Spin wrapperClassName="workspace-list__spin" spinning={fetchLoading} indicator={<SpinIndicator />}>
      <div className="redesign workspace-list">
        <div className="workspace-list__content">
          <div className="workspace-list__search">
            <Input
              ref={searchRef}
              placeholder="Поиск по картам оснащения"
              allowClear={{ clearIcon: <CloseIcon className="icon-close-input" onClick={() => setSearch('')} /> }}
              onKeyDown={(e) => onInputKeyDown(e.key, onSearchClick)}
            />

            <Button
              className="button-circle primary"
              icon={<SearchIcon className="icon-search-white" />}
              onClick={onSearchClick}
            />

            <Tooltip title="Добавить новую карту" placement="bottom">
              <Button
                className="button-circle primary"
                icon={<PlusIcon className="icon-plus-white" />}
                onClick={onCreateWorkspaceClick}
              />
            </Tooltip>
          </div>

          <div className="workspace-list__content-container">
            <div className="text-h1 workspace-list__title">Карты оснащения</div>
          </div>

          <InfiniteScrollContainer>
            <Spin
              wrapperClassName="workspace-list__spin"
              spinning={workspaceListLoading || userListLoading}
              indicator={<SpinIndicator />}
            >
              <div className="workspace-list__content-container">
                {filteredGroupedWorkspaceList.map((group, index) => (
                  <WorkspaceListGroup
                    key={index}
                    group={group}
                    onCopyClick={onCopyWorkspaceClick}
                    onEditClick={onEditWorkspaceClick}
                    onDeleteClick={onDeleteWorkspaceClick}
                  />
                ))}

                <EmptyList
                  open={!workspaceListLoading && !filteredGroupedWorkspaceList.length}
                  width={730}
                  title="Карты оснащения отсутствуют"
                  description="Создайте карты для сотрудников, чтобы зафиксировать, какие ценности могут использоваться в работе"
                  btnTitle="Создать карту"
                  onClick={onCreateWorkspaceClick}
                />
              </div>
            </Spin>
          </InfiniteScrollContainer>
        </div>

        <WorkspaceListSubdivisions
          workspaceList={workspaceList}
          subdivisionList={subdivisionList}
          subdivision={selectedSubdivision}
          setSubdivision={setSelectedSubdivision}
        />
      </div>

      <CreateWorkspaceCard
        open={openCreateWorkspaceCard}
        mode={createWorkspaceCardMode}
        workspace={selectedWorkspace}
        onOpenChange={setOpenCreateWorkspaceCard}
        onClose={() => {
          setOpenCreateWorkspaceCard(false);
          setSelectedWorkspace(null);
          setCreateWorkspaceCardMode(EMode.Create);
        }}
      />

      <WarningCard
        open={openDeleteWorkspaceDrawer}
        content="Вы уверены, что хотите удалить карту оснащения?"
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Удалить"
        loading={workspaceLoading}
        onClose={() => {
          setOpenDeleteWorkspaceDrawer(false);
          setSelectedWorkspace(null);
        }}
        onConfirm={onDeleteWorkspaceConfirm}
      />
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  workspaceList: state.workspaceListState.data,
  workspaceListLoading: state.workspaceListState.loading,
  subdivisionList: state.subdivisionListState.data,
  userListLoading: state.userListState.loading,
  auth: state.authState.data,
  workspaceLoading: state.workspaceState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getWorkspaceList: dispatch.workspaceListState.getWorkspaceList,
  getSubdivisionList: dispatch.subdivisionListState.getSubdivisionList,
  getUserList: dispatch.userListState.getUserList,
  deleteWorkspace: dispatch.workspaceState.deleteWorkspace,
});

export const WorkspaceList = connect(mapState, mapDispatch)(Component);
