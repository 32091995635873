import React, { FC, ReactNode, useMemo } from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { ESupplyListMenuTab } from 'common/const/supply.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { getFilterOptions } from 'common/helpers/option.helper';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { RootState } from 'app/store';
import { ActiveSupplyListForSeller } from 'entities/Supply/components/Seller/ActiveSupplyListForSeller';
import { CompletedSupplyListForSeller } from 'entities/Supply/components/Seller/CompletedSupplyListForSeller';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const Component: FC<AllType> = ({ header, navigation, buyerList, subdivisionList, addressList, userList }) => {
  const { getSearchParam, setSearchParam } = useSearchParamsHook();

  const buyerOptions = getFilterOptions.buyer(buyerList);
  const subdivisionOptions = getFilterOptions.subdivision(subdivisionList);
  const addressOptions = getFilterOptions.address(addressList);
  const userOptions = getFilterOptions.user(userList);
  const tab = getSearchParam(ESearchParams.Tab);
  const isCompletedSupplyListPage = tab === ESupplyListMenuTab.Completed;
  const menu = useMemo(() => {
    return (
      <Menu
        items={[
          { key: ESupplyListMenuTab.Active, label: 'Активные поставки' },
          { key: ESupplyListMenuTab.Completed, label: 'Завершенные поставки' },
        ]}
        selectedKeys={[tab === null ? ESupplyListMenuTab.Active : tab]}
        onClick={({ key }) => setSearchParam(ESearchParams.Tab, key)}
        mode="horizontal"
      />
    );
  }, [tab]);

  return isCompletedSupplyListPage ? (
    <CompletedSupplyListForSeller
      header={header}
      navigation={navigation}
      menu={menu}
      buyerOptions={buyerOptions}
      subdivisionOptions={subdivisionOptions}
      addressOptions={addressOptions}
      userOptions={userOptions}
    />
  ) : (
    <ActiveSupplyListForSeller
      header={header}
      navigation={navigation}
      menu={menu}
      buyerOptions={buyerOptions}
      subdivisionOptions={subdivisionOptions}
      addressOptions={addressOptions}
      userOptions={userOptions}
    />
  );
};

const mapState = (state: RootState) => ({
  buyerList: state.buyerListState.data,
  subdivisionList: state.supplyListState.subdivisionList,
  addressList: state.supplyListState.addressList,
  userList: state.supplyListState.userList,
});

export const SupplyListForSeller = connect(mapState)(Component);
