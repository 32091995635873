import React, { FC, useState } from 'react';
import { Button, Drawer } from 'antd';
import { connect } from 'react-redux';
import { UserRoleChip } from 'common/components/UserRoleChip';
import { Notification } from 'common/components/Notification';
import { Label } from 'common/components/Label';
import { WarningCard } from 'common/components/WarningCard';
import { EUserRole, EUserRoleLabel, EUserStatus } from 'common/const/user.enum';
import { EColor } from 'common/const/color.enum';
import { dateFormatter, phoneFormatter } from 'common/helpers/formatter.helper';
import { showMessage } from 'common/helpers/message.helper';
import { useUserContext } from 'common/hooks/useUserContext';
import { ReactComponent as AffiliateIcon } from 'app/assets/images/redesign/affiliate.svg';
import { ReactComponent as DocumentIcon } from 'app/assets/images/redesign/document.svg';
import { ReactComponent as CopyIcon } from 'app/assets/images/redesign/copy.svg';
import { ReactComponent as EditIcon } from 'app/assets/images/redesign/edit.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { UserCardRoleSelector } from 'entities/User/components/UserCardRoleSelector';
import { getUserName, getUserRole, getUserRoleChipColor } from 'entities/User/User.helper';

interface IComponentProps {
  fromSubdivision?: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    fromSubdivision,
    // state
    auth,
    user,
    currentUser,
    userLoading,
    // dispatch
    updateUserByAdmin,
    deleteUser,
    removeUserSubdivision,
    filterSubdivisionUserList,
  } = props;

  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const { openUserCard, setOpenUserCard, setOpenEditUserCard } = useUserContext();

  const isSeller = auth?.access.isSeller;
  const isActivatedUser = user?.status === EUserStatus.Active;
  const { isUser } = getUserRole(user?.roles);

  const onClose = () => {
    if (userLoading) {
      return;
    }

    setOpenWarningCard(false);
    setOpenUserCard(false);
  };

  const onDeleteUserClick = () => {
    if (user) {
      deleteUser({
        id: user.id,
        onSuccess: () => {
          showMessage({ icon: <BinIcon className="icon-bin-dark-grey" />, content: `Пользователь «${user.email}» удален.` });
          onClose();
        },
      });
    }
  };

  const onRemoveUserSubdivisionClick = () => {
    if (user) {
      removeUserSubdivision({
        userId: user.id,
        onSuccess: () => {
          filterSubdivisionUserList(user.id);
          onClose();
        },
      });
    }
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <Drawer
        rootClassName="redesign drawer user-card"
        destroyOnClose
        width={720}
        open={openUserCard}
        onClose={onClose}
        footer={[
          fromSubdivision ? (
            <Button key="delete" className="button-lg deleting" loading={userLoading} onClick={onRemoveUserSubdivisionClick}>
              Отвязать
            </Button>
          ) : (
            <Button
              key="delete"
              className="button-lg deleting"
              icon={<BinIcon className="icon-bin-pale-red" />}
              disabled={user.id === currentUser?.id || user.isAccountOwner}
              onClick={() => {
                setOpenUserCard(false);
                setOpenWarningCard(true);
              }}
            >
              Удалить
            </Button>
          ),
          <Button
            key="edit"
            className="button-lg secondary"
            icon={<EditIcon className="icon-edit-dark-grey" />}
            loading={userLoading}
            onClick={() => {
              setOpenUserCard(false);
              setOpenEditUserCard(true);
            }}
          >
            Редактировать
          </Button>,
        ]}
      >
        <div className="drawer__body">
          <div className="drawer__title mb-52">{getUserName.fullName(user.lastName, user.firstName, user.patronymic)}</div>

          <div className="text-h4-item-name color-dark-grey mb-20">
            {user.activatedAt
              ? `Активирован ${dateFormatter.fullDateDotSeparator(user.activatedAt)}`
              : `Приглашение отправлено ${dateFormatter.fullDateDotSeparator(user.createdAt)}`}
          </div>

          <div className="user-card__roles mb-52">
            <UserCardRoleSelector
              roles={user.roles}
              isAccountOwner={user.isAccountOwner}
              isSeller={isSeller}
              // @ts-ignore
              onChange={(value) => updateUserByAdmin({ id: user.id, roles: value })}
            />

            {isUser ? (
              <UserRoleChip color={EColor.LightGrey} name={EUserRoleLabel.user} />
            ) : (
              user.roles
                .filter((role) => role !== EUserRole.User)
                .map((role) => {
                  const color = getUserRoleChipColor(role);

                  return <UserRoleChip key={role} color={color} name={EUserRoleLabel[role]} />;
                })
            )}
          </div>

          {!isSeller && !isActivatedUser && (
            <div className="mb-52">
              <Notification type="error" description="Пользователь не активирован" />
            </div>
          )}

          {!isSeller && isActivatedUser && !user.subdivision && (
            <div className="mb-52">
              <Notification type="warning" description="Подразделение не назначено" />
            </div>
          )}

          {user.subdivision && (
            <div className="mb-52 user-card__subdivision">
              <Label
                icon={<AffiliateIcon className="icon-affiliate-bright-green" />}
                name={user.subdivision.name}
                color={EColor.PaleGreen}
              />

              {user.workspace && (
                <Label
                  icon={<DocumentIcon className="icon-document-bright-green" />}
                  name={user.workspace.name}
                  color={EColor.PaleGreen}
                />
              )}
            </div>
          )}

          <div className="user-card__contacts">
            <div className="text-h4 user-card__contacts-title">Контактные данные</div>

            <div className="user-card__contacts-table">
              {user.position && (
                <div className="user-card__contacts-table-row">
                  <span className="text-body color-dark-grey">Должность:</span>

                  <div className="text-body">{user.position}</div>
                </div>
              )}

              <div className="user-card__contacts-table-row">
                <span className="text-body color-dark-grey">Электронный адрес:</span>

                <div className="user-card__contacts-table-row-container">
                  <div className="text-body ellipsis">{user.email}</div>

                  <Button
                    className="button-icon"
                    icon={<CopyIcon className="icon-copy-dark-grey" />}
                    onClick={() => navigator.clipboard.writeText(user.email)}
                  />
                </div>
              </div>

              {user.phone && (
                <div className="user-card__contacts-table-row">
                  <span className="text-body color-dark-grey">Контактный телефон:</span>

                  <div className="user-card__contacts-table-row-container">
                    <span className="text-body">{phoneFormatter(user.phone)}</span>

                    <Button
                      className="button-icon"
                      icon={<CopyIcon className="icon-copy-dark-grey" />}
                      onClick={() => navigator.clipboard.writeText(phoneFormatter(user.phone))}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Drawer>

      <WarningCard
        open={openWarningCard}
        content="Вы уверены, что хотите удалить пользователя?"
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Удалить"
        loading={userLoading}
        onClose={() => {
          setOpenWarningCard(false);
          setOpenUserCard(true);
        }}
        onConfirm={onDeleteUserClick}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  auth: state.authState.data,
  user: state.userState.data,
  currentUser: state.userState.currentUser,
  userLoading: state.userState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateUserByAdmin: dispatch.userState.updateUserByAdmin,
  deleteUser: dispatch.userState.deleteUser,
  removeUserSubdivision: dispatch.userState.removeUserSubdivision,
  filterSubdivisionUserList: dispatch.subdivisionState.filterUserList,
});

export const UserCard = connect(mapState, mapDispatch)(Component);
