import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import { IBrandListParams } from 'entities/Brand/Brand.models';
import { brandTransport } from 'entities/Brand/Brand.transport';

export const brandListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getBrandList(params: IBrandListParams) {
    dispatch.brandListState.setLoading(true);

    try {
      const response = await brandTransport.getBrandList();
      dispatch.brandListState.setList(response.data);
      dispatch.brandListState.setCount(response.count);
      dispatch.brandListState.onSuccess(params);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.brandListState.setLoading(false);
    }
  },
});
