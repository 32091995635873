import { IStock, IStockListState, IStockState } from 'entities/Stock/Stock.models';

export const stockListStateReducers = {
  setList: (state: IStockListState, payload: IStock[]) => ({ ...state, data: payload }),
  setCount: (state: IStockListState, payload: number) => ({ ...state, count: payload }),
  setLoading: (state: IStockListState, payload: boolean) => ({ ...state, loading: payload }),
  addStock: (state: IStockListState, payload: IStock) => ({ ...state, data: [...state.data, payload] }),
  updateStock: (state: IStockListState, payload: IStock) => ({
    ...state,
    data: state.data.map((stock) => (stock.id === payload.id ? payload : stock)),
  }),
  deleteStock: (state: IStockListState, payload: number) => ({
    ...state,
    data: state.data.filter((stock) => stock.id !== payload),
  }),
};

export const stockStateReducers = {
  setStock: (state: IStockState, payload: IStock) => ({ ...state, data: payload }),
  setLoading: (state: IStockState, payload: boolean) => ({ ...state, loading: payload }),
};
