import React, { FC, useEffect } from 'react';
import { Button, Drawer, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { NewPasswordFormField } from 'common/components/NewPasswordFormField';
import { NewPasswordConfirmFormField } from 'common/components/NewPasswordConfirmFormField';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { rules } from 'common/helpers/form.helper';
import { ReactComponent as EyeOffIcon } from 'app/assets/images/redesign/eyeOff.svg';
import { ReactComponent as EyeOnIcon } from 'app/assets/images/redesign/eyeOn.svg';
import { IUserChangePasswordPayload } from 'entities/User/User.models';

interface IComponentProps {
  open: boolean;
  error: string | null;
  loading: boolean;
  onClose: () => void;
  onConfirm: (value: IUserChangePasswordPayload) => void;
}

export const ChangePasswordCard: FC<IComponentProps> = ({ open, error, loading, onClose, onConfirm }) => {
  const [form] = useForm();

  useEffect(() => {
    if (error) {
      form.setFields([{ name: 'currentPassword', errors: [error] }]);
    }
  }, [error]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open]);

  return (
    <Drawer rootClassName="redesign drawer" open={open} onClose={loading ? undefined : onClose} width={720} destroyOnClose>
      <div className="drawer__title">Изменение пароля</div>

      <div className="drawer__body">
        <Form
          layout="vertical"
          form={form}
          requiredMark={false}
          onFinish={({ currentPassword, newPassword }) => onConfirm({ currentPassword, newPassword })}
        >
          <Form.Item className="mb-32" label="Cтарый пароль">
            <Form.Item className="mb-0" name="currentPassword" rules={[rules.required()]}>
              <Input.Password
                placeholder={EPlaceholder.EnterOldPassword}
                iconRender={(visible) => (visible ? <EyeOnIcon className="icon-eye" /> : <EyeOffIcon className="icon-eye" />)}
              />
            </Form.Item>
          </Form.Item>

          <NewPasswordFormField className="mb-32" label="Новый пароль" name="newPassword" checkCurrentPassword />

          <NewPasswordConfirmFormField label="Подтвердите пароль" newPasswordName="newPassword" />
        </Form>
      </div>

      <div className="drawer__footer">
        <Button className="button-lg primary" onClick={() => form.submit()} loading={loading}>
          Изменить пароль
        </Button>

        <Button className="button-lg secondary" onClick={onClose} disabled={loading}>
          Отмена
        </Button>
      </div>
    </Drawer>
  );
};
