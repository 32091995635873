import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import { invitationTransport } from 'entities/Invitation/Invitation.transport';
import { IInvitationActivatePayload, IInvitationCreatePayload } from 'entities/Invitation/Invitation.models';

export const invitationStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getInvitation(hash: string) {
    dispatch.invitationState.setLoading(true);

    try {
      const response = await invitationTransport.getInvitation(hash);
      dispatch.invitationState.setInvitation(response);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.invitationState.setLoading(false);
    }
  },
  async activateInvitation(payload: IInvitationActivatePayload) {
    dispatch.invitationState.setLoading(true);

    try {
      const response = await invitationTransport.activateInvitation(payload);
      dispatch.invitationState.onSuccess(payload);
      return response;
    } catch (error) {
      axiosErrorHandler(error);
      return null;
    } finally {
      dispatch.invitationState.setLoading(false);
    }
  },
  async inviteUser(payload: IInvitationCreatePayload) {
    dispatch.invitationState.setLoading(true);

    try {
      await invitationTransport.inviteUser(payload);
      dispatch.invitationState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error, dispatch.invitationState.setError);
    } finally {
      dispatch.invitationState.setLoading(false);
    }
  },
});
