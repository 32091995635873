import React, { FC } from 'react';
import { Button, Form, Input } from 'antd';

interface IComponentProps {
  loading: boolean;
}

export const ActivationStepOne: FC<IComponentProps> = ({ loading }) => {
  return (
    <>
      <div className="mb-40 auth-card__content-form-title">Вы приглашены в систему управления закупками СПРАУТ</div>

      <Form.Item className="w-100" label="Компания*" name="accountName">
        <Input disabled />
      </Form.Item>

      <Form.Item className="w-100 mb-40" label="Электронная почта*" name="email">
        <Input disabled />
      </Form.Item>

      <Button className="button-lg primary w-100" loading={loading} htmlType="submit">
        Активировать аккаунт
      </Button>
    </>
  );
};
