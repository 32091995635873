import React, { FC } from 'react';
import { Button, Form, Input } from 'antd';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { rules } from 'common/helpers/form.helper';

interface IComponentProps {
  loading: boolean;
}

export const ActivationStepTwo: FC<IComponentProps> = ({ loading }) => {
  return (
    <>
      <div className="mb-40 auth-card__content-form-title">Пожалуйста, введите ваши персональные данные</div>

      <Form.Item className="w-100" label="Фамилия*" name="lastName" rules={[rules.required()]}>
        <Input placeholder={EPlaceholder.EnterLastName} />
      </Form.Item>

      <Form.Item className="w-100" label="Имя*" name="firstName" rules={[rules.required()]}>
        <Input placeholder={EPlaceholder.EnterFirstName} />
      </Form.Item>

      <Form.Item className="w-100 mb-40" label="Отчество" name="patronymic">
        <Input placeholder={EPlaceholder.EnterPatronymic} />
      </Form.Item>

      <Button className="button-lg primary w-100" loading={loading} htmlType="submit">
        Далее
      </Button>
    </>
  );
};
