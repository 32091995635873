import React, { FC, useEffect, useState } from 'react';
import { AutoComplete, Button, Drawer, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { WarningCard } from 'common/components/WarningCard';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { EMode } from 'common/const/common.enum';
import { debounce } from 'common/helpers/common.helper';
import { rules } from 'common/helpers/form.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { SELECT_LIST_HEIGHT_320 } from 'common/config';
import { IFormValue } from 'common/models';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { RootDispatch, RootState } from 'app/store';
import { IAddress, IAddressUpdatePayload } from 'entities/Address/Address.models';
import { getAddressSuggestionOptions } from 'entities/Address/Address.helper';

interface IComponentProps {
  open: boolean;
  subdivisionId?: number;
  address: IAddress | null;
  onOpenChange: (value: boolean) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    open,
    subdivisionId,
    address,
    onOpenChange,
    // state
    addressSuggestionList,
    addressLoading,
    // dispatch
    setAddressSuggestionList,
    getAddressSuggestionList,
    createAddress,
    updateAddress,
  } = props;

  const [payload, setPayload] = useState<IAddressUpdatePayload | null>(null);
  const [formNotSaved, setFormNotSaved] = useState<boolean>(false);
  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const [mode, setMode] = useState<EMode>(EMode.Create);
  const [form] = useForm();

  const isEditMode = mode === EMode.Edit;
  const addressSuggestionOptions = getAddressSuggestionOptions(addressSuggestionList);

  const debouncedSearch = debounce((value: string) => getAddressSuggestionList({ name: value }));

  const onAddressSelect = (value: string) => {
    const address = addressSuggestionList.find((item) => item.name === value);

    if (address) {
      setFormNotSaved(true);
      form.setFieldsValue({
        name: address.name,
        city: address.city,
        postalCode: address.postalCode,
      });
    }
  };

  const onClose = () => {
    setAddressSuggestionList([]);
    setOpenWarningCard(false);
    setFormNotSaved(false);
    onOpenChange(false);
    setMode(EMode.Create);
    form.resetFields();
  };

  const handleClose = () => {
    if (addressLoading) {
      return;
    }

    if (formNotSaved) {
      onOpenChange(false);
      setOpenWarningCard(true);
      return;
    }

    onClose();
  };

  const onFormValuesChange = (value: IFormValue) => {
    setFormNotSaved(true);
    setPayload((prev) => (prev ? { ...prev, ...value } : null));
  };

  const onCreateSubmit = ({ name, city, postalCode }: IFormValue) => {
    if (subdivisionId) {
      createAddress({
        subdivisionId,
        name: name.replaceAll('\n', ' '),
        city,
        postalCode,
        onSuccess: () => {
          showSuccessMessage('Новый адрес добавлен в подразделение.');
          onClose();
        },
      });
    }
  };

  const onEditSubmit = () => {
    if (payload) {
      updateAddress({
        ...payload,
        name: payload.name?.replaceAll('\n', ' '),
        onSuccess: () => {
          showSuccessMessage('Адрес доставки успешно изменен.');
          onClose();
        },
      });
    }
  };

  useEffect(() => {
    if (open && address) {
      setMode(EMode.Edit);
      setPayload({ id: address.id });
      form.setFieldsValue({
        name: address.name,
        city: address.city,
        postalCode: address.postalCode,
      });
    }
  }, [open, address]);

  return (
    <>
      <Drawer
        rootClassName="redesign drawer address-create-card"
        destroyOnClose
        width={720}
        open={open}
        onClose={handleClose}
        footer={
          <Button className="button-lg primary" onClick={form.submit} loading={addressLoading}>
            {isEditMode ? 'Сохранить изменения' : 'Добавить адрес'}
          </Button>
        }
      >
        <div className="drawer__body">
          <div className="drawer__title mb-52">{isEditMode ? 'Редактировать адрес' : 'Новый адрес'}</div>

          <div className="input-with-label mb-32">
            <AutoComplete
              className="redesign autosize-autocomplete"
              rootClassName="redesign"
              popupClassName="redesign"
              virtual={false}
              listHeight={SELECT_LIST_HEIGHT_320}
              allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
              onChange={debouncedSearch}
              options={addressSuggestionOptions}
              onSelect={onAddressSelect}
            >
              <Input.TextArea placeholder={EPlaceholder.StartTypingAddress} autoSize />
            </AutoComplete>

            <div className="text-tag mb-8 input-label">Автоматический поиск</div>
          </div>

          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onValuesChange={onFormValuesChange}
            onFinish={isEditMode ? onEditSubmit : onCreateSubmit}
          >
            <Form.Item className="mb-32" label="Адрес*" name="name" rules={[rules.required()]}>
              <Input.TextArea
                className="autosize-input"
                placeholder={EPlaceholder.SpecifyAddress}
                autoSize
                allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
              />
            </Form.Item>

            <Form.Item className="mb-32" label="Город*" name="city" rules={[rules.required()]}>
              <Input
                placeholder={EPlaceholder.SpecifyCity}
                allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
              />
            </Form.Item>

            <Form.Item className="mb-32" label="Индекс*" name="postalCode" rules={[rules.required()]}>
              <Input
                placeholder={EPlaceholder.SpecifyPostalCode}
                allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
              />
            </Form.Item>
          </Form>
        </div>
      </Drawer>

      <WarningCard
        open={openWarningCard}
        content="Вы уверены, что не хотите сохранить изменения?"
        subtitle="Введенные данные будут потеряны."
        confirmBtnTitle="Не сохранять"
        onConfirm={onClose}
        onClose={() => {
          setOpenWarningCard(false);
          onOpenChange(true);
        }}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  addressSuggestionList: state.addressSuggestionListState.data,
  addressLoading: state.addressState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setAddressSuggestionList: dispatch.addressSuggestionListState.setList,
  getAddressSuggestionList: dispatch.addressSuggestionListState.getAddressSuggestionList,
  createAddress: dispatch.addressState.createAddress,
  updateAddress: dispatch.addressState.updateAddress,
});

export const AddressCreateCard = connect(mapState, mapDispatch)(Component);
