import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store';
import { buyersTransport } from 'entities/Buyer/Buyer.transport';

export const buyerListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getBuyerList() {
    dispatch.buyerListState.seLoading(true);

    try {
      const response = await buyersTransport.getBuyerList();
      dispatch.buyerListState.seList(response.data);
      dispatch.buyerListState.setCount(response.count);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.buyerListState.seLoading(false);
    }
  },
});
