import React, { FC, useState } from 'react';
import { Button, Drawer } from 'antd';
import { connect } from 'react-redux';
import { WarningCard } from 'common/components/WarningCard';
import { EMode } from 'common/const/common.enum';
import { showMessage } from 'common/helpers/message.helper';
import { useLegalEntityContext } from 'common/hooks/useLegalEntityContext';
import { ReactComponent as CopyIcon } from 'app/assets/images/redesign/copy.svg';
import { ReactComponent as EditIcon } from 'app/assets/images/redesign/edit.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export const Component: FC<AllType> = ({ legalEntity, legalEntityLoading, deleteLegalEntity }) => {
  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const { setEditLegalEntityCardMode, openLegalEntityCard, setOpenLegalEntityCard, setOpenEditLegalEntityCard } =
    useLegalEntityContext();

  const onClose = () => {
    if (legalEntityLoading) {
      return;
    }

    setOpenLegalEntityCard(false);
  };

  const onDeleteLegalEntityConfirmClick = () => {
    if (legalEntity) {
      deleteLegalEntity({
        id: legalEntity.id,
        onSuccess: () => {
          showMessage({
            content: `Юридическое лицо «${legalEntity.name}» удалено.`,
            icon: <BinIcon className="icon-bin-dark-grey" />,
          });
          setOpenWarningCard(false);
        },
      });
    }
  };

  if (!legalEntity) {
    return null;
  }

  return (
    <>
      <Drawer
        rootClassName="redesign drawer legal-entity-card"
        destroyOnClose
        width={720}
        open={openLegalEntityCard}
        onClose={onClose}
        footer={[
          <Button
            key="delete"
            className="button-lg deleting"
            icon={<BinIcon className="icon-bin-pale-red" />}
            onClick={() => {
              setOpenLegalEntityCard(false);
              setOpenWarningCard(true);
            }}
          >
            Удалить
          </Button>,
          <Button
            key="edit"
            className="button-lg secondary"
            icon={<EditIcon className="icon-edit-dark-grey" />}
            onClick={() => {
              setEditLegalEntityCardMode(EMode.Edit);
              setOpenLegalEntityCard(false);
              setOpenEditLegalEntityCard(true);
            }}
          >
            Редактировать
          </Button>,
        ]}
      >
        <div className="drawer__body">
          <div className="drawer__title mb-52">{legalEntity.name}</div>

          <div className="legal-entity-card__details">
            <div className="text-h4 legal-entity-card__details-title">Реквизиты юридического лица</div>

            <div className="legal-entity-card__details-table">
              <div className="legal-entity-card__details-table-row">
                <span className="text-body color-dark-grey">ИНН:</span>

                <div className="legal-entity-card__details-table-row-container">
                  <div className="text-body">{legalEntity.inn}</div>

                  <Button
                    className="button-icon"
                    icon={<CopyIcon className="icon-copy-dark-grey" />}
                    onClick={() => navigator.clipboard.writeText(legalEntity.inn)}
                  />
                </div>
              </div>

              <div className="legal-entity-card__details-table-row">
                <span className="text-body color-dark-grey">КПП:</span>

                <div className="legal-entity-card__details-table-row-container">
                  <span className="text-body">{legalEntity.kpp}</span>

                  <Button
                    className="button-icon"
                    icon={<CopyIcon className="icon-copy-dark-grey" />}
                    onClick={() => navigator.clipboard.writeText(legalEntity.kpp)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      <WarningCard
        open={openWarningCard}
        content="Вы уверены, что хотите удалить юр. лицо?"
        subtitle="Юридическое лицо будет отвязано от подразделений и корзин пользователей."
        confirmBtnTitle="Удалить"
        loading={legalEntityLoading}
        onConfirm={onDeleteLegalEntityConfirmClick}
        onClose={() => {
          setOpenWarningCard(false);
          setOpenLegalEntityCard(true);
        }}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  legalEntity: state.legalEntityState.data,
  legalEntityLoading: state.legalEntityState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  deleteLegalEntity: dispatch.legalEntityState.deleteLegalEntity,
});

export const LegalEntityCard = connect(mapState, mapDispatch)(Component);
