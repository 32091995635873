import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  IBuyerCityListParams,
  IDeliveryCreatePayload,
  IDeliveryDeletePayload,
  IDeliveryListParams,
  IDeliveryUpdatePayload,
} from 'entities/Delivery/Delivery.models';
import { deliveryTransport } from 'entities/Delivery/Delivery.transport';

export const buyerCityListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getBuyerCityList(params: IBuyerCityListParams) {
    dispatch.buyerCityListState.setLoading(true);

    try {
      const response = await deliveryTransport.getBuyerCityList(params);
      dispatch.buyerCityListState.setList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.buyerCityListState.setLoading(false);
    }
  },
});

export const deliveryListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getDeliveryList(params: IDeliveryListParams) {
    dispatch.deliveryListState.setLoading(true);

    try {
      const response = await deliveryTransport.getDeliveryList(params);
      dispatch.deliveryListState.setList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.deliveryListState.setLoading(false);
    }
  },
});

export const deliveryStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async createDelivery(payload: IDeliveryCreatePayload) {
    dispatch.deliveryState.setLoading(true);

    try {
      const response = await deliveryTransport.createDelivery(payload);
      dispatch.deliveryListState.addDelivery(response);
      dispatch.deliveryState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.deliveryState.setLoading(false);
    }
  },
  async updateDelivery(payload: IDeliveryUpdatePayload) {
    dispatch.deliveryState.setLoading(true);

    try {
      const response = await deliveryTransport.updateDelivery(payload);
      dispatch.deliveryListState.updateDelivery(response);
      dispatch.deliveryState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.deliveryState.setLoading(false);
    }
  },
  async deleteDelivery(payload: IDeliveryDeletePayload) {
    dispatch.deliveryState.setLoading(true);

    try {
      const response = await deliveryTransport.deleteDelivery(payload);
      dispatch.deliveryListState.deleteDelivery(response.id);
      dispatch.deliveryState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.deliveryState.setLoading(false);
    }
  },
});
