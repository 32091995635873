export enum ESettingsTab {
  Account = 'account',
  Subdivision = 'subdivision',
  Contract = 'contract',
}

export enum ESettingsMenuTab {
  Account = 'account',
  Subdivisions = 'subdivisions',
  Discounts = 'discounts',
  Stocks = 'stocks',
  Integration = 'integration',
  AccountList = 'account-list',
  // Redesign
  Contracts = 'contracts',
  UserList = 'user-list',
  LegalEntityList = 'legal-entity-list',
  StockList = 'stock-list',
  Integrations = 'integrations',
  General = 'general',
  AddressList = 'address-list',
  Assortment = 'assortment',
  DiscountList = 'discount-list',
  DeliveryTimeList = 'delivery-time-list',
}

export enum EAccountTariffPlanTitle {
  free = 'Бесплатный',
}
