/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useState } from 'react';

export interface IUserContext {
  openUserCard: boolean;
  setOpenUserCard: (value: boolean) => void;
  openEditUserCard: boolean;
  setOpenEditUserCard: (value: boolean) => void;
}

export const UserContext = createContext<IUserContext>({
  openUserCard: false,
  setOpenUserCard: () => {},
  openEditUserCard: false,
  setOpenEditUserCard: () => {},
});

export const useUserContext = () => useContext(UserContext);

export interface IComponentProps {
  children: React.ReactNode;
}

export const UserProvider: FC<IComponentProps> = ({ children }) => {
  const [openUserCard, setOpenUserCard] = useState<boolean>(false);
  const [openEditUserCard, setOpenEditUserCard] = useState<boolean>(false);

  return (
    <UserContext.Provider
      value={{
        openUserCard,
        setOpenUserCard,
        openEditUserCard,
        setOpenEditUserCard,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
