import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { IInvitationState } from 'entities/Invitation/Invitation.models';
import { invitationStateReducers } from 'entities/Invitation/Invitation.reducers';
import { invitationStateEffects } from 'entities/Invitation/Invitation.effects';

export const invitationState = createModel<IRootModel>()({
  state: {
    data: null,
    error: null,
    loading: false,
  } as IInvitationState,
  reducers: invitationStateReducers,
  effects: invitationStateEffects,
});
