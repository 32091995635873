import React, { FC, useState } from 'react';
import { Form, Input } from 'antd';
import { EFormFieldMessage } from 'common/const/form.enum';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { rules } from 'common/helpers/form.helper';
import { PASSWORD_PATTERN } from 'common/config';
import { ReactComponent as EyeOnIcon } from 'app/assets/images/redesign/eyeOn.svg';
import { ReactComponent as EyeOffIcon } from 'app/assets/images/redesign/eyeOff.svg';
import { ReactComponent as SuccessIcon } from 'app/assets/images/redesign/success.svg';

interface IComponentProps {
  className?: string;
  label: string;
  name: string;
  checkCurrentPassword?: boolean;
}

const passwordRequirements = [
  { id: 'length', label: 'минимум 8 символов', regex: /.{8,}/ },
  { id: 'lowercase', label: 'одна строчная буква', regex: /[a-z]/ },
  { id: 'uppercase', label: 'одна заглавная буква', regex: /[A-Z]/ },
];

export const NewPasswordFormField: FC<IComponentProps> = ({ className, label, name, checkCurrentPassword }) => {
  const [validations, setValidations] = useState<{ [key: string]: boolean }>({});
  const [newPasswordIsFocused, setNewPasswordIsFocused] = useState<boolean>(false);

  return (
    <Form.Item className={className} label={label}>
      <Form.Item
        className="mb-0"
        name={name}
        validateTrigger={['onChange', 'onBlur']}
        dependencies={checkCurrentPassword ? ['currentPassword'] : undefined}
        rules={[
          rules.required(),
          rules.pattern(PASSWORD_PATTERN, EFormFieldMessage.InvalidPasswordFormat, 'onBlur'),
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (checkCurrentPassword) {
                if (!value || getFieldValue('currentPassword') !== value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(EFormFieldMessage.PasswordsMatch));
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
      >
        <Input.Password
          placeholder={EPlaceholder.EnterNewPassword}
          iconRender={(visible) => (visible ? <EyeOnIcon /> : <EyeOffIcon />)}
          onChange={(e) => {
            const newValidations: { [key: string]: boolean } = {};

            passwordRequirements.forEach((requirement) => {
              newValidations[requirement.id] = requirement.regex.test(e.target.value);
            });

            setValidations(newValidations);
          }}
          onFocus={() => setNewPasswordIsFocused(true)}
          onBlur={() => setNewPasswordIsFocused(false)}
        />
      </Form.Item>

      {newPasswordIsFocused && (
        <div className="password-requirements">
          {passwordRequirements.map((requirement) => {
            return (
              <div key={requirement.id} className={`password-requirements__item ${validations[requirement.id] ? 'checked' : ''}`}>
                <SuccessIcon />

                <span className="password-requirements__item-label">{requirement.label}</span>
              </div>
            );
          })}
        </div>
      )}
    </Form.Item>
  );
};
