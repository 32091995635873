import React, { FC } from 'react';
import { ReactComponent as LogoIcon } from 'app/assets/images/redesign/logo.svg';

export const AuthCardLogo: FC = () => {
  return (
    <div className="auth-card-logo">
      <div className="auth-card-logo__icon">
        <LogoIcon />
      </div>

      <span>sprout</span>
    </div>
  );
};
