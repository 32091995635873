import React, { FC, useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Menu, Spin } from 'antd';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { ERoute } from 'common/const/route.enum';
import { ESettingsMenuTab, ESettingsTab } from 'common/const/settings.enum';
import { stringToNumber } from 'common/helpers/common.helper';
import { DEFAULT_VALUE_1 } from 'common/config';
import { ReactComponent as ArrowRightShortIcon } from 'app/assets/images/redesign/arrow-right-short.svg';
import { RootDispatch, RootState } from 'app/store';
import { SubdivisionGeneralSettings } from 'entities/Settings/components/SubdivisionSettings/General/SubdivisionGeneralSettings';
import { SubdivisionUserListSettings } from 'entities/Settings/components/SubdivisionSettings/UserList/SubdivisionUserListSettings';
import { SubdivisionSettingsAddressList } from 'entities/Settings/components/SubdivisionSettings/AddressList/SubdivisionSettingsAddressList';
import { SubdivisionSettingsName } from 'entities/Settings/components/SubdivisionSettings/SubdivisionSettingsName';
import { getSubdivisionSettingsBreadcrumbItems, getSubdivisionTreeKeysBySubdivisionId } from 'entities/Settings/Settings.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ subdivisionList, subdivision, subdivisionLoading, getSubdivisionById, updateSubdivision }) => {
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const { id, menu } = useParams();
  const navigate = useNavigate();

  const selectedSubdivisionTreeKeys = getSubdivisionTreeKeysBySubdivisionId(subdivisionList, id);
  const subdivisionBreadcrumbItems = getSubdivisionSettingsBreadcrumbItems(subdivisionList, selectedSubdivisionTreeKeys, (id) => {
    navigate(`${ERoute.Settings}/${ESettingsTab.Subdivision}/${id}/${menu}`);
  });

  const header = useMemo(() => {
    return (
      <>
        <div className="subdivision-settings__breadcrumb mb-20">
          <Breadcrumb
            items={subdivisionBreadcrumbItems.length > DEFAULT_VALUE_1 ? subdivisionBreadcrumbItems : []}
            separator={<ArrowRightShortIcon className="icon-arrow-right-short-light-grey" />}
          />
        </div>

        <SubdivisionSettingsName subdivision={subdivision} loading={subdivisionLoading} onSubdivisionUpdate={updateSubdivision} />

        <Menu
          items={[
            { key: ESettingsMenuTab.General, label: 'Настройки подразделения' },
            { key: ESettingsMenuTab.UserList, label: 'Пользователи' },
            { key: ESettingsMenuTab.AddressList, label: 'Адреса доставки' },
          ]}
          selectedKeys={[!menu ? ESettingsMenuTab.General : menu]}
          onClick={({ key }) => navigate(`${ERoute.Settings}/${ESettingsTab.Subdivision}/${subdivision?.id}/${key}`)}
          mode="horizontal"
        />
      </>
    );
  }, [subdivision, subdivisionLoading, menu]);

  const renderContent = () => {
    switch (menu) {
      case ESettingsMenuTab.AddressList: {
        return <SubdivisionSettingsAddressList header={header} subdivision={subdivision} />;
      }
      case ESettingsMenuTab.UserList: {
        return <SubdivisionUserListSettings header={header} subdivision={subdivision} />;
      }
      default: {
        return <SubdivisionGeneralSettings header={header} subdivision={subdivision} onSubdivisionUpdate={updateSubdivision} />;
      }
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (id) {
        await getSubdivisionById({ id: stringToNumber(id) });
      }

      setFetchLoading(false);
    };

    fetch();
  }, [id]);

  if (fetchLoading) {
    return (
      <div className="subdivision-settings">
        <Spin indicator={<SpinIndicator />} />
      </div>
    );
  }

  return <div className="subdivision-settings">{renderContent()}</div>;
};

const mapState = (state: RootState) => ({
  subdivisionList: state.subdivisionListState.data,
  subdivision: state.subdivisionState.data,
  subdivisionLoading: state.subdivisionState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSubdivisionById: dispatch.subdivisionState.getSubdivisionById,
  updateSubdivision: dispatch.subdivisionState.updateSubdivision,
});

export const SubdivisionSettings = connect(mapState, mapDispatch)(Component);
