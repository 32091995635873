import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthLayout } from 'common/components/AuthLayout';
import { PrivateLayout } from 'common/components/PrivateLayout';
import { ERoute } from 'common/const/route.enum';
import { RootDispatch } from 'app/store';

type AllType = ReturnType<typeof mapDispatch>;

const AppComponent: React.FC<AllType> = ({ initAuth }) => {
  useEffect(() => {
    initAuth();
  }, []);

  return (
    <div className="app">
      <Routes>
        <Route path={ERoute.Login} element={<AuthLayout path={ERoute.Login} />} />
        <Route path={ERoute.Activation} element={<AuthLayout path={ERoute.Activation} />} />
        <Route path={ERoute.RestorePassword} element={<AuthLayout path={ERoute.RestorePassword} />} />
        <Route path="*" element={<PrivateLayout />} />
      </Routes>
    </div>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  initAuth: dispatch.authState.initAuth,
});

export const App = connect(null, mapDispatch)(AppComponent);
