import {
  IAddress,
  IAddressListState,
  IAddressState,
  IAddressSuggestion,
  IAddressSuggestionListState,
} from 'entities/Address/Address.models';

export const addressSuggestionListStateReducers = {
  setList: (state: IAddressSuggestionListState, payload: IAddressSuggestion[]) => ({ ...state, data: payload }),
  setLoading: (state: IAddressSuggestionListState, payload: boolean) => ({ ...state, loading: payload }),
};

export const addressListStateReducers = {
  setList: (state: IAddressListState, payload: IAddress[]) => ({ ...state, data: payload }),
  setCount: (state: IAddressListState, payload: number) => ({ ...state, count: payload }),
  setLoading: (state: IAddressListState, payload: boolean) => ({ ...state, loading: payload }),
  addAddress: (state: IAddressListState, payload: IAddress) => ({
    ...state,
    data: [...state.data, payload],
    count: state.count + 1,
  }),
  updateAddress: (state: IAddressListState, payload: IAddress) => ({
    ...state,
    data: state.data.map((address) => (address.id === payload.id ? payload : address)),
  }),
  reassignAddress: (state: IAddressListState, payload: IAddress) => ({
    ...state,
    data: state.data.map((address) => (address.id === payload.id ? payload : { ...address, isMain: false })),
  }),
  deleteAddress: (state: IAddressListState, payload: number) => ({
    ...state,
    data: state.data.filter((address) => address.id !== payload),
    count: state.count - 1,
  }),
};

export const addressStateReducers = {
  setAddress: (state: IAddressState, payload: IAddress) => ({ ...state, data: payload }),
  setLoading: (state: IAddressState, payload: boolean) => ({ ...state, loading: payload }),
};
