import React, { FC, ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Collapse, Select, Spin } from 'antd';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { IconBackground } from 'common/components/IconBackground';
import { EColor } from 'common/const/color.enum';
import { mapToMenuWithDivider } from 'common/helpers/common.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { LIST_LIMIT_0 } from 'common/config';
import { ReactComponent as DeliveryIcon } from 'app/assets/images/redesign/delivery.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as ArrowUpShortIcon } from 'app/assets/images/redesign/arrow-up-short.svg';
import { ReactComponent as ArrowRightLongIcon } from 'app/assets/images/redesign/arrow-right-long.svg';
import { RootDispatch, RootState } from 'app/store';
import { IContract } from 'entities/Contract/Contract.models';
import { deliveryInDaysOptions } from 'entities/Delivery/Delivery.const';

interface IComponentProps {
  header: ReactNode;
  contract: IContract | null;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    contract,
    // state
    stockList,
    buyerCityList,
    deliveryList,
    // dispatch
    getStockList,
    getBuyerCityList,
    getDeliveryList,
    createDelivery,
    updateDelivery,
    deleteDelivery,
  } = props;

  const [fetchLoading, setFetchLoading] = useState<boolean>(true);

  const successMessage = 'Срок доставки успешно изменен.';

  const onCreateDelivery = (city: string, stockId: number, deliveryInDays: number) => {
    if (contract) {
      createDelivery({
        contractId: contract.id,
        city,
        stockId,
        deliveryInDays,
        onSuccess: () => {
          showSuccessMessage(successMessage);
        },
      });
    }
  };

  const onUpdateDelivery = (id: number, deliveryInDays: number) => {
    updateDelivery({
      id,
      deliveryInDays,
      onSuccess: () => {
        showSuccessMessage(successMessage);
      },
    });
  };

  const onDeletedDelivery = (id: number) => {
    deleteDelivery({
      id,
      onSuccess: () => {
        showSuccessMessage(successMessage);
      },
    });
  };

  useEffect(() => {
    const fetch = async () => {
      if (contract) {
        await getStockList({ limit: LIST_LIMIT_0 });
        await getBuyerCityList({ buyerId: contract.buyerId });
        await getDeliveryList({ contractId: contract.id, limit: LIST_LIMIT_0 });
      }

      setFetchLoading(false);
    };

    fetch();
  }, [contract?.id]);

  return (
    <InfiniteScrollContainer>
      {header}

      <Spin spinning={fetchLoading} indicator={<SpinIndicator />}>
        <Collapse
          accordion
          className="collapse"
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? (
              <ArrowUpShortIcon className="icon-arrow-up-short-dark-grey" />
            ) : (
              <ArrowDownShortIcon className="icon-arrow-down-short-dark-grey" />
            )
          }
          items={stockList.map((stock) => {
            return {
              key: stock.id,
              label: (
                <div className="contract-delivery-time-list-settings__collapse-header">
                  <IconBackground content={<DeliveryIcon className="icon-delivery-bright-green" />} color={EColor.PaleGreen} />

                  <div style={{ flex: 1 }}>
                    <div className="text-controls-accent mb-4">{stock.name}</div>

                    <span className="text-tag color-dark-grey">{stock.address}</span>
                  </div>
                </div>
              ),
              children: buyerCityList.map((city, index) => {
                const delivery = deliveryList.find((item) => item.stockId === stock.id && item.city === city);

                return (
                  <div key={index} className="collapse__content-row contract-delivery-time-list-settings__collapse-content-row">
                    <div className="contract-delivery-time-list-settings__collapse-content-row-container">
                      <IconBackground
                        content={<ArrowRightLongIcon className="icon-arrow-right-long-dark-grey" />}
                        color={EColor.LightGrey}
                      />

                      <span className="text-controls-accent" style={{ flex: 1 }}>
                        {city}
                      </span>
                    </div>

                    <div
                      className={`contract-delivery-time-list-settings__collapse-content-row-selector ${
                        delivery ? 'bright-green' : 'light-grey'
                      }`}
                    >
                      <DeliveryIcon className="contract-delivery-time-list-settings__collapse-content-row-selector-icon" />

                      <Select
                        popupClassName="redesign contract-delivery-time-list-settings__collapse-content-row-selector-select"
                        virtual={false}
                        popupMatchSelectWidth={false}
                        suffixIcon={null}
                        options={mapToMenuWithDivider(deliveryInDaysOptions)}
                        value={delivery ? delivery.deliveryInDays : null}
                        onChange={(value) => {
                          if (!delivery && value) {
                            onCreateDelivery(city, stock.id, value);
                          }

                          if (delivery && value) {
                            onUpdateDelivery(delivery.id, value);
                          }

                          if (delivery && !value) {
                            onDeletedDelivery(delivery.id);
                          }
                        }}
                      />
                    </div>
                  </div>
                );
              }),
            };
          })}
        />
      </Spin>
    </InfiniteScrollContainer>
  );
};

const mapState = (state: RootState) => ({
  stockList: state.stockListState.data,
  buyerCityList: state.buyerCityListState.data,
  deliveryList: state.deliveryListState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getStockList: dispatch.stockListState.getStockList,
  getBuyerCityList: dispatch.buyerCityListState.getBuyerCityList,
  getDeliveryList: dispatch.deliveryListState.getDeliveryList,
  createDelivery: dispatch.deliveryState.createDelivery,
  updateDelivery: dispatch.deliveryState.updateDelivery,
  deleteDelivery: dispatch.deliveryState.deleteDelivery,
});

export const ContractDeliveryTimeListSettings = connect(mapState, mapDispatch)(Component);
