import React, { FC, useMemo, useState } from 'react';
import { Button, Drawer, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { WarningCard } from 'common/components/WarningCard';
import { SelectWithSearch } from 'common/components/SelectWithSearch';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { ERoute } from 'common/const/route.enum';
import { ESettingsMenuTab, ESettingsTab } from 'common/const/settings.enum';
import { rules } from 'common/helpers/form.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { renderSelectCustomOptionWithUser } from 'common/helpers/select.helper';
import { useSubdivisionContext } from 'common/hooks/useSubdivisionContext';
import { RootDispatch, RootState } from 'app/store';
import {
  getSubdivisionSettingsSubdivisionHeadOptions,
  getSubdivisionSettingsSubdivisionLegalEntityOptions,
} from 'entities/Settings/Settings.helper';
import { ISubdivisionCreatePayload } from 'entities/Subdivision/Subdivision.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ userList, legalEntityList, subdivisionLoading, createSubdivision }) => {
  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
  const [form] = useForm();
  const navigate = useNavigate();
  const { parentId, openSubdivisionCreateCard, setOpenSubdivisionCreateCard } = useSubdivisionContext();

  const subdivisionHeadOptions = useMemo(() => {
    return getSubdivisionSettingsSubdivisionHeadOptions(userList);
  }, [userList]);
  const legalEntityOptions = useMemo(() => {
    return getSubdivisionSettingsSubdivisionLegalEntityOptions(legalEntityList);
  }, [legalEntityList]);

  const onClose = () => {
    setFormIsChanged(false);
    setOpenWarningCard(false);
    setOpenSubdivisionCreateCard(false);
    form.resetFields();
  };

  const handleClose = () => {
    if (subdivisionLoading) {
      return;
    }

    if (formIsChanged) {
      setOpenSubdivisionCreateCard(false);
      setOpenWarningCard(true);
      return;
    }

    onClose();
  };

  const onSubmit = async (values: ISubdivisionCreatePayload) => {
    const response = await createSubdivision({ ...values, parentId });

    if (response) {
      showSuccessMessage('Новое подразделение добавлено в аккаунт.');
      onClose();
      navigate(`${ERoute.Settings}/${ESettingsTab.Subdivision}/${response.id}/${ESettingsMenuTab.General}`);
    }
  };

  return (
    <>
      <Drawer
        className="redesign drawer subdivision-create-card"
        open={openSubdivisionCreateCard}
        width={720}
        destroyOnClose
        onClose={handleClose}
        footer={
          <Button className="button-lg primary" onClick={form.submit} loading={subdivisionLoading}>
            Добавить подразделение
          </Button>
        }
      >
        <div className="drawer__body">
          <div className="drawer__title mb-52">Новое подразделение</div>

          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onSubmit}
            onValuesChange={() => setFormIsChanged(true)}
          >
            <Form.Item className="mb-32" label="Название подразделения*" name="name" rules={[rules.required()]}>
              <Input placeholder={EPlaceholder.EnterName} />
            </Form.Item>

            <Form.Item className="mb-32" label="Руководитель подразделения" name="headOfDepartmentId">
              <SelectWithSearch
                placeholder={EPlaceholder.SelectFromEmployeeList}
                options={subdivisionHeadOptions}
                optionRender={(option) => {
                  const user = userList.find((item) => item.id === option.value);

                  return user ? renderSelectCustomOptionWithUser(user) : option.label;
                }}
              />
            </Form.Item>

            <Form.Item className="mb-32" label="Юридическое лицо" name="legalId">
              <SelectWithSearch placeholder={EPlaceholder.SelectFromCompanyLegalEntityList} options={legalEntityOptions} />
            </Form.Item>
          </Form>
        </div>
      </Drawer>

      <WarningCard
        open={openWarningCard}
        content="Вы уверены, что не хотите сохранить изменения?"
        subtitle="Введенные данные будут потеряны."
        confirmBtnTitle="Не сохранять"
        onConfirm={onClose}
        onClose={() => {
          setOpenWarningCard(false);
          setOpenSubdivisionCreateCard(true);
        }}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  userList: state.userListState.data,
  legalEntityList: state.legalEntityListState.data,
  subdivisionLoading: state.subdivisionState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  createSubdivision: dispatch.subdivisionState.createSubdivision,
});

export const SubdivisionCreateCard = connect(mapState, mapDispatch)(Component);
