import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Spin, Table } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { EUserRole } from 'common/const/user.enum';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { showMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { LIST_LIMIT_0 } from 'common/config';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { IContract } from 'entities/Contract/Contract.models';
import { UserAddModal } from 'entities/User/components/UserAddModal';
import { IUser } from 'entities/User/User.models';
import { renderContractGeneralSettingsUserListRecords } from 'entities/Settings/Settings.helper';

interface IComponentProps {
  header: ReactNode;
  contract: IContract | null;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    contract,
    // state
    userList,
    contractLoading,
    contractUserList,
    // dispatch
    getUserList,
    getContractUserList,
    createContractUser,
    deleteContractUser,
  } = props;

  const [openUserAddModal, setOpenUserAddModal] = useState<boolean>(false);

  const contractSellerManagerListIds = contractUserList.map((user) => user.id);
  const sellerManagerList = userList.filter((user) => !contractSellerManagerListIds.includes(user.id));

  const onOpenUserAddModalClick = () => {
    getUserList({
      limit: LIST_LIMIT_0,
      role: EUserRole.SellerManager,
      onSuccess: () => setOpenUserAddModal(true),
    });
  };

  const onAddContractManagerClick = (value: IUser, onSuccess: () => void) => {
    if (contract) {
      createContractUser({
        contractId: contract.id,
        userId: value.id,
        onSuccess: () => {
          onSuccess();
          showSuccessMessage(`Новый менеджер добавлен для контракта «${contract.buyerName}».`);
        },
      });
    }
  };

  const onDeleteContractManagerClick = (id: number) => {
    if (contract) {
      deleteContractUser({
        contractId: contract.id,
        userId: id,
        onSuccess: () => {
          showMessage({
            icon: <BinIcon className="icon-bin-dark-grey" />,
            content: `Менеджер удален из контракта «${contract.buyerName}».`,
          });
        },
      });
    }
  };

  useEffect(() => {
    if (contract?.id) {
      getContractUserList({ contractId: contract.id });
    }
  }, [contract?.id]);

  return (
    <>
      <InfiniteScrollContainer>
        {header}

        <Spin spinning={contractLoading} indicator={<SpinIndicator />}>
          <div className="text-h4 mb-6">Менеджер по контракту</div>

          <div className="text-tag color-dark-grey mb-20" style={{ maxWidth: 800 }}>
            Менеджер может обрабатывать входящие заявки, добавлять товары в корзины клиентов и управлять настройками контракта.
            Количество менеджеров контракта не ограничено.
          </div>

          <Button
            className="button-sm primary mb-20"
            icon={<PlusIcon className="icon-plus-white" />}
            onClick={onOpenUserAddModalClick}
          >
            Добавить менеджера
          </Button>

          {!!contractUserList?.length && (
            <Table
              className="mb-72 contract-general-settings__table"
              showHeader={false}
              pagination={false}
              size="middle"
              dataSource={contractUserList.map((user) => ({ ...user, key: user.id }))}
              columns={renderContractGeneralSettingsUserListRecords(onDeleteContractManagerClick)}
            />
          )}
        </Spin>
      </InfiniteScrollContainer>

      <UserAddModal
        open={openUserAddModal}
        placeholder={EPlaceholder.SearchByEmployees}
        loading={false}
        userList={sellerManagerList}
        onClose={() => setOpenUserAddModal(false)}
        onAdd={onAddContractManagerClick}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  userList: state.userListState.data,
  contractLoading: state.contractState.loading,
  contractUserList: state.contractState.userList,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserList: dispatch.userListState.getUserList,
  getContractUserList: dispatch.contractState.getContractUserList,
  createContractUser: dispatch.contractState.createContractUser,
  deleteContractUser: dispatch.contractState.deleteContractUser,
});

export const ContractGeneralSettings = connect(mapState, mapDispatch)(Component);
