import React, { FC } from 'react';
import { Progress } from 'antd';
import { AuthCardLogo } from 'entities/Auth/components/AuthCardLogo';
import { AuthCardFooter } from 'entities/Auth/components/AuthCardFooter';

interface IComponentProps {
  firstName?: string;
  patronymic?: string;
}

export const ActivationSuccessCard: FC<IComponentProps> = ({ firstName, patronymic }) => {
  return (
    <div className="auth-card">
      <AuthCardLogo />

      <div className="auth-card__content">
        <div className="auth-card__content-success-container">
          <div className="text-h1-drawers">Добро пожаловать,</div>

          <div style={{ textAlign: 'center' }}>
            <span className="text-h1-drawers">{firstName}</span>

            <span className="text-h1-drawers">{patronymic ? ` ${patronymic}!` : '!'}</span>
          </div>
        </div>
      </div>

      <AuthCardFooter content={<Progress showInfo={false} percent={100} steps={21} strokeColor="#69A443" />} />
    </div>
  );
};
