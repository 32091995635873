import axios from 'axios';
import {
  IAddressSuggestionCollectionDto,
  IAddressSuggestionListParams,
  IAddress,
  IAddressCreatePayload,
  IAddressListParams,
  IAddressCollectionDto,
  IAddressUpdatePayload,
  IAddressDeletePayload,
} from 'entities/Address/Address.models';

const basePath = 'address';

export const addressesTransport = {
  getAddressSuggestionList: (params: IAddressSuggestionListParams): Promise<IAddressSuggestionCollectionDto> =>
    axios.get(`${basePath}/suggestions`, { params }),
  getAddressList: (params: IAddressListParams): Promise<IAddressCollectionDto> => axios.get(`${basePath}`, { params }),
  createAddress: (payload: IAddressCreatePayload): Promise<IAddress> => axios.post(`${basePath}`, payload),
  updateAddress: (payload: IAddressUpdatePayload): Promise<IAddress> => axios.patch(`${basePath}`, payload),
  deleteAddress: (payload: IAddressDeletePayload): Promise<IAddress> => axios.delete(`${basePath}`, { data: payload }),
};
