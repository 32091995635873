import React, { FC, ReactNode, useEffect } from 'react';
import { Form, InputNumber, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { debounce } from 'common/helpers/common.helper';
import { IFormValue } from 'common/models';
import { IAccount, IAccountUpdatePayload } from 'entities/Account/Account.models';

interface IComponentProps {
  header: ReactNode;
  account: IAccount | null;
  onAccountUpdate: (payload: IAccountUpdatePayload) => Promise<void>;
}

export const AccountContractsSettings: FC<IComponentProps> = ({ header, account, onAccountUpdate }) => {
  const [form] = useForm();

  const debouncedAccountUpdate = debounce(onAccountUpdate);

  const onFormValuesChange = (value: IFormValue) => {
    if (account) {
      debouncedAccountUpdate({
        id: account.id,
        ...value,
        onSuccess: () => {
          if ('priceRounding' in value) {
            showSuccessMessage(`Округление итоговой суммы спецификации ${value.priceRounding ? 'включено' : 'отключено'}.`);
          } else {
            showSuccessMessage('Лимит цены за ед. товара изменен в настройках аккаунта.');
          }
        },
      });
    }
  };

  useEffect(() => {
    if (account) {
      form.setFieldsValue({
        priceRounding: account.priceRounding,
        maxGoodPrice: account.maxGoodPrice,
      });
    }
  }, [account]);

  return (
    <InfiniteScrollContainer>
      {header}

      <Form className="account-contracts-settings" layout="vertical" form={form} onValuesChange={onFormValuesChange}>
        <div className="account-contracts-settings__row">
          <div className="account-contracts-settings__row-container">
            <div className="text-h4 mb-6">Округление итоговой суммы</div>

            <span className="text-tag color-dark-grey">
              Когда включена эта функция, цены на товары будут округляться до рублей.
            </span>
          </div>

          <Form.Item noStyle name="priceRounding" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>

        <div className="account-contracts-settings__row">
          <div className="account-contracts-settings__row-container">
            <div className="text-h4 mb-6">Лимиты на закупку</div>

            <div className="text-tag color-dark-grey mb-20">
              Установленные в настройках аккаунта лимиты применяются ко всем подразделениям. Изменить лимит для отдельного
              подразделения можно в настройках подразделения.
            </div>

            <Form.Item label="Цена за ед. товара" name="maxGoodPrice">
              <InputNumber
                className="account-contracts-settings__row-input"
                placeholder="0.00"
                suffix="₽"
                controls={false}
                precision={0}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </InfiniteScrollContainer>
  );
};
