import React, { FC, ReactNode, useMemo } from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { ESupplyListMenuTab } from 'common/const/supply.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { getFilterOptions } from 'common/helpers/option.helper';
import { RootState } from 'app/store';
import { PendingSupplyListForManager } from 'entities/Supply/components/Manager/PendingSupplyListForManager';
import { ProcessingSupplyListForManager } from 'entities/Supply/components/Manager/ProcessingSupplyListForManager';
import { CompletedSupplyListForManager } from 'entities/Supply/components/Manager/CompletedSupplyListForManager';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const Component: FC<AllType> = ({ header, navigation, subdivisionList, userList }) => {
  const { getSearchParam, setSearchParam } = useSearchParamsHook();

  const subdivisionOptions = getFilterOptions.subdivision(subdivisionList);
  const userOptions = getFilterOptions.user(userList);
  const tab = getSearchParam(ESearchParams.Tab);
  const isProcessingSupplyListPage = tab === ESupplyListMenuTab.Processing;
  const isCompletedSupplyListPage = tab === ESupplyListMenuTab.Completed;
  const menu = useMemo(() => {
    return (
      <Menu
        items={[
          { key: ESupplyListMenuTab.Pending, label: 'Требующие подтверждения' },
          { key: ESupplyListMenuTab.Processing, label: 'Ожидаемые поставки' },
          { key: ESupplyListMenuTab.Completed, label: 'Завершённые поставки' },
        ]}
        selectedKeys={[tab === null ? ESupplyListMenuTab.Pending : tab]}
        onClick={({ key }) => setSearchParam(ESearchParams.Tab, key)}
        mode="horizontal"
      />
    );
  }, [tab]);

  return isProcessingSupplyListPage ? (
    <ProcessingSupplyListForManager
      header={header}
      navigation={navigation}
      menu={menu}
      subdivisionOptions={subdivisionOptions}
      userOptions={userOptions}
    />
  ) : isCompletedSupplyListPage ? (
    <CompletedSupplyListForManager
      header={header}
      navigation={navigation}
      menu={menu}
      subdivisionOptions={subdivisionOptions}
      userOptions={userOptions}
    />
  ) : (
    <PendingSupplyListForManager
      header={header}
      navigation={navigation}
      menu={menu}
      subdivisionOptions={subdivisionOptions}
      userOptions={userOptions}
    />
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.supplyListState.subdivisionList,
  userList: state.supplyListState.userList,
});

export const SupplyListForManager = connect(mapState)(Component);
