import { init, RematchRootState, RematchDispatch, Models } from '@rematch/core';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { accountListState, accountState } from 'entities/Account/Account.slice';
import { modalCollection } from 'entities/Modal/Modal.slice';
import { authState } from 'entities/Auth/Auth.slice';
import { invitationState } from 'entities/Invitation/Invitation.slice';
import { statisticsState, userInventoryStatisticsState, userListState, userState } from 'entities/User/User.slice';
import { subdivisionListState, subdivisionState } from 'entities/Subdivision/Subdivision.slice';
import { addressSuggestionListState, addressListState, addressState } from 'entities/Address/Address.slice';
import {
  groupedWorkspaceState,
  workspaceListState,
  workspacePositionState,
  workspaceState,
} from 'entities/Workspace/Workspace.slice';
import { categoryListState, categoryState } from 'entities/Category/Category.slice';
import { propertyCascaderState, propertyListState, propertyTreeState } from 'entities/Property/Property.slice';
import { catalogListState, catalogState } from 'entities/Catalog/Catalog.slice';
import { sellerListState } from 'entities/Sellers/Sellers.slice';
import { basketListState, basketState } from 'entities/Basket/Basket.slice';
import { goodsListState, goodsState } from 'entities/Goods/Goods.slice';
import { brandListState } from 'entities/Brand/Brand.slice';
import { filesState } from 'entities/Files/Files.slice';
import { buyerListState } from 'entities/Buyer/Buyer.slice';
import { contractState, contractListState } from 'entities/Contract/Contract.slice';
import { legalEntityState, legalEntityListState } from 'entities/LegalEntity/LegalEntity.slice';
import { brandDiscountListState, brandDiscountState } from 'entities/Discount/Discount.slice';
import { stockState, stockListState } from 'entities/Stock/Stock.slice';
import { buyerCityListState, deliveryState, deliveryListState } from 'entities/Delivery/Delivery.slice';
import { needListState, needState } from 'entities/Need/Need.slice';
import { groupedSupplyListState, supplyListState, supplyState } from 'entities/Supply/Supply.slice';
import { searchSuggestionsState } from 'entities/Search/Search.slice';
import {
  inventoryActionListState,
  inventoryActionState,
  inventoryListState,
  inventoryState,
} from 'entities/Inventory/Inventory.slice';
import { UIState } from 'entities/UI/UI.slice';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const reducers = { router: routerReducer };

export interface IRootModel extends Models<IRootModel> {
  authState: typeof authState;
  accountState: typeof accountState;
  accountListState: typeof accountListState;
  invitationState: typeof invitationState;
  userState: typeof userState;
  userListState: typeof userListState;
  statisticsState: typeof statisticsState;
  userInventoryStatisticsState: typeof userInventoryStatisticsState;
  subdivisionListState: typeof subdivisionListState;
  subdivisionState: typeof subdivisionState;
  addressSuggestionListState: typeof addressSuggestionListState;
  addressListState: typeof addressListState;
  addressState: typeof addressState;
  workspaceListState: typeof workspaceListState;
  workspaceState: typeof workspaceState;
  groupedWorkspaceState: typeof groupedWorkspaceState;
  workspacePositionState: typeof workspacePositionState;
  categoryListState: typeof categoryListState;
  categoryState: typeof categoryState;
  propertyListState: typeof propertyListState;
  catalogListState: typeof catalogListState;
  catalogState: typeof catalogState;
  sellerListState: typeof sellerListState;
  basketState: typeof basketState;
  basketListState: typeof basketListState;
  goodsListState: typeof goodsListState;
  goodsState: typeof goodsState;
  brandListState: typeof brandListState;
  filesState: typeof filesState;
  buyerListState: typeof buyerListState;
  propertyCascaderState: typeof propertyCascaderState;
  propertyTreeState: typeof propertyTreeState;
  contractListState: typeof contractListState;
  contractState: typeof contractState;
  legalEntityListState: typeof legalEntityListState;
  legalEntityState: typeof legalEntityState;
  brandDiscountListState: typeof brandDiscountListState;
  brandDiscountState: typeof brandDiscountState;
  stockListState: typeof stockListState;
  stockState: typeof stockState;
  buyerCityListState: typeof buyerCityListState;
  deliveryListState: typeof deliveryListState;
  deliveryState: typeof deliveryState;
  needListState: typeof needListState;
  needState: typeof needState;
  supplyListState: typeof supplyListState;
  groupedSupplyListState: typeof groupedSupplyListState;
  supplyState: typeof supplyState;
  modalCollection: typeof modalCollection;
  searchSuggestionsState: typeof searchSuggestionsState;
  inventoryListState: typeof inventoryListState;
  inventoryState: typeof inventoryState;
  inventoryActionListState: typeof inventoryActionListState;
  inventoryActionState: typeof inventoryActionState;
  UIState: typeof UIState;
}

const models = {
  authState,
  accountState,
  accountListState,
  invitationState,
  userState,
  userListState,
  statisticsState,
  userInventoryStatisticsState,
  subdivisionListState,
  subdivisionState,
  addressSuggestionListState,
  addressListState,
  addressState,
  workspaceListState,
  workspaceState,
  groupedWorkspaceState,
  workspacePositionState,
  categoryListState,
  categoryState,
  propertyListState,
  catalogListState,
  catalogState,
  sellerListState,
  basketState,
  basketListState,
  goodsListState,
  goodsState,
  brandListState,
  filesState,
  buyerListState,
  propertyCascaderState,
  propertyTreeState,
  contractListState,
  contractState,
  legalEntityListState,
  legalEntityState,
  brandDiscountListState,
  brandDiscountState,
  stockListState,
  stockState,
  buyerCityListState,
  deliveryListState,
  deliveryState,
  needListState,
  needState,
  supplyListState,
  groupedSupplyListState,
  supplyState,
  modalCollection,
  searchSuggestionsState,
  inventoryListState,
  inventoryState,
  inventoryActionListState,
  inventoryActionState,
  UIState,
} as IRootModel;

const store = init<IRootModel>({
  redux: {
    reducers,
    middlewares: [routerMiddleware],
    devtoolOptions: {},
  },
  models,
});

export default store;
export const history = createReduxHistory(store);
export type RootState = RematchRootState<IRootModel>;
export type RootDispatch = RematchDispatch<IRootModel>;
