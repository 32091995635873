import React, { FC } from 'react';
import { EColor } from 'common/const/color.enum';

interface IComponentProps {
  color: EColor;
  name: string;
}

export const UserRoleChip: FC<IComponentProps> = ({ color, name }) => {
  return (
    <div className={`user-role-chip ${color}`}>
      <span className="user-role-chip__name">{name}</span>
    </div>
  );
};
