import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Drawer, Form, InputNumber, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Notification } from 'common/components/Notification';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { EMode } from 'common/const/common.enum';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { useWorkspaceContext } from 'common/hooks/useWorkspaceContext';
import { usePropertyContext } from 'common/hooks/usePropertyContext';
import { IFormValue } from 'common/models';
import { RootDispatch, RootState } from 'app/store';
import {
  getWorkspacePositionOptionalPropertiesForView,
  getWorkspacePositionRequiredPropertiesForView,
  mapFormValuesToWorkspacePositionPropertyListWithResult,
} from 'entities/Workspace/Workspace.helper';
import { IWorkspaceCategory } from 'entities/Workspace/Workspace.models';
import { WorkspaceSimilarPositions } from 'entities/Workspace/components/WorkspaceSimilarPositions';
import { WorkspacePositionChips } from 'entities/Workspace/components/WorkspacePositionChips';
import {
  getPropertyPresetType,
  propertyRangeResultToRangeValue,
  renderPropertyFormField,
  setPropertyListItemHidden,
} from 'entities/Property/Property.helper';

interface IComponentProps {
  categories?: IWorkspaceCategory[];
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

export const Component: FC<AllType> = (props) => {
  const {
    // props
    categories,
    // state
    workspacePosition,
    workspacePositionLoading,
    workspacePositionError,
    // dispatch
    setPropertyList,
    setWorkspacePositionError,
    createWorkspacePosition,
    updateWorkspacePosition,
    increaseGroupedWorkspacePositionsCount,
  } = props;

  const [form] = useForm();
  const { openEditPositionCard, setOpenEditPositionCard, editPositionCardMode, setEditPositionCardMode } = useWorkspaceContext();
  const { propertiesForView, onPropertiesForViewChange, initialFormValues } = usePropertyContext();

  const isCopyMode = editPositionCardMode === EMode.Copy;
  const propertiesAsCategories = propertiesForView.filter((property) => !!property.isCategory);
  const requiredPropertiesForView = getWorkspacePositionRequiredPropertiesForView(propertiesForView);
  const optionalPropertiesForView = getWorkspacePositionOptionalPropertiesForView(propertiesForView);

  const handleClose = () => {
    if (workspacePositionLoading) {
      return;
    }

    setOpenEditPositionCard(false);
    setEditPositionCardMode(EMode.Edit);
    setWorkspacePositionError(null);
    setPropertyList([]);
  };

  const onPresetClose = (id: number) => {
    const newPropertiesForViewWithResult = propertiesForView.map((property) => {
      if (property.id === id) {
        form.resetFields([property.id]);

        return { ...property, result: [] };
      }

      return property;
    });

    const newPropertiesForVieWithHidden = setPropertyListItemHidden(newPropertiesForViewWithResult);

    setWorkspacePositionError(null);
    onPropertiesForViewChange(newPropertiesForVieWithHidden);
  };

  const onClearAllPresetClick = () => {
    const propertyListIds = propertiesForView.filter((property) => !property.isCategory).map((property) => property.id);
    const newPropertiesForViewWithResult = propertiesForView.map((property) => {
      return property.isCategory ? property : { ...property, result: [] };
    });
    const newPropertiesForVieWithHidden = setPropertyListItemHidden(newPropertiesForViewWithResult);

    setWorkspacePositionError(null);
    onPropertiesForViewChange(newPropertiesForVieWithHidden);
    form.resetFields(propertyListIds);
  };

  const onFormValuesChange = (values: IFormValue) => {
    const newPropertiesForViewWithResult = mapFormValuesToWorkspacePositionPropertyListWithResult(values, propertiesForView);
    const newPropertiesForVieWithHidden = setPropertyListItemHidden(newPropertiesForViewWithResult);

    newPropertiesForVieWithHidden.forEach((property) => {
      if (property.hidden) {
        form.resetFields([property.id]);
      }
    });

    setWorkspacePositionError(null);
    onPropertiesForViewChange(newPropertiesForVieWithHidden);
  };

  const onEditSubmit = (values: IFormValue) => {
    if (workspacePosition) {
      const { inventoryLimit } = values;

      updateWorkspacePosition({
        id: workspacePosition.id,
        categoryId: workspacePosition.categoryId,
        inventoryLimit,
        properties: propertiesForView
          .filter((property) => !!property.result?.length)
          .map((property) => {
            const { isRange } = getPropertyPresetType(property);

            return {
              propertyId: property.id,
              values: !isRange ? property.result : undefined,
              range: isRange ? propertyRangeResultToRangeValue(property.result) : undefined,
            };
          }),
        onSuccess: () => {
          handleClose();
          showSuccessMessage('Позиция была отредактирована.');
        },
      });
    }
  };

  const onCopySubmit = (values: IFormValue) => {
    if (workspacePosition) {
      const { inventoryLimit } = values;

      createWorkspacePosition({
        workspaceId: workspacePosition.workspaceId,
        categoryId: workspacePosition.categoryId,
        inventoryLimit,
        properties: propertiesForView
          .filter((property) => !!property.result?.length)
          .map((property) => {
            const { isRange } = getPropertyPresetType(property);

            return {
              propertyId: property.id,
              values: !isRange ? property.result : undefined,
              range: isRange ? propertyRangeResultToRangeValue(property.result) : undefined,
            };
          }),
        onSuccess: () => {
          handleClose();
          increaseGroupedWorkspacePositionsCount(workspacePosition.categoryId);
          showSuccessMessage('Добавлена новая позиция.');
        },
      });
    }
  };

  useEffect(() => {
    if (initialFormValues) {
      form.setFieldsValue(initialFormValues);
    }
  }, [initialFormValues]);

  useEffect(() => {
    if (!openEditPositionCard) {
      form.resetFields();
    }
  }, [openEditPositionCard]);

  if (!workspacePosition) {
    return null;
  }

  return (
    <Drawer className="redesign drawer" open={openEditPositionCard} width={1260} onClose={handleClose} destroyOnClose>
      <div className="drawer__body">
        <Spin wrapperClassName="workspace-position__spin" spinning={workspacePositionLoading} indicator={<SpinIndicator />}>
          <div className="drawer__title">{isCopyMode ? 'Уточните параметры позиции' : 'Редактировать позицию'}</div>

          <WorkspacePositionChips
            categoryName={workspacePosition.categoryName}
            propertyList={propertiesForView}
            onClear={onPresetClose}
            onClearAll={onClearAllPresetClick}
          />

          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            scrollToFirstError
            onValuesChange={onFormValuesChange}
            onFinish={isCopyMode ? onCopySubmit : onEditSubmit}
          >
            <div className="mb-72">
              <div className="workspace-position__properties">
                <Form.Item className="mb-0" label="Норма хранения" name="inventoryLimit">
                  <InputNumber suffix="шт" controls={false} />
                </Form.Item>
              </div>
            </div>

            {!!requiredPropertiesForView.length && (
              <div className="mb-72">
                <div className="text-h4 mb-32">Обязательные параметры*</div>

                <div className="workspace-position__properties">
                  {requiredPropertiesForView.map((property) => renderPropertyFormField(property))}
                </div>
              </div>
            )}

            {!!optionalPropertiesForView.length && (
              <div className="mb-72">
                <div className="text-h4 mb-32">Дополнительные параметры</div>

                <div className="workspace-position__properties">
                  {optionalPropertiesForView.map((property) => renderPropertyFormField(property))}
                </div>
              </div>
            )}

            {propertiesAsCategories.map(({ id }) => {
              return <Form.Item noStyle key={id} name={id} children={<div />} />;
            })}
          </Form>

          {workspacePositionError && (
            <div className="mb-32">
              <Notification type="error" description={workspacePositionError} />
            </div>
          )}

          <WorkspaceSimilarPositions
            position={workspacePosition}
            category={categories?.find((category) => category.categoryId === workspacePosition.categoryId)}
          />
        </Spin>
      </div>

      <div className="drawer__footer mb-72">
        <Button className="button-lg primary" onClick={form.submit} loading={workspacePositionLoading}>
          Сохранить позицию
        </Button>
      </div>
    </Drawer>
  );
};

const mapState = (state: RootState) => ({
  workspacePosition: state.workspacePositionState.data,
  workspacePositionLoading: state.workspacePositionState.loading,
  workspacePositionError: state.workspacePositionState.error,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setPropertyList: dispatch.propertyListState.setPropertyList,
  setWorkspacePositionError: dispatch.workspacePositionState.setError,
  createWorkspacePosition: dispatch.workspacePositionState.createWorkspacePosition,
  updateWorkspacePosition: dispatch.workspacePositionState.updateWorkspacePosition,
  increaseGroupedWorkspacePositionsCount: dispatch.groupedWorkspaceState.increasePositionsCount,
});

export const EditWorkspacePositionCard = connect(mapState, mapDispatch)(Component);
