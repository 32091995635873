export enum ERoute {
  Activation = '/activation',
  Login = '/login',
  SendRestorePasswordEmail = '/send-restore-password-email',
  RestorePasswordConfirmation = '/restore-password-confirmation',
  RestorePassword = '/restore-password',
  // Redesign
  // Super Admin
  CatalogList = '/catalog-list',
  CatalogListItem = '/catalog-list/:id',
  PropertiesSettings = '/properties-settings',
  AccountList = '/account-list',
  ContractList = '/contract-list',
  // Admin
  // TODO Remove
  SettingsOld = '/settings-old',
  Settings = '/settings',
  AdminContractList = '/admin-contract-list',
  Contract = '/admin-contract-list/:id',
  DeliveryForContract = '/admin-contract-list/:id/delivery/:buyerId',
  WorkspaceList = '/workspace-list',
  NewWorkspacePosition = '/new-workspace-position/:workspaceId',
  InventoryListForAccountAdmin = '/inventory-list/account_admin',
  // Seller Manager
  RequestsForSeller = '/requests/seller_manager',
  ActiveBasketList = '/active-basket-list',
  ActiveBasket = '/active-basket-list/:id',
  // Manager
  RequestsForManager = '/requests/manager',
  // Common
  Catalog = '/catalog',
  Basket = '/basket',
  RequestsForUser = '/requests/user',
  Profile = '/profile',
  Requests = '/requests',
  Workspace = '/workspace-list/:id',
  WorkspaceForUser = '/workspace',
  InventoryList = '/inventory-list',
  InventoryListForUser = '/inventory-list/user',
}
