import React, { FC, ReactNode, useMemo, useState } from 'react';
import { Button, Spin } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { LIST_LIMIT_0 } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { IContract } from 'entities/Contract/Contract.models';
import { ContractBrandsModal } from 'entities/Contract/components/ContractBrandsModal';
import { ContractCategoriesModal } from 'entities/Contract/components/ContractCategoriesModal';
import { buildTreeKeysFromCategoryList, mapCategoryListToTreeData } from 'entities/Settings/Settings.helper';

interface IComponentProps {
  header: ReactNode;
  contract: IContract | null;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    contract,
    // state
    catalogListLoading,
    categoryList,
    categoryListLoading,
    brandList,
    brandListLoading,
    currentUser,
    contractLoading,
    // dispatch
    getCatalogList,
    getCategoryList,
    getBrandList,
    updateContract,
  } = props;

  const [openContractCategoriesModal, setOpenContractCategoriesModal] = useState<boolean>(false);
  const [openContractBrandsModal, setOpenContractBrandsModal] = useState<boolean>(false);

  const accountId = currentUser?.accountId;
  const brandOptions = useMemo(() => brandList.map((brand) => ({ label: brand, value: brand })), [brandList]);
  const categoryTreeData = useMemo(() => mapCategoryListToTreeData(categoryList), [categoryList]);
  const allCategoryTreeKeys = useMemo(() => buildTreeKeysFromCategoryList(categoryList, null), [categoryList]);

  const onOpenContractCategoriesModal = async () => {
    const catalogList = await getCatalogList({ limit: LIST_LIMIT_0 });

    if (catalogList) {
      const catalog = catalogList.find((item) => item.sellerId === accountId);

      if (catalog) {
        await getCategoryList({
          catalogId: catalog.id,
          limit: LIST_LIMIT_0,
          onSuccess: () => setOpenContractCategoriesModal(true),
        });
      }
    }
  };

  const onContractCategoriesChange = (value: number[], onSuccess: () => void) => {
    if (contract) {
      updateContract({
        id: contract.id,
        availableCategories: value.length ? value : null,
        onSuccess: () => {
          onSuccess();
          showSuccessMessage('Категории успешно изменены.');
        },
      });
    }
  };

  const onOpenContractBrandsModal = () => {
    getBrandList({ onSuccess: () => setOpenContractBrandsModal(true) });
  };

  const onContractBrandsChange = (value: string[], onSuccess: () => void) => {
    if (contract) {
      updateContract({
        id: contract.id,
        availableBrands: value.length ? value : null,
        onSuccess: () => {
          onSuccess();
          showSuccessMessage('Бренды успешно изменены.');
        },
      });
    }
  };

  return (
    <>
      <InfiniteScrollContainer>
        {header}

        <Spin spinning={catalogListLoading || categoryListLoading || brandListLoading} indicator={<SpinIndicator />}>
          <div className="contract-assortment-settings__row mb-52">
            <div>
              <div className="text-h4 mb-6">Категории товаров</div>

              <span className="text-tag color-dark-grey">Настроить ассортимент, доступный к заказу.</span>
            </div>

            <Button className="button-sm secondary" onClick={onOpenContractCategoriesModal}>
              Настроить
            </Button>
          </div>

          <div className="contract-assortment-settings__row mb-52">
            <div>
              <div className="text-h4 mb-6">Бренды</div>

              <span className="text-tag color-dark-grey">Настроить ассортимент, доступный к заказу.</span>
            </div>

            <Button className="button-sm secondary" onClick={onOpenContractBrandsModal}>
              Настроить
            </Button>
          </div>
        </Spin>
      </InfiniteScrollContainer>

      <ContractCategoriesModal
        open={openContractCategoriesModal}
        loading={contractLoading}
        defaultValue={contract?.availableCategories}
        treeData={categoryTreeData}
        allTreeKeys={allCategoryTreeKeys}
        onClose={() => setOpenContractCategoriesModal(false)}
        onSave={onContractCategoriesChange}
      />

      <ContractBrandsModal
        open={openContractBrandsModal}
        loading={contractLoading}
        options={brandOptions}
        defaultValue={contract?.availableBrands}
        onClose={() => setOpenContractBrandsModal(false)}
        onSave={onContractBrandsChange}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  catalogListLoading: state.catalogListState.loading,
  categoryList: state.categoryListState.data,
  categoryListLoading: state.categoryListState.loading,
  brandList: state.brandListState.data,
  brandListLoading: state.brandListState.loading,
  currentUser: state.userState.currentUser,
  contractLoading: state.contractState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCatalogList: dispatch.catalogListState.getCatalogList,
  getCategoryList: dispatch.categoryListState.getCategoryList,
  getBrandList: dispatch.brandListState.getBrandList,
  updateContract: dispatch.contractState.updateContract,
});

export const ContractAssortmentSettings = connect(mapState, mapDispatch)(Component);
