import React, { FC } from 'react';
import { Button, Tooltip } from 'antd';
import { ReactComponent as LinkIcon } from 'app/assets/images/redesign/link.svg';
import { ReactComponent as InfoGreenIcon } from 'app/assets/images/redesign/info-green.svg';
import { IBasket } from 'entities/Basket/Basket.models';
import { getUserName } from 'entities/User/User.helper';

interface IComponentProps {
  basket: IBasket | null;
  isCurrentBasket: boolean;
  onLink: () => void;
  onUnlink: () => void;
}

export const ActiveBasketFooter: FC<IComponentProps> = ({ basket, isCurrentBasket, onLink, onUnlink }) => {
  if (!basket) {
    return null;
  }

  return (
    <div className="active-basket-footer">
      <div>
        <div className="text-controls-accent mb-4">{`Вы в корзине #${basket.id}`}</div>

        <div className="color-dark-grey">
          <span className="text-tag">{`Владелец: ${getUserName.fullLastNameAndInitials(
            basket.user.lastName,
            basket.user.firstName,
            basket.user.patronymic,
          )}, ${basket.account.name}`}</span>

          {basket.subdivision && <span className="text-tag">{`, ${basket.subdivision.name}`}</span>}
        </div>
      </div>

      {isCurrentBasket ? (
        <Button className="button-sm deleting" icon={<LinkIcon className="icon-unlink-red" />} onClick={onUnlink}>
          Отвязать корзину
        </Button>
      ) : (
        <Button className="button-sm primary" icon={<LinkIcon className="icon-link-white" />} onClick={onLink}>
          <span>Привязать корзину</span>

          <Tooltip
            overlayClassName="active-basket-footer__button-tooltip"
            title="Привяжите корзину клиента, чтобы добавить в неё товары из каталога."
          >
            <InfoGreenIcon className="icon-info-green" />
          </Tooltip>
        </Button>
      )}
    </div>
  );
};
