import React from 'react';
import { Collapse, Form } from 'antd';
import { IFormValue } from 'common/models';
import { EPresetType } from 'common/const/preset.enum';
import { EPropertyFormType } from 'common/const/property.enum';
import { getFormItemLabel } from 'common/helpers/form.helper';
import { FormCheckbox } from 'common/components/Form/FormCheckbox';
import { FormSelect } from 'common/components/Form/FormSelect';
import { FormInputRange } from 'common/components/Form/FormInputRange';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { IProperty, IPropertyItem, IPropertyShowCondition } from 'entities/Property/Property.models';

const propertiesFormItemFieldCanBeRendered = (
  property: IProperty,
  formType: EPropertyFormType,
  getFieldValue: (id: number) => string | string[],
) => {
  return property.showCondition.some((condition: IPropertyShowCondition) => {
    const { propertyId, values, goodCanHaveMultipleValues, presetType } = condition;
    const currentParentValue = getFieldValue(propertyId);

    if (
      condition.presetType === EPresetType.Checkbox &&
      values.some((expectedValue) => currentParentValue?.includes(expectedValue))
    ) {
      return true;
    }

    if (formType === EPropertyFormType.AddGoodsForm) {
      if (!goodCanHaveMultipleValues && values.includes(currentParentValue as string)) {
        return true;
      }

      if (goodCanHaveMultipleValues && values.some((expectedValue) => currentParentValue?.includes(expectedValue))) {
        return true;
      }
    } else {
      if (presetType === EPresetType.SingleValue && values.includes(currentParentValue as string)) {
        return true;
      }

      if (
        presetType === EPresetType.MultipleValue &&
        values.some((expectedValue) => currentParentValue?.includes(expectedValue))
      ) {
        return true;
      }
    }

    return false;
  });
};

const propertiesFormItemShouldBeUpdated = (
  conditions: IPropertyShowCondition[],
  previousFormValues: IFormValue,
  currentFormValues: IFormValue,
) => {
  const parentIds = conditions.map((parent) => parent.propertyId);

  return parentIds.some((id) => {
    const prevValues = previousFormValues[id.toString()];
    const currValues = currentFormValues[id.toString()];

    return JSON.stringify(prevValues) !== JSON.stringify(currValues);
  });
};

export const renderPropertiesFormItem = (
  property: IProperty,
  formType: EPropertyFormType,
  renderPropertiesFormItemField: (property: IProperty) => React.ReactNode,
) => {
  if (!property.showCondition) {
    return <div key={property.id}>{renderPropertiesFormItemField(property)}</div>;
  } else {
    /* Rendering a form field based on conditions
      https://ant.design/components/form#components-form-demo-control-hooks */
    return (
      <Form.Item
        key={property.id}
        noStyle
        shouldUpdate={(prev, curr) => propertiesFormItemShouldBeUpdated(property.showCondition, prev, curr)}
      >
        {({ getFieldValue }) => {
          const canBeRendered = propertiesFormItemFieldCanBeRendered(property, formType, getFieldValue);

          if (canBeRendered) {
            return renderPropertiesFormItemField(property);
          }

          return null;
        }}
      </Form.Item>
    );
  }
};

export const formValuesToPropertiesPayload = (values: IFormValue, properties?: IProperty[]) => {
  return Object.entries(values)
    .map(([key, value]) => {
      const property = properties?.find((item) => item.id === Number(key));

      if (property && value) {
        switch (property.presetType) {
          case EPresetType.SingleValue: {
            return {
              propertyId: property.id,
              values: value,
            };
          }
          case EPresetType.MultipleValue:
          case EPresetType.Checkbox: {
            if (value.length) {
              return {
                propertyId: property.id,
                values: value,
              };
            } else {
              return null;
            }
          }
          case EPresetType.Range: {
            const range = value[0];

            if (range?.from && range?.to) {
              return {
                propertyId: property.id,
                range: {
                  from: range.from,
                  to: range.to,
                },
              };
            } else if (range?.from) {
              return {
                propertyId: property.id,
                range: {
                  from: range.from,
                },
              };
            } else if (range?.to) {
              return {
                propertyId: property.id,
                range: {
                  to: range.to,
                },
              };
            }

            return null;
          }
          default: {
            return null;
          }
        }
      } else {
        return null;
      }
    })
    .filter((item) => item !== null);
};

export const propertiesToFormValues = (properties: IPropertyItem[]) => {
  const initialValues: { [key: string]: any } = {};

  properties.forEach((property) => {
    const propertyId = property.propertyId.toString();

    switch (property.presetType) {
      case EPresetType.SingleValue: {
        initialValues[propertyId] = property.values?.[0];
        break;
      }
      case EPresetType.Range: {
        initialValues[propertyId] = [
          {
            from: property.range?.from,
            to: property.range?.to,
          },
        ];
        break;
      }
      case EPresetType.MultipleValue:
      case EPresetType.Checkbox: {
        initialValues[propertyId] = property.values;
        break;
      }
      default: {
        break;
      }
    }
  });

  return initialValues;
};

export const sortProperties = {
  byRequired: (properties: IProperty[]) => {
    const requiredProperties = properties.filter((property) => property.isRequiredForPresets).sort((a, b) => a.id - b.id);
    const optionalProperties = properties.filter((property) => !property.isRequiredForPresets).sort((a, b) => a.id - b.id);

    return requiredProperties.concat(optionalProperties);
  },
  byId: (properties: IProperty[]) => properties.sort((a, b) => a.id - b.id),
  byDisplayName: (properties?: IProperty[]) => properties?.sort((a, b) => a.displayName.localeCompare(b.displayName)) || [],
  byName: (properties?: IProperty[]) => properties?.sort((a, b) => a.name.localeCompare(b.name)) || [],
  // @ts-ignore
  byIsCategory: (properties?: IProperty[]) => properties?.sort((a, b) => b.isCategory - a.isCategory) || [],
};

export const getOptionsFromProperty = (property: IProperty) =>
  property.values.map((item) => ({ label: item.value || '', value: item.value || '' }));

export const mapPropertiesToFormBuilderProperties = (propertyList: IProperty[]) => {
  return propertyList.map((property, _, allProperties) => ({
    ...property,
    disabled: property.isCategory,
    showCondition: property.showCondition?.map((condition) => {
      const parent = allProperties.find((item) => item.id === condition.propertyId);

      return parent
        ? {
            ...condition,
            presetType: parent.presetType,
            goodCanHaveMultipleValues: parent.goodCanHaveMultipleValues,
          }
        : condition;
    }),
  }));
};

const renderListingPropertiesFormItemField = (property: IProperty) => {
  const disabled = property.isCategory;

  switch (property.presetType) {
    case EPresetType.MultipleValue: {
      const options = getOptionsFromProperty(property).sort((a, b) => a.value.localeCompare(b.value));

      const groupClassName = options.every((item) => item.value.length < 20)
        ? 'listing__filter_item-checkbox-group-multi-columns'
        : 'listing__filter_item-checkbox-group-single-columns';

      return <FormCheckbox name={property.id} className={groupClassName} options={options} disabled={disabled} />;
    }
    case EPresetType.Checkbox: {
      const groupClassName = property.values.every((item) => Number(item.value?.length) < 20)
        ? 'listing__filter_item-checkbox-group-multi-columns'
        : 'listing__filter_item-checkbox-group-single-columns';

      return (
        <FormCheckbox
          name={property.id}
          className={groupClassName}
          // TODO
          options={[{ label: 'да', value: 'да' }]}
          disabled={disabled}
        />
      );
    }
    case EPresetType.SingleValue: {
      const options = getOptionsFromProperty(property).sort((a, b) => a.value.localeCompare(b.value));

      return <FormSelect name={property.id} options={options} disabled={disabled} />;
    }
    case EPresetType.Range: {
      return <FormInputRange key={property.id} name={property.id} className="listing__filter_item-range" disabled={disabled} />;
    }
    default: {
      return null;
    }
  }
};

export const renderPropertiesFilterFormItemContainer = (property: IProperty, defaultActiveKeys?: number[]) => {
  return (
    <Collapse
      defaultActiveKey={defaultActiveKeys}
      className="listing__filter_item"
      key={property.id}
      size="small"
      ghost
      expandIcon={() => <ChevronIcon />}
      items={[
        {
          key: property.id,
          label: getFormItemLabel(property.displayName, property.unitOfMeasurement),
          children: renderListingPropertiesFormItemField(property),
        },
      ]}
    />
  );
};

export const renderPropertiesFilterForm = (properties: IProperty[], defaultActiveKeys?: number[]) => {
  const sortedProperties = sortProperties.byIsCategory(sortProperties.byId(properties));

  return (
    <>
      {sortedProperties.map((property) => {
        if (property.presetType !== EPresetType.NotApplicable) {
          return renderPropertiesFormItem(property, EPropertyFormType.ListingFilterForm, () =>
            renderPropertiesFilterFormItemContainer(property, defaultActiveKeys),
          );
        } else {
          return null;
        }
      })}
    </>
  );
};
