import { RematchDispatch, RematchRootState } from '@rematch/core';
import { EStorageKey } from 'common/const/axios.enum';
import { axiosErrorHandler, getStorageItem, removeStorageItem, saveStorageItem } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  IAuthLoginPayload,
  IRestorePasswordEmailPayload,
  IRestorePasswordPayload,
  IToken,
  ILoginAsUserPayload,
} from 'entities/Auth/Auth.models';
import { authTransport } from 'entities/Auth/Auth.transport';
import { clearCreds, saveCreds } from 'entities/Auth/Auth.helper';

export const authStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async initAuth() {
    dispatch.authState.setLoading(true);

    const creds = getStorageItem<IToken>(EStorageKey.Creds);
    const xUserId = getStorageItem<string>(EStorageKey.XUserId);
    const userCatalogId = getStorageItem<number>(EStorageKey.UserCatalogId);
    const xBasketId = getStorageItem<number>(EStorageKey.XBasketId);

    if (creds) {
      const now = Math.round(Date.now() / 1000);

      if (creds.refresh.expiredAt && creds.refresh.expiredAt < now) {
        clearCreds(dispatch);
        return;
      }

      if (creds.access.expiredAt && creds.access.expiredAt < now) {
        if (creds.refresh.token) {
          try {
            const refreshResponse = await authTransport.refreshToken(creds.refresh.token);
            await saveCreds(dispatch, refreshResponse);
          } catch (refreshError) {
            axiosErrorHandler(refreshError);
            clearCreds(dispatch);
          }
        } else {
          clearCreds(dispatch);
        }
      }

      dispatch.authState.setAuth(creds);

      if (xUserId) {
        dispatch.authState.setSuperAdminAsUser(true);
      }

      dispatch.authState.setUserCatalogId(userCatalogId);
      dispatch.authState.setXBasketId(xBasketId);
      dispatch.authState.setLoading(false);
    } else {
      clearCreds(dispatch);
    }
  },
  async addAuth(payload: IAuthLoginPayload) {
    dispatch.authState.setLoading(true);

    try {
      const response = await authTransport.addAuth(payload);
      await saveCreds(dispatch, response);
      dispatch.authState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error, dispatch.authState.setError);
    } finally {
      dispatch.authState.setLoading(false);
    }
  },
  async deleteAuth() {
    clearCreds(dispatch);
  },
  async sendRestorePasswordEmail(payload: IRestorePasswordEmailPayload) {
    dispatch.authState.setLoading(true);

    try {
      await authTransport.sendRestorePasswordEmail(payload);
      dispatch.authState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error, dispatch.authState.setError);
    } finally {
      dispatch.authState.setLoading(false);
    }
  },
  async restorePassword(payload: IRestorePasswordPayload) {
    dispatch.authState.setLoading(true);

    try {
      const response = await authTransport.restorePassword(payload);
      await saveCreds(dispatch, response);
      dispatch.authState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.authState.setLoading(false);
    }
  },
  loginAsUser(payload: ILoginAsUserPayload, models: RematchRootState<IRootModel, Record<string, never>>) {
    const { user, isSeller } = payload;

    const auth = models.authState.data;

    if (auth) {
      /*
      Access/Refresh token, issuedAt, expiredAt - from super admin creds
      */
      const creds = {
        access: {
          ...auth.access,
          id: user.id,
          userId: user.id,
          accountId: user.accountId,
          accountName: user.account.name,
          firstName: user.firstName,
          lastName: user.lastName,
          patronymic: user.patronymic,
          roles: user.roles,
          isSeller,
          subdivisionName: user.subdivision?.name,
          basketId: user.basketId,
        },
        refresh: {
          ...auth.refresh,
          id: user.id,
          userId: user.id,
        },
      };

      dispatch.authState.setAuth(creds);
      dispatch.authState.setSuperAdminAsUser(true);
      saveStorageItem(EStorageKey.Creds, creds);
      saveStorageItem(EStorageKey.XUserId, user.id);
      saveStorageItem(EStorageKey.SuperAdminCreds, auth);
    }
  },
  async logoutAsUser() {
    const superAdminCreds = getStorageItem<IToken>(EStorageKey.SuperAdminCreds);

    if (superAdminCreds) {
      dispatch.authState.setAuth(superAdminCreds);
      dispatch.authState.setSuperAdminAsUser(false);
      dispatch.authState.setUserCatalogId(null);
      dispatch.authState.setXBasketId(null);
      dispatch.userState.setCurrentUser(null);
      saveStorageItem(EStorageKey.Creds, superAdminCreds);
      removeStorageItem(EStorageKey.XUserId);
      removeStorageItem(EStorageKey.XBasketId);
      removeStorageItem(EStorageKey.SuperAdminCreds);
      removeStorageItem(EStorageKey.UserCatalogId);
    } else {
      clearCreds(dispatch);
    }
  },
  updateAccountName(payload: string) {
    const creds = getStorageItem<IToken>(EStorageKey.Creds);

    if (creds) {
      const newCreds = { ...creds, access: { ...creds.access, accountName: payload } };

      saveStorageItem(EStorageKey.Creds, newCreds);
      dispatch.authState.setAuth(newCreds);
    }
  },
});
