import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  IContractCreatePayload,
  IContractCreateUserPayload,
  IContractDeletePayload,
  IContractDeleteUserPayload,
  IContractListParams,
  IContractUpdatePayload,
  IContractUserListParams,
} from 'entities/Contract/Contract.models';
import { contractTransport } from 'entities/Contract/Contract.transport';

export const contractListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getContractList(params: IContractListParams) {
    dispatch.contractListState.setLoading(true);

    try {
      const response = await contractTransport.getContractList(params);
      dispatch.contractListState.setList(response.data);
      dispatch.contractListState.setCount(response.count);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.contractListState.setLoading(false);
    }
  },
});

export const contractStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getContractById(id: number) {
    dispatch.contractState.setLoading(true);

    try {
      const response = await contractTransport.getContractById(id);
      dispatch.contractState.setContract(response);
      return response;
    } catch (error) {
      axiosErrorHandler(error);
      return null;
    } finally {
      dispatch.contractState.setLoading(false);
    }
  },
  async createContract(payload: IContractCreatePayload) {
    dispatch.contractState.setLoading(true);

    try {
      const response = await contractTransport.createContract(payload);
      dispatch.contractListState.addContract(response);
      dispatch.contractState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.contractState.setLoading(false);
    }
  },
  async updateContract(payload: IContractUpdatePayload) {
    dispatch.contractState.setLoading(true);

    try {
      const response = await contractTransport.updateContract(payload);
      dispatch.contractListState.updateContract(response);
      dispatch.contractState.setContract(response);
      dispatch.contractState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.contractState.setLoading(false);
    }
  },
  async deleteContract(payload: IContractDeletePayload) {
    dispatch.contractState.setLoading(true);

    try {
      const response = await contractTransport.deleteContract(payload);
      dispatch.contractListState.deleteContract(response.id);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.contractState.setLoading(false);
    }
  },
  async getContractUserList(params: IContractUserListParams) {
    dispatch.contractState.setLoading(true);

    try {
      const response = await contractTransport.getContractUserList(params);
      dispatch.contractState.setUserList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.contractState.setLoading(false);
    }
  },
  async createContractUser(payload: IContractCreateUserPayload) {
    dispatch.contractState.setLoading(true);

    try {
      const response = await contractTransport.createContractUser(payload);
      dispatch.contractState.addUser(response);
      dispatch.contractState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.contractState.setLoading(false);
    }
  },
  async deleteContractUser(payload: IContractDeleteUserPayload) {
    dispatch.contractState.setLoading(true);

    try {
      const response = await contractTransport.deleteContractUser(payload);
      dispatch.contractState.deleteUser(response);
      dispatch.contractState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.contractState.setLoading(false);
    }
  },
});
