import React, { ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Input, Select, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { WarningCard } from 'common/components/WarningCard';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { EOrderField } from 'common/const/common.enum';
import { useNeedContext } from 'common/hooks/useNeedContext';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { onInputKeyDown } from 'common/helpers/input.helper';
import { ERequestsMenuTab } from 'common/const/request.enum';
import { ENeedCardMenuItem, ENeedStatus } from 'common/const/need.enum';
import {
  DEFAULT_LIST_OFFSET,
  DEFAULT_PAGINATION_PAGE,
  LIST_LIMIT_20,
  LIST_LIMIT_0,
  DEFAULT_EMPTY_VALUE,
  SELECT_LIST_HEIGHT_320,
} from 'common/config';
import { IOption } from 'common/models';
import { ReactComponent as ArrowDownIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ExportIcon } from 'app/assets/images/redesign/export.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { RootDispatch, RootState } from 'app/store';
import { NeedListEmpty } from 'entities/Need/components/NeedListEmpty';
import { NeedCard } from 'entities/Need/components/NeedCard';
import { INeed, INeedListPayload } from 'entities/Need/Need.models';
import { renderNeedListRecords } from 'entities/Need/Need.helper';
import { SupplyCard } from 'entities/Supply/components/SupplyCard';
import { SupplyCorrectionRequestDrawer } from 'entities/Supply/components/SupplyCorrectionRequestDrawer';
import { GoodsCard } from 'entities/Goods/components/GoodsCard';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  menu: ReactNode;
  subdivisionOptions: IOption[];
  userOptions: IOption[];
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: React.FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    menu,
    subdivisionOptions,
    userOptions,
    // state
    needList,
    needListCount,
    needListLoading,
    // dispatch
    setNeedList,
    filterNeedList,
    getNeedSubdivisionListForManager,
    getNeedUserList,
    getNeedList,
    getNeedListPart,
    changeNeedStatus,
    uploadNeedExcel,
    getNeedById,
  } = props;

  const [search, setSearch] = useState<string>('');
  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const [payload, setPayload] = useState<INeedListPayload>({
    statuses: [ENeedStatus.Pending],
    orderField: EOrderField.UpdatedAt,
    asManager: true,
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
  });
  const {
    page,
    setPage,
    setNeedCardCurrentMenuTab,
    setOpenNeedCard,
    checkAll,
    fetchLoading,
    setFetchLoading,
    indeterminate,
    selectedNeedListIds,
    selectedNeedList,
    setSelectedNeedList,
  } = useNeedContext();

  const onSubdivisionChange = async (value: number) => {
    const newPayload = {
      ...payload,
      subdivisionIds: value === DEFAULT_EMPTY_VALUE ? undefined : [value],
      userId: undefined,
    };

    setPage(DEFAULT_PAGINATION_PAGE);
    setPayload(newPayload);
    setNeedList([]);
    setSelectedNeedList([]);
    await getNeedUserList({ statuses: newPayload.statuses, subdivisionId: newPayload.subdivisionIds?.[0] });
    await getNeedList(newPayload);
  };

  const onUserChange = (value: number) => {
    const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

    setPage(DEFAULT_PAGINATION_PAGE);
    setPayload(newPayload);
    setNeedList([]);
    setSelectedNeedList([]);
    getNeedList(newPayload);
  };

  const onSearchClick = (value: string) => {
    const newPayload = { ...payload, search: value };

    setPage(DEFAULT_PAGINATION_PAGE);
    setPayload(newPayload);
    setNeedList([]);
    setSelectedNeedList([]);
    getNeedList(newPayload);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setSelectedNeedList(needList);
    } else {
      setSelectedNeedList([]);
    }
  };

  const onNeedSelectionChange = (id: number, checked: boolean) => {
    const need = needList.find((item) => item.id === id) as INeed;

    setSelectedNeedList(checked ? [...selectedNeedList, need] : selectedNeedList.filter((need) => need.id !== id));
  };

  const onChangeStatusSuccess = (ids: number[]) => {
    const filteredNeedList = needList.filter((need) => !ids.includes(need.id));
    const filteredSelectedNeedList = selectedNeedList.filter((need) => !ids.includes(need.id));

    filterNeedList(ids);
    setSelectedNeedList(filteredSelectedNeedList);

    if (filteredNeedList.length === LIST_LIMIT_0) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPage(DEFAULT_PAGINATION_PAGE);
      setPayload(newPayload);
      getNeedList(newPayload);
    }
  };

  const onRejectClick = () => {
    changeNeedStatus({
      ids: selectedNeedListIds,
      status: ENeedStatus.Cancelled,
      onSuccess: () => {
        setOpenWarningCard(false);
        showSuccessMessage(`Заявки (${selectedNeedListIds.length}) завершены.`);
        onChangeStatusSuccess(selectedNeedListIds);
      },
    });
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setSelectedNeedList([]);
      setNeedList([]);
      await getNeedSubdivisionListForManager({ statuses: payload.statuses });
      await getNeedUserList({ statuses: payload.statuses });
      await getNeedList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!needListLoading && needList.length < needListCount}
        scrollToTopTrigger={[payload]}
        onLoad={() => {
          setPage(page + 1);
          getNeedListPart({ ...payload, offset: LIST_LIMIT_20 * page });
        }}
      >
        {header}
        {navigation}
        {menu}

        <div className="need-list__filters">
          <Select
            rootClassName="redesign"
            className="need-list__filters-select"
            options={subdivisionOptions}
            value={payload.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
            onChange={onSubdivisionChange}
            placeholder="Выберите подразделение"
            listHeight={SELECT_LIST_HEIGHT_320}
            virtual={false}
            suffixIcon={<ArrowDownIcon />}
          />

          <Select
            rootClassName="redesign"
            className="need-list__filters-select"
            options={userOptions}
            value={payload.userId ? payload.userId : DEFAULT_EMPTY_VALUE}
            onChange={onUserChange}
            placeholder="Выберите пользователя"
            listHeight={SELECT_LIST_HEIGHT_320}
            virtual={false}
            suffixIcon={<ArrowDownIcon />}
          />

          <Input
            style={{ flex: 1 }}
            placeholder="Поиск"
            allowClear={{
              clearIcon: (
                <CloseIcon
                  className="icon-close-input"
                  onClick={() => {
                    if (payload.search?.length) {
                      onSearchClick('');
                    } else {
                      setSearch('');
                    }
                  }}
                />
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => onInputKeyDown(e.key, () => onSearchClick(search))}
          />

          <Button
            className="button-circle primary"
            icon={<SearchIcon className="icon-search-white" />}
            onClick={() => onSearchClick(search)}
          />

          <Button className="button-circle secondary" icon={<ExportIcon />} onClick={() => uploadNeedExcel(payload)} />
        </div>

        {!!needList.length && (
          <div className="need-list__actions">
            <Checkbox checked={checkAll} indeterminate={indeterminate} onChange={onCheckAllChange}>
              Выбрать заявки
            </Checkbox>

            {!!selectedNeedList.length && (
              <Button className="button-sm danger" onClick={() => setOpenWarningCard(true)}>
                Завершить выбранные
              </Button>
            )}
          </div>
        )}

        <Table
          className={`
              table-hover need-list__table
              ${!!needList.length && needList.length === needListCount && 'need-list__table-end-of-list'}
              ${!needList.length && 'need-list__table-empty'}
            `}
          dataSource={needList.map((need) => ({ ...need, key: need.id }))}
          columns={renderNeedListRecords(ERequestsMenuTab.Pending)}
          rowSelection={{
            selectedRowKeys: selectedNeedListIds,
            onSelect: ({ id }, checked) => onNeedSelectionChange(id, checked),
            columnWidth: 40,
          }}
          onRow={({ id }) => ({
            onClick: () => {
              getNeedById({
                id,
                onSuccess: () => {
                  setNeedCardCurrentMenuTab(ENeedCardMenuItem.Info);
                  setOpenNeedCard(true);
                },
              });
            },
          })}
          showHeader={false}
          pagination={false}
          locale={{ emptyText: <NeedListEmpty open={!(needListLoading || fetchLoading) && !needList.length} /> }}
          loading={{ spinning: needListLoading || fetchLoading, indicator: <SpinIndicator /> }}
        />
      </InfiniteScrollContainer>

      <NeedCard onChangeStatusSuccess={onChangeStatusSuccess} />

      <SupplyCard nested onChangeStatusSuccess={onChangeStatusSuccess} />

      <SupplyCorrectionRequestDrawer />

      <GoodsCard />

      <WarningCard
        open={openWarningCard}
        content={`Вы уверены, что хотите завершить выбранные заявки (${selectedNeedList.length})?`}
        subtitle="Запланированные поставки по данным заявкам будут отменены."
        confirmBtnTitle="Завершить все"
        loading={needListLoading}
        onClose={() => setOpenWarningCard(false)}
        onConfirm={onRejectClick}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  needList: state.needListState.data,
  needListCount: state.needListState.count,
  needListLoading: state.needListState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setNeedList: dispatch.needListState.setList,
  filterNeedList: dispatch.needListState.filterList,
  getNeedSubdivisionListForManager: dispatch.needListState.getNeedSubdivisionListForManager,
  getNeedUserList: dispatch.needListState.getNeedUserList,
  getNeedList: dispatch.needListState.getNeedList,
  getNeedListPart: dispatch.needListState.getNeedListPart,
  changeNeedStatus: dispatch.needListState.changeNeedStatus,
  uploadNeedExcel: dispatch.needListState.uploadNeedExcel,
  getNeedById: dispatch.needState.getNeedById,
});

export const PendingNeedListForManager = connect(mapState, mapDispatch)(Component);
