import React, { FC } from 'react';
import { Button, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { NewPasswordFormField } from 'common/components/NewPasswordFormField';
import { NewPasswordConfirmFormField } from 'common/components/NewPasswordConfirmFormField';
import { ERoute } from 'common/const/route.enum';
import { IFormValue } from 'common/models';
import { RootDispatch, RootState } from 'app/store';
import { AuthCardLogo } from 'entities/Auth/components/AuthCardLogo';
import { AuthCardFooter } from 'entities/Auth/components/AuthCardFooter';

interface IComponentProps {
  hash?: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

export const Component: FC<AllType> = ({ hash, authLoading, setAuthError, restorePassword }) => {
  const [form] = useForm();
  const navigate = useNavigate();

  const onSubmit = ({ password }: IFormValue) => {
    setAuthError(null);

    if (hash) {
      restorePassword({
        hash,
        password,
        onSuccess: () => navigate(ERoute.Login),
      });
    }
  };

  return (
    <div className="auth-card">
      <AuthCardLogo />

      <div className="auth-card__content">
        <Form className="auth-card__content-form" form={form} layout="vertical" requiredMark={false} onFinish={onSubmit}>
          <div className="mb-40 auth-card__content-form-title">Придумайте надежный пароль для входа в систему</div>

          <NewPasswordFormField className="w-100" label="Ваш пароль*" name="password" />

          <NewPasswordConfirmFormField className="w-100 mb-40" label="Подтвердите пароль*" newPasswordName="password" />

          <Button className="button-lg primary w-100" loading={authLoading} htmlType="submit">
            Войти в систему
          </Button>
        </Form>
      </div>

      <AuthCardFooter content="СПРАУТ. Система управления закупками." />
    </div>
  );
};

const mapState = (state: RootState) => ({
  authLoading: state.authState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setAuthError: dispatch.authState.setError,
  restorePassword: dispatch.authState.restorePassword,
});

export const RestorePasswordCard = connect(mapState, mapDispatch)(Component);
