import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Menu } from 'antd';
import { connect } from 'react-redux';
import { NeedStatus } from 'common/components/NeedStatus';
import { SuccessCard } from 'common/components/SuccessCard';
import { WarningCard } from 'common/components/WarningCard';
import { useNeedContext } from 'common/hooks/useNeedContext';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { useDebounce } from 'common/hooks/useDebounce';
import { useGoodsContext } from 'common/hooks/useGoodsContext';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { dateFormatter } from 'common/helpers/formatter.helper';
import { EOrderDirection, EOrderField } from 'common/const/common.enum';
import { ESupplyStatus } from 'common/const/supply.enum';
import { ENeedCardMenuItem, ENeedStatus } from 'common/const/need.enum';
import { EUserRole } from 'common/const/user.enum';
import { EDateFormat } from 'common/const/date.enum';
import { COUNT_CHANGE_DELAY, LIST_LIMIT_0, DEFAULT_GOODS_COUNT, DRAWER_Z_INDEX_1, DRAWER_Z_INDEX_2 } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ArrowLeftLongIcon } from 'app/assets/images/redesign/arrow-left-long.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { NeedCardInfo } from 'entities/Need/components/NeedCardInfo';
import { NeedCardSupplies } from 'entities/Need/components/NeedCardSupplies';
import { NeedCardHistory } from 'entities/Need/components/NeedCardHistory';
import { getNeedCardMenuItems, getNeedFormattedDate } from 'entities/Need/Need.helper';

interface IComponentProps {
  nested?: boolean;
  asUser?: boolean;
  onChangeStatusSuccess?: (ids: number[]) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const NeedCardComponent: FC<AllType> = (props) => {
  const {
    nested,
    asUser,
    onChangeStatusSuccess,
    // state
    needList,
    needListLoading,
    currentUser,
    need,
    needLoading,
    supplyList,
    historyList,
    supplyLoading,
    currentBasket,
    goodsLoading,
    // dispatch
    changeNeedStatus,
    setNeed,
    getNeedSupplyList,
    getNeedHistoryList,
    updateNeed,
    getSupplyById,
  } = props;

  const [count, setCount] = useState<number>(DEFAULT_GOODS_COUNT);
  const [countIsChanged, setCountIsChanged] = useState<boolean>(false);
  const [openSuccessCard, setOpenSuccessCard] = useState<boolean>(false);
  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const { needCardCurrentMenuTab, setNeedCardCurrentMenuTab, openNeedCard, setOpenNeedCard } = useNeedContext();
  const { setOpenSupplyCard } = useSupplyContext();
  const { onOpenGoodsCard } = useGoodsContext();

  const isManager = currentUser ? currentUser.roles.includes(EUserRole.Manager) : false;
  const isSellerManager = currentUser ? currentUser.roles.includes(EUserRole.SellerManager) : false;
  const needId = need?.id;
  const canBeRejected =
    isManager &&
    need?.status !== ENeedStatus.Cancelled &&
    (!supplyList.length ||
      supplyList.every((supply) => supply.status === ESupplyStatus.Created || supply.status === ESupplyStatus.Pending));

  const onClose = () => {
    setOpenNeedCard(false);

    if (nested) {
      setOpenSupplyCard(true);
    }
  };

  const onCountChange = (value: number) => {
    setCountIsChanged(true);
    setCount(value);
  };

  const onAcceptToWorkClick = () => {
    if (needId) {
      changeNeedStatus({
        ids: [needId],
        status: ENeedStatus.Pending,
        onSuccess: () => {
          setOpenNeedCard(false);
          setOpenSuccessCard(true);
          onChangeStatusSuccess?.([needId]);
        },
      });
    }
  };

  const onRejectClick = () => {
    setOpenNeedCard(false);
    setOpenWarningCard(true);
  };

  const handleReject = () => {
    if (needId) {
      changeNeedStatus({
        ids: [needId],
        status: ENeedStatus.Cancelled,
        onSuccess: () => {
          setOpenWarningCard(false);

          if (isSellerManager) {
            showSuccessMessage(`Заявка от ${dateFormatter.fullDateDotSeparator(need.createdAt)} отклонена.`);
          } else {
            showSuccessMessage(`Заявка от ${dateFormatter.fullDateDotSeparator(need.createdAt)} завершена.`);
          }

          onChangeStatusSuccess?.([needId]);
        },
      });
    }
  };

  const onSupplyClick = (id: number) => {
    getSupplyById({
      id,
      onSuccess: () => {
        setOpenNeedCard(false);
        setOpenSupplyCard(true);
      },
    });
  };

  const onGoodsClick = (id: number) => {
    if (currentBasket) {
      onOpenGoodsCard(id, currentBasket);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (openNeedCard && needId) {
        await getNeedSupplyList({
          supplyNeedId: needId,
          orderField: EOrderField.DeliveryDate,
          orderDirection: EOrderDirection.ASC,
        });
        await getNeedHistoryList({ supplyNeedId: needId, limit: LIST_LIMIT_0 });
      }
    };

    fetch();
  }, [openNeedCard, needId]);

  useEffect(() => {
    if (need) {
      setCount(need.count);
    }
  }, [need]);

  useDebounce(
    () => {
      if (need && countIsChanged) {
        updateNeed({
          supplyNeeds: [{ id: need.id, count }],
          onSuccess: () => {
            setCountIsChanged(false);
            showSuccessMessage(`Количество товаров в заявке от ${dateFormatter.fullDateDotSeparator(need.createdAt)} изменено.`);
          },
        });
      }
    },
    [need, count, countIsChanged],
    COUNT_CHANGE_DELAY,
  );

  if (!need) {
    return null;
  }

  return (
    <>
      <Drawer
        rootClassName={`redesign drawer need-card ${nested ? 'nested' : ''}`}
        open={openNeedCard}
        onClose={needListLoading ? undefined : onClose}
        width={800}
        closeIcon={nested ? <ArrowLeftLongIcon /> : <CloseIcon />}
        destroyOnClose
        zIndex={nested ? DRAWER_Z_INDEX_2 : DRAWER_Z_INDEX_1}
      >
        <span className="text-body color-dark-grey need-card__created-at">
          {getNeedFormattedDate(EDateFormat.FullDateDotSeparator, need.createdAt)}
        </span>

        <div className="drawer__title">Заявка на закупку</div>

        <div className="mb-52">
          <NeedStatus status={need.status} />
        </div>

        <Menu
          items={getNeedCardMenuItems(nested)}
          onClick={(e) => setNeedCardCurrentMenuTab(e.key as ENeedCardMenuItem)}
          selectedKeys={[needCardCurrentMenuTab]}
          mode="horizontal"
        />

        {needCardCurrentMenuTab === ENeedCardMenuItem.Info && (
          <NeedCardInfo
            need={need}
            canEditCount={!asUser && isManager && need.status === ENeedStatus.Created}
            count={count}
            goodsCardCanBeOpened={currentBasket?.userId === need.userId}
            goodsLoading={goodsLoading}
            onCountChange={onCountChange}
            onGoodsClick={onGoodsClick}
          />
        )}

        {needCardCurrentMenuTab === ENeedCardMenuItem.Supplies && (
          <NeedCardSupplies
            supplyList={supplyList}
            historyList={historyList}
            loading={needLoading || supplyLoading}
            onSupplyClick={onSupplyClick}
          />
        )}

        {needCardCurrentMenuTab === ENeedCardMenuItem.History && <NeedCardHistory historyList={historyList} />}

        {!asUser && !nested && needCardCurrentMenuTab === ENeedCardMenuItem.Info && (
          <div className="drawer__footer">
            {isManager && need.status === ENeedStatus.Created && (
              <Button className="button-lg primary" onClick={onAcceptToWorkClick} loading={needListLoading}>
                Взять в работу
              </Button>
            )}

            {canBeRejected && (
              <Button className="button-lg danger" onClick={onRejectClick} loading={needListLoading}>
                Завершить
              </Button>
            )}
          </div>
        )}
      </Drawer>

      <SuccessCard
        open={openSuccessCard}
        subtitle="Готово!"
        content="Заявка принята в работу и направлена поставщику. Статус её подтверждения можно увидеть в разделе “Заявки в работе”."
        btnTitle="К следующей заявке"
        onClose={() => setOpenSuccessCard(false)}
        onConfirm={() => {
          // next need
          setNeed(needList[0]);
          setOpenSuccessCard(false);
          setOpenNeedCard(true);
        }}
      />

      <WarningCard
        open={openWarningCard}
        content={
          isSellerManager ? 'Вы уверены, что хотите отклонить данную заявку?' : 'Вы уверены, что хотите завершить данную заявку?'
        }
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle={isSellerManager ? 'Отклонить' : 'Завершить'}
        loading={needListLoading}
        onClose={() => {
          setOpenWarningCard(false);
          setOpenNeedCard(true);
        }}
        onConfirm={handleReject}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  needList: state.needListState.data,
  needListLoading: state.needListState.loading,
  currentUser: state.userState.currentUser,
  need: state.needState.data,
  needLoading: state.needState.loading,
  supplyList: state.needState.supplyList,
  historyList: state.needState.historyList,
  supplyLoading: state.supplyState.loading,
  currentBasket: state.basketState.currentBasket,
  goodsLoading: state.goodsState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  changeNeedStatus: dispatch.needListState.changeNeedStatus,
  setNeed: dispatch.needState.setNeed,
  getNeedSupplyList: dispatch.needState.getNeedSupplyList,
  getNeedHistoryList: dispatch.needState.getNeedHistoryList,
  updateNeed: dispatch.needState.updateNeed,
  getSupplyById: dispatch.supplyState.getSupplyById,
});

export const NeedCard = connect(mapState, mapDispatch)(NeedCardComponent);
