import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { WarningCard } from 'common/components/WarningCard';
import { EFormFieldMessage } from 'common/const/form.enum';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { rules } from 'common/helpers/form.helper';
import { normalizePhoneNumber, phoneFormatter } from 'common/helpers/formatter.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { useUserContext } from 'common/hooks/useUserContext';
import { PHONE_PATTERN } from 'common/config';
import { IFormValue } from 'common/models';
import { ReactComponent as ArrowLeftLongIcon } from 'app/assets/images/redesign/arrow-left-long.svg';
import { RootDispatch, RootState } from 'app/store';
import { IUserUpdateByAdminPayload } from 'entities/User/User.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ auth, user, userLoading, updateUserByAdmin }) => {
  const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const [payload, setPayload] = useState<IUserUpdateByAdminPayload>({} as IUserUpdateByAdminPayload);
  const [form] = useForm();
  const { setOpenUserCard, openEditUserCard, setOpenEditUserCard } = useUserContext();

  const isSeller = auth?.access.isSeller;

  const onClose = () => {
    setFormIsChanged(false);
    setOpenWarningCard(false);
    setOpenEditUserCard(false);
    setOpenUserCard(true);
  };

  const handleClose = () => {
    if (userLoading) {
      return;
    }

    if (formIsChanged) {
      setOpenEditUserCard(false);
      setOpenWarningCard(true);
      return;
    }

    onClose();
  };

  const onFormValuesChange = (value: IFormValue) => {
    setFormIsChanged(true);

    if ('phone' in value) {
      setPayload((prev) => ({ ...prev, phone: value.phone.length ? normalizePhoneNumber(value.phone) : null }));
    } else {
      setPayload((prev) => ({ ...prev, ...value }));
    }
  };

  const onSubmit = () => {
    updateUserByAdmin({
      ...payload,
      onSuccess: () => {
        showSuccessMessage('Данные пользователя успешно изменены.');
        onClose();
      },
    });
  };

  useEffect(() => {
    if (openEditUserCard && user) {
      setPayload({ id: user.id });
      form.setFieldsValue({
        lastName: user.lastName,
        firstName: user.firstName,
        patronymic: user.patronymic,
        position: user.position,
        email: user.email,
        phone: phoneFormatter(user.phone),
        // TODO
        subdivision: user.subdivision?.name,
        workspace: user.workspace?.name,
      });
    }
  }, [openEditUserCard, user]);

  if (!user) {
    return null;
  }

  return (
    <>
      <Drawer
        rootClassName="redesign drawer user-edit-card"
        closeIcon={<ArrowLeftLongIcon />}
        width={720}
        destroyOnClose
        open={openEditUserCard}
        onClose={handleClose}
        footer={[
          <Button key="cancel" className="button-lg secondary" loading={userLoading} onClick={handleClose}>
            Отмена
          </Button>,
          <Button key="save" className="button-lg primary" loading={userLoading} onClick={form.submit}>
            Сохранить
          </Button>,
        ]}
      >
        <div className="drawer__body">
          <div className="drawer__title mb-52">Редактировать данные</div>

          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            scrollToFirstError
            onValuesChange={onFormValuesChange}
            onFinish={onSubmit}
          >
            <div className="mb-52">
              <div className="text-h3 mb-32">Персональные данные</div>

              <Form.Item label="Фамилия*" name="lastName" rules={[rules.required()]}>
                <Input placeholder={EPlaceholder.EnterLastName} />
              </Form.Item>

              <Form.Item label="Имя*" name="firstName" rules={[rules.required()]}>
                <Input placeholder={EPlaceholder.EnterFirstName} />
              </Form.Item>

              <Form.Item label="Отчество" name="patronymic">
                <Input placeholder={EPlaceholder.EnterPatronymic} />
              </Form.Item>
            </div>

            {!isSeller && (
              <div className="mb-52">
                <div className="text-h3 mb-32">Подразделение и карта оснащения </div>

                {/* TODO */}
                <Form.Item label="Подразделение" name="subdivision">
                  <Input placeholder={EPlaceholder.SelectSubdivision} disabled />
                </Form.Item>

                {/* TODO */}
                <Form.Item label="Карта оснащения" name="workspace">
                  <Input placeholder={EPlaceholder.SelectWorkspace} disabled />
                </Form.Item>
              </div>
            )}

            <div className="mb-52">
              <div className="text-h3 mb-32">Контактные данные</div>

              <Form.Item label="Должность" name="position">
                <Input placeholder={EPlaceholder.EnterPosition} />
              </Form.Item>

              <Form.Item label="Электронный адрес" name="email">
                <Input placeholder={EPlaceholder.EnterEmailAddress} disabled />
              </Form.Item>

              <Form.Item
                label="Контактный телефон"
                name="phone"
                normalize={phoneFormatter}
                rules={[
                  {
                    pattern: PHONE_PATTERN,
                    message: EFormFieldMessage.InvalidPhoneFormat,
                  },
                ]}
              >
                <Input placeholder={EPlaceholder.EnterContactPhoneNumber} />
              </Form.Item>
            </div>
          </Form>
        </div>
      </Drawer>

      <WarningCard
        open={openWarningCard}
        content="Вы уверены, что не хотите сохранить изменения?"
        subtitle="Введенные данные будут потеряны."
        confirmBtnTitle="Не сохранять"
        onConfirm={onClose}
        onClose={() => {
          setOpenWarningCard(false);
          setOpenEditUserCard(true);
        }}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  auth: state.authState.data,
  user: state.userState.data,
  userLoading: state.userState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateUserByAdmin: dispatch.userState.updateUserByAdmin,
});

export const UserEditCard = connect(mapState, mapDispatch)(Component);
