import { IWorkspace } from 'entities/Workspace/Workspace.models';
import {
  IUser,
  IUserInventoryStatistics,
  IUserInventoryStatisticsState,
  IUserListState,
  IUserState,
  IUserStatistics,
  IUserStatisticsState,
} from 'entities/User/User.models';
import { ISubdivision } from 'entities/Subdivision/Subdivision.models';

export const userListStateReducers = {
  setList: (state: IUserListState, payload: IUser[]) => ({ ...state, data: payload }),
  setPart: (state: IUserListState, payload: IUser[]) => ({ ...state, data: [...state.data, ...payload] }),
  setCount: (state: IUserListState, payload: number) => ({ ...state, count: payload }),
  setLoading: (state: IUserListState, payload: boolean) => ({ ...state, loading: payload }),
  addUser: (state: IUserListState, payload: IUser) => ({ ...state, data: [...state.data, payload], count: state.count + 1 }),
  updateUser: (state: IUserListState, payload: IUser) => ({
    ...state,
    data: state.data.map((user) => (user.id === payload.id ? payload : user)),
  }),
  deleteUser: (state: IUserListState, payload: number) => ({
    ...state,
    data: state.data.filter((user) => user.id !== payload),
    count: state.count - 1,
  }),
};

export const userStateReducers = {
  setUser: (state: IUserState, payload: IUser) => {
    return { ...state, data: payload, currentUser: state.currentUser?.id === payload.id ? payload : state.currentUser };
  },
  setCurrentUser: (state: IUserState, payload: IUser | null) => ({ ...state, currentUser: payload }),
  setUserLoading: (state: IUserState, payload: boolean) => ({ ...state, loading: payload }),
  setUserError: (state: IUserState, payload: string | null) => ({ ...state, error: payload }),
  updateSubdivision: (state: IUserState, payload: ISubdivision) => {
    if (state.currentUser) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          subdivision: state.currentUser.subdivision?.id === payload.id ? payload : state.currentUser.subdivision,
        },
      };
    }

    return state;
  },
  createWorkSpace: (state: IUserState, payload: IWorkspace) => {
    if (state.currentUser) {
      return {
        ...state,
        currentUser:
          state.currentUser.id === payload.user?.id
            ? {
                ...state.currentUser,
                workspace: payload,
                workspaceId: payload.id,
              }
            : state.currentUser,
      };
    }

    return state;
  },
  updateWorkspace: (state: IUserState, payload: IWorkspace) => {
    if (state.currentUser && state.currentUser.id === payload.user?.id) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          workspace: payload,
          workspaceId: payload.id,
        },
      };
    } else if (state.currentUser && state.currentUser.id !== payload.user?.id && state.currentUser.workspaceId === payload.id) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          workspace: undefined,
          workspaceId: undefined,
        },
      };
    } else {
      return state;
    }
  },
  deleteWorkspace: (state: IUserState, payload: IWorkspace) => {
    if (state.currentUser && state.currentUser.id === payload.user?.id) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          workspace: undefined,
          workspaceId: undefined,
        },
      };
    }

    return state;
  },
};

export const statisticsStateReducers = {
  setStatistics: (state: IUserStatisticsState, payload: IUserStatistics) => ({ ...state, data: payload }),
  setStatisticsLoading: (state: IUserStatisticsState, payload: boolean) => ({ ...state, loading: payload }),
};

export const userInventoryStatisticsStateReducers = {
  setUserInventoryStatistics: (state: IUserInventoryStatisticsState, payload: IUserInventoryStatistics) => ({
    ...state,
    data: payload,
  }),
  setLoading: (state: IUserInventoryStatisticsState, payload: boolean) => ({ ...state, loading: payload }),
};
