import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  IInventoryActionCreatePayload,
  IInventoryActionDeletePayload,
  IInventoryActionListPayload,
  IInventoryActionListSubdivisionsParams,
  IInventoryActionListUsersParams,
  IInventoryActionUpdatePayload,
  IInventoryListPayload,
  IInventoryListSubdivisionsParams,
  IInventoryListUsersParams,
  IInventoryParams,
} from 'entities/Inventory/Inventory.models';
import { inventoryTransport } from 'entities/Inventory/Inventory.transport';
import { IUserListParams } from 'entities/User/User.models';
import { userTransport } from 'entities/User/User.transport';

export const inventoryListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getInventoryList(payload: IInventoryListPayload) {
    dispatch.inventoryListState.setLoading(true);

    try {
      const response = await inventoryTransport.getInventoryList(payload);
      dispatch.inventoryListState.setInventoryList(response.data);
      dispatch.inventoryListState.setCount(response.count);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.inventoryListState.setLoading(false);
    }
  },
  async getInventoryListPart(payload: IInventoryListPayload) {
    dispatch.inventoryListState.setLoading(true);

    try {
      const response = await inventoryTransport.getInventoryList(payload);
      dispatch.inventoryListState.setInventoryListPart(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.inventoryListState.setLoading(false);
    }
  },
  async getInventoryListSubdivisions(params: IInventoryListSubdivisionsParams) {
    dispatch.inventoryListState.setLoading(true);

    try {
      const response = await inventoryTransport.getInventoryListSubdivisions(params);
      dispatch.inventoryListState.setSubdivisionList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.inventoryListState.setLoading(false);
    }
  },
  async getInventoryListUsers(params: IInventoryListUsersParams) {
    dispatch.inventoryListState.setLoading(true);

    try {
      const response = await inventoryTransport.getInventoryListUsers(params);
      dispatch.inventoryListState.setUserList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.inventoryListState.setLoading(false);
    }
  },
});

export const inventoryStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getInventoryById({ id, onSuccess }: IInventoryParams) {
    dispatch.inventoryState.setLoading(true);

    try {
      const response = await inventoryTransport.getInventoryById(id);
      dispatch.inventoryState.setInventory(response);
      dispatch.inventoryState.onSuccess({ onSuccess });
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.inventoryState.setLoading(false);
    }
  },
  async getInventoryUserList(params: IUserListParams) {
    dispatch.inventoryState.setLoading(true);

    try {
      const response = await userTransport.getUserList(params);
      dispatch.inventoryState.setUserList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.inventoryState.setLoading(false);
    }
  },
});

export const inventoryActionListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getInventoryActionList(payload: IInventoryActionListPayload) {
    dispatch.inventoryActionListState.setLoading(true);

    try {
      const response = await inventoryTransport.getInventoryActionList(payload);
      dispatch.inventoryActionListState.setInventoryActionList(response.data);
      dispatch.inventoryActionListState.setCount(response.count);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.inventoryActionListState.setLoading(false);
    }
  },
  async getInventoryActionListPart(payload: IInventoryActionListPayload) {
    dispatch.inventoryActionListState.setLoading(true);

    try {
      const response = await inventoryTransport.getInventoryActionList(payload);
      dispatch.inventoryActionListState.setInventoryActionListPart(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.inventoryActionListState.setLoading(false);
    }
  },
  async getInventoryActionListSubdivisions(params: IInventoryActionListSubdivisionsParams) {
    dispatch.inventoryActionListState.setLoading(true);

    try {
      const response = await inventoryTransport.getInventoryActionListSubdivisions(params);
      dispatch.inventoryActionListState.setSubdivisionList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.inventoryActionListState.setLoading(false);
    }
  },
  async getInventoryActionListUsers(params: IInventoryActionListUsersParams) {
    dispatch.inventoryActionListState.setLoading(true);

    try {
      const response = await inventoryTransport.getInventoryActionListUsers(params);
      dispatch.inventoryActionListState.setUserList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.inventoryActionListState.setLoading(false);
    }
  },
});

export const inventoryActionStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getInventoryActionById(id: number) {
    dispatch.inventoryActionState.setLoading(true);

    try {
      const response = await inventoryTransport.getInventoryActionById(id);
      dispatch.inventoryActionState.setInventoryAction(response);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.inventoryActionState.setLoading(false);
    }
  },
  async createInventoryAction(payload: IInventoryActionCreatePayload) {
    dispatch.inventoryActionState.setLoading(true);

    try {
      const response = await inventoryTransport.createInventoryAction(payload);
      dispatch.inventoryListState.addAction(response);
      dispatch.inventoryActionState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.inventoryActionState.setLoading(false);
    }
  },
  async updateInventoryAction(payload: IInventoryActionUpdatePayload) {
    dispatch.inventoryActionState.setLoading(true);

    try {
      await inventoryTransport.updateInventoryAction(payload);
      dispatch.inventoryActionState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.inventoryActionState.setLoading(false);
    }
  },
  async deleteInventoryAction(payload: IInventoryActionDeletePayload) {
    dispatch.inventoryActionState.setLoading(true);

    try {
      await inventoryTransport.deleteInventoryAction(payload);
      dispatch.inventoryActionState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.inventoryActionState.setLoading(false);
    }
  },
});
