import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { EFormFieldMessage } from 'common/const/form.enum';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { rules } from 'common/helpers/form.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { ReactComponent as EditIcon } from 'app/assets/images/redesign/edit.svg';
import { IAccount, IAccountUpdatePayload } from 'entities/Account/Account.models';

interface IComponentProps {
  account: IAccount | null;
  loading: boolean;
  onAccountUpdate: (payload: IAccountUpdatePayload) => Promise<void>;
}

export const AccountSettingsName: FC<IComponentProps> = ({ account, loading, onAccountUpdate }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [form] = useForm();

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onSubmit = () => {
    if (account) {
      form.validateFields().then(({ name }) => {
        onAccountUpdate({
          id: account.id,
          name,
          onSuccess: () => {
            onClose();
            showSuccessMessage('Название аккаунта успешно изменено.');
          },
        });
      });
    }
  };

  useEffect(() => {
    if (open && account) {
      form.setFieldValue('name', account.name);
    }
  }, [open, account]);

  return (
    <>
      <div className="account-settings-name mb-52">
        <div className="text-h1 account-settings-name__value">{account?.name}</div>

        <Button
          className="button-icon account-settings-name__edit-btn"
          icon={<EditIcon className="icon-edit-dark-grey" />}
          onClick={() => setOpen(true)}
        />
      </div>

      <Modal
        rootClassName="redesign modal"
        width={400}
        title="Изменить название аккаунта"
        centered
        open={open}
        onCancel={onClose}
        footer={[
          <Button key="cancel" className="button-sm secondary" onClick={onClose} disabled={loading}>
            Отмена
          </Button>,
          <Button key="save" className="button-sm primary" loading={loading} onClick={form.submit}>
            Сохранить
          </Button>,
        ]}
      >
        <Form form={form} requiredMark={false} layout="vertical" onFinish={onSubmit}>
          <Form.Item label="Название компании" name="name" rules={[rules.required(true, EFormFieldMessage.EditAccountName)]}>
            <Input placeholder={EPlaceholder.EnterName} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
