import React, { ChangeEvent, useState } from 'react';
import { Button, Input, Table } from 'antd';
import { connect } from 'react-redux';
import { toDataSourceMapper } from 'common/helpers/common.helper';
import { useDebounce } from 'common/hooks/useDebounce';
import { EMessage } from 'common/const/message.enum';
import { ENotificationType } from 'common/const/notification.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { EModalCollectionKey } from 'common/const/modal.enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as TrashIcon } from 'app/assets/images/trash.svg';
import { ReactComponent as ProfileIcon } from 'app/assets/images/profile.svg';
import { IUser } from 'entities/User/User.models';
import { renderSubdivisionUserCollectionRecords } from 'entities/Subdivision/Subdivision.helper';
import { CreateSubdivisionUser } from 'entities/Subdivisions/components/CreateSubdivisionUser';
import { Notification } from 'entities/Modal/components/Notification';

interface IComponentProps {
  subdivisionId?: number;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const SubdivisionUsersComponents: React.FC<AllType> = (props) => {
  const {
    subdivisionId,
    userListState,
    userState,
    excludeSubdivisionUserModalParams,
    getUserList,
    removeUserSubdivision,
    showModal,
    hideModal,
  } = props;
  const { data: userList, loading: userListLoading } = userListState;
  const { loading: userLoading } = userState;
  const { open } = excludeSubdivisionUserModalParams;

  const { setSearchParam, removeSearchParam } = useSearchParamsHook();
  const [userId, setUserId] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const dataSource = toDataSourceMapper<IUser>(userList);
  const columns = renderSubdivisionUserCollectionRecords(setSearchParam);

  const onBackClick = () => {
    setSearchValue('');
    removeSearchParam(ESearchParams.Frame);
  };

  const onUserSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onExcludeClick = (id: number) => {
    setUserId(id);
    showModal(EModalCollectionKey.ExcludeSubdivisionUser);
  };

  const onExcludeUserSubdivisionModalCancelClick = () => {
    setUserId(null);
    hideModal(EModalCollectionKey.ExcludeSubdivisionUser);
  };

  const onExcludeUserSubdivisionModalConfirmClick = () => {
    removeUserSubdivision({
      userId: userId as number,
      onSuccess() {
        setUserId(null);
        hideModal(EModalCollectionKey.ExcludeSubdivisionUser);
        getUserList({ subdivisionId, search: searchValue, limit: 0 });
      },
    });
  };

  columns.push({
    key: 'exclude',
    render: (record) => <Button className="btn btn-icon" icon={<TrashIcon />} onClick={() => onExcludeClick(record.id)} />,
    width: '34px',
  });

  useDebounce(() => {
    if (subdivisionId) {
      getUserList({ subdivisionId, search: searchValue, limit: 0 });
    }
  }, [subdivisionId, searchValue]);

  return (
    <div className="subdivision-users">
      <div>
        <Button className="btn btn-default" onClick={onBackClick}>
          Назад
        </Button>
      </div>

      <div>Пользователи подразделения</div>

      <div className="subdivision-users__search-container">
        <Input value={searchValue} onChange={onUserSearch} placeholder="Найти пользователя" prefix={<ProfileIcon />} />

        <CreateSubdivisionUser
          subdivisionId={subdivisionId}
          updateSubdivisionUserCollection={() => {
            getUserList({ subdivisionId, search: searchValue, limit: 0 });
          }}
        />
      </div>

      <Table
        size="small"
        className="subdivision-users__table"
        rowClassName="subdivision-users__table_item"
        dataSource={dataSource}
        columns={columns}
        showHeader={false}
        loading={userListLoading}
        pagination={false}
        locale={{ emptyText: EMessage.AddNewUser }}
      />

      <Notification
        open={open}
        type={ENotificationType.Warning}
        description={EMessage.UserWillBeRemovedFromSubdivision}
        loading={userLoading}
        onCancelClick={onExcludeUserSubdivisionModalCancelClick}
        onConfirmClick={onExcludeUserSubdivisionModalConfirmClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  userListState: state.userListState,
  userState: state.userState,
  excludeSubdivisionUserModalParams: state.modalCollection.excludeSubdivisionUserModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserList: dispatch.userListState.getUserList,
  removeUserSubdivision: dispatch.userState.removeUserSubdivision,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const SubdivisionUsers = connect(mapState, mapDispatch)(SubdivisionUsersComponents);
