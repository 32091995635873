import React, { ChangeEvent, useState } from 'react';
import { Button, Checkbox, Input, Popover, Spin, Table, Tag, message } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { toDataSourceMapper } from 'common/helpers/common.helper';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { EMessage } from 'common/const/message.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { EModalCollectionKey } from 'common/const/modal.enum';
import { EUserFilterOption, EUserFilterOptionLabel } from 'common/const/user.enum';
import { useDebounce } from 'common/hooks/useDebounce';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { ReactComponent as FilterIcon } from 'app/assets/images/filter.svg';
import { ReactComponent as ProfileIcon } from 'app/assets/images/profile.svg';
import { ReactComponent as SearchIcon } from 'app/assets/images/search.svg';
import { IUser } from 'entities/User/User.models';
import {
  toUserSettingsSearchParams,
  getUserSettingsFilterOptions,
  renderUsersSettingsRecords,
} from 'entities/Settings/Settings.helper';
import { UserCardOld } from 'entities/User/components/UserCardOld';
import { InviteUserModal } from 'entities/Modal/components/InviteUserModal';
import { IInvitationCreatePayload } from 'entities/Invitation/Invitation.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const UsersSettingsComponent: React.FC<AllType> = (props) => {
  const {
    currentUser,
    userListState,
    invitationState,
    inviteUserModalParams,
    getUserList,
    inviteUser,
    setInvitationError,
    showModal,
    hideModal,
  } = props;
  const { data: userList, loading: userListLoading } = userListState;
  const { loading: invitationLoading, error: invitationError } = invitationState;
  const { open } = inviteUserModalParams;

  const [searchValue, setSearchValue] = useState<string>('');
  const [filterCheckedList, setFilterCheckedList] = useState<EUserFilterOption[]>([]);
  const { searchParams, setSearchParam } = useSearchParamsHook();
  const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
  const [form] = useForm();

  const userId = searchParams.get(ESearchParams.UserId);
  const dataSource = toDataSourceMapper<IUser>(userList);
  const filterOptions = getUserSettingsFilterOptions();
  const params = toUserSettingsSearchParams(currentUser, searchValue, filterCheckedList);
  const { accountId, search, withoutSubdivision, status } = params;
  const checkAll = Object.keys(EUserFilterOption).length === filterCheckedList.length;

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormIsChanged(true);
    setSearchValue(e.target.value);
  };

  const onFilterChange = (list: CheckboxValueType[]) => {
    setFormIsChanged(true);
    setFilterCheckedList(list as EUserFilterOption[]);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setFormIsChanged(true);
    const checkedList = filterOptions.map((item) => item.value);
    setFilterCheckedList(e.target.checked ? checkedList : []);
  };

  const onTagClose = (value: EUserFilterOption) => {
    const filteredList = filterCheckedList.filter((item) => item !== value);
    setFilterCheckedList(filteredList);
  };

  const onAddUserClick = () => {
    showModal(EModalCollectionKey.InviteUser);
  };

  const onInviteUserModalCancelClick = () => {
    setInvitationError(null);
    form.resetFields();
    hideModal(EModalCollectionKey.InviteUser);
  };

  const onInviteUserModalSubmit = (values: IInvitationCreatePayload) => {
    setInvitationError(null);

    inviteUser({
      ...values,
      onSuccess: () => {
        form.resetFields();
        hideModal(EModalCollectionKey.InviteUser);
        message.success(EMessage.LetterWasSentSuccessfully);
        getUserList(params);
      },
    });
  };

  const filterDropdown = (
    <div className="users__filter-dropdown">
      <Checkbox onChange={onCheckAllChange} checked={checkAll}>
        Все
      </Checkbox>

      <Checkbox.Group
        className="users__filter-dropdown"
        options={filterOptions}
        value={filterCheckedList}
        onChange={onFilterChange}
      />
    </div>
  );

  useDebounce(() => {
    if (accountId && formIsChanged) {
      getUserList(params);
    }
  }, [accountId, search, withoutSubdivision, status, formIsChanged]);

  const { contentLoading } = useContentLoader(async () => {
    await getUserList({ limit: 0 });
  });

  if (contentLoading) {
    return <ContentLoader />;
  }

  return (
    <Spin spinning={userListLoading || invitationLoading}>
      <div className="users" style={{ padding: '0px 40px' }}>
        <div className="users__wrapper">
          <div className="users__title">Список пользователей аккаунта</div>

          <div className="users__header">
            <div className="users__header-container">
              <Input
                value={searchValue}
                onChange={onSearchChange}
                placeholder="Введите имя, фамилию или e-mail"
                prefix={<ProfileIcon />}
                suffix={<SearchIcon />}
              />

              <div className="users__header-actions">
                <Popover className="users__filter" trigger="click" content={filterDropdown} placement="bottomRight">
                  <Button className="btn users__filter-btn">
                    <FilterIcon />
                    <span>Фильтры</span>
                    <ChevronIcon />
                  </Button>
                </Popover>

                <Button className="btn btn-primary" onClick={onAddUserClick}>
                  Добавить пользователя
                </Button>
              </div>
            </div>

            <div className="users__header-tags">
              {filterCheckedList.map((item: EUserFilterOption, index) => {
                return (
                  <Tag key={index} closable onClose={() => onTagClose(item)}>
                    {EUserFilterOptionLabel[item]}
                  </Tag>
                );
              })}

              {!!filterCheckedList.length && (
                <Button type="link" className="btn btn-tag" onClick={() => setFilterCheckedList([])}>
                  | Очистить все
                </Button>
              )}
            </div>
          </div>

          <Table
            className="users__list"
            rowClassName="users__list_item"
            dataSource={dataSource}
            columns={renderUsersSettingsRecords(setSearchParam, onInviteUserModalSubmit)}
            showHeader={false}
            pagination={false}
          />
        </div>

        <UserCardOld userId={userId} />
      </div>

      <InviteUserModal
        open={open}
        form={form}
        loading={invitationLoading}
        error={invitationError}
        onSubmit={onInviteUserModalSubmit}
        onCancelClick={onInviteUserModalCancelClick}
      />
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  currentUser: state.userState.currentUser,
  userListState: state.userListState,
  invitationState: state.invitationState,
  inviteUserModalParams: state.modalCollection.inviteUserModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserList: dispatch.userListState.getUserList,
  inviteUser: dispatch.invitationState.inviteUser,
  setInvitationError: dispatch.invitationState.setError,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const UsersSettings = connect(mapState, mapDispatch)(UsersSettingsComponent);
