import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { uploadExcelFile } from 'common/helpers/file';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  INeedChangeStatusPayload,
  INeedHistoryListPayload,
  INeedListPayload,
  INeedPayload,
  INeedSubdivisionListParams,
  INeedUpdatePayload,
  INeedUserListParams,
} from 'entities/Need/Need.models';
import { needTransport } from 'entities/Need/Need.transport';
import { ISupplyListPayload } from 'entities/Supply/Supply.models';
import { supplyTransport } from 'entities/Supply/Supply.transport';

export const needListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getNeedList(payload: INeedListPayload) {
    dispatch.needListState.setLoading(true);

    try {
      const response = await needTransport.getNeedList(payload);
      dispatch.needListState.setList(response.data);
      dispatch.needListState.setCount(response.count);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.needListState.setLoading(false);
    }
  },
  async getNeedListPart(payload: INeedListPayload) {
    dispatch.needListState.setLoading(true);

    try {
      const response = await needTransport.getNeedList(payload);
      dispatch.needListState.setListPart(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.needListState.setLoading(false);
    }
  },
  async getNeedUserList(params: INeedUserListParams) {
    dispatch.needListState.setLoading(true);

    try {
      const response = await needTransport.getNeedUserList(params);
      dispatch.needListState.setUserList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.needListState.setLoading(false);
    }
  },
  async changeNeedStatus(payload: INeedChangeStatusPayload) {
    dispatch.needListState.setLoading(true);

    try {
      const response = await needTransport.changeNeedStatus(payload);
      dispatch.needListState.updateList(response.data);
      dispatch.needListState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.needListState.setLoading(false);
    }
  },
  async uploadNeedExcel(payload: INeedListPayload) {
    dispatch.needListState.setLoading(true);

    try {
      const response = await needTransport.uploadNeedExcel(payload);
      uploadExcelFile(response, `supply_need_list_${Date.now()}`);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.needListState.setLoading(false);
    }
  },
  async getNeedSubdivisionListForManager(params: INeedSubdivisionListParams) {
    dispatch.needListState.setLoading(true);

    try {
      const response = await needTransport.getNeedSubdivisionListForManager(params);
      dispatch.needListState.setSubdivisionList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.needListState.setLoading(false);
    }
  },
  async getNeedSubdivisionListForSeller(buyerId?: number) {
    dispatch.needListState.setSubdivisionListLoading(true);

    try {
      const response = await needTransport.getNeedSubdivisionListForSeller(buyerId);
      dispatch.needListState.setSubdivisionList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.needListState.setSubdivisionListLoading(false);
    }
  },
});

export const needStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getNeedById(payload: INeedPayload) {
    dispatch.needState.setLoading(true);

    try {
      const response = await needTransport.getNeedById(payload.id);
      dispatch.needState.setNeed(response);
      dispatch.needState.onSuccess(payload);
      return response;
    } catch (error) {
      axiosErrorHandler(error);
      return null;
    } finally {
      dispatch.needState.setLoading(false);
    }
  },
  async updateNeed(payload: INeedUpdatePayload) {
    dispatch.needState.setLoading(true);

    try {
      const response = await needTransport.updateNeed(payload);
      dispatch.needState.setNeed(response.data[0]);
      dispatch.needListState.updateList(response.data);
      dispatch.needListState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.needState.setLoading(false);
    }
  },
  async getNeedSupplyList(payload: ISupplyListPayload) {
    dispatch.needState.setLoading(true);

    try {
      const response = await supplyTransport.getSupplyList(payload);
      dispatch.needState.setSupplyList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.needState.setLoading(false);
    }
  },
  async getNeedHistoryList(payload: INeedHistoryListPayload) {
    dispatch.needState.setLoading(true);

    try {
      const response = await needTransport.getNeedHistoryList(payload);
      dispatch.needState.setHistoryList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.needState.setLoading(false);
    }
  },
});
