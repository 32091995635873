import React, { FC, Key, useMemo, useState } from 'react';
import { Button, Input, Modal, Tree } from 'antd';
import { AntTreeNodeProps } from 'antd/es/tree';
import { EPlaceholder } from 'common/const/placeholder.enum';
import {
  filterTreeDataBySearch,
  getSelectedKeyFromTreeKey,
  getTreeNodeParentKey,
  highlightTreeTitle,
} from 'common/helpers/tree.helper';
import { stringToNumber } from 'common/helpers/common.helper';
import { ITreeDataNode } from 'common/models';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as ArrowRightShortIcon } from 'app/assets/images/redesign/arrow-right-short.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';

interface IComponentProps {
  open: boolean;
  treeData: ITreeDataNode[];
  loading: boolean;
  onClose: () => void;
  onSave: (id: number, onClose: () => void) => void;
}

export const SubdivisionTransferModal: FC<IComponentProps> = ({ open, treeData, loading, onClose, onSave }) => {
  const [search, setSearch] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);

  const highlightedTreeData = useMemo(() => highlightTreeTitle(treeData, search), [search, treeData]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const matchedKeys = filterTreeDataBySearch(treeData, value);
    const newExpandedKeys = matchedKeys
      .map((key) => getTreeNodeParentKey(key, treeData))
      .filter((key, index, array): key is Key => key !== null && array.indexOf(key) === index);

    setSelectedKeys([]);
    setExpandedKeys(newExpandedKeys);
    setSearch(value);
    setAutoExpandParent(true);
  };

  const onExpand = (keys: Key[]) => {
    setExpandedKeys(keys);
    setAutoExpandParent(false);
  };

  const onSelect = (keys: Key[]) => {
    if (!keys.length) {
      return;
    }

    setSelectedKeys(keys);
  };

  const handleClose = () => {
    setSearch('');
    setExpandedKeys([]);
    setSelectedKeys([]);
    onClose();
  };

  const onSaveClick = () => {
    if (selectedKeys.length) {
      const subdivisionKey = getSelectedKeyFromTreeKey(selectedKeys[0]);

      onSave(stringToNumber(subdivisionKey), handleClose);
    }
  };

  return (
    <Modal
      rootClassName="redesign modal"
      width={400}
      centered
      open={open}
      onCancel={handleClose}
      destroyOnClose
      closeIcon={false}
      footer={[
        <Button key="cancel" className="button-sm secondary" onClick={handleClose} loading={loading}>
          Отмена
        </Button>,
        <Button key="save" className="button-sm primary" loading={loading} disabled={!selectedKeys.length} onClick={onSaveClick}>
          Сохранить
        </Button>,
      ]}
    >
      <Input
        className="mb-20"
        placeholder={EPlaceholder.TransferTo}
        allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
        value={search}
        onChange={onSearchChange}
      />

      <div className="mb-20" style={{ height: 360 }}>
        <Tree
          height={360}
          virtual
          treeData={highlightedTreeData}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          selectedKeys={selectedKeys}
          onExpand={onExpand}
          onSelect={onSelect}
          switcherIcon={(props: AntTreeNodeProps) => {
            return props.expanded ? (
              <ArrowDownShortIcon className="icon-arrow-down-short-dark-grey" />
            ) : (
              <ArrowRightShortIcon className="icon-arrow-right-short-dark-grey" />
            );
          }}
        />
      </div>
    </Modal>
  );
};
