import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Progress } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Navigate } from 'react-router-dom';
import { ERoute } from 'common/const/route.enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { AUTH_REDIRECT_TIMEOUT_MS, ACTIVATION_STEP_ONE, ACTIVATION_STEP_TWO, ACTIVATION_STEP_THREE } from 'common/config';
import { IFormValue } from 'common/models';
import { RootDispatch, RootState } from 'app/store';
import { ActivationStepOne } from 'entities/Auth/components/Activation/ActivationStepOne';
import { ActivationStepTwo } from 'entities/Auth/components/Activation/ActivationStepTwo';
import { ActivationStepThree } from 'entities/Auth/components/Activation/ActivationStepThree';
import { ActivationSuccessCard } from 'entities/Auth/components//Activation/ActivationSuccessCard';
import { AuthCardLogo } from 'entities/Auth/components/AuthCardLogo';
import { AuthCardFooter } from 'entities/Auth/components/AuthCardFooter';
import { getActivationProgressPercent } from 'entities/Auth/Auth.helper';
import { IInvitationActivatePayload } from 'entities/Invitation/Invitation.models';
import { getUserRole } from 'entities/User/User.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = (props) => {
  const { auth, authLoading, invitation, invitationLoading, addAuth, getInvitation, activateInvitation } = props;

  const [payload, setPayload] = useState<IInvitationActivatePayload | null>(null);
  const [showSuccessCard, setShowSuccessCard] = useState<boolean>(false);
  const [canBeRedirected, setCanBeRedirected] = useState<boolean>(true);
  const [step, setStep] = useState<number>(ACTIVATION_STEP_ONE);
  const [form] = useForm();
  const { searchParams } = useSearchParamsHook();

  const hash = searchParams.get('hash');
  const isFinishStep = step === ACTIVATION_STEP_THREE;
  const { isSuperAdmin } = getUserRole(auth?.access.roles);

  const onFormValuesChange = (value: IFormValue) => {
    if (!('passwordConfirm' in value)) {
      setPayload((prev) => (prev ? { ...prev, ...value } : null));
    }
  };

  const onSubmit = async () => {
    setCanBeRedirected(false);

    if (payload) {
      if (isFinishStep) {
        const response = await activateInvitation(payload);

        if (response) {
          await addAuth({
            email: response.email,
            password: payload.password,
            onSuccess: () => {
              setShowSuccessCard(true);
              setTimeout(() => setCanBeRedirected(true), AUTH_REDIRECT_TIMEOUT_MS);
            },
          });
        }
      }

      setStep((prev) => prev + 1);
    }
  };

  const renderStep = () => {
    switch (step) {
      case ACTIVATION_STEP_TWO: {
        return <ActivationStepTwo loading={invitationLoading} />;
      }
      case ACTIVATION_STEP_THREE: {
        return <ActivationStepThree loading={invitationLoading || authLoading} />;
      }
      default: {
        return <ActivationStepOne loading={invitationLoading} />;
      }
    }
  };

  useEffect(() => {
    if (invitation) {
      setPayload({
        hash: hash as string,
        lastName: invitation.lastName,
        firstName: invitation.firstName,
        patronymic: invitation.patronymic,
        password: '',
      });

      form.setFieldsValue({
        accountName: invitation.accountName,
        email: invitation.email,
        lastName: invitation.lastName,
        firstName: invitation.firstName,
        patronymic: invitation.patronymic,
      });
    }
  }, [invitation]);

  useEffect(() => {
    if (hash) {
      getInvitation(hash);
    }
  }, [hash]);

  if (auth && canBeRedirected) {
    return isSuperAdmin ? <Navigate to={ERoute.AccountList} replace /> : <Navigate to={ERoute.Profile} replace />;
  }

  if (showSuccessCard) {
    return <ActivationSuccessCard firstName={payload?.firstName} patronymic={payload?.patronymic} />;
  }

  return (
    <div className="auth-card">
      <AuthCardLogo />

      <div className="auth-card__content">
        <Form
          className="auth-card__content-form"
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={onSubmit}
          onValuesChange={onFormValuesChange}
        >
          {renderStep()}
        </Form>
      </div>

      <AuthCardFooter
        content={<Progress showInfo={false} percent={getActivationProgressPercent(step)} steps={21} strokeColor="#69A443" />}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  auth: state.authState.data,
  authLoading: state.authState.loading,
  invitation: state.invitationState.data,
  invitationLoading: state.invitationState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  addAuth: dispatch.authState.addAuth,
  getInvitation: dispatch.invitationState.getInvitation,
  activateInvitation: dispatch.invitationState.activateInvitation,
});

export const ActivationCard = connect(mapState, mapDispatch)(Component);
