import { ICatalog, ICatalogListState, ICatalogState } from 'entities/Catalog/Catalog.models';

export const catalogListStateReducers = {
  seList: (state: ICatalogListState, payload: ICatalog[]) => ({ ...state, data: payload }),
  seCount: (state: ICatalogListState, payload: number) => ({ ...state, count: payload }),
  setLoading: (state: ICatalogListState, payload: boolean) => ({ ...state, loading: payload }),
  addCatalog: (state: ICatalogListState, payload: ICatalog) => ({
    ...state,
    data: [...state.data, payload],
  }),
  updateCatalog: (state: ICatalogListState, payload: ICatalog) => ({
    ...state,
    data: state.data.map((catalog) => (catalog.id === payload.id ? payload : catalog)),
  }),
  deleteCatalog: (state: ICatalogListState, payload: number) => ({
    ...state,
    data: state.data.filter((catalog) => catalog.id !== payload),
  }),
};

export const catalogStateReducers = {
  setCatalog: (state: ICatalogState, payload: ICatalog | null) => ({ ...state, data: payload }),
  seLoading: (state: ICatalogState, payload: boolean) => ({ ...state, loading: payload }),
};
