import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, InputNumber, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { WarningCard } from 'common/components/WarningCard';
import { SelectWithSearch } from 'common/components/SelectWithSearch';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { EOrderDirection, EOrderField } from 'common/const/common.enum';
import { ERoute } from 'common/const/route.enum';
import { ESettingsMenuTab, ESettingsTab } from 'common/const/settings.enum';
import { debounce } from 'common/helpers/common.helper';
import { showMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { renderSelectCustomOptionWithUser } from 'common/helpers/select.helper';
import { useSubdivisionContext } from 'common/hooks/useSubdivisionContext';
import { LIST_LIMIT_0 } from 'common/config';
import { IFormValue } from 'common/models';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';
import { RootDispatch, RootState } from 'app/store';
import {
  getSubdivisionSettingsManagerOptions,
  getSubdivisionSettingsSubdivisionHeadOptions,
  getSubdivisionSettingsSubdivisionLegalEntityOptions,
} from 'entities/Settings/Settings.helper';
import { ISubdivision, ISubdivisionUpdatePayload } from 'entities/Subdivision/Subdivision.models';
import { SubdivisionCreateCard } from 'entities/Subdivision/components/SubdivisionCreateCard';
import { findSubdivisionChildrenIds } from 'entities/Subdivision/Subdivision.helper';

interface IComponentProps {
  header: ReactNode;
  subdivision: ISubdivision | null;
  onSubdivisionUpdate: (payload: ISubdivisionUpdatePayload) => Promise<void>;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    subdivision,
    onSubdivisionUpdate,
    // state
    legalEntityList,
    userList,
    subdivisionList,
    currentUser,
    subdivisionLoading,
    // dispatch
    getLegalEntityList,
    getUserList,
    filterSubdivisionList,
    deleteSubdivision,
  } = props;

  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const [form] = useForm();
  const navigate = useNavigate();
  const { setParentId, setOpenSubdivisionCreateCard } = useSubdivisionContext();

  const accountId = currentUser?.account?.id;
  const legalEntityOptions = useMemo(() => {
    return getSubdivisionSettingsSubdivisionLegalEntityOptions(legalEntityList);
  }, [legalEntityList]);
  const subdivisionHeadOptions = useMemo(() => {
    return getSubdivisionSettingsSubdivisionHeadOptions(userList);
  }, [userList]);
  const managerOptions = useMemo(() => {
    return getSubdivisionSettingsManagerOptions(userList);
  }, [userList]);

  const debouncedSubdivisionUpdate = debounce(onSubdivisionUpdate);

  const onFormValuesChange = (value: IFormValue) => {
    const successMessage = 'Подразделение успешно изменено.';

    if (subdivision) {
      if ('code' in value || 'limit' in value || 'maxGoodPrice' in value) {
        debouncedSubdivisionUpdate({
          id: subdivision.id,
          ...value,
          onSuccess: () => showSuccessMessage(successMessage),
        });
      } else if ('headOfDepartmentId' in value) {
        onSubdivisionUpdate({
          id: subdivision.id,
          headOfDepartmentId: value.headOfDepartmentId ? value.headOfDepartmentId : null,
          onSuccess: () => showSuccessMessage(successMessage),
        });
      } else {
        onSubdivisionUpdate({
          id: subdivision.id,
          ...value,
          onSuccess: () => showSuccessMessage(successMessage),
        });
      }
    }
  };

  const onDeleteSubdivisionConfirmClick = () => {
    if (subdivision) {
      deleteSubdivision({
        id: subdivision.id,
        onSuccess: () => {
          const subdivisionAndChildrenIds = findSubdivisionChildrenIds(subdivision.id, subdivisionList);

          setOpenWarningCard(false);
          showMessage({
            content: `Подразделение ${subdivision.name} удалено.`,
            icon: <BinIcon className="icon-bin-dark-grey" />,
          });
          filterSubdivisionList(subdivisionAndChildrenIds);

          if (subdivision.parentId) {
            navigate(`${ERoute.Settings}/${ESettingsTab.Subdivision}/${subdivision.parentId}/${ESettingsMenuTab.General}`);
          } else {
            navigate(`${ERoute.Settings}/${ESettingsTab.Account}/${ESettingsMenuTab.Contracts}`);
          }
        },
      });
    }
  };

  useEffect(() => {
    if (!fetchLoading && subdivision?.id) {
      form.setFieldsValue({
        legalId: subdivision.legalId,
        code: subdivision.code,
        headOfDepartmentId: subdivision.headOfDepartmentId,
        managerId: subdivision.managerId,
        limit: subdivision.limit,
        maxGoodPrice: subdivision.maxGoodPrice,
      });
    }
  }, [fetchLoading, subdivision?.id]);

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      form.resetFields();
      await getLegalEntityList({ limit: LIST_LIMIT_0 });

      if (accountId) {
        await getUserList({
          accountId,
          orderField: EOrderField.LastName,
          orderDirection: EOrderDirection.ASC,
          limit: LIST_LIMIT_0,
        });
      }

      setFetchLoading(false);
    };

    fetch();
  }, [accountId, subdivision?.id]);

  return (
    <>
      <div className="subdivision-general-settings">
        <InfiniteScrollContainer>
          <Form className="redesign mb-72" form={form} layout="vertical" onValuesChange={onFormValuesChange}>
            {header}

            <Spin spinning={fetchLoading} indicator={<SpinIndicator />}>
              <div className="mb-20 subdivision-general-settings__container">
                <Form.Item className="mb-32" label="Юридическое лицо" name="legalId">
                  <SelectWithSearch placeholder={EPlaceholder.SelectLegalEntity} options={legalEntityOptions} />
                </Form.Item>

                <Form.Item className="mb-32" label="Код подразделения" name="code">
                  <Input placeholder={EPlaceholder.EnterSubdivisionCode} />
                </Form.Item>

                <Form.Item className="mb-32" name="headOfDepartmentId" label="Руководитель подразделения">
                  <SelectWithSearch
                    placeholder={EPlaceholder.SelectSubdivisionHead}
                    options={subdivisionHeadOptions}
                    allowClear
                    optionRender={(option) => {
                      const user = userList.find((item) => item.id === option.value);

                      return user ? renderSelectCustomOptionWithUser(user) : option.label;
                    }}
                  />
                </Form.Item>

                <Form.Item className="mb-32" label="Менеджер по закупкам" name="managerId">
                  <SelectWithSearch
                    placeholder={EPlaceholder.SelectManager}
                    options={managerOptions}
                    optionRender={(option) => {
                      const user = userList.find((item) => item.id === option.value);

                      return user ? renderSelectCustomOptionWithUser(user) : option.label;
                    }}
                  />
                </Form.Item>
              </div>

              <div className="mb-32" style={{ maxWidth: 800 }}>
                <div className="text-h4 mb-6">Лимиты на закупку</div>

                <span className="text-tag color-dark-grey">
                  Лимиты, установленные в настройках аккаунта, применены к подразделению по-умолчанию. Изменения лимитов в
                  настройках подразделения распространяются только на подразделение.
                </span>
              </div>

              <div className="mb-52 subdivision-general-settings__container">
                <Form.Item className="mb-32" label="Лимит расходов на закупки" name="limit">
                  <InputNumber placeholder="0.00" suffix="₽" controls={false} precision={0} />
                </Form.Item>

                <Form.Item className="mb-32" label="Лимит цены на товар (включительно)" name="maxGoodPrice">
                  <InputNumber placeholder="0.00" suffix="₽" controls={false} precision={0} />
                </Form.Item>
              </div>
            </Spin>
          </Form>
        </InfiniteScrollContainer>

        <div className="subdivision-general-settings__footer">
          <Button
            className="button-sm deleting"
            icon={<BinIcon className="icon-bin-pale-red" />}
            onClick={() => setOpenWarningCard(true)}
          >
            Удалить подразделение
          </Button>

          <Button
            className="button-sm primary"
            icon={<PlusIcon className="icon-plus-white" />}
            onClick={() => {
              setParentId(subdivision?.id);
              setOpenSubdivisionCreateCard(true);
            }}
          >
            Дочернее подразделение
          </Button>
        </div>
      </div>

      <SubdivisionCreateCard />

      <WarningCard
        open={openWarningCard}
        content="Вы уверены, что хотите удалить подразделение?"
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Удалить подразделение"
        loading={subdivisionLoading}
        onConfirm={onDeleteSubdivisionConfirmClick}
        onClose={() => setOpenWarningCard(false)}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  legalEntityList: state.legalEntityListState.data,
  userList: state.userListState.data,
  subdivisionList: state.subdivisionListState.data,
  currentUser: state.userState.currentUser,
  subdivisionLoading: state.subdivisionState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getLegalEntityList: dispatch.legalEntityListState.getLegalEntityList,
  getUserList: dispatch.userListState.getUserList,
  filterSubdivisionList: dispatch.subdivisionListState.filterList,
  deleteSubdivision: dispatch.subdivisionState.deleteSubdivision,
});

export const SubdivisionGeneralSettings = connect(mapState, mapDispatch)(Component);
