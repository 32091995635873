import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Popover, Spin, Switch } from 'antd';
import { connect } from 'react-redux';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { TabNavigation } from 'common/components/TabNavigation';
import { ERequestsNavigationTab } from 'common/const/request.enum';
import { ERoute } from 'common/const/route.enum';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as SettingsIcon } from 'app/assets/images/redesign/settings.svg';
import { IncomingRequestsForManager } from 'entities/Requests/components/IncomingRequestsForManager';
import { SupplyListForManager } from 'entities/Supply/components/Manager/SupplyListForManager';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ auth, currentUser, updateUserAutoSupplyApprove, getCurrentBasket }) => {
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const { tab } = useParams();

  const basketId = auth?.access.basketId;
  const isSupplyList = tab === ERequestsNavigationTab.SupplyList;
  const header = useMemo(() => {
    return (
      <>
        <div className="need-list-header">
          <Popover
            overlayStyle={{ width: 360 }}
            overlayClassName="redesign"
            placement="bottomRight"
            content={
              <div className="need-list-header__settings-popover">
                <span className="text-h4 mb-6">Автоматическое подтверждение поставок</span>

                <span className="text-tag color-dark-grey">
                  Когда включена эта функция, все поставки, кроме тех, где поставщик изменил цену, подтверждаются автоматически.
                </span>

                <div className="divider" style={{ margin: '12px 0' }} />

                <div className="need-list-header__settings-popover-footer">
                  <span className="text-controls">Подтверждать автоматически</span>

                  <Switch
                    defaultChecked={currentUser?.autoSupplyApprove}
                    onChange={(checked) => updateUserAutoSupplyApprove({ autoSupplyApprove: checked })}
                  />
                </div>
              </div>
            }
            trigger="click"
          >
            <Button className="button-circle secondary" icon={<SettingsIcon />} />
          </Popover>
        </div>

        <div className="text-body color-dark-grey need-list__subtitle">Заявки на закупку</div>
      </>
    );
  }, [currentUser]);
  const navigation = useMemo(() => {
    return (
      <TabNavigation
        items={[
          { label: 'Входящие заявки', path: ERoute.RequestsForManager },
          { label: 'Поставки', path: `${ERoute.RequestsForManager}/${ERequestsNavigationTab.SupplyList}` },
        ]}
      />
    );
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (basketId) {
        await getCurrentBasket(basketId);
      }

      setFetchLoading(false);
    };

    fetch();
  }, [basketId]);

  if (fetchLoading) {
    return <Spin indicator={<SpinIndicator />} />;
  }

  return isSupplyList ? (
    <SupplyListForManager header={header} navigation={navigation} />
  ) : (
    <IncomingRequestsForManager header={header} navigation={navigation} />
  );
};

const mapState = (state: RootState) => ({
  auth: state.authState.data,
  currentUser: state.userState.currentUser,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateUserAutoSupplyApprove: dispatch.userState.updateUserAutoSupplyApprove,
  getCurrentBasket: dispatch.basketState.getCurrentBasket,
});

export const RequestsForManager = connect(mapState, mapDispatch)(Component);
