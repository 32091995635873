import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { IBrandDiscountState, IBrandDiscountListState } from 'entities/Discount/Discount.models';
import { brandDiscountListStateReducers, brandDiscountStateReducers } from 'entities/Discount/Discount.reducers';
import { brandDiscountListStateEffects, brandDiscountStateEffects } from 'entities/Discount/Discount.effects';

export const brandDiscountListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IBrandDiscountListState,
  reducers: brandDiscountListStateReducers,
  effects: brandDiscountListStateEffects,
});

export const brandDiscountState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IBrandDiscountState,
  reducers: brandDiscountStateReducers,
  effects: brandDiscountStateEffects,
});
