import { ISubdivision, ISubdivisionListState, ISubdivisionState } from 'entities/Subdivision/Subdivision.models';
import { IUser } from 'entities/User/User.models';

export const subdivisionListStateReducers = {
  setList: (state: ISubdivisionListState, payload: ISubdivision[]) => ({ ...state, data: payload }),
  filterList: (state: ISubdivisionListState, payload: number[]) => {
    return {
      ...state,
      data: state.data.filter((subdivision) => !payload.includes(subdivision.id)),
    };
  },
  setCount: (state: ISubdivisionListState, payload: number) => ({ ...state, count: payload }),
  setLoading: (state: ISubdivisionListState, payload: boolean) => ({ ...state, loading: payload }),
  addSubdivision: (state: ISubdivisionListState, payload: ISubdivision) => ({ ...state, data: [...state.data, payload] }),
  updateSubdivision: (state: ISubdivisionListState, payload: ISubdivision) => ({
    ...state,
    data: state.data.map((subdivision) => (subdivision.id === payload.id ? payload : subdivision)),
  }),
  deleteSubdivision: (state: ISubdivisionListState, payload: number) => ({
    ...state,
    data: state.data.filter((subdivision) => subdivision.id !== payload),
  }),
};

export const subdivisionStateReducers = {
  setSubdivision: (state: ISubdivisionState, payload: ISubdivision) => ({ ...state, data: payload }),
  setLoading: (state: ISubdivisionState, payload: boolean) => ({ ...state, loading: payload }),
  setUserList: (state: ISubdivisionState, payload: IUser[]) => ({ ...state, userList: payload }),
  setUserListPart: (state: ISubdivisionState, payload: IUser[]) => ({ ...state, userList: [...state.userList, ...payload] }),
  setUserListCount: (state: ISubdivisionState, payload: number) => ({ ...state, userListCount: payload }),
  addUser: (state: ISubdivisionState, payload: IUser) => {
    return {
      ...state,
      userList: [...state.userList, payload],
      userListCount: state.userListCount + 1,
    };
  },
  updateUser: (state: ISubdivisionState, payload: IUser) => {
    return {
      ...state,
      userList: state.userList.map((user) => (user.id === payload.id ? payload : user)),
    };
  },
  filterUserList: (state: ISubdivisionState, payload: number) => {
    return {
      ...state,
      userList: state.userList.filter((user) => user.id !== payload),
      userListCount: state.userListCount - 1,
    };
  },
};
