export const deliveryInDaysOptions = [
  {
    label: 'Не доставляется',
    value: null,
  },
  {
    label: '24 часа',
    value: 1,
  },
  {
    label: '72 часа',
    value: 3,
  },
  {
    label: '1 неделя',
    value: 7,
  },
  {
    label: '2–3 недели',
    value: 14,
  },
  {
    label: '1 месяц',
    value: 30,
  },
  {
    label: '2–3 месяца',
    value: 60,
  },
  {
    label: '4–6 месяцев',
    value: 120,
  },
];
