import React, { FC, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { IconBackground } from 'common/components/IconBackground';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { EColor } from 'common/const/color.enum';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as InfoYellowIcon } from 'app/assets/images/redesign/info-yellow.svg';
import { IUser } from 'entities/User/User.models';
import { getUserName } from 'entities/User/User.helper';
import { getSubdivisionSettingsBreadcrumbItems, getSubdivisionTreeKeysBySubdivisionId } from 'entities/Settings/Settings.helper';
import { ISubdivision } from 'entities/Subdivision/Subdivision.models';

interface IComponentProps {
  open: boolean;
  placeholder: EPlaceholder;
  loading: boolean;
  userList: IUser[];
  checkSubdivision?: boolean;
  subdivisionList?: ISubdivision[];
  onClose: () => void;
  onAdd: (value: IUser, onSuccess: () => void) => void;
}

export const UserAddModal: FC<IComponentProps> = (props) => {
  const { open, placeholder, loading, userList, checkSubdivision, subdivisionList = [], onClose, onAdd } = props;

  const [search, setSearch] = useState('');
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [openNotificationModal, setOpenNotificationModal] = useState<boolean>(false);

  const userListForView = userList.filter((user) => {
    return getUserName.fullName(user.lastName, user.firstName, user.patronymic).toLowerCase().includes(search.toLowerCase());
  });
  const subdivisionTreeKeys = getSubdivisionTreeKeysBySubdivisionId(subdivisionList, selectedUser?.subdivision?.id.toString());
  const subdivisionBreadcrumbItems = getSubdivisionSettingsBreadcrumbItems(subdivisionList, subdivisionTreeKeys);

  const handleClose = () => {
    if (loading) {
      return;
    }

    setSearch('');
    setSelectedUser(null);
    onClose();
    setOpenNotificationModal(false);
  };

  const onAddClick = () => {
    if (selectedUser) {
      onAdd(selectedUser, handleClose);
    }
  };

  const handleAdd = () => {
    if (checkSubdivision && selectedUser?.subdivision) {
      onClose();
      setOpenNotificationModal(true);
      return;
    }

    onAddClick();
  };

  const onNotificationClose = () => {
    setSearch('');
    setSelectedUser(null);
    setOpenNotificationModal(false);
  };

  return (
    <>
      <Modal
        rootClassName="redesign modal"
        width={400}
        centered
        open={open}
        onCancel={handleClose}
        destroyOnClose
        closeIcon={false}
        footer={[
          <Button key="cancel" className="button-sm secondary" onClick={handleClose} loading={loading}>
            Отмена
          </Button>,
          <Button key="save" className="button-sm primary" loading={loading} disabled={!selectedUser} onClick={handleAdd}>
            Добавить
          </Button>,
        ]}
      >
        <Input
          className="mb-20"
          placeholder={placeholder}
          allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <div className="scrollbar user-add-modal__scroll-container">
          {userListForView.length ? (
            userListForView.map((user) => {
              return (
                <Button
                  key={user.id}
                  className={`user-add-modal__list-item ${user.id === selectedUser?.id ? 'active' : ''}`}
                  onClick={() => setSelectedUser(user)}
                >
                  <IconBackground
                    content={
                      <span className="text-profile-icon color-dark-grey">
                        {getUserName.initials(user.lastName, user.firstName)}
                      </span>
                    }
                    color={EColor.LightGrey}
                  />

                  <div>
                    <div className="text-controls-accent color-black mb-4">
                      {getUserName.fullName(user.lastName, user.firstName, user.patronymic)}
                    </div>

                    <span className="text-tag color-dark-grey">{user.email}</span>
                  </div>
                </Button>
              );
            })
          ) : (
            <div className="text-body color-dark-grey user-add-modal__list-empty">Список пользователей пуст</div>
          )}
        </div>
      </Modal>

      <Modal
        rootClassName="redesign modal"
        width={440}
        centered
        open={openNotificationModal}
        onCancel={onNotificationClose}
        destroyOnClose
        footer={[
          <Button key="cancel" className="button-sm secondary" onClick={onNotificationClose} loading={loading}>
            Отмена
          </Button>,
          <Button key="accept" className="button-sm primary" loading={loading} onClick={onAddClick}>
            Подтвердить
          </Button>,
        ]}
      >
        <div className="mb-24">
          <IconBackground content={<InfoYellowIcon className="icon-info-yellow" />} color={EColor.PaleYellow} />
        </div>

        <div className="text-h4 mb-12">Переназначение подразделения</div>

        <div className="text-controls color-dark-grey mb-12">{`Пользователь ${selectedUser?.email} уже привязан к подразделению «${subdivisionBreadcrumbItems
          .map((item: any) => item.title)
          .join('/')}».`}</div>

        <div className="text-controls color-dark-grey mb-20">Вы уверены, что хотите изменить назначение?</div>
      </Modal>
    </>
  );
};
