/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'common/hooks/useDebounce';
import { RootState } from 'app/store';
import { IProperty } from 'entities/Property/Property.models';
import {
  filterNotApplicableProperties,
  getSearchParamsUrlFromPropertyList,
  mapPropertyListForViewToFormValues,
  setPropertyListItemHidden,
  setPropertyListResult,
} from 'entities/Property/Property.helper';
import { ICategory } from 'entities/Category/Category.models';

export interface IPropertyContext {
  setFilterIsChanged: (value: boolean) => void;
  selectedCategory: ICategory | null;
  onSelectedCategoryChange: (value: ICategory | null) => void;
  propertiesForView: IProperty[];
  onPropertiesForViewChange: (value: IProperty[]) => void;
  initialFormValues: { [key: string]: any };
}

export const PropertyContext = createContext<IPropertyContext>({
  setFilterIsChanged: () => {},
  selectedCategory: null,
  onSelectedCategoryChange: () => {},
  propertiesForView: [],
  onPropertiesForViewChange: () => {},
  initialFormValues: {},
});

export const usePropertyContext = () => useContext(PropertyContext);

export interface IComponentProps {
  children: React.ReactNode;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

export const Component: FC<AllType> = ({ children, propertyList, workspacePosition, category }) => {
  const [filterIsChanged, setFilterIsChanged] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(null);
  const [propertiesForView, setPropertiesForView] = useState<IProperty[]>([]);
  const [initialFormValues, setInitialFormValues] = useState<{ [key: string]: any }>({});
  const location = useLocation();
  const navigate = useNavigate();

  const isNewWorkspacePosition = location.pathname.includes('/new-workspace-position');
  const isEditWorkspacePosition = location.pathname.includes('/workspace-list');
  const isCatalog = location.pathname.includes('/catalog');

  const onSelectedCategoryChange = (value: ICategory | null) => setSelectedCategory(value);

  const onPropertiesForViewChange = (value: IProperty[]) => setPropertiesForView(value);

  useEffect(() => {
    if (isCatalog && category) {
      const filteredPropertyList = filterNotApplicableProperties(propertyList);
      const propertyListWithResult = setPropertyListResult.forCatalogGoodsListFilter(filteredPropertyList, category.positions);
      const propertyListForView = setPropertyListItemHidden(propertyListWithResult);

      setPropertiesForView(propertyListForView);
    }
  }, [location, isCatalog, propertyList, category]);

  useEffect(() => {
    if (isNewWorkspacePosition && selectedCategory) {
      const filteredPropertyList = filterNotApplicableProperties(propertyList);
      const propertyListWithResult = setPropertyListResult.forNewWorkspacePosition(
        filteredPropertyList,
        selectedCategory.positions,
      );
      const propertyListForView = setPropertyListItemHidden(propertyListWithResult);

      setPropertiesForView(propertyListForView);
    }
  }, [isNewWorkspacePosition, propertyList]);

  useEffect(() => {
    if (isEditWorkspacePosition && workspacePosition) {
      const filteredPropertyList = filterNotApplicableProperties(propertyList);
      const propertyListWithResult = setPropertyListResult.forEditWorkspacePosition(filteredPropertyList, workspacePosition);
      const propertyListForView = setPropertyListItemHidden(propertyListWithResult);
      const initialFormValues = mapPropertyListForViewToFormValues(propertyListForView);

      setPropertiesForView(propertyListForView);
      setInitialFormValues({ inventoryLimit: workspacePosition.inventoryLimit, ...Object.fromEntries(initialFormValues) });
    }
  }, [isEditWorkspacePosition, propertyList, workspacePosition]);

  useDebounce(() => {
    if (filterIsChanged) {
      const searchUrl = getSearchParamsUrlFromPropertyList(propertiesForView);

      navigate(`${location.pathname}?${searchUrl}`);
      setFilterIsChanged(false);
    }
  }, [filterIsChanged, propertiesForView]);

  return (
    <PropertyContext.Provider
      value={{
        setFilterIsChanged,
        selectedCategory,
        onSelectedCategoryChange,
        propertiesForView,
        onPropertiesForViewChange,
        initialFormValues,
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};

const mapState = (state: RootState) => ({
  propertyList: state.propertyListState.data,
  workspacePosition: state.workspacePositionState.data,
  category: state.categoryState.data,
});

export const PropertyProvider = connect(mapState)(Component);
