import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { WarningCard } from 'common/components/WarningCard';
import { EMode } from 'common/const/common.enum';
import { showMessage } from 'common/helpers/message.helper';
import { LIST_LIMIT_0 } from 'common/config';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { renderStockListSettingsRecords } from 'entities/Settings/Settings.helper';
import { StockCreateCard } from 'entities/Stock/components/StockCreateCard';

interface IComponentProps {
  header: ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const { header, stockList, stockListLoading, stock, stockLoading, getStockList, getStockById, deleteStock } = props;

  const [openStockCreateCard, setOpenStockCreateCard] = useState<boolean>(false);
  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const [stockCreateCardMode, setStockCreateCardMode] = useState<EMode>(EMode.Create);

  const onCreateStockClick = () => {
    setStockCreateCardMode(EMode.Create);
    setOpenStockCreateCard(true);
  };

  const onEditStockClick = (id: number) => {
    getStockById({
      id,
      onSuccess: () => {
        setStockCreateCardMode(EMode.Edit);
        setOpenStockCreateCard(true);
      },
    });
  };

  const onDeleteStockClick = (id: number) => {
    getStockById({ id, onSuccess: () => setOpenWarningCard(true) });
  };

  const onDeleteStockConfirmClick = () => {
    if (stock) {
      deleteStock({
        id: stock.id,
        onSuccess: () => {
          showMessage({
            content: `Склад «${stock.name}» удален.`,
            icon: <BinIcon className="icon-bin-dark-grey" />,
          });
          setOpenWarningCard(false);
        },
      });
    }
  };

  useEffect(() => {
    getStockList({ limit: LIST_LIMIT_0 });
  }, []);

  return (
    <>
      <InfiniteScrollContainer>
        {header}

        <div className="mb-20">
          <Button className="button-sm primary" icon={<PlusIcon className="icon-plus-white" />} onClick={onCreateStockClick}>
            Добавить склад
          </Button>
        </div>

        <Table
          className={`${!stockList.length && 'table-empty'} mb-72 account-stock-list-settings__table`}
          showHeader={false}
          pagination={false}
          size="middle"
          dataSource={stockList.map((stock) => ({ ...stock, key: stock.id }))}
          columns={renderStockListSettingsRecords(onEditStockClick, onDeleteStockClick)}
          locale={{ emptyText: <div className="text-body color-dark-grey">Юридические лица ещё не указаны.</div> }}
          loading={{ spinning: stockLoading || stockListLoading, indicator: <SpinIndicator /> }}
        />
      </InfiniteScrollContainer>

      <StockCreateCard open={openStockCreateCard} mode={stockCreateCardMode} onOpenChange={setOpenStockCreateCard} />

      <WarningCard
        open={openWarningCard}
        content="Вы уверены, что хотите удалить адрес доставки?"
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Удалить"
        loading={stockLoading}
        onConfirm={onDeleteStockConfirmClick}
        onClose={() => setOpenWarningCard(false)}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  stockList: state.stockListState.data,
  stockListLoading: state.stockListState.loading,
  stock: state.stockState.data,
  stockLoading: state.stockState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getStockList: dispatch.stockListState.getStockList,
  getStockById: dispatch.stockState.getStockById,
  deleteStock: dispatch.stockState.deleteStock,
});

export const AccountStockListSettings = connect(mapState, mapDispatch)(Component);
