import { createModel } from '@rematch/core';
import { DEFAULT_VALUE_0 } from 'common/config';
import { IRootModel } from 'app/store';
import { IStockListState, IStockState } from 'entities/Stock/Stock.models';
import { stockListStateReducers, stockStateReducers } from 'entities/Stock/Stock.reducers';
import { stockListStateEffects, stockStateEffects } from 'entities/Stock/Stock.effects';

export const stockListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: DEFAULT_VALUE_0,
    loading: false,
  } as IStockListState,
  reducers: stockListStateReducers,
  effects: stockListStateEffects,
});

export const stockState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IStockState,
  reducers: stockStateReducers,
  effects: stockStateEffects,
});
