import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Spin, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { DeliveryBadge } from 'common/components/DeliveryBadge';
import { SuccessCard } from 'common/components/SuccessCard';
import { WarningCard } from 'common/components/WarningCard';
import { Notification } from 'common/components/Notification';
import { useDebounce } from 'common/hooks/useDebounce';
import { LIST_LIMIT_0 } from 'common/config';
import { showMessage } from 'common/helpers/message.helper';
import { EMessage } from 'common/const/message.enum';
import { EDateFormat } from 'common/const/date.enum';
import { ERoute } from 'common/const/route.enum';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { BasketEmpty } from 'entities/Basket/components/BasketEmpty';
import { BasketProgress } from 'entities/Basket/components/BasketProgress';
import { BasketRequestComposition } from 'entities/Basket/components/BasketRequestComposition';
import { BasketAddGoodsButton } from 'entities/Basket/components/BasketAddGoodsButton';
import { BasketMoreButton } from 'entities/Basket/components/BasketMoreButton';
import {
  filterBasketGoodsList,
  getBasketGoodsListDataSource,
  getBasketGoodsListRowKeys,
  getBasketGoodsPayload,
  getBasketRequestCompositionInfo,
  goodsToBasketGoods,
  renderBasketRecords,
} from 'entities/Basket/Basket.helper';
import { IBasketGoods } from 'entities/Basket/Basket.models';
import { IGoods } from 'entities/Goods/Goods.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const BasketComponent: FC<AllType> = (props) => {
  const {
    // state
    auth,
    currentBasket,
    basketLoading,
    basketGoodsCount,
    // dispatch
    getAddressList,
    getLegalEntityList,
    getCurrentBasket,
    updateBasket,
    createBasketRequest,
    getUserStatistics,
  } = props;

  const [goodsList, setGoodsList] = useState<IBasketGoods[]>([]);
  const [selectedGoodsList, setSelectedGoodsList] = useState<IBasketGoods[]>([]);
  const [goodsId, setGoodsId] = useState<number | null>(null);
  const [countIsChanged, setCountIsChanged] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [openSuccessCard, setOpenSuccessCard] = useState<boolean>(false);
  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const [openClearAllWarningCard, setOpenClearAllWarningCard] = useState<boolean>(false);
  const navigate = useNavigate();

  const basketId = auth?.access.basketId;
  const checkAll = goodsList.length === selectedGoodsList.length;
  const indeterminate = selectedGoodsList.length > 0 && selectedGoodsList.length < goodsList.length;
  const goodsListDataSource = useMemo(() => getBasketGoodsListDataSource(goodsList), [goodsList]);
  const goodsListSelectedRowKeys = useMemo(() => getBasketGoodsListRowKeys(selectedGoodsList), [selectedGoodsList]);
  const goodsListExpandedRowKeys = useMemo(() => getBasketGoodsListRowKeys(goodsList), [goodsList]);
  const { amount, availableAmount, limitExceeded } = useMemo(() => {
    return getBasketRequestCompositionInfo(selectedGoodsList, currentBasket?.availableLimit);
  }, [selectedGoodsList, currentBasket?.availableLimit]);

  const onCountChange = (id: number, value: number) => {
    setGoodsList((prev) => prev.map((goods) => (id === goods.goodId ? { ...goods, count: value } : goods)));
    setSelectedGoodsList((prev) => prev.map((goods) => (id === goods.goodId ? { ...goods, count: value } : goods)));
    setCountIsChanged(true);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (indeterminate) {
      setSelectedGoodsList([]);
      return;
    }

    if (e.target.checked) {
      setSelectedGoodsList(filterBasketGoodsList.byMaxGoodPrice(goodsList, currentBasket?.maxGoodPrice));
    } else {
      setSelectedGoodsList([]);
    }
  };

  const onGoodsSelectionChange = (id: number, checked: boolean) => {
    if (checked) {
      const goods = goodsList.find((goods) => goods.goodId === id) as IBasketGoods;

      setSelectedGoodsList((prev) => [...prev, goods]);
    } else {
      setSelectedGoodsList((prev) => filterBasketGoodsList.notEqualId(prev, id));
    }
  };

  const onDeleteGoodsClick = () => {
    if (currentBasket && goodsId) {
      const newGoodsList = filterBasketGoodsList.notEqualId(goodsList, goodsId);

      updateBasket({
        id: currentBasket.id,
        goods: getBasketGoodsPayload(newGoodsList),
        onSuccess: () => {
          setGoodsList(newGoodsList);
          setSelectedGoodsList((prev) => filterBasketGoodsList.notEqualId(prev, goodsId));
          setOpenWarningCard(false);
          showMessage({ content: EMessage.GoodsHasBeenRemoved, icon: <BinIcon className="icon-bin-dark-grey" /> });
          getUserStatistics();
        },
      });
    }
  };

  const handleAddToBasket = (goods: IGoods, onSuccess: () => void) => {
    if (currentBasket) {
      const goodsForBasket = goodsToBasketGoods(goods);
      const newGoodsList = [...goodsList, goodsForBasket];

      updateBasket({
        id: currentBasket.id,
        goods: getBasketGoodsPayload(newGoodsList),
        onSuccess: () => {
          setGoodsList(newGoodsList);

          if (
            currentBasket?.maxGoodPrice !== undefined &&
            currentBasket.maxGoodPrice >= goodsForBasket.price &&
            goodsForBasket.isAvailableForCustomer
          ) {
            setSelectedGoodsList((prev) => [...prev, goodsForBasket]);
          }

          onSuccess();
          getUserStatistics();
        },
      });
    }
  };

  const onClearBasketClick = () => {
    if (currentBasket) {
      updateBasket({
        id: currentBasket.id,
        goods: [],
        onSuccess: () => {
          setGoodsList([]);
          setSelectedGoodsList([]);
          setOpenClearAllWarningCard(false);
          showMessage({ content: EMessage.AllGoodsHaveBeenRemoved, icon: <BinIcon className="icon-bin-dark-grey" /> });
          getUserStatistics();
        },
      });
    }
  };

  const onSendRequestClick = async () => {
    setRequestLoading(true);
    await createBasketRequest({
      goods: getBasketGoodsPayload(selectedGoodsList),
      onSuccess: () => {
        setOpenSuccessCard(true);
        setGoodsList((prev) => {
          return prev.filter((goods) => {
            return !selectedGoodsList.some((selectedGoods) => selectedGoods.goodId === goods.goodId);
          });
        });
        setSelectedGoodsList([]);
        getUserStatistics();
      },
    });
    setRequestLoading(false);
  };

  useEffect(() => {
    const fetch = async () => {
      if (basketId) {
        const response = await getCurrentBasket(basketId);

        if (response) {
          const { goods, subdivisionId, maxGoodPrice } = response;

          if (subdivisionId) {
            await getAddressList({ subdivisionId, limit: LIST_LIMIT_0 });
          }

          await getLegalEntityList({ limit: LIST_LIMIT_0 });

          setGoodsList(goods);
          setSelectedGoodsList(filterBasketGoodsList.byMaxGoodPrice(goods, maxGoodPrice));
        }
      }

      setFetchLoading(false);
    };

    fetch();
  }, [basketId]);

  useDebounce(() => {
    if (currentBasket && countIsChanged) {
      updateBasket({
        id: currentBasket.id,
        goods: getBasketGoodsPayload(goodsList),
        onSuccess: () => setCountIsChanged(false),
      });
    }
  }, [currentBasket, goodsList, countIsChanged]);

  return (
    <div className="redesign basket">
      <div className="basket__container">
        <div className="basket__header">
          <BasketAddGoodsButton loading={basketLoading} addToBasket={handleAddToBasket} />

          <BasketMoreButton
            id={currentBasket?.id}
            clearDisabled={!currentBasket?.goods.length}
            onDelete={() => setOpenClearAllWarningCard(true)}
          />
        </div>

        <div className="basket__content">
          <div className="basket__title">
            <span className="text-h1">Корзина</span>

            <span className="text-h3 basket__title-count">{basketGoodsCount}</span>
          </div>

          {!!goodsList.length && (
            <Checkbox checked={checkAll} indeterminate={indeterminate} className="mb-16" onChange={onCheckAllChange}>
              Включить в заявку
            </Checkbox>
          )}

          <Spin wrapperClassName="basket__spin" spinning={fetchLoading} indicator={<SpinIndicator />}>
            <div className="basket__scroll">
              <div className="basket__scroll-container">
                {!!goodsList.length && (
                  <Table
                    className="table-expandable basket__goods-list"
                    rowClassName="expanded"
                    dataSource={goodsListDataSource}
                    columns={renderBasketRecords(onCountChange)}
                    rowSelection={{
                      selectedRowKeys: goodsListSelectedRowKeys,
                      onSelect: ({ goodId }, checked) => onGoodsSelectionChange(goodId, checked),
                      columnWidth: 20,
                      getCheckboxProps: ({ price, isAvailableForCustomer }) => ({
                        disabled: !isAvailableForCustomer
                          ? true
                          : currentBasket?.maxGoodPrice !== undefined
                          ? currentBasket.maxGoodPrice < price
                          : false,
                      }),
                    }}
                    expandable={{
                      showExpandColumn: false,
                      expandedRowKeys: goodsListExpandedRowKeys,
                      expandedRowRender: ({ goodId, count, remains, price, isAvailableForCustomer }) => {
                        return (
                          <>
                            {currentBasket?.maxGoodPrice !== undefined && currentBasket.maxGoodPrice < price && (
                              <div className="mb-32">
                                <Notification
                                  type="error"
                                  description="Стоимость позиции превышает допустимый лимит на цену товара."
                                />
                              </div>
                            )}

                            {!isAvailableForCustomer && (
                              <div className="mb-32">
                                <Notification type="error" description="Товар недоступен для заказа" />
                              </div>
                            )}

                            <div className="basket__goods-list-item-footer">
                              <DeliveryBadge remains={remains} count={count} />

                              <Button
                                className="button-text"
                                onClick={() => {
                                  setGoodsId(goodId);
                                  setOpenWarningCard(true);
                                }}
                              >
                                <span>Удалить товар</span>

                                <BinIcon className="icon-bin-dark-green" />
                              </Button>
                            </div>
                          </>
                        );
                      },
                    }}
                    pagination={false}
                    showHeader={false}
                  />
                )}

                <BasketEmpty show={!currentBasket?.goods.length && !basketLoading} />
              </div>
            </div>
          </Spin>

          {currentBasket?.availableLimit && (
            <BasketProgress
              limit={currentBasket?.availableLimit}
              amount={amount}
              availableAmount={availableAmount}
              limitExceeded={limitExceeded}
            />
          )}
        </div>
      </div>

      {!!goodsList.length && (
        <BasketRequestComposition
          basket={currentBasket}
          isCurrentBasket
          selectedGoodsList={selectedGoodsList}
          limitExceeded={limitExceeded}
          loading={requestLoading}
          updateGoodsList={setGoodsList}
          onSendClick={onSendRequestClick}
        />
      )}

      <SuccessCard
        open={openSuccessCard}
        content={`Заявка от ${dayjs().format(EDateFormat.FullDateDotSeparator)} в очереди на рассмотрение`}
        subtitle="Отправлено!"
        btnTitle="К моим заявкам"
        onClose={() => setOpenSuccessCard(false)}
        onConfirm={() => navigate(ERoute.RequestsForUser)}
      />

      <WarningCard
        open={openWarningCard}
        content="Вы уверены, что хотите удалить товар?"
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Удалить"
        loading={basketLoading}
        onClose={() => setOpenWarningCard(false)}
        onConfirm={onDeleteGoodsClick}
      />

      <WarningCard
        open={openClearAllWarningCard}
        content="Вы уверены, что хотите очистить корзину?"
        subtitle="Все товары будут безвозвратно удалены."
        confirmBtnTitle="Очистить"
        loading={basketLoading}
        onClose={() => setOpenClearAllWarningCard(false)}
        onConfirm={onClearBasketClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  auth: state.authState.data,
  currentBasket: state.basketState.currentBasket,
  basketLoading: state.basketState.loading,
  basketGoodsCount: state.basketState.goodsCount,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getAddressList: dispatch.addressListState.getAddressList,
  getLegalEntityList: dispatch.legalEntityListState.getLegalEntityList,
  getCurrentBasket: dispatch.basketState.getCurrentBasket,
  updateBasket: dispatch.basketState.updateBasket,
  createBasketRequest: dispatch.basketState.createBasketRequest,
  getUserStatistics: dispatch.statisticsState.getUserStatistics,
});

export const Basket = connect(mapState, mapDispatch)(BasketComponent);
