import { createModel } from '@rematch/core';
import { DEFAULT_VALUE_0 } from 'common/config';
import { IRootModel } from 'app/store';
import { ISubdivisionListState, ISubdivisionState } from 'entities/Subdivision/Subdivision.models';
import { subdivisionListStateEffects, subdivisionStateEffects } from 'entities/Subdivision/Subdivision.effects';
import { subdivisionListStateReducers, subdivisionStateReducers } from 'entities/Subdivision/Subdivision.reducers';

export const subdivisionListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: DEFAULT_VALUE_0,
    loading: false,
  } as ISubdivisionListState,
  reducers: subdivisionListStateReducers,
  effects: subdivisionListStateEffects,
});

export const subdivisionState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    userList: [],
    userListCount: DEFAULT_VALUE_0,
  } as ISubdivisionState,
  reducers: subdivisionStateReducers,
  effects: subdivisionStateEffects,
});
