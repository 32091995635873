import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { IAccountState, IAccountListState } from 'entities/Account/Account.models';
import { accountListStateReducers, accountStateReducers } from 'entities/Account/Account.reducers';
import { accountListStateEffects, accountStateEffects } from 'entities/Account/Account.effects';

export const accountListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IAccountListState,
  reducers: accountListStateReducers,
  effects: accountListStateEffects,
});

export const accountState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    error: null,
  } as IAccountState,
  reducers: accountStateReducers,
  effects: accountStateEffects,
});
