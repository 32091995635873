import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import {
  IInventoryActionListState,
  IInventoryActionState,
  IInventoryListState,
  IInventoryState,
} from 'entities/Inventory/Inventory.models';
import {
  inventoryActionListStateReducers,
  inventoryActionStateReducers,
  inventoryListStateReducers,
  inventoryStateReducers,
} from 'entities/Inventory/Inventory.reducers';
import {
  inventoryActionListStateEffects,
  inventoryActionStateEffects,
  inventoryListStateEffects,
  inventoryStateEffects,
} from 'entities/Inventory/Inventory.effects';

export const inventoryListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
    subdivisionList: [],
    userList: [],
  } as IInventoryListState,
  reducers: inventoryListStateReducers,
  effects: inventoryListStateEffects,
});

export const inventoryState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    userList: [],
  } as IInventoryState,
  reducers: inventoryStateReducers,
  effects: inventoryStateEffects,
});

export const inventoryActionListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
    subdivisionList: [],
    userList: [],
  } as IInventoryActionListState,
  reducers: inventoryActionListStateReducers,
  effects: inventoryActionListStateEffects,
});

export const inventoryActionState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    userList: [],
  } as IInventoryActionState,
  reducers: inventoryActionStateReducers,
  effects: inventoryActionStateEffects,
});
