import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Input, Select, Table } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import {
  EInventoryActionStatus,
  EInventoryActionType,
  EInventoryListMenuTab,
  EInventoryListNavigationTab,
} from 'common/const/inventory.enum';
import { ERoute } from 'common/const/route.enum';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { onInputKeyDown } from 'common/helpers/input.helper';
import { showMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { useInventoryContext } from 'common/hooks/useInventoryContext';
import {
  CHARACTER_LIMIT_MIN,
  DEFAULT_EMPTY_VALUE,
  DEFAULT_LIST_OFFSET,
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_VALUE_0,
  LIST_LIMIT_0,
  LIST_LIMIT_20,
  SELECT_LIST_HEIGHT_320,
} from 'common/config';
import { IOption } from 'common/models';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { RootDispatch, RootState } from 'app/store';
import { InventoryActionCard } from 'entities/Inventory/components/InventoryActionCard';
import { InventoryEmptyList } from 'entities/Inventory/components/InventoryEmptyList';
import { renderInventoryActionListRecords } from 'entities/Inventory/Inventory.helper';
import { IInventoryAction, IInventoryActionListPayload } from 'entities/Inventory/Inventory.models';
import { GoodsCard } from 'entities/Goods/components/GoodsCard';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  menu: ReactNode;
  subdivisionOptions: IOption[];
  userOptions: IOption[];
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    menu,
    subdivisionOptions,
    userOptions,
    // state
    userListLoading,
    inventoryActionList,
    inventoryActionListCount,
    inventoryActionListLoading,
    inventoryActionLoading,
    currentUser,
    userInventoryStatisticsLoading,
    // dispatch
    setInventoryActionList,
    filterInventoryActionList,
    getInventoryActionList,
    getInventoryActionListPart,
    getInventoryActionListSubdivisions,
    getInventoryActionListUsers,
    getInventoryActionById,
    updateInventoryAction,
    deleteInventoryAction,
    getUserInventoryStatistics,
  } = props;

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [payload, setPayload] = useState<IInventoryActionListPayload>({
    types: [EInventoryActionType.Transfer],
    statuses: [EInventoryActionStatus.New],
    receiverId: currentUser?.id,
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
  });
  const navigate = useNavigate();
  const { page, setPage, fetchLoading, setFetchLoading, setOpenInventoryActionCard } = useInventoryContext();

  const listLoading =
    userListLoading || inventoryActionListLoading || inventoryActionLoading || userInventoryStatisticsLoading || fetchLoading;

  const onSubdivisionChange = (value: number) => {
    const newPayload = {
      ...payload,
      userSubdivisionId: value !== DEFAULT_EMPTY_VALUE ? value : undefined,
      userId: undefined,
    };

    setPage(DEFAULT_PAGINATION_PAGE);
    setPayload(newPayload);
    getInventoryActionList(newPayload);
  };

  const onUserChange = (value: number) => {
    const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

    setPage(DEFAULT_PAGINATION_PAGE);
    setPayload(newPayload);
    getInventoryActionList(newPayload);
  };

  const onSearchChange = (value: string) => {
    value.trim().length >= CHARACTER_LIMIT_MIN ? setSearch(value) : setSearch(undefined);
  };

  const onSearchClick = (value?: string) => {
    const newPayload = { ...payload, search: value };

    setPage(DEFAULT_PAGINATION_PAGE);
    setPayload(newPayload);
    getInventoryActionList(newPayload);
  };

  const onChangeRequestStatusSuccess = async (records: IInventoryAction[]) => {
    const recordIds = records.map((record) => record.id);
    const statistics = await getUserInventoryStatistics();

    if (statistics && statistics.incomingTransfersCount === DEFAULT_VALUE_0) {
      if (statistics.outcomingTransfersCount !== DEFAULT_VALUE_0) {
        navigate(`${ERoute.InventoryList}/${EInventoryListNavigationTab.Transfer}?tab=${EInventoryListMenuTab.Sent}`, {
          replace: true,
        });
        return;
      }

      navigate(ERoute.InventoryList, { replace: true });
      return;
    }

    filterInventoryActionList(records);
    setOpenInventoryActionCard(false);

    const filteredInventoryActionList = inventoryActionList.filter((action) => !recordIds.includes(action.id));

    if (filteredInventoryActionList.length === LIST_LIMIT_0) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPage(DEFAULT_PAGINATION_PAGE);
      setPayload(newPayload);
      getInventoryActionList(newPayload);
    }
  };

  const onAcceptRequestClick = (record: IInventoryAction) => {
    updateInventoryAction({
      id: record.id,
      onSuccess: () => {
        showSuccessMessage(`Вы приняли ответственность за оборудование в количестве ${record.count} шт.`);
        onChangeRequestStatusSuccess([record]);
      },
    });
  };

  const onCancelRequestClick = (records: IInventoryAction[]) => {
    const recordIds = records.map((record) => record.id);
    const recordsCount = records.reduce((acc, record) => acc + record.count, 0);

    deleteInventoryAction({
      ids: recordIds,
      onSuccess: () => {
        showMessage({
          content: `Вы отклонили запросы о передаче оборудования в количестве ${recordsCount} шт.`,
          icon: <BinIcon className="icon-bin-dark-grey" />,
        });
        onChangeRequestStatusSuccess(records);
      },
    });
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setInventoryActionList([]);

      const statistics = await getUserInventoryStatistics();

      if (statistics && statistics.incomingTransfersCount === DEFAULT_VALUE_0) {
        if (statistics.outcomingTransfersCount !== DEFAULT_VALUE_0) {
          navigate(`${ERoute.InventoryList}/${EInventoryListNavigationTab.Transfer}?tab=${EInventoryListMenuTab.Sent}`, {
            replace: true,
          });
          return;
        }

        navigate(ERoute.InventoryList, { replace: true });
        return;
      }

      await getInventoryActionListSubdivisions({
        isReceiver: true,
        type: EInventoryActionType.Transfer,
        statuses: [EInventoryActionStatus.New],
      });
      await getInventoryActionListUsers({
        isReceiver: true,
        type: EInventoryActionType.Transfer,
        statuses: [EInventoryActionStatus.New],
      });
      await getInventoryActionList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!inventoryActionListLoading && inventoryActionList.length < inventoryActionListCount}
        scrollToTopTrigger={[payload]}
        onLoad={() => {
          setPage(page + 1);
          getInventoryActionListPart({ ...payload, offset: LIST_LIMIT_20 * page });
        }}
      >
        {header}
        {navigation}
        {menu}

        <div className="inventory-list__filters mb-12">
          <Select
            rootClassName="redesign"
            className="inventory-list__filters-select"
            options={subdivisionOptions}
            value={payload?.userSubdivisionId ? payload.userSubdivisionId : DEFAULT_EMPTY_VALUE}
            onChange={onSubdivisionChange}
            placeholder={EPlaceholder.SelectCompany}
            listHeight={SELECT_LIST_HEIGHT_320}
            virtual={false}
            suffixIcon={<ArrowDownShortIcon />}
          />

          <Select
            rootClassName="redesign"
            className="inventory-list__filters-select"
            options={userOptions}
            value={payload?.userId !== undefined ? payload.userId : DEFAULT_EMPTY_VALUE}
            onChange={onUserChange}
            placeholder={EPlaceholder.SelectUser}
            listHeight={SELECT_LIST_HEIGHT_320}
            virtual={false}
            suffixIcon={<ArrowDownShortIcon />}
          />

          <Input
            style={{ flex: 1 }}
            placeholder={EPlaceholder.SearchByNameOrArticle}
            allowClear={{
              clearIcon: (
                <CloseIcon
                  className="icon-close-input"
                  onClick={() => {
                    if (payload.search?.length) {
                      onSearchClick();
                    } else {
                      setSearch('');
                    }
                  }}
                />
              ),
            }}
            value={search}
            onChange={(e) => onSearchChange(e.target.value)}
            onKeyDown={(e) => onInputKeyDown(e.key, () => onSearchClick(search))}
          />

          <Button
            className="button-circle primary"
            icon={<SearchIcon className="icon-search-white" />}
            onClick={() => onSearchClick(search)}
          />
        </div>

        <Table
          className={`
                table-hover inventory-list__table
                ${
                  !!inventoryActionList.length &&
                  inventoryActionList.length === inventoryActionListCount &&
                  'inventory-list__table-end-of-list'
                }
                ${!inventoryActionList.length && 'inventory-list__table-empty'}
              `}
          dataSource={inventoryActionList.map((inventory) => ({ ...inventory, key: inventory.id }))}
          columns={renderInventoryActionListRecords({ isIncoming: true, onAcceptRequestClick, onCancelRequestClick })}
          showHeader={false}
          pagination={false}
          onRow={({ id }) => ({
            onClick: async () => {
              await getInventoryActionById(id);
              setOpenInventoryActionCard(true);
            },
          })}
          locale={{ emptyText: <InventoryEmptyList open={!listLoading && !inventoryActionList.length} /> }}
          loading={{ spinning: listLoading, indicator: <SpinIndicator /> }}
        />
      </InfiniteScrollContainer>

      <InventoryActionCard isIncoming onAccept={onAcceptRequestClick} onCancel={onCancelRequestClick} />

      <GoodsCard />
    </>
  );
};

const mapState = (state: RootState) => ({
  userListLoading: state.userListState.loading,
  inventoryActionList: state.inventoryActionListState.data,
  inventoryActionListCount: state.inventoryActionListState.count,
  inventoryActionListLoading: state.inventoryActionListState.loading,
  inventoryActionLoading: state.inventoryActionState.loading,
  currentUser: state.userState.currentUser,
  userInventoryStatisticsLoading: state.userInventoryStatisticsState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setInventoryActionList: dispatch.inventoryActionListState.setInventoryActionList,
  filterInventoryActionList: dispatch.inventoryActionListState.filterList,
  getInventoryActionList: dispatch.inventoryActionListState.getInventoryActionList,
  getInventoryActionListPart: dispatch.inventoryActionListState.getInventoryActionListPart,
  getInventoryActionListSubdivisions: dispatch.inventoryActionListState.getInventoryActionListSubdivisions,
  getInventoryActionListUsers: dispatch.inventoryActionListState.getInventoryActionListUsers,
  getInventoryActionById: dispatch.inventoryActionState.getInventoryActionById,
  updateInventoryAction: dispatch.inventoryActionState.updateInventoryAction,
  deleteInventoryAction: dispatch.inventoryActionState.deleteInventoryAction,
  getUserInventoryStatistics: dispatch.userInventoryStatisticsState.getUserInventoryStatistics,
});

export const TransferIncomingInventoryActionList = connect(mapState, mapDispatch)(Component);
