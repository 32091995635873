import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  ISubdivisionCreatePayload,
  ISubdivisionDeletePayload,
  ISubdivisionListParams,
  ISubdivisionParams,
  ISubdivisionUpdatePayload,
} from 'entities/Subdivision/Subdivision.models';
import { subdivisionTransport } from 'entities/Subdivision/Subdivision.transport';
import { IUserListParams } from 'entities/User/User.models';
import { userTransport } from 'entities/User/User.transport';

export const subdivisionListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getSubdivisionList(params: ISubdivisionListParams) {
    dispatch.subdivisionListState.setLoading(true);

    try {
      const response = await subdivisionTransport.getSubdivisionList(params);
      dispatch.subdivisionListState.setList(response.data);
      dispatch.subdivisionListState.setCount(response.count);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.subdivisionListState.setLoading(false);
    }
  },
});

export const subdivisionStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getSubdivisionById(params: ISubdivisionParams) {
    dispatch.subdivisionState.setLoading(true);

    try {
      const response = await subdivisionTransport.getSubdivisionById(params);
      dispatch.subdivisionState.setSubdivision(response);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.subdivisionState.setLoading(false);
    }
  },
  async createSubdivision(payload: ISubdivisionCreatePayload) {
    dispatch.subdivisionState.setLoading(true);

    try {
      const response = await subdivisionTransport.createSubdivision(payload);
      dispatch.subdivisionListState.addSubdivision(response);
      dispatch.subdivisionState.onSuccess(payload);
      return response;
    } catch (error) {
      axiosErrorHandler(error);
      return null;
    } finally {
      dispatch.subdivisionState.setLoading(false);
    }
  },
  async updateSubdivision(payload: ISubdivisionUpdatePayload) {
    dispatch.subdivisionState.setLoading(true);

    try {
      const response = await subdivisionTransport.updateSubdivision(payload);
      dispatch.subdivisionListState.updateSubdivision(response);
      dispatch.userState.updateSubdivision(response);
      dispatch.subdivisionState.setSubdivision(response);
      dispatch.subdivisionState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.subdivisionState.setLoading(false);
    }
  },
  async deleteSubdivision(payload: ISubdivisionDeletePayload) {
    dispatch.subdivisionState.setLoading(true);

    try {
      const response = await subdivisionTransport.deleteSubdivision(payload);
      dispatch.subdivisionListState.deleteSubdivision(response.id);
      dispatch.subdivisionState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.subdivisionState.setLoading(false);
    }
  },
  async getSubdivisionUserList(params: IUserListParams) {
    dispatch.subdivisionState.setLoading(true);

    try {
      const response = await userTransport.getUserList(params);
      dispatch.subdivisionState.setUserList(response.data);
      dispatch.subdivisionState.setUserListCount(response.count);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.subdivisionState.setLoading(false);
    }
  },
  async getSubdivisionUserListPart(params: IUserListParams) {
    dispatch.subdivisionState.setLoading(true);

    try {
      const response = await userTransport.getUserList(params);
      dispatch.subdivisionState.setUserListPart(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.subdivisionState.setLoading(false);
    }
  },
});
