import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, Drawer, Input, Table, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { Image } from 'common/components/Image';
import { BuyerCode } from 'common/components/BuyerCode';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { useDebounce } from 'common/hooks/useDebounce';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE, LIST_LIMIT_20 } from 'common/config';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { RootDispatch, RootState } from 'app/store';
import { IGoods, IGoodsListPayload } from 'entities/Goods/Goods.models';

interface IComponentProps {
  loading: boolean;
  addToBasket: (goods: IGoods, onSuccess: () => void) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const BasketAddGoodsButtonComponent: FC<AllType> = (props) => {
  const {
    // props
    loading,
    addToBasket,
    // state
    goodsList,
    goodsListLoading,
    goodsListCount,
    // dispatch
    getGoodsList,
    getGoodsListPart,
  } = props;

  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [open, setOpen] = useState<boolean>(false);
  const [payloadIsChanged, setPayloadIsChanged] = useState<boolean>(false);
  const [payload, setPayload] = useState<IGoodsListPayload>({
    search: '',
    goodInBasket: false,
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
  });

  const toggleOpen = () => setOpen((prev) => !prev);

  const onOpenClick = () => getGoodsList({ ...payload, onSuccess: toggleOpen });

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPage(DEFAULT_PAGINATION_PAGE);
    setPayloadIsChanged(true);
    setPayload((prev) => ({
      ...prev,
      search: e.target.value,
      offset: DEFAULT_LIST_OFFSET,
    }));
  };

  const onRowClick = (goods: IGoods) => {
    addToBasket(goods, toggleOpen);
  };

  useDebounce(() => {
    if (payloadIsChanged) {
      getGoodsList({ ...payload, onSuccess: () => setPayloadIsChanged(false) });
    }
  }, [payload, payloadIsChanged]);

  useEffect(() => {
    if (!open) {
      setPayload({
        search: '',
        goodInBasket: false,
        limit: LIST_LIMIT_20,
        offset: DEFAULT_LIST_OFFSET,
      });
    }
  }, [open]);

  return (
    <>
      <Tooltip overlayClassName="redesign" title="Быстрое добавление товара">
        <Button className="button-circle primary" icon={<PlusIcon className="icon-plus-white" />} onClick={onOpenClick} />
      </Tooltip>

      <Drawer
        rootClassName="redesign drawer basket-add-goods-button"
        open={open}
        onClose={!loading ? toggleOpen : undefined}
        width={720}
      >
        <div className="basket-add-goods-button__container">
          <div className="drawer__title basket-add-goods-button__title">Быстрое добавление</div>

          <Input
            className="basket-add-goods-button__input"
            value={payload?.search}
            onChange={onSearchChange}
            placeholder={EPlaceholder.SearchGoodsByNameOrVendorCode}
            disabled={loading}
            allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
          />

          <InfiniteScrollContainer
            canLoad={!goodsListLoading && goodsList.length < goodsListCount}
            scrollToTopTrigger={[payload]}
            onLoad={() => {
              setPage(page + 1);
              getGoodsListPart({ ...payload, offset: LIST_LIMIT_20 * page });
            }}
          >
            <Table
              className="table-hover-bg basket-add-goods-button__table"
              dataSource={goodsList.map((goods) => ({ key: goods.id, ...goods }))}
              onRow={(record) => ({ onClick: () => onRowClick(record) })}
              columns={[
                {
                  key: 'image',
                  render: (_, { image }) => <Image size={80} src={image} />,
                  width: 100,
                },
                {
                  key: 'name',
                  render: (_, { name, sellerCode, buyerCode, price }) => {
                    return (
                      <div>
                        <span className="text-h4 basket-add-goods-button__table-item-name">{name}</span>

                        <div className="basket-add-goods-button__table-item-container">
                          <div className="basket-add-goods-button__table-item-codes">
                            {sellerCode && (
                              <span className="text-tag color-dark-grey" style={{ marginRight: 20 }}>
                                {sellerCode}
                              </span>
                            )}

                            <BuyerCode code={buyerCode} />
                          </div>

                          <span className="text-accent">{priceFormatter(price)}</span>
                        </div>
                      </div>
                    );
                  },
                },
              ]}
              loading={{ spinning: loading || goodsListLoading, indicator: <SpinIndicator /> }}
              pagination={false}
              showHeader={false}
            />
          </InfiniteScrollContainer>
        </div>
      </Drawer>
    </>
  );
};

const mapState = (state: RootState) => ({
  goodsList: state.goodsListState.data,
  goodsListLoading: state.goodsListState.loading,
  goodsListCount: state.goodsListState.count,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getGoodsList: dispatch.goodsListState.getGoodsList,
  getGoodsListPart: dispatch.goodsListState.getGoodsListPart,
});

export const BasketAddGoodsButton = connect(mapState, mapDispatch)(BasketAddGoodsButtonComponent);
