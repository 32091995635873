import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  IAccountBlockPayload,
  IAccountCreatePayload,
  IAccountDeletePayload,
  IAccountListParams,
  IAccountUnblockPayload,
  IAccountUpdatePayload,
  ISellerAccountUpdatePayload,
} from 'entities/Account/Account.models';
import { accountTransport } from 'entities/Account/Account.transport';

export const accountListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getAccountList(params: IAccountListParams) {
    dispatch.accountListState.setLoading(true);

    try {
      const response = await accountTransport.getAccountList(params);
      dispatch.accountListState.setAccountList(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.accountListState.setLoading(false);
    }
  },
});

export const accountStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getAccountById(id: number) {
    dispatch.accountState.setLoading(true);

    try {
      const response = await accountTransport.getAccountById(id);
      dispatch.accountState.setAccount(response);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.accountState.setLoading(false);
    }
  },
  async createAccount(payload: IAccountCreatePayload) {
    dispatch.accountState.setLoading(true);

    try {
      const response = await accountTransport.createAccount(payload);
      dispatch.accountListState.addAccount(response);
      dispatch.accountState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error, dispatch.accountState.setError);
    } finally {
      dispatch.accountState.setLoading(false);
    }
  },
  async updateAccount(payload: IAccountUpdatePayload) {
    dispatch.accountState.setLoading(true);

    try {
      const response = await accountTransport.updateAccount(payload);
      dispatch.accountListState.updateAccount(response);
      dispatch.accountState.setAccount(response);
      dispatch.accountState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.accountState.setLoading(false);
    }
  },
  async updateAccountName(payload: IAccountUpdatePayload) {
    dispatch.accountState.setLoading(true);

    try {
      const response = await accountTransport.updateAccount(payload);
      dispatch.authState.updateAccountName(response.name);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.accountState.setLoading(false);
    }
  },
  async blockAccount(payload: IAccountBlockPayload) {
    dispatch.accountState.setLoading(true);

    try {
      const response = await accountTransport.blockAccount(payload);
      dispatch.accountListState.updateAccount(response);
      dispatch.accountState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.accountState.setLoading(false);
    }
  },
  async unblockAccount(payload: IAccountUnblockPayload) {
    dispatch.accountState.setLoading(true);

    try {
      const response = await accountTransport.unblockAccount(payload);
      dispatch.accountListState.updateAccount(response);
      dispatch.accountState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.accountState.setLoading(false);
    }
  },
  async deleteAccount(payload: IAccountDeletePayload) {
    dispatch.accountState.setLoading(true);

    try {
      const response = await accountTransport.deleteAccount(payload);
      dispatch.accountListState.deleteAccount(response.id);
      dispatch.accountState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.accountState.setLoading(false);
    }
  },
  async updateSellerAccount(payload: ISellerAccountUpdatePayload) {
    dispatch.accountState.setLoading(true);

    try {
      const response = await accountTransport.updateSellerAccount(payload);
      dispatch.accountListState.updateAccount(response);
      dispatch.accountState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.accountState.setLoading(false);
    }
  },
});
