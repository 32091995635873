import { createModel } from '@rematch/core';
import { DEFAULT_VALUE_0 } from 'common/config';
import { IRootModel } from 'app/store';
import { INeedListState, INeedState } from 'entities/Need/Need.models';
import { needListStateReducers, needStateReducers } from 'entities/Need/Need.reducers';
import { needListStateEffects, needStateEffects } from 'entities/Need/Need.effects';

export const needListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: DEFAULT_VALUE_0,
    loading: false,
    userList: [],
    userListLoading: true,
    subdivisionList: [],
    subdivisionListLoading: true,
  } as INeedListState,
  reducers: needListStateReducers,
  effects: needListStateEffects,
});

export const needState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    supplyList: [],
    historyList: [],
  } as INeedState,
  reducers: needStateReducers,
  effects: needStateEffects,
});
