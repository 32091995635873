import React from 'react';
import { getUserName } from 'entities/User/User.helper';

interface IComponentProps {
  size: number;
  firstName?: string;
  lastName?: string;
}

export const Avatar: React.FC<IComponentProps> = ({ size, firstName, lastName }) => (
  <div style={{ width: `${size}px`, height: `${size}px` }} className="avatar">
    {getUserName.initials(lastName, firstName)}
  </div>
);
