import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Input, Select, Spin, Table } from 'antd';
import { connect } from 'react-redux';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { EGroupedSupplyListKey, ESupplyListMenuTab, ESupplyStatus } from 'common/const/supply.enum';
import { EDateFormat } from 'common/const/date.enum';
import { groupList } from 'common/helpers/common.helper';
import { onInputKeyDown } from 'common/helpers/input.helper';
import {
  DEFAULT_EMPTY_VALUE,
  DEFAULT_LIST_OFFSET,
  DEFAULT_PAGINATION_PAGE,
  LIST_LIMIT_20,
  SELECT_LIST_HEIGHT_320,
} from 'common/config';
import { IOption } from 'common/models';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as ArrowUpShortIcon } from 'app/assets/images/redesign/arrow-up-short.svg';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { NeedCard } from 'entities/Need/components/NeedCard';
import { getNeedFormattedDate } from 'entities/Need/Need.helper';
import { SupplyCard } from 'entities/Supply/components/SupplyCard';
import { IGroupedSupplyListPayload } from 'entities/Supply/Supply.models';
import { renderGroupedSupplyListRecords, renderSupplyListRecords } from 'entities/Supply/Supply.helper';
import { SupplyListEmpty } from 'entities/Supply/components/SupplyListEmpty';
import { GoodsCard } from 'entities/Goods/components/GoodsCard';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  menu: ReactNode;
  subdivisionOptions: IOption[];
  userOptions: IOption[];
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    menu,
    subdivisionOptions,
    userOptions,
    // state
    supplyListLoading,
    groupedSupplyList,
    groupedSupplyListCount,
    groupedSupplyListLoading,
    // dispatch
    getSupplySubdivisionListForManager,
    getSupplyUserList,
    setGroupedSupplyList,
    getGroupedSupplyList,
    getGroupedSupplyListPart,
    getSupplyById,
    getNeedById,
  } = props;

  const [search, setSearch] = useState<string>('');
  const [payload, setPayload] = useState<IGroupedSupplyListPayload>({
    statuses: [ESupplyStatus.Approved, ESupplyStatus.Delivery],
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
    key: EGroupedSupplyListKey.ByDeliveryDate,
  });
  const { page, setPage, setOpenSupplyCard, fetchLoading, setFetchLoading } = useSupplyContext();

  const groupedList = groupList.byAddressLegalUser(groupedSupplyList);

  const onSubdivisionChange = async (value: number) => {
    const newPayload = {
      ...payload,
      subdivisionIds: value === DEFAULT_EMPTY_VALUE ? undefined : [value],
      userId: undefined,
    };

    setPage(DEFAULT_PAGINATION_PAGE);
    setPayload(newPayload);
    setGroupedSupplyList([]);
    await getSupplyUserList({ statuses: newPayload.statuses, subdivisionId: newPayload.subdivisionIds?.[0] });
    await getGroupedSupplyList(newPayload);
  };

  const onUserChange = (value: number) => {
    const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

    setPage(DEFAULT_PAGINATION_PAGE);
    setGroupedSupplyList([]);
    setPayload(newPayload);
    getGroupedSupplyList(newPayload);
  };

  const onSearchClick = (value: string) => {
    const newPayload = { ...payload, search: value };

    setPage(DEFAULT_PAGINATION_PAGE);
    setPayload(newPayload);
    setGroupedSupplyList([]);
    getGroupedSupplyList(newPayload);
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setGroupedSupplyList([]);
      await getSupplySubdivisionListForManager({ statuses: payload.statuses });
      await getSupplyUserList({ statuses: payload.statuses });
      await getGroupedSupplyList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!groupedSupplyListLoading && groupedSupplyList.length < groupedSupplyListCount}
        scrollToTopTrigger={[payload]}
        onLoad={() => {
          setPage(page + 1);
          getGroupedSupplyListPart({ ...payload, offset: LIST_LIMIT_20 * page });
        }}
      >
        {header}
        {navigation}
        {menu}

        <div className="need-list__filters">
          <Select
            rootClassName="redesign"
            className="need-list__filters-select"
            options={subdivisionOptions}
            value={payload.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
            onChange={onSubdivisionChange}
            placeholder="Выберите подразделение"
            listHeight={SELECT_LIST_HEIGHT_320}
            virtual={false}
            suffixIcon={<ArrowDownShortIcon />}
          />

          <Select
            rootClassName="redesign"
            className="need-list__filters-select"
            options={userOptions}
            value={payload.userId ? payload.userId : DEFAULT_EMPTY_VALUE}
            onChange={onUserChange}
            placeholder="Выберите пользователя"
            listHeight={SELECT_LIST_HEIGHT_320}
            virtual={false}
            suffixIcon={<ArrowDownShortIcon />}
          />

          <Input
            style={{ flex: 1 }}
            placeholder="Поиск"
            allowClear={{
              clearIcon: (
                <CloseIcon
                  className="icon-close-input"
                  onClick={() => {
                    if (payload.search?.length) {
                      onSearchClick('');
                    } else {
                      setSearch('');
                    }
                  }}
                />
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => onInputKeyDown(e.key, () => onSearchClick(search))}
          />

          <Button
            className="button-circle primary"
            icon={<SearchIcon className="icon-search-white" />}
            onClick={() => onSearchClick(search)}
          />
        </div>

        <Spin
          wrapperClassName="need-list__spin"
          spinning={groupedSupplyListLoading || supplyListLoading || fetchLoading}
          indicator={<SpinIndicator />}
        >
          {groupedList.map((group, index) => {
            return (
              <div key={index} className="need-list__group">
                <span className="need-list__group-date">{getNeedFormattedDate(EDateFormat.FullDate, group.deliveryDate)}</span>

                <Table
                  className="need-list__table need-list__table-expandable"
                  rowClassName="need-list__table-expandable-row"
                  columns={renderGroupedSupplyListRecords()}
                  dataSource={group.dataSource.map((item, index) => ({ ...item, key: index }))}
                  expandable={{
                    expandIconColumnIndex: 7,
                    columnWidth: 40,
                    expandIcon: ({ expanded, onExpand, record }) => {
                      return (
                        <Button
                          className="button-icon"
                          icon={
                            expanded ? (
                              <ArrowUpShortIcon className="icon-arrow-up-short-dark-grey" />
                            ) : (
                              <ArrowDownShortIcon className="icon-arrow-up-short-dark-grey" />
                            )
                          }
                          onClick={(e) => onExpand(record, e)}
                        />
                      );
                    },
                    expandedRowRender: ({ dataSource }) => {
                      return (
                        <Table
                          className="table-hover need-list__table"
                          columns={renderSupplyListRecords(ESupplyListMenuTab.Processing)}
                          dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
                          pagination={false}
                          showHeader={false}
                          onRow={({ id, supplyNeedId }) => ({
                            onClick: async () => {
                              await getSupplyById({ id, onSuccess: () => setOpenSupplyCard(true) });
                              await getNeedById({ id: supplyNeedId });
                            },
                          })}
                        />
                      );
                    },
                  }}
                  pagination={false}
                  showHeader={false}
                />
              </div>
            );
          })}

          <SupplyListEmpty
            open={!(groupedSupplyListLoading || supplyListLoading || fetchLoading) && !groupedSupplyList.length}
            forSeller
          />
        </Spin>
      </InfiniteScrollContainer>

      <SupplyCard />

      <NeedCard nested />

      <GoodsCard />
    </>
  );
};

const mapState = (state: RootState) => ({
  supplyListLoading: state.supplyListState.loading,
  groupedSupplyList: state.groupedSupplyListState.data,
  groupedSupplyListCount: state.groupedSupplyListState.count,
  groupedSupplyListLoading: state.groupedSupplyListState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSupplySubdivisionListForManager: dispatch.supplyListState.getSupplySubdivisionListForManager,
  getSupplyUserList: dispatch.supplyListState.getSupplyUserList,
  setGroupedSupplyList: dispatch.groupedSupplyListState.setList,
  getGroupedSupplyList: dispatch.groupedSupplyListState.getGroupedSupplyList,
  getGroupedSupplyListPart: dispatch.groupedSupplyListState.getGroupedSupplyListPart,
  getSupplyById: dispatch.supplyState.getSupplyById,
  getNeedById: dispatch.needState.getNeedById,
});

export const ProcessingSupplyListForManager = connect(mapState, mapDispatch)(Component);
