import React, { ChangeEvent, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Navigate } from 'react-router-dom';
import { EMessage } from 'common/const/message.enum';
import { ENotificationType } from 'common/const/notification.enum';
import { ERoute } from 'common/const/route.enum';
import { EUserStatus } from 'common/const/user.enum';
import { toDataSourceMapper } from 'common/helpers/common.helper';
import { useDebounce } from 'common/hooks/useDebounce';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { ReactComponent as SearchIcon } from 'app/assets/images/search.svg';
import { RootDispatch, RootState } from 'app/store';
import { Notification } from 'entities/Modal/components/Notification';
import { CreateAccountModal } from 'entities/Modal/components/CreateAccountModal';
import { IAccount, IAccountCreatePayload } from 'entities/Account/Account.models';
import { filterAccountUsers, renderAccountListRecords } from 'entities/Account/Account.helper';
import { SelectAccountUserModal } from 'entities/Modal/components/SelectAccountUserModal';
import { IUser } from 'entities/User/User.models';
import { AddFeedModal } from 'entities/Modal/components/AddFeedModal';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const AccountListComponent: React.FC<AllType> = (props) => {
  const {
    accountListState,
    authState,
    accountState,
    userListState,
    getAccountList,
    loginAsUser,
    createAccount,
    updateAccount,
    blockAccount,
    unblockAccount,
    deleteAccount,
    updateSellerAccount,
    setAccountError,
    getUserList,
  } = props;

  const { superAdminAsUser } = authState;
  const { loading: accountLoading, error: accountError } = accountState;
  const { data: accountList, loading: accountListLoading } = accountListState;
  const { data: userList, loading: userListLoading } = userListState;

  const [searchValue, setSearchValue] = useState<string>('');
  const [accountId, setAccountId] = useState<number | null>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [isSeller, setIsSeller] = useState<boolean>(false);
  const [searchUserValue, setSearchUserValue] = useState<string>('');
  const [feedUrl, setFeedUrl] = useState<string>('');
  const [openCreateAccountModal, setOpenCreateAccountModal] = useState<boolean>(false);
  const [openBlockAccountModal, setOpenBlockAccountModal] = useState<boolean>(false);
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState<boolean>(false);
  const [openSelectAccountUserModal, setOpenSelectAccountUserModal] = useState<boolean>(false);
  const [openMakeSellerAccountModal, setOpenMakeSellerAccountModal] = useState<boolean>(false);
  const [openAddFeedModal, setOpenAddFeedModal] = useState<boolean>(false);
  const [form] = useForm();

  const selectedAccount = accountList.find((account) => account.id === accountId);
  const dataSource = toDataSourceMapper<IAccount>(accountList);
  const columns = renderAccountListRecords(
    handleLoginToAccountClick,
    handleEditClick,
    handleBlockClick,
    handleUnblockClick,
    handleDeleteClick,
    handleMakeSellerClick,
  );
  const users = useMemo(() => {
    return filterAccountUsers(searchUserValue, userList);
  }, [userList, searchUserValue]);

  const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormIsChanged(true);
    setSearchValue(e.target.value);
  };

  const onCreateAccountClick = () => {
    setOpenCreateAccountModal(true);
  };

  const onCreateAccountModalCancelClick = () => {
    form.resetFields();
    setAccountError(null);
    setOpenCreateAccountModal(false);

    if (isEdit) {
      setAccountId(null);
      setIsEdit(false);
    }
  };

  async function handleLoginToAccountClick(id: number, isSeller: boolean) {
    const response = await getUserList({ accountId: id, status: EUserStatus.Active, limit: 0 });

    if (response) {
      setIsSeller(isSeller);
      setOpenSelectAccountUserModal(true);
    }
  }

  const onSelectAccountUserModalCancelClick = () => {
    setSelectedUser(null);
    setIsSeller(false);
    setSearchUserValue('');
    setOpenSelectAccountUserModal(false);
  };

  const onSelectAccountUserModalLoginClick = () => {
    if (selectedUser) {
      loginAsUser({ user: selectedUser, isSeller });
      setSelectedUser(null);
    }
  };

  function handleEditClick(record: IAccount) {
    form.setFieldsValue({
      name: record.name,
      email: record.email,
      firstName: record.firstName,
      lastName: record.lastName,
    });

    setAccountId(record.id);
    setIsEdit(true);
    setOpenCreateAccountModal(true);
  }

  function handleBlockClick(id: number) {
    setAccountId(id);
    setOpenBlockAccountModal(true);
  }

  const onBlockAccountModalCancelClick = () => {
    setAccountId(null);
    setOpenBlockAccountModal(false);
  };

  const onBlockAccountModalConfirmClick = () => {
    blockAccount({
      id: accountId as number,
      onSuccess: () => {
        setAccountId(null);
        setOpenBlockAccountModal(false);
      },
    });
  };

  function handleUnblockClick(id: number) {
    unblockAccount({ id });
  }

  function handleDeleteClick(id: number) {
    setAccountId(id);
    setOpenDeleteAccountModal(true);
  }

  const onDeleteAccountModalCancelClick = () => {
    setAccountId(null);
    setOpenDeleteAccountModal(false);
  };

  const onDeleteAccountModalConfirmClick = () => {
    deleteAccount({
      id: accountId as number,
      onSuccess: () => {
        setAccountId(null);
        setOpenDeleteAccountModal(false);
      },
    });
  };

  function handleMakeSellerClick(id: number) {
    setAccountId(id);
    setOpenMakeSellerAccountModal(true);
  }

  const onMakeSellerAccountModalCancelClick = () => {
    setAccountError(null);
    setAccountId(null);
    setOpenMakeSellerAccountModal(false);
  };

  const onMakeSellerAccountModalConfirmClick = () => {
    setAccountError(null);

    if (accountId) {
      updateSellerAccount({
        id: accountId,
        onSuccess: () => {
          setOpenMakeSellerAccountModal(false);
          setOpenAddFeedModal(true);
        },
      });
    }
  };

  const onAddFeedModalCancelClick = () => {
    setAccountError(null);
    setAccountId(null);
    setFeedUrl('');
    setOpenAddFeedModal(false);
  };

  const onAddFeedModalConfirmClick = () => {
    setAccountError(null);

    if (accountId) {
      updateSellerAccount({ id: accountId, feedUrl, onSuccess: onAddFeedModalCancelClick });
    }
  };

  const onSubmit = (values: IAccountCreatePayload) => {
    setAccountError(null);

    if (isEdit) {
      updateAccount({
        id: accountId as number,
        name: values.name,
        onSuccess: () => {
          form.resetFields();
          setAccountId(null);
          setIsEdit(false);
          setOpenCreateAccountModal(false);
        },
      });
    } else {
      createAccount({
        ...values,
        onSuccess: () => {
          form.resetFields();
          setOpenCreateAccountModal(false);
        },
      });
    }
  };

  useDebounce(() => {
    if (formIsChanged) {
      getAccountList({ name: searchValue });
    }
  }, [searchValue, formIsChanged]);

  const { contentLoading } = useContentLoader(async () => {
    await getAccountList({ limit: 0 });
  });

  if (superAdminAsUser) {
    return <Navigate to={ERoute.Profile} />;
  }

  return (
    <div className="account-list">
      {contentLoading ? (
        <ContentLoader />
      ) : (
        <>
          <div className="account-list__header">
            <Input
              className="account-list__search"
              prefix={<SearchIcon />}
              placeholder="Найти аккаунт"
              onChange={onSearchValueChange}
            />

            <Button className="btn btn-primary account-list__create-btn" onClick={onCreateAccountClick}>
              Создать аккаунт
            </Button>
          </div>

          <Table
            dataSource={dataSource}
            columns={columns}
            showHeader={false}
            pagination={false}
            loading={accountListLoading || accountLoading || userListLoading}
          />
        </>
      )}

      <CreateAccountModal
        open={openCreateAccountModal}
        form={form}
        loading={accountLoading}
        error={accountError}
        isEdit={isEdit}
        onSubmit={onSubmit}
        onCancelClick={onCreateAccountModalCancelClick}
      />

      <Notification
        open={openBlockAccountModal}
        type={ENotificationType.Warning}
        description={EMessage.ClientsAccountWillBeBlocked}
        loading={accountLoading}
        onConfirmClick={onBlockAccountModalConfirmClick}
        onCancelClick={onBlockAccountModalCancelClick}
      />

      <Notification
        open={openDeleteAccountModal}
        type={ENotificationType.Warning}
        description={EMessage.ClientsAccountWillBeDeleted}
        loading={accountLoading}
        onConfirmClick={onDeleteAccountModalConfirmClick}
        onCancelClick={onDeleteAccountModalCancelClick}
      />

      <SelectAccountUserModal
        open={openSelectAccountUserModal}
        searchValue={searchUserValue}
        users={users}
        selectedUser={selectedUser}
        onSearch={setSearchUserValue}
        onSelect={setSelectedUser}
        onCancel={onSelectAccountUserModalCancelClick}
        onLogin={onSelectAccountUserModalLoginClick}
      />

      <Notification
        open={openMakeSellerAccountModal}
        type={ENotificationType.Notification}
        header={`Сделать аккаунт ${selectedAccount?.name || ''} продавцом`}
        description={EMessage.IrreversibleEffect}
        loading={accountLoading}
        onConfirmClick={onMakeSellerAccountModalConfirmClick}
        onCancelClick={onMakeSellerAccountModalCancelClick}
      />

      <AddFeedModal
        open={openAddFeedModal}
        loading={accountLoading}
        error={accountError}
        feedUrl={feedUrl}
        changeFeedUrl={setFeedUrl}
        onCancelClick={onAddFeedModalCancelClick}
        onConfirmClick={onAddFeedModalConfirmClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  accountListState: state.accountListState,
  authState: state.authState,
  accountState: state.accountState,
  userListState: state.userListState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getAccountList: dispatch.accountListState.getAccountList,
  loginAsUser: dispatch.authState.loginAsUser,
  createAccount: dispatch.accountState.createAccount,
  updateAccount: dispatch.accountState.updateAccount,
  blockAccount: dispatch.accountState.blockAccount,
  unblockAccount: dispatch.accountState.unblockAccount,
  deleteAccount: dispatch.accountState.deleteAccount,
  updateSellerAccount: dispatch.accountState.updateSellerAccount,
  setAccountError: dispatch.accountState.setError,
  getUserList: dispatch.userListState.getUserList,
});

export const AccountList = connect(mapState, mapDispatch)(AccountListComponent);
