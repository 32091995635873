import React from 'react';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { discountFormatter } from 'common/helpers/formatter.helper';
import { ReactComponent as CloseIcon } from 'app/assets/images/close.svg';
import { IBrandDiscount } from 'entities/Discount/Discount.models';

export const brandCollectionToSelectOptions = (data: string[]) => data.map((item) => ({ value: item }));

export const renderDiscountsOnBrandsRecords = (onDelete: (id: number) => void) => {
  return [
    {
      key: 'brand',
      render: (_, record) => `Бренд = ${record.brand}`,
    },
    {
      key: 'discountValue',
      render: (_, record) => {
        return <div className="discount__table-item-value">{discountFormatter(record.discountValue)}</div>;
      },
      width: 96,
    },
    {
      key: 'icon',
      render: (_, record) => <Button className="btn btn-icon" icon={<CloseIcon />} onClick={() => onDelete(record.id)} />,
      width: 54,
    },
  ] as ColumnsType<IBrandDiscount>;
};
