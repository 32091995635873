import React, { FC, useEffect, useRef, useState } from 'react';
import { Progress } from 'antd';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { getBasketProgressColor } from 'entities/Basket/Basket.helper';

interface IComponentProps {
  limit?: number;
  amount: number;
  availableAmount: number;
  limitExceeded: boolean;
}

export const BasketProgress: FC<IComponentProps> = ({ limit = 0, amount, availableAmount, limitExceeded }) => {
  const [steps, setSteps] = useState<number>(0);
  const ref = useRef<HTMLDivElement | null>(null);

  const stepWidthInPx = 10;
  const percent = Math.round((amount * 100) / limit);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setSteps(Math.round(entry.contentRect.width / stepWidthInPx));
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div ref={ref} className="redesign basket-progress">
      <div className="basket-progress__header">
        <span className="text-h4">Доступные средства</span>

        {limitExceeded ? (
          <span className="text-accent color-red">{`Лимит превышен на ${priceFormatter(Math.abs(availableAmount))}`}</span>
        ) : (
          <span className="text-accent">{priceFormatter(availableAmount)}</span>
        )}
      </div>

      <Progress className="mb-6" showInfo={false} percent={percent} steps={steps} strokeColor={getBasketProgressColor(percent)} />

      <div className="basket-progress__footer">
        <span className="text-tag color-dark-grey">{`${priceFormatter(amount)} сумма заявки`}</span>

        <span className="text-tag color-dark-grey">{`лимит на закупку ${priceFormatter(limit)}`}</span>
      </div>
    </div>
  );
};
