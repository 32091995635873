import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Collapse, Dropdown, Spin } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { IconBackground } from 'common/components/IconBackground';
import { EColor } from 'common/const/color.enum';
import { stringToNumber } from 'common/helpers/common.helper';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as CopyIcon } from 'app/assets/images/redesign/copy.svg';
import { ReactComponent as ContractIcon } from 'app/assets/images/redesign/contract.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as ArrowUpShortIcon } from 'app/assets/images/redesign/arrow-up-short.svg';
import { ReactComponent as LegalEntityIcon } from 'app/assets/images/redesign/legal-entity.svg';
import { ReactComponent as OptionsIcon } from 'app/assets/images/redesign/options.svg';
import { FeedCreateModal } from 'entities/Account/components/FeedCreateModal';
import { LegalEntityCustomerIdCreateModal } from 'entities/LegalEntity/components/LegalEntityCustomerIdCreateModal';
import { ILegalEntity } from 'entities/LegalEntity/LegalEntity.models';

interface IComponentProps {
  header: ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    // state
    contractList,
    legalEntityList,
    legalEntityListLoading,
    currentUser,
    account,
    accountLoading,
    legalEntityLoading,
    // dispatch
    getLegalEntityList,
    getAccountById,
    updateAccount,
    updateLegalEntityBySeller,
  } = props;

  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [openFeedCreateModal, setOpenFeedCreateModal] = useState<boolean>(false);
  const [openLegalEntityCustomerIdCreateModal, setOpenLegalEntityCustomerIdCreateModal] = useState<boolean>(false);
  const [contractActiveKey, setContractActiveKey] = useState<string[]>([]);
  const [selectedLegalEntity, setSelectedLegalEntity] = useState<ILegalEntity | null>(null);

  const accountId = currentUser?.account?.id;

  const onAccountUpdate = (value: string, onSuccess: () => void) => {
    if (account) {
      updateAccount({ id: account.id, feedUrl: value, onSuccess });
    }
  };

  const onLegalEntityUpdate = (id: number, value: string | null, onSuccess?: () => void) => {
    updateLegalEntityBySeller({ id, customerId: value, onSuccess });
  };

  useEffect(() => {
    const fetch = async () => {
      if (accountId) {
        await getAccountById(accountId);
      }

      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <>
      <InfiniteScrollContainer>
        {header}

        <Spin spinning={fetchLoading} indicator={<SpinIndicator />}>
          <div className="text-h4 mb-6">Настройка интеграции</div>

          <div className="text-tag color-dark-grey mb-20" style={{ maxWidth: 700 }}>
            Настройте интеграцию каталога, указав ссылку на фид для автоматического экспорта товаров на платформу. Убедитесь, что
            данные актуальны и доступны в нужном формате.
          </div>

          {account?.feedUrl && (
            <div className="mb-20 account-integrations-settings__feed-container">
              <span className="text-controls-accent ellipsis" title={account.feedUrl}>
                {account.feedUrl}
              </span>

              <Button
                className="button-icon"
                icon={<CopyIcon className="icon-copy-dark-grey" />}
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(`${account.feedUrl}`);
                }}
              />
            </div>
          )}

          <Button className="button-sm primary mb-52" onClick={() => setOpenFeedCreateModal(true)}>
            {account?.feedUrl?.length ? 'Изменить фид' : 'Добавить фид'}
          </Button>

          <div className="text-h4 mb-6">ID покупателя</div>

          <div className="text-tag color-dark-grey mb-20" style={{ maxWidth: 700 }}>
            Настройте ID для идентификации юридических лиц покупателя в системе учёта.
          </div>

          <Spin spinning={legalEntityListLoading || legalEntityLoading} indicator={<SpinIndicator />}>
            <Collapse
              accordion
              className="collapse mb-52"
              expandIconPosition="end"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <ArrowUpShortIcon className="icon-arrow-up-short-dark-grey" />
                ) : (
                  <ArrowDownShortIcon className="icon-arrow-down-short-dark-grey" />
                )
              }
              activeKey={contractActiveKey}
              onChange={async (value) => {
                const accountId = value[0];

                if (accountId) {
                  await getLegalEntityList({ accountId: stringToNumber(accountId) });
                  setContractActiveKey(value as string[]);
                }
              }}
              items={contractList.map((contract) => {
                return {
                  key: contract.buyerId,
                  label: (
                    <div className="account-integrations-settings__collapse-header">
                      <IconBackground
                        content={<ContractIcon className="icon-contract-bright-green" />}
                        color={EColor.PaleGreen}
                      />

                      <div style={{ flex: 1 }} className="text-controls-accent">
                        {contract.buyerName}
                      </div>
                    </div>
                  ),
                  children: legalEntityList.map((legalEntity) => {
                    return (
                      <div
                        key={legalEntity.id}
                        className="collapse__content-row account-integrations-settings__collapse-content-row"
                      >
                        <div className="account-integrations-settings__collapse-content-row-container">
                          <IconBackground
                            content={<LegalEntityIcon className="icon-legal-entity-dark-grey" />}
                            color={EColor.LightGrey}
                          />

                          <span className="text-controls-accent" style={{ flex: 1 }}>
                            {legalEntity.name}
                          </span>
                        </div>

                        <div className="account-integrations-settings__collapse-content-row-container">
                          {legalEntity.customerId ? (
                            <>
                              <span className="text-controls-accent">{legalEntity.customerId}</span>

                              <Button
                                className="button-icon"
                                icon={<CopyIcon className="icon-copy-dark-grey" />}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigator.clipboard.writeText(`${legalEntity.customerId}`);
                                }}
                              />

                              <Dropdown
                                overlayClassName="redesign"
                                menu={{
                                  items: [
                                    {
                                      key: 'edit',
                                      label: 'Редактировать',
                                      onClick: () => {
                                        setSelectedLegalEntity(legalEntity);
                                        setOpenLegalEntityCustomerIdCreateModal(true);
                                      },
                                    },
                                    { type: 'divider' },
                                    {
                                      key: 'delete',
                                      label: 'Удалить ID покупателя',
                                      className: 'red',
                                      onClick: () => onLegalEntityUpdate(legalEntity.id, null),
                                    },
                                  ],
                                }}
                              >
                                <Button
                                  className="button-icon"
                                  icon={<OptionsIcon className="icon-options-dark-grey" style={{ transform: 'rotate(90deg)' }} />}
                                />
                              </Dropdown>
                            </>
                          ) : (
                            <Button
                              className="button-sm secondary"
                              onClick={() => {
                                setSelectedLegalEntity(legalEntity);
                                setOpenLegalEntityCustomerIdCreateModal(true);
                              }}
                            >
                              Добавить ID покупателя
                            </Button>
                          )}
                        </div>
                      </div>
                    );
                  }),
                };
              })}
            />
          </Spin>
        </Spin>
      </InfiniteScrollContainer>

      <FeedCreateModal
        open={openFeedCreateModal}
        loading={accountLoading}
        url={account?.feedUrl}
        onClose={() => setOpenFeedCreateModal(false)}
        onSave={onAccountUpdate}
      />

      <LegalEntityCustomerIdCreateModal
        open={openLegalEntityCustomerIdCreateModal}
        loading={legalEntityLoading}
        legalEntity={selectedLegalEntity}
        onClose={() => setOpenLegalEntityCustomerIdCreateModal(false)}
        onSave={onLegalEntityUpdate}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  contractList: state.contractListState.data,
  legalEntityList: state.legalEntityListState.data,
  legalEntityListLoading: state.legalEntityListState.loading,
  currentUser: state.userState.currentUser,
  account: state.accountState.data,
  accountLoading: state.accountState.loading,
  legalEntityLoading: state.legalEntityState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getLegalEntityList: dispatch.legalEntityListState.getLegalEntityList,
  getAccountById: dispatch.accountState.getAccountById,
  updateAccount: dispatch.accountState.updateAccount,
  updateLegalEntityBySeller: dispatch.legalEntityState.updateLegalEntityBySeller,
});

export const AccountIntegrationsSettings = connect(mapState, mapDispatch)(Component);
