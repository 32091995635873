import { createModel } from '@rematch/core';
import { DEFAULT_VALUE_0 } from 'common/config';
import { IRootModel } from 'app/store';
import { IContractListState, IContractState } from 'entities/Contract/Contract.models';
import { contractListStateReducers, contractStateReducers } from 'entities/Contract/Contract.reducers';
import { contractListStateEffects, contractStateEffects } from 'entities/Contract/Contract.effects';

export const contractListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: DEFAULT_VALUE_0,
    loading: false,
  } as IContractListState,
  reducers: contractListStateReducers,
  effects: contractListStateEffects,
});

export const contractState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    userList: [],
  } as IContractState,
  reducers: contractStateReducers,
  effects: contractStateEffects,
});
