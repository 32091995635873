import { IAccount, IAccountListState, IAccountState } from 'entities/Account/Account.models';

export const accountListStateReducers = {
  setAccountList: (state: IAccountListState, payload: IAccount[]) => ({ ...state, data: payload }),
  setLoading: (state: IAccountListState, payload: boolean) => ({ ...state, loading: payload }),
  addAccount: (state: IAccountListState, payload: IAccount) => ({ ...state, data: [...state.data, payload] }),
  updateAccount: (state: IAccountListState, payload: IAccount) => ({
    ...state,
    data: state.data.map((account) => {
      if (account.id === payload.id) {
        return payload;
      }

      return account;
    }),
  }),
  deleteAccount: (state: IAccountListState, payload: number) => ({
    ...state,
    data: state.data.filter((account) => account.id !== payload),
  }),
};

export const accountStateReducers = {
  setAccount: (state: IAccountState, payload: IAccount) => ({ ...state, data: payload }),
  setLoading: (state: IAccountState, payload: boolean) => ({ ...state, loading: payload }),
  setError: (state: IAccountState, payload: string | null) => ({ ...state, error: payload }),
};
