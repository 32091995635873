import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Select, Spin, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { ERoute } from 'common/const/route.enum';
import { onInputKeyDown } from 'common/helpers/input.helper';
import { getFilterOptions } from 'common/helpers/option.helper';
import {
  DEFAULT_EMPTY_VALUE,
  DEFAULT_LIST_OFFSET,
  DEFAULT_PAGINATION_PAGE,
  LIST_LIMIT_20,
  SELECT_LIST_HEIGHT_320,
} from 'common/config';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { RootDispatch, RootState } from 'app/store';
import { IBasketListParams } from 'entities/Basket/Basket.models';
import { renderActiveBasketListRecords } from 'entities/Basket/Basket.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = (props) => {
  const {
    // state
    basketList,
    basketListCount,
    basketListLoading,
    basketListSubdivisions,
    // dispatch
    getBasketListSubdivisions,
    getBasketList,
    getBasketListPart,
  } = props;

  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [search, setSearch] = useState<string>('');
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [params, setParams] = useState<IBasketListParams>({
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
  });
  const navigate = useNavigate();

  const subdivisionOptions = getFilterOptions.subdivision(basketListSubdivisions);

  const onSubdivisionChange = (value: number) => {
    const newParams = { ...params, subdivisionId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

    setPage(DEFAULT_PAGINATION_PAGE);
    setParams(newParams);
    getBasketList(newParams);
  };

  const onSearchClick = (value: string) => {
    const newParams = { ...params, search: value };

    setPage(DEFAULT_PAGINATION_PAGE);
    setParams(newParams);
    getBasketList(newParams);
  };

  const navigateToBasket = (id: number) => {
    navigate(`${ERoute.ActiveBasketList}/${id}`);
  };

  useEffect(() => {
    const fetch = async () => {
      await getBasketListSubdivisions();
      await getBasketList(params);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  if (fetchLoading) {
    return (
      <div className="redesign active-basket-list">
        <Spin indicator={<SpinIndicator />} />
      </div>
    );
  }

  return (
    <div className="redesign active-basket-list">
      <InfiniteScrollContainer
        canLoad={!basketListLoading && basketList.length < basketListCount}
        scrollToTopTrigger={[params]}
        onLoad={() => {
          setPage(page + 1);
          getBasketListPart({ ...params, offset: LIST_LIMIT_20 * page });
        }}
      >
        <div className="text-body color-dark-grey mb-20">Корзины клиентов</div>

        <div className="text-h1 mb-52">Список активных корзин</div>

        <div className="active-basket-list__filters mb-20">
          <Select
            rootClassName="redesign"
            className="active-basket-list__filters-selector"
            options={subdivisionOptions}
            value={params.subdivisionId ? params.subdivisionId : DEFAULT_EMPTY_VALUE}
            onChange={onSubdivisionChange}
            placeholder="Выберите подразделение"
            listHeight={SELECT_LIST_HEIGHT_320}
            virtual={false}
            suffixIcon={<ArrowDownShortIcon />}
          />

          <Input
            style={{ flex: 1 }}
            placeholder={EPlaceholder.EnterEmailCartNumberOrUserName}
            allowClear={{
              clearIcon: (
                <CloseIcon
                  className="icon-close-input"
                  onClick={() => {
                    if (params.search?.length) {
                      onSearchClick('');
                    } else {
                      setSearch('');
                    }
                  }}
                />
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => onInputKeyDown(e.key, () => onSearchClick(search))}
          />

          <Button
            className="button-circle primary"
            icon={<SearchIcon className="icon-search-white" />}
            onClick={() => onSearchClick(search)}
          />
        </div>

        <Table
          className={`${!basketList.length && 'table-empty'}`}
          dataSource={basketList.map((basket) => ({ ...basket, key: basket.id }))}
          tableLayout="auto"
          columns={renderActiveBasketListRecords(navigateToBasket)}
          showHeader={false}
          pagination={false}
          locale={{
            emptyText: <div className="text-controls-accent color-dark-grey">Список корзин пуст</div>,
          }}
          loading={{ spinning: basketListLoading, indicator: <SpinIndicator /> }}
        />
      </InfiniteScrollContainer>
    </div>
  );
};

const mapState = (state: RootState) => ({
  basketList: state.basketListState.data,
  basketListCount: state.basketListState.count,
  basketListLoading: state.basketListState.loading,
  basketListSubdivisions: state.basketListState.subdivisionList,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getBasketListSubdivisions: dispatch.basketListState.getBasketListSubdivisions,
  getBasketList: dispatch.basketListState.getBasketList,
  getBasketListPart: dispatch.basketListState.getBasketListPart,
});

export const ActiveBasketList = connect(mapState, mapDispatch)(Component);
