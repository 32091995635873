import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';
import { Button, Input, Table, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { debounce } from 'common/helpers/common.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { useUserContext } from 'common/hooks/useUserContext';
import { DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE, LIST_LIMIT_0, LIST_LIMIT_20 } from 'common/config';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';
import { RootDispatch, RootState } from 'app/store';
import { ISubdivision } from 'entities/Subdivision/Subdivision.models';
import { IUser, IUserListParams } from 'entities/User/User.models';
import { renderUserListSettingsRecords } from 'entities/Settings/Settings.helper';
import { UserCard } from 'entities/User/components/UserCard';
import { UserEditCard } from 'entities/User/components/UserEditCard';
import { UserAddModal } from 'entities/User/components/UserAddModal';

interface IComponentProps {
  header: ReactNode;
  subdivision: ISubdivision | null;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    subdivision,
    // state
    userList,
    userListLoading,
    subdivisionList,
    userLoading,
    subdivisionUserList,
    subdivisionUserListCount,
    subdivisionLoading,
    // dispatch
    getUserList,
    getUserById,
    updateUserByAdmin,
    addSubdivisionUser,
    getSubdivisionUserList,
    getSubdivisionUserListPart,
  } = props;

  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [openUserAddModal, setOpenUserAddModal] = useState<boolean>(false);
  const [params, setParams] = useState<IUserListParams>({
    search: '',
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
  });
  const { setOpenUserCard } = useUserContext();

  const debouncedSearchChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    setParams({ ...params, search: e.target.value });
  });

  const onOpenUserAddModalClick = async () => {
    await getUserList({ excludeSubdivisionId: subdivision?.id, limit: LIST_LIMIT_0, onSuccess: () => setOpenUserAddModal(true) });
  };

  const onAddSubdivisionUserClick = (value: IUser, onSuccess: () => void) => {
    if (subdivision) {
      updateUserByAdmin({
        id: value.id,
        subdivisionId: subdivision.id,
        onSuccess: () => {
          showSuccessMessage(`Пользователь «${value.email}» добавлен в подразделение.`);
          onSuccess();
          addSubdivisionUser({
            ...value,
            subdivisionId: subdivision.id,
            subdivision: subdivision,
          });
        },
      });
    }
  };

  useEffect(() => {
    if (subdivision?.id) {
      getSubdivisionUserList({ ...params, subdivisionId: subdivision.id });
    }
  }, [subdivision?.id, params]);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!subdivisionLoading && subdivisionUserList.length < subdivisionUserListCount}
        scrollToTopTrigger={[params]}
        onLoad={() => {
          setPage(page + 1);
          getSubdivisionUserListPart({ ...params, offset: LIST_LIMIT_20 * page });
        }}
      >
        {header}

        <div className="account-user-list-settings__filters">
          <Input
            placeholder={EPlaceholder.EnterFirstNameLastNameEmail}
            prefix={<SearchIcon className="icon-search-dark-grey" />}
            allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
            // TODO value
            onChange={debouncedSearchChange}
          />

          <Tooltip title="Добавить сотрудника" placement="bottom">
            <Button
              className="button-circle primary"
              icon={<PlusIcon className="icon-plus-white" />}
              onClick={onOpenUserAddModalClick}
            />
          </Tooltip>
        </div>

        <Table
          className={`table-hover ${!subdivisionUserList.length && 'table-empty'} account-user-list-settings__table`}
          showHeader={false}
          pagination={false}
          size="middle"
          dataSource={subdivisionUserList.map((user) => ({ ...user, key: user.id }))}
          columns={renderUserListSettingsRecords()}
          loading={{ spinning: subdivisionLoading || userListLoading || userLoading, indicator: <SpinIndicator /> }}
          locale={{
            emptyText: (
              <div className="account-user-list-settings__table-empty">
                <div className="text-body color-dark-grey">В подразделении ещё нет пользователей.</div>

                <Button className="button-sm secondary" onClick={onOpenUserAddModalClick}>
                  Добавить пользователя
                </Button>
              </div>
            ),
          }}
          onRow={({ id }) => ({
            onClick: () => {
              getUserById({
                id,
                onSuccess: () => setOpenUserCard(true),
              });
            },
          })}
        />
      </InfiniteScrollContainer>

      <UserCard fromSubdivision />

      <UserEditCard />

      <UserAddModal
        open={openUserAddModal}
        placeholder={EPlaceholder.FindAndAddEmployee}
        loading={userLoading}
        userList={userList}
        checkSubdivision
        subdivisionList={subdivisionList}
        onClose={() => setOpenUserAddModal(false)}
        onAdd={onAddSubdivisionUserClick}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  userList: state.userListState.data,
  userListLoading: state.userListState.loading,
  subdivisionList: state.subdivisionListState.data,
  userLoading: state.userState.loading,
  subdivisionUserList: state.subdivisionState.userList,
  subdivisionUserListCount: state.subdivisionState.userListCount,
  subdivisionLoading: state.subdivisionState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserList: dispatch.userListState.getUserList,
  getUserById: dispatch.userState.getUserById,
  updateUserByAdmin: dispatch.userState.updateUserByAdmin,
  addSubdivisionUser: dispatch.subdivisionState.addUser,
  getSubdivisionUserList: dispatch.subdivisionState.getSubdivisionUserList,
  getSubdivisionUserListPart: dispatch.subdivisionState.getSubdivisionUserListPart,
});

export const SubdivisionUserListSettings = connect(mapState, mapDispatch)(Component);
