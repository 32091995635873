import React, { FC } from 'react';
import { Button, Drawer, Spin } from 'antd';
import { connect } from 'react-redux';
import { Image } from 'common/components/Image';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { Notification } from 'common/components/Notification';
import { EInventoryActionType } from 'common/const/inventory.enum';
import { countFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { useInventoryContext } from 'common/hooks/useInventoryContext';
import { useGoodsContext } from 'common/hooks/useGoodsContext';
import { DRAWER_Z_INDEX_1 } from 'common/config';
import { RootState } from 'app/store';
import { ReactComponent as UserIcon } from 'app/assets/images/redesign/user.svg';
import { ReactComponent as HistoryIcon } from 'app/assets/images/redesign/history.svg';
import { ReactComponent as ArrowRightLongIcon } from 'app/assets/images/redesign/arrow-right-long.svg';
import { IInventoryAction } from 'entities/Inventory/Inventory.models';
import {
  getInventoryActionTransferNotificationDescription,
  getInventoryActionWriteOffNotificationDescription,
} from 'entities/Inventory/Inventory.helper';
import { getUserName } from 'entities/User/User.helper';

interface IComponentProps {
  isIncoming?: boolean;
  onAccept?: (record: IInventoryAction) => void;
  onCancel?: (records: IInventoryAction[]) => void;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const { isIncoming, onAccept, onCancel, inventoryAction, inventoryActionLoading, currentBasket, goodsLoading } = props;

  const { openInventoryActionCard, setOpenInventoryActionCard } = useInventoryContext();
  const { onOpenGoodsCard } = useGoodsContext();

  const isTransferInventoryAction = inventoryAction?.type === EInventoryActionType.Transfer;
  const isWriteOffInventoryAction = inventoryAction?.type === EInventoryActionType.WriteOff;

  const onClose = () => {
    if (inventoryActionLoading) {
      return;
    }

    setOpenInventoryActionCard(false);
  };

  const onGoodsClick = (id: number) => {
    if (currentBasket) {
      onOpenGoodsCard(id, currentBasket);
    }
  };

  if (!inventoryAction) {
    return null;
  }

  return (
    <Drawer
      rootClassName="redesign drawer"
      open={openInventoryActionCard}
      onClose={onClose}
      width={800}
      destroyOnClose
      zIndex={DRAWER_Z_INDEX_1}
    >
      <div className="drawer__body">
        <div className="drawer__title mb-52">{inventoryAction.good.name}</div>

        <div className="inventory-card__info mb-32">
          {inventoryAction.user && (
            <div className="inventory-card__info-container">
              <UserIcon className="icon-user-dark-grey" />

              <span className="text-tag-accent color-dark-grey">
                Ответственный:{' '}
                {getUserName.fullLastNameAndInitials(
                  inventoryAction.user.lastName,
                  inventoryAction.user.firstName,
                  inventoryAction.user.patronymic,
                )}
              </span>
            </div>
          )}

          {isTransferInventoryAction && (
            <div className="inventory-card__info-container">
              <HistoryIcon className="icon-history-dark-grey" />

              <span className="text-tag-accent color-dark-grey">Запрос о передаче {countFormatter(inventoryAction.count)}</span>
            </div>
          )}

          {isWriteOffInventoryAction && (
            <div className="inventory-card__info-container">
              <ArrowRightLongIcon className="icon-arrow-right-long-dark-grey" />

              <span className="text-tag-accent color-dark-grey">Запрос о списании {countFormatter(inventoryAction.count)}</span>
            </div>
          )}
        </div>

        <Spin spinning={goodsLoading} indicator={<SpinIndicator />}>
          <div className="inventory-card__good-card mb-52" onClick={() => onGoodsClick(inventoryAction.good.id)}>
            <Image size={80} src={inventoryAction.good.image} />

            <div style={{ flex: 1 }}>
              <div className="text-h4 mb-12">{inventoryAction.good.name}</div>

              <div className="inventory-card__good-card-footer">
                <div>
                  {inventoryAction.good.sellerCode && (
                    <span className="text-tag-accent color-dark-grey" style={{ marginRight: 20 }}>
                      {inventoryAction.good.sellerCode}
                    </span>
                  )}

                  {inventoryAction.good.buyerCode && (
                    <span className="text-tag-accent color-bright-green">{inventoryAction.good.buyerCode}</span>
                  )}
                </div>

                <div className="text-accent">{priceFormatter(inventoryAction.good.price)}</div>
              </div>
            </div>
          </div>
        </Spin>

        {isTransferInventoryAction && (
          <div className="mb-52">
            <Notification
              type="success"
              title={`Запрошена передача ${inventoryAction.count} шт`}
              description={getInventoryActionTransferNotificationDescription(inventoryAction)}
            />
          </div>
        )}

        {isWriteOffInventoryAction && (
          <div className="mb-52">
            <Notification
              type="warning"
              title={`Запрошено списание ${inventoryAction.count} шт`}
              description={getInventoryActionWriteOffNotificationDescription(inventoryAction)}
            />
          </div>
        )}
      </div>

      <div className="drawer__footer">
        {isIncoming && (
          <>
            <Button className="button-lg primary" onClick={() => onAccept?.(inventoryAction)} loading={inventoryActionLoading}>
              Принять запрос
            </Button>

            <Button
              className="button-lg secondary"
              onClick={() => onCancel?.([inventoryAction])}
              loading={inventoryActionLoading}
            >
              Отклонить
            </Button>
          </>
        )}

        {!isIncoming && (
          <Button className="button-lg danger" onClick={() => onCancel?.([inventoryAction])} loading={inventoryActionLoading}>
            Отменить запрос
          </Button>
        )}
      </div>
    </Drawer>
  );
};

const mapState = (state: RootState) => ({
  inventoryAction: state.inventoryActionState.data,
  inventoryActionLoading: state.inventoryActionState.loading,
  currentBasket: state.basketState.currentBasket,
  goodsLoading: state.goodsState.loading,
});

export const InventoryActionCard = connect(mapState)(Component);
