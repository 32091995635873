/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { DEFAULT_GOODS_COUNT } from 'common/config';
import { RootDispatch } from 'app/store';
import { IBasket } from 'entities/Basket/Basket.models';

export interface IGoodsContext {
  count: number;
  setCount: (value: number) => void;
  openGoodsCard: boolean;
  setOpenGoodsCard: (value: boolean) => void;
  onOpenGoodsCard: (id: number, basket: IBasket, catalogId?: number) => void;
}

export const GoodsContext = createContext<IGoodsContext>({
  count: DEFAULT_GOODS_COUNT,
  setCount: () => {},
  openGoodsCard: false,
  setOpenGoodsCard: () => {},
  onOpenGoodsCard: () => {},
});

export const useGoodsContext = () => useContext(GoodsContext);

export interface IComponentProps {
  children: React.ReactNode;
}

type AllType = ReturnType<typeof mapDispatch> & IComponentProps;

export const Component: FC<AllType> = ({ children, getGoodsById }) => {
  const [count, setCount] = useState<number>(DEFAULT_GOODS_COUNT);
  const [openGoodsCard, setOpenGoodsCard] = useState<boolean>(false);

  const onOpenGoodsCard = (id: number, basket: IBasket, catalogId?: number) => {
    const goodsFromBasket = basket.goods.find((goods) => goods.goodId === id);

    if (goodsFromBasket) {
      setCount(goodsFromBasket.count);
    } else {
      setCount(DEFAULT_GOODS_COUNT);
    }

    getGoodsById({ id, catalogId, onSuccess: () => setOpenGoodsCard(true) });
  };

  return (
    <GoodsContext.Provider
      value={{
        count,
        setCount,
        openGoodsCard,
        setOpenGoodsCard,
        onOpenGoodsCard,
      }}
    >
      {children}
    </GoodsContext.Provider>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  getGoodsById: dispatch.goodsState.getGoodsById,
});

export const GoodsProvider = connect(null, mapDispatch)(Component);
