import React, { FC } from 'react';
import { Button } from 'antd';
import { NewPasswordConfirmFormField } from 'common/components/NewPasswordConfirmFormField';
import { NewPasswordFormField } from 'common/components/NewPasswordFormField';

interface IComponentProps {
  loading: boolean;
}

export const ActivationStepThree: FC<IComponentProps> = ({ loading }) => {
  return (
    <>
      <div className="mb-40 auth-card__content-form-title">Придумайте надежный пароль для входа в систему</div>

      <NewPasswordFormField className="w-100" label="Ваш пароль*" name="password" />

      <NewPasswordConfirmFormField className="w-100 mb-40" label="Подтвердите пароль*" newPasswordName="password" />

      <Button className="button-lg primary w-100" loading={loading} htmlType="submit">
        Войти в систему
      </Button>
    </>
  );
};
