import React, { FC, useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ERoute } from 'common/const/route.enum';
import { ESettingsMenuTab, ESettingsTab } from 'common/const/settings.enum';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { stringToNumber } from 'common/helpers/common.helper';
import { ReactComponent as ArrowRightShortIcon } from 'app/assets/images/redesign/arrow-right-short.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as ContractIcon } from 'app/assets/images/redesign/contract.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { RootState } from 'app/store';

interface IComponentProps {
  active: boolean;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

export const Component: FC<AllType> = ({ active, contractList }) => {
  const [search, setSearch] = useState<string>('');
  const [showContractList, setShowContractList] = useState<boolean>(false);
  const { tab, id, menu } = useParams();
  const navigate = useNavigate();

  const contractListForView = contractList.filter((contract) => contract.buyerName?.toLowerCase().includes(search.toLowerCase()));

  useEffect(() => {
    if (tab === ESettingsTab.Contract) {
      setShowContractList(true);
    }
  }, [tab]);

  return (
    <>
      <div className={`settings-sidebar__btn ${active ? 'active' : ''}`}>
        <div className="settings-sidebar__btn-container">
          <Button
            className="button-icon settings-sidebar__btn-toggle"
            icon={
              showContractList ? (
                <ArrowDownShortIcon className="icon-arrow-down-short-dark-grey" />
              ) : (
                <ArrowRightShortIcon className="icon-arrow-right-short-dark-grey" />
              )
            }
            onClick={() => {
              setShowContractList((prev) => !prev);
              setSearch('');
            }}
          />

          <div className="settings-sidebar__btn-title-container">
            <span className="text-h4-item-name settings-sidebar__btn-text">Контракты</span>
          </div>
        </div>

        <span className="text-tag color-dark-grey settings-sidebar__btn-text">Управление контрактами на поставку</span>
      </div>

      {showContractList && (
        <>
          <Input
            className="mb-16"
            placeholder={EPlaceholder.FindContract}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
          />

          <div className="mb-52">
            {contractListForView.map((contract) => {
              return (
                <div
                  key={contract.id}
                  className={`settings-sidebar__list-item ${id && stringToNumber(id) === contract.id ? 'active' : ''}`}
                  onClick={() =>
                    navigate(
                      `${ERoute.Settings}/${ESettingsTab.Contract}/${contract.id}/${
                        tab !== ESettingsTab.Contract ? ESettingsMenuTab.General : menu
                      }`,
                    )
                  }
                >
                  <div className="settings-sidebar__list-item-icon">
                    <ContractIcon className="icon-contract-dark-grey" />
                  </div>

                  <div className="settings-sidebar__list-item-label">{contract.buyerName}</div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

const mapState = (state: RootState) => ({
  contractList: state.contractListState.data,
});

export const SettingsSidebarContractList = connect(mapState)(Component);
