import { createModel } from '@rematch/core';
import { DEFAULT_VALUE_0 } from 'common/config';
import { IRootModel } from 'app/store';
import { IGroupedSupplyListState, ISupplyListState, ISupplyState } from 'entities/Supply/Supply.models';
import { groupedSupplyListStateReducers, supplyListStateReducers, supplyStateReducers } from 'entities/Supply/Supply.reducers';
import { groupedSupplyListStateEffects, supplyListStateEffects, supplyStateEffects } from 'entities/Supply/Supply.effects';

export const supplyListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: DEFAULT_VALUE_0,
    loading: false,
    userList: [],
    subdivisionList: [],
    legalList: [],
    addressList: [],
  } as ISupplyListState,
  reducers: supplyListStateReducers,
  effects: supplyListStateEffects,
});

export const groupedSupplyListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: DEFAULT_VALUE_0,
    loading: false,
  } as IGroupedSupplyListState,
  reducers: groupedSupplyListStateReducers,
  effects: groupedSupplyListStateEffects,
});

export const supplyState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ISupplyState,
  reducers: supplyStateReducers,
  effects: supplyStateEffects,
});
