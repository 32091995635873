import axios from 'axios';
import { IUserShort, IUserShortCollection } from 'common/models';
import {
  IContract,
  IContractCollectionDto,
  IContractListParams,
  IContractCreatePayload,
  IContractDeletePayload,
  IContractUpdatePayload,
  IContractUserListParams,
  IContractCreateUserPayload,
  IContractDeleteUserPayload,
} from 'entities/Contract/Contract.models';

const basePath = 'contract';

export const contractTransport = {
  getContractList: (params: IContractListParams): Promise<IContractCollectionDto> => axios.get(`${basePath}`, { params }),
  getContractById: (id: number): Promise<IContract> => axios.get(`${basePath}/${id}`),
  createContract: (payload: IContractCreatePayload): Promise<IContract> => axios.post(`${basePath}`, payload),
  updateContract: (payload: IContractUpdatePayload): Promise<IContract> => axios.patch(`${basePath}`, payload),
  deleteContract: (payload: IContractDeletePayload): Promise<IContract> => axios.delete(`${basePath}`, { data: payload }),
  getContractUserList: (params: IContractUserListParams): Promise<IUserShortCollection> =>
    axios.get(`${basePath}/users`, { params }),
  createContractUser: (payload: IContractCreateUserPayload): Promise<IUserShort> => axios.post(`${basePath}/users`, payload),
  deleteContractUser: (payload: IContractDeleteUserPayload): Promise<IUserShort> =>
    axios.delete(`${basePath}/users`, { data: payload }),
};
