import React, { ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Select, Spin, Table } from 'antd';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { ENeedCardMenuItem, ENeedStatus } from 'common/const/need.enum';
import { EDateFormat } from 'common/const/date.enum';
import { groupList, mapToMenuWithDivider } from 'common/helpers/common.helper';
import { onInputKeyDown } from 'common/helpers/input.helper';
import { useNeedContext } from 'common/hooks/useNeedContext';
import { DEFAULT_EMPTY_VALUE, LIST_LIMIT_20, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { INeedListPayload } from 'entities/Need/Need.models';
import { needListFilterStatusOptionsForUser } from 'entities/Need/Need.const';
import { getNeedFormattedDate, renderNeedListRecords } from 'entities/Need/Need.helper';
import { NeedCard } from 'entities/Need/components/NeedCard';
import { NeedListEmpty } from 'entities/Need/components/NeedListEmpty';
import { SupplyCard } from 'entities/Supply/components/SupplyCard';
import { SupplyCorrectionRequestDrawer } from 'entities/Supply/components/SupplyCorrectionRequestDrawer';
import { GoodsCard } from 'entities/Goods/components/GoodsCard';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CreatedNeedListForUserComponent: React.FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    // state
    needList,
    needListLoading,
    needListCount,
    currentUser,
    // dispatch
    setNeedList,
    getNeedList,
    getNeedListPart,
    getNeedById,
  } = props;

  const [search, setSearch] = useState<string>('');
  const [payload, setPayload] = useState<INeedListPayload>({
    userId: currentUser?.id,
    statuses: [ENeedStatus.Created, ENeedStatus.Pending, ENeedStatus.Approved],
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
  });
  const { page, setPage, setNeedCardCurrentMenuTab, setOpenNeedCard } = useNeedContext();

  const groupedNeedList = groupList.byCreatedAt(needList);

  const onStatusChange = (value: number | string) => {
    const newPayload = {
      ...payload,
      statuses:
        value === DEFAULT_EMPTY_VALUE ? [ENeedStatus.Created, ENeedStatus.Pending, ENeedStatus.Approved] : [value as ENeedStatus],
    };

    setPage(DEFAULT_PAGINATION_PAGE);
    setNeedList([]);
    setPayload(newPayload);
    getNeedList(newPayload);
  };

  const onSearchClick = (value: string) => {
    const newPayload = { ...payload, search: value };

    setSearch(value);
    setPage(DEFAULT_PAGINATION_PAGE);
    setNeedList([]);
    setPayload(newPayload);
    getNeedList(newPayload);
  };

  useEffect(() => {
    setPage(DEFAULT_PAGINATION_PAGE);
    setNeedList([]);
    getNeedList(payload);
  }, []);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!needListLoading && needList.length < needListCount}
        scrollToTopTrigger={[payload]}
        onLoad={() => {
          setPage(page + 1);
          getNeedListPart({ ...payload, offset: LIST_LIMIT_20 * page });
        }}
      >
        {header}

        {navigation}

        <div className="need-list__filters">
          <Select
            rootClassName="redesign"
            className="need-list__filters-select"
            options={mapToMenuWithDivider(needListFilterStatusOptionsForUser)}
            value={payload.statuses && payload.statuses.length === 1 ? payload.statuses[0] : DEFAULT_EMPTY_VALUE}
            onChange={onStatusChange}
            placeholder="Выберите подразделение"
            virtual={false}
            suffixIcon={<ArrowDownShortIcon />}
          />

          <Input
            style={{ flex: 1 }}
            placeholder="Поиск"
            value={search}
            allowClear={{
              clearIcon: (
                <CloseIcon
                  className="icon-close-input"
                  onClick={() => {
                    if (payload.search?.length) {
                      onSearchClick('');
                    } else {
                      setSearch('');
                    }
                  }}
                />
              ),
            }}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => onInputKeyDown(e.key, () => onSearchClick(search))}
          />

          <Button
            className="button-circle primary"
            icon={<SearchIcon className="icon-search-white" />}
            onClick={() => onSearchClick(search)}
          />
        </div>

        <Spin wrapperClassName="need-list__spin" spinning={needListLoading} indicator={<SpinIndicator />}>
          {groupedNeedList.map((group, index) => {
            return (
              <div key={index} className="need-list__group">
                <span className="need-list__group-date">{getNeedFormattedDate(EDateFormat.FullDate, group.createdAt)}</span>

                <Table
                  className="table-hover need-list__table"
                  dataSource={group.dataSource.map((need) => ({ ...need, key: need.id }))}
                  columns={renderNeedListRecords()}
                  onRow={({ id }) => ({
                    onClick: () => {
                      getNeedById({
                        id,
                        onSuccess: () => {
                          setNeedCardCurrentMenuTab(ENeedCardMenuItem.Info);
                          setOpenNeedCard(true);
                        },
                      });
                    },
                  })}
                  showHeader={false}
                  pagination={false}
                />
              </div>
            );
          })}

          <NeedListEmpty open={!needListLoading && !groupedNeedList.length} />
        </Spin>
      </InfiniteScrollContainer>

      <NeedCard asUser />

      <SupplyCard nested asUser />

      <SupplyCorrectionRequestDrawer />

      <GoodsCard />
    </>
  );
};

const mapState = (state: RootState) => ({
  needList: state.needListState.data,
  needListLoading: state.needListState.loading,
  needListCount: state.needListState.count,
  currentUser: state.userState.currentUser,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setNeedList: dispatch.needListState.setList,
  getNeedList: dispatch.needListState.getNeedList,
  getNeedListPart: dispatch.needListState.getNeedListPart,
  getNeedById: dispatch.needState.getNeedById,
});

export const CreatedNeedListForUser = connect(mapState, mapDispatch)(CreatedNeedListForUserComponent);
