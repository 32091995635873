import React, { FC, ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, InputNumber, Spin, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { debounce } from 'common/helpers/common.helper';
import { DEFAULT_VALUE_0 } from 'common/config';
import { IFormValue } from 'common/models';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';
import { ReactComponent as PercentageIcon } from 'app/assets/images/redesign/percentage.svg';
import { RootDispatch, RootState } from 'app/store';
import { IContract } from 'entities/Contract/Contract.models';
import { BrandDiscountCreateCard } from 'entities/Discount/components/BrandDiscountCreateCard';
import { IBrandDiscount, IBrandDiscountUpdatePayload } from 'entities/Discount/Discount.models';
import { renderBrandDiscountListRecords } from 'entities/Settings/Settings.helper';

interface IComponentProps {
  header: ReactNode;
  contract: IContract | null;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    contract,
    // state
    brandDiscountList,
    brandDiscountListLoading,
    brandList,
    brandListLoading,
    brandDiscountLoading,
    contractLoading,
    // dispatch
    getBrandDiscountList,
    getBrandList,
    createBrandDiscount,
    updateBrandDiscount,
    deleteBrandDiscount,
    updateContract,
  } = props;

  const [selectedBrandDiscount, setSelectedBrandDiscount] = useState<IBrandDiscount | null>(null);
  const [openBrandDiscountCreateCard, setOpenBrandDiscountCreateCard] = useState<boolean>(false);
  const [form] = useForm();

  const contractBrands = brandDiscountList.map((discount) => discount.brand);
  const brandListForView = brandList.filter((brand) => {
    return !contractBrands.some((contractBrand) => contractBrand.toLowerCase() === brand.toLowerCase());
  });

  const debouncedUpdateContract = debounce(updateContract);

  const onCreateBrandDiscountClick = (record?: IBrandDiscount) => {
    if (contract) {
      getBrandList({
        onSuccess: () => {
          setSelectedBrandDiscount(record ? record : ({ discountValue: contract.discountValue } as IBrandDiscount));
          setOpenBrandDiscountCreateCard(true);
        },
      });
    }
  };

  const onBrandDiscountCreateCardCreateClick = (brand: string, discountValue: number, onSuccess: () => void) => {
    if (contract) {
      createBrandDiscount({
        contractId: contract.id,
        brand,
        discountValue,
        onSuccess,
      });
    }
  };

  const onBrandDiscountCreateCardUpdateClick = (payload: IBrandDiscountUpdatePayload) => {
    updateBrandDiscount(payload);
  };

  const onBrandDiscountCreateCardDeleteClick = (id: number, onSuccess?: () => void) => {
    deleteBrandDiscount({ id, onSuccess });
  };

  const onFormValuesChange = ({ discountValue }: IFormValue) => {
    if (contract) {
      debouncedUpdateContract({ id: contract.id, discountValue: discountValue ? discountValue : DEFAULT_VALUE_0 });
    }
  };

  useEffect(() => {
    if (contract) {
      form.setFieldValue('discountValue', contract.discountValue);
      getBrandDiscountList({ contractId: contract.id });
    }
  }, [contract?.id]);

  return (
    <>
      <InfiniteScrollContainer>
        {header}

        <Spin spinning={contractLoading || brandDiscountListLoading || brandListLoading} indicator={<SpinIndicator />}>
          <div className="text-h4 mb-6">Базовая скидка на ассортимент</div>

          <div className="text-tag color-dark-grey mb-20" style={{ maxWidth: 800 }}>
            Базовая скидка применяется ко всем категориям товаров и брендам, установленным в разделе «Ассортимент» этого
            контракта. Базовая скидка не суммируется и не исключает другие типы скидок.
          </div>

          <Form form={form} layout="vertical" requiredMark={false} onValuesChange={onFormValuesChange}>
            <Form.Item label="Скидка на товары*" name="discountValue" className="mb-52" style={{ maxWidth: 360 }}>
              <InputNumber
                placeholder="0"
                suffix={<PercentageIcon className="icon-percentage-dark-grey" />}
                controls={false}
                precision={0}
              />
            </Form.Item>
          </Form>

          <Button
            className="button-sm primary mb-20"
            icon={<PlusIcon className="icon-plus-white" />}
            onClick={() => onCreateBrandDiscountClick()}
          >
            Скидка на бренд
          </Button>

          {!!brandDiscountList?.length && (
            <Table
              className="mb-72 contract-discount-list-settings__table"
              showHeader={false}
              pagination={false}
              size="middle"
              dataSource={brandDiscountList.map((discount) => ({ ...discount, key: discount.id }))}
              columns={renderBrandDiscountListRecords(onCreateBrandDiscountClick, onBrandDiscountCreateCardDeleteClick)}
            />
          )}
        </Spin>
      </InfiniteScrollContainer>

      <BrandDiscountCreateCard
        open={openBrandDiscountCreateCard}
        loading={brandDiscountLoading}
        brands={brandListForView}
        brandDiscount={selectedBrandDiscount}
        onClose={() => setOpenBrandDiscountCreateCard(false)}
        onCreate={onBrandDiscountCreateCardCreateClick}
        onUpdate={onBrandDiscountCreateCardUpdateClick}
        onDelete={onBrandDiscountCreateCardDeleteClick}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  brandDiscountList: state.brandDiscountListState.data,
  brandDiscountListLoading: state.brandDiscountListState.loading,
  brandList: state.brandListState.data,
  brandListLoading: state.brandListState.loading,
  brandDiscountLoading: state.brandDiscountState.loading,
  contractLoading: state.contractState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getBrandDiscountList: dispatch.brandDiscountListState.getBrandDiscountList,
  getBrandList: dispatch.brandListState.getBrandList,
  createBrandDiscount: dispatch.brandDiscountState.createBrandDiscount,
  updateBrandDiscount: dispatch.brandDiscountState.updateBrandDiscount,
  deleteBrandDiscount: dispatch.brandDiscountState.deleteBrandDiscount,
  updateContract: dispatch.contractState.updateContract,
});

export const ContractDiscountListSettings = connect(mapState, mapDispatch)(Component);
