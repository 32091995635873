import React, { FC, useEffect, useState } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ERoute } from 'common/const/route.enum';
import { PREVIOUS_PATH_KEY } from 'common/config';
import { ReactComponent as ArrowLeftLongIcon } from 'app/assets/images/redesign/arrow-left-long.svg';

export const SettingsSidebarBackBtn: FC = () => {
  const navigate = useNavigate();
  const [previousPath, setPreviousPath] = useState<string>(ERoute.Profile);

  useEffect(() => {
    const storedPath = sessionStorage.getItem(PREVIOUS_PATH_KEY);

    if (storedPath) {
      setPreviousPath(storedPath);
    }
  }, []);

  return <Button className="button-circle secondary" icon={<ArrowLeftLongIcon />} onClick={() => navigate(previousPath)} />;
};
