import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Button, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { DEFAULT_EMPTY_VALUE } from 'common/config';
import { ReactComponent as ArrowLeftLongIcon } from 'app/assets/images/redesign/arrow-left-long.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as ArrowLeftShortIcon } from 'app/assets/images/redesign/arrow-left-short.svg';
import { ReactComponent as ToolIcon } from 'app/assets/images/redesign/tool.svg';
import { RootDispatch } from 'app/store';
import { ICategory } from 'entities/Category/Category.models';
import { sortWorkspacePositionLowerLevelCategories } from 'entities/Workspace/Workspace.helper';

interface IComponentProps {
  categoryList: ICategory[];
  selectedCategory: ICategory | null;
  onSelectedCategoryChange: (value: ICategory | null) => void;
}

type AllType = ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    categoryList,
    selectedCategory,
    onSelectedCategoryChange,
    // dispatch
    getPropertyListByCategoryId,
    setWorkspaceError,
    setWorkspacePositionError,
  } = props;

  const [search, setSearch] = useState<string>('');
  const [upperLevelCategories, setUpperLevelCategories] = useState<ICategory[]>([]);
  const [lowerLevelCategories, setLowerLevelCategories] = useState<ICategory[]>([]);
  const navigate = useNavigate();

  const sortedLowerLevelCategories = useMemo(() => {
    return sortWorkspacePositionLowerLevelCategories(categoryList, lowerLevelCategories);
  }, [categoryList, lowerLevelCategories]);

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setWorkspaceError(null);
    setWorkspacePositionError(null);
    onSelectedCategoryChange(null);
    setSearch(e.target.value);
  };

  const onCategoryClick = async (value: ICategory) => {
    if (value.id === DEFAULT_EMPTY_VALUE) {
      setSearch('');
      onSelectedCategoryChange(null);

      if (!search.trim().length) {
        setUpperLevelCategories([]);
        setLowerLevelCategories(categoryList.filter((category) => !category.parentId));
      }
    } else {
      onSelectedCategoryChange(value);
      const lowerLevelCategories = categoryList.filter((category) => category.parentId === value.id);

      if (lowerLevelCategories.length) {
        setLowerLevelCategories(lowerLevelCategories);

        if (!upperLevelCategories.length) {
          setUpperLevelCategories([{ id: DEFAULT_EMPTY_VALUE, displayName: 'Все инструменты' } as ICategory, value]);
        } else {
          const index = upperLevelCategories.findIndex((category) => category.id === value.id);

          if (index === DEFAULT_EMPTY_VALUE) {
            setUpperLevelCategories((prev) => [...prev, value]);
          } else {
            setUpperLevelCategories((prev) => [...prev.slice(0, index), value]);
          }
        }
      }

      getPropertyListByCategoryId(value.id);
    }

    setWorkspaceError(null);
    setWorkspacePositionError(null);
  };

  useEffect(() => {
    setUpperLevelCategories([]);

    if (search.trim().length) {
      setLowerLevelCategories(
        categoryList.filter((category) => {
          return search.trim().length ? category.displayName.toLowerCase().includes(search.trim().toLowerCase()) : true;
        }),
      );
    } else {
      setLowerLevelCategories(categoryList.filter((category) => !category.parentId));
    }
  }, [categoryList, search]);

  return (
    <div className="workspace-position-templates">
      <div className="workspace-position-templates__header">
        <Button
          className="button-circle secondary"
          icon={<ArrowLeftLongIcon className="icon-arrow-left-long" />}
          onClick={() => navigate(-1)}
        />
      </div>

      <div className="workspace-position-templates__body">
        <InfiniteScrollContainer>
          <div className="text-h1-drawers mb-52">1. Выберите шаблон</div>

          <Input
            className="mb-32"
            style={{ minHeight: 44 }}
            placeholder={EPlaceholder.SearchByTemplates}
            allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
            value={search}
            onChange={onSearchChange}
          />

          <div className="mb-52">
            {upperLevelCategories.map((category, _, array) => {
              const isActive = selectedCategory?.id === category.id;
              const isLastItem = category.id === array[array.length - 1].id;

              return (
                <div
                  key={category.id}
                  className={`workspace-list-subdivisions__item ${isActive ? 'active' : ''}`}
                  onClick={() => onCategoryClick(category)}
                >
                  <div className="workspace-list-subdivisions__item-container">
                    {!!selectedCategory && isLastItem ? (
                      <ToolIcon className="icon-tool-dark-grey" />
                    ) : (
                      <ArrowLeftShortIcon className="icon-arrow-left-short-dark-grey" />
                    )}

                    <div className="workspace-list-subdivisions__item-label">{category.displayName}</div>
                  </div>
                </div>
              );
            })}

            {sortedLowerLevelCategories.map((category) => {
              const isActive = selectedCategory?.id === category.id;
              const lowerLevelCategories = categoryList.filter((categoryItem) => categoryItem.parentId === category.id);

              return (
                <div
                  key={category.id}
                  className={`workspace-list-subdivisions__item ${isActive ? 'active' : ''}`}
                  onClick={() => onCategoryClick(category)}
                >
                  <div className="workspace-list-subdivisions__item-container">
                    <div className="workspace-list-subdivisions__item-label">{category.displayName}</div>
                  </div>

                  {!lowerLevelCategories.length && (
                    <div className="text-tag color-dark-grey" style={{ marginTop: 4 }}>
                      {`${category.propertiesCount} параметров`}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </InfiniteScrollContainer>
      </div>
    </div>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  getPropertyListByCategoryId: dispatch.propertyListState.getPropertyListByCategoryId,
  setWorkspaceError: dispatch.workspaceState.setError,
  setWorkspacePositionError: dispatch.workspacePositionState.setError,
});

export const NewWorkspacePositionTemplates = connect(null, mapDispatch)(Component);
