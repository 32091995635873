import React, { FC, useState } from 'react';
import { Button, Drawer, Form, Input, InputNumber, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { Image } from 'common/components/Image';
import { InventoryCount } from 'common/components/InventoryCount';
import { SuccessCard } from 'common/components/SuccessCard';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { EInventoryActionReason, EInventoryActionReasonLabel, EInventoryActionType } from 'common/const/inventory.enum';
import { EFormFieldMessage } from 'common/const/form.enum';
import { mapToMenuWithDivider } from 'common/helpers/common.helper';
import { rules } from 'common/helpers/form.helper';
import { useInventoryContext } from 'common/hooks/useInventoryContext';
import { CHARACTER_LIMIT_MIN, DEFAULT_VALUE_0, DRAWER_Z_INDEX_1 } from 'common/config';
import { IFormValue } from 'common/models';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { inventoryCount } from 'entities/Inventory/Inventory.helper';
import { getUserRole } from 'entities/User/User.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = (props) => {
  const {
    // state
    currentUser,
    inventory,
    inventoryActionLoading,
    // dispatch
    filterInventoryListByCount,
    createInventoryAction,
    getUserInventoryStatistics,
  } = props;

  const [openSuccessCard, setOpenSuccessCard] = useState<boolean>(false);
  const [count, setCount] = useState<number | null>(null);
  const [form] = useForm();
  const { isNestedCard, setIsNestedCard, setOpenInventoryCard, openInventoryWriteOffCard, setOpenInventoryWriteOffCard } =
    useInventoryContext();

  const { isAccountAdmin } = getUserRole(currentUser?.roles);
  const availableCount = inventoryCount.withoutAllRequestsCount(inventory);

  const onClose = () => {
    if (inventoryActionLoading) {
      return;
    }

    setOpenInventoryWriteOffCard(false);

    if (isNestedCard) {
      setOpenInventoryCard(true);
    }

    setIsNestedCard(false);
    form.resetFields();
  };

  const onSuccessCardClose = () => {
    setOpenSuccessCard(false);
    form.resetFields();
  };

  const onSubmit = (values: IFormValue) => {
    const { reason, count, comment } = values;

    if (inventory) {
      createInventoryAction({
        inventoryId: inventory.id,
        type: EInventoryActionType.WriteOff,
        count,
        reason,
        comment,
        onSuccess: () => {
          setOpenInventoryWriteOffCard(false);
          setOpenSuccessCard(true);
          filterInventoryListByCount();
          getUserInventoryStatistics();
        },
      });
    }
  };

  if (!inventory) {
    return null;
  }

  return (
    <>
      <Drawer
        rootClassName="redesign drawer"
        open={openInventoryWriteOffCard}
        onClose={onClose}
        width={800}
        destroyOnClose
        zIndex={DRAWER_Z_INDEX_1}
      >
        <div className="drawer__body">
          <div className="text-h1-drawers mb-52">Списание оборудования</div>

          <div className="inventory-card__good-card mb-52">
            <Image size={80} src={inventory.good.image} />

            <div style={{ flex: 1 }}>
              <div className="text-h4 mb-12">{inventory.good.name}</div>

              <div className="inventory-card__good-card-footer">
                <div>
                  {inventory.good.sellerCode && (
                    <span className="text-tag-accent color-dark-grey" style={{ marginRight: 20 }}>
                      {inventory.good.sellerCode}
                    </span>
                  )}

                  {inventory.good.buyerCode && (
                    <span className="text-tag-accent color-bright-green">{inventory.good.buyerCode}</span>
                  )}
                </div>
              </div>

              <div style={{ marginTop: 12 }}>
                <InventoryCount count={inventory.count} />
              </div>
            </div>
          </div>

          <Form layout="vertical" requiredMark={false} form={form} onFinish={onSubmit}>
            <Form.Item className="mb-32" label="Причина*" name="reason" rules={[rules.required()]}>
              <Select
                rootClassName="redesign"
                options={mapToMenuWithDivider([
                  { label: EInventoryActionReasonLabel.tool_damage, value: EInventoryActionReason.ToolDamage },
                  { label: EInventoryActionReasonLabel.tool_loss, value: EInventoryActionReason.ToolLoss },
                  {
                    label: EInventoryActionReasonLabel.scheduled_replacement,
                    value: EInventoryActionReason.ScheduledReplacement,
                  },
                  { label: EInventoryActionReasonLabel.other, value: EInventoryActionReason.Other },
                ])}
                placeholder={EPlaceholder.SelectReasonForWriting}
                virtual={false}
                suffixIcon={<ArrowDownShortIcon />}
              />
            </Form.Item>

            <Form.Item
              className="mb-32"
              label="Количество оборудования*"
              name="count"
              rules={[
                rules.required(),
                () => ({
                  validator(_, value) {
                    if (value > availableCount || value <= DEFAULT_VALUE_0) {
                      return Promise.reject(new Error('Невозможно указать введённое количество.'));
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber controls={false} suffix="шт" onChange={(value) => setCount(value as number)} />
            </Form.Item>

            <Form.Item
              className="mb-32"
              label="Комментарий"
              name="comment"
              rules={[rules.min(CHARACTER_LIMIT_MIN, EFormFieldMessage.CharacterLimitMin)]}
            >
              <Input.TextArea
                rows={4}
                className="textarea"
                placeholder={EPlaceholder.ProvideAdditionalDetailsAboutReasonForWriteOff}
              />
            </Form.Item>
          </Form>
        </div>

        <div className="drawer__footer">
          <Button className="button-lg primary" loading={inventoryActionLoading} onClick={form.submit}>
            Направить запрос
          </Button>

          <Button className="button-lg secondary" onClick={onClose} loading={inventoryActionLoading}>
            Отмена
          </Button>
        </div>
      </Drawer>

      <SuccessCard
        open={openSuccessCard}
        content={
          isAccountAdmin
            ? `Оборудование в количестве ${count} шт списано с баланса подразделения`
            : 'Запрос о списании оборудования направлен на согласование'
        }
        subtitle="Готово!"
        btnTitle="Назад к списку"
        onClose={onSuccessCardClose}
        onConfirm={onSuccessCardClose}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  currentUser: state.userState.currentUser,
  inventory: state.inventoryState.data,
  inventoryActionLoading: state.inventoryActionState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  filterInventoryListByCount: dispatch.inventoryListState.filterListByCount,
  createInventoryAction: dispatch.inventoryActionState.createInventoryAction,
  getUserInventoryStatistics: dispatch.userInventoryStatisticsState.getUserInventoryStatistics,
});

export const InventoryWriteOffCard = connect(mapState, mapDispatch)(Component);
