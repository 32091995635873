import React, { FC, useState } from 'react';
import { Image, Button } from 'antd';
import { connect } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { SidebarItem } from 'common/components/SidebarItem';
import { ERoute } from 'common/const/route.enum';
import { ESettingsMenuTab, ESettingsTab } from 'common/const/settings.enum';
import { getSidebarHeaderSubtitle, getSidebarItemClassName, getSidebarItems } from 'common/helpers/sidebar.helper';
import { PREVIOUS_PATH_KEY } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import sidebar from 'app/assets/images/redesign/sidebar.svg';
import { ReactComponent as ArrowRightShortIcon } from 'app/assets/images/redesign/arrow-right-short.svg';
import { getUserRole } from 'entities/User/User.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = (props) => {
  const { auth, currentUser, xBasketId, superAdminAsUser, statistics, deleteAuth, logoutAsUser, setIsSidebarOpen } = props;

  const [collapsed, setCollapsed] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();

  const collapseSidebar = () => {
    setCollapsed(true);
    setIsSidebarOpen(false);
  };

  const expandSidebar = () => {
    setCollapsed(false);
    setIsSidebarOpen(true);
  };

  const { isSuperAdmin, isAccountAdmin } = getUserRole(auth?.access.roles);
  const accountName = auth?.access.accountName;
  const isSeller = auth?.access.isSeller;
  const headerSubtitle = getSidebarHeaderSubtitle(auth?.access.roles, currentUser?.subdivision?.name);

  const onLogout = () => {
    if (superAdminAsUser) {
      logoutAsUser();
      navigate(ERoute.AccountList);
    } else {
      deleteAuth();
    }
  };

  const sidebarItems = getSidebarItems(auth, currentUser, xBasketId, superAdminAsUser, statistics, collapseSidebar, onLogout);

  return (
    <div
      className={`redesign sidebar ${collapsed ? 'sidebar-collapsed' : ''}`}
      onMouseEnter={expandSidebar}
      onMouseLeave={collapseSidebar}
    >
      <div className="sidebar__header">
        {!isSuperAdmin && isAccountAdmin ? (
          <NavLink
            to={`${ERoute.Settings}/${ESettingsTab.Account}/${isSeller ? ESettingsMenuTab.UserList : ESettingsMenuTab.Contracts}`}
            className={getSidebarItemClassName}
            onClick={() => {
              if (!location.pathname.startsWith(ERoute.Settings)) {
                sessionStorage.setItem(PREVIOUS_PATH_KEY, `${location.pathname}${location.search}`);
              }

              collapseSidebar();
            }}
          >
            <div className="sidebar-item__content">
              <Image wrapperClassName="sidebar__header-image" src={sidebar} preview={false} />

              <div className="sidebar-item__label sidebar__header-label">
                <span className="text-h4 color-black" title={accountName}>
                  {accountName}
                </span>

                <span className="text-tag color-dark-grey" title={headerSubtitle ? headerSubtitle : undefined}>
                  {headerSubtitle}
                </span>
              </div>

              <Button className="button-icon" icon={<ArrowRightShortIcon className="icon-arrow-right-short-dark-grey" />} />
            </div>
          </NavLink>
        ) : (
          <NavLink to="" className={getSidebarItemClassName} style={{ pointerEvents: 'none' }}>
            <div className="sidebar-item__content">
              <Image wrapperClassName="sidebar__header-image" src={sidebar} preview={false} />

              <div className="sidebar-item__label sidebar__header-label">
                <span className="text-h4 color-black">{accountName}</span>

                <span className="text-tag color-dark-grey" title={headerSubtitle ? headerSubtitle : undefined}>
                  {headerSubtitle}
                </span>
              </div>
            </div>
          </NavLink>
        )}
      </div>

      <div className="sidebar__body">
        <div className="sidebar__body-group orange-group">
          {sidebarItems.body.orangeGroup.map(({ key, show, to, icon, label, onClick }) => {
            if (!show) {
              return null;
            }

            return <SidebarItem key={key} to={to} icon={icon} label={label} onClick={onClick} />;
          })}
        </div>

        <div className="sidebar__body-group dark-green-group">
          {sidebarItems.body.darkGreenGroup.map(({ key, show, to, icon, label, badge, onClick }) => {
            if (!show) {
              return null;
            }

            return <SidebarItem key={key} to={to} icon={icon} label={label} badge={badge} onClick={onClick} />;
          })}
        </div>

        <div className="sidebar__body-group bright-green-group">
          {sidebarItems.body.brightGreenGroup.map(({ key, show, to, icon, label, end, badge, onClick }) => {
            if (!show) {
              return null;
            }

            return <SidebarItem key={key} to={to} icon={icon} label={label} end={end} badge={badge} onClick={onClick} />;
          })}
        </div>
      </div>

      <div className="sidebar__footer">
        {sidebarItems.footer.map(({ key, show, to, icon, label, iconClassName, onClick }) => {
          if (!show) {
            return null;
          }

          return <SidebarItem key={key} to={to} icon={icon} label={label} iconClassName={iconClassName} onClick={onClick} />;
        })}
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  auth: state.authState.data,
  currentUser: state.userState.currentUser,
  xBasketId: state.authState.xBasketId,
  superAdminAsUser: state.authState.superAdminAsUser,
  statistics: state.statisticsState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  deleteAuth: dispatch.authState.deleteAuth,
  logoutAsUser: dispatch.authState.logoutAsUser,
  setIsSidebarOpen: dispatch.UIState.setIsSidebarOpen,
});

export const Sidebar = connect(mapState, mapDispatch)(Component);
