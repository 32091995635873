import React, { FC, useMemo } from 'react';
import { Chip } from 'common/components/Chip';
import { EColor } from 'common/const/color.enum';
import { generateUid } from 'common/helpers/common.helper';
import {
  mapNewWorkspacePositionPropertyListToChips,
  showWorkspacePositionChipsClearAllBtn,
} from 'entities/Workspace/Workspace.helper';
import { IProperty } from 'entities/Property/Property.models';

interface IComponentProps {
  categoryName: string;
  propertyList: IProperty[];
  onClear: (id: number) => void;
  onClearAll: () => void;
}

export const WorkspacePositionChips: FC<IComponentProps> = ({ categoryName, propertyList, onClear, onClearAll }) => {
  const chips = useMemo(() => mapNewWorkspacePositionPropertyListToChips(propertyList), [propertyList]);
  const showClearAll = showWorkspacePositionChipsClearAllBtn(propertyList);

  return (
    <div className="chip-list mb-52">
      <Chip color={EColor.PalePurple} name="Категория" value={categoryName} />

      {chips.map((chip) => {
        if (!chip) {
          return null;
        }

        return (
          <Chip
            key={generateUid()}
            color={chip.isRequiredForPresets ? EColor.PaleBlue : EColor.LightGrey}
            name={chip.name}
            value={`${chip.value} ${chip.unit}`}
            onClear={() => onClear(chip.id)}
          />
        );
      })}

      {showClearAll && <Chip key={generateUid()} color={EColor.Red} name="Очистить всё" onClear={onClearAll} />}
    </div>
  );
};
