import React, { FC } from 'react';
import { Breadcrumb } from 'antd';
import { useCatalogContext } from 'common/hooks/useCatalogContext';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { ESearchParams } from 'common/const/searchParams.enum';
import { ReactComponent as ArrowRightShortIcon } from 'app/assets/images/redesign/arrow-right-short.svg';
import { ICategoryDetailed } from 'entities/Category/Category.models';
import { getCatalogBreadcrumbItems } from 'entities/Catalog/Catalog.helper';

interface IComponentProps {
  catalogId?: string | number;
  catalogName?: string;
  category?: ICategoryDetailed | null;
  className?: string;
}

export const CatalogBreadcrumb: FC<IComponentProps> = (props) => {
  const { catalogId, catalogName, category, className } = props;
  const { getSearchParam } = useSearchParamsHook();
  const { onCatalogChange, onCategoryChange } = useCatalogContext();

  const search = getSearchParam(ESearchParams.Search);

  if (!catalogId || !(category || search !== null)) {
    return null;
  }

  return (
    <Breadcrumb
      className={className}
      items={getCatalogBreadcrumbItems(catalogId, onCatalogChange, onCategoryChange, search, catalogName, category)}
      separator={<ArrowRightShortIcon className="icon-arrow-right-short-light-grey" />}
    />
  );
};
