import { CHIP_LIST_LIMIT } from 'common/config';
import { EPresetType } from 'common/const/preset.enum';
import { numberToString, stringToNumber } from 'common/helpers/common.helper';
import { getPresetRangeValue } from 'common/helpers/preset.helper';
import { IFormValue } from 'common/models';
import { ICategory } from 'entities/Category/Category.models';
import { getPropertyPresetType, getPropertyRangeResult } from 'entities/Property/Property.helper';
import { IProperty } from 'entities/Property/Property.models';
import { ISubdivision } from 'entities/Subdivision/Subdivision.models';
import {
  IGroupedWorkspace,
  IWorkspaceCatalogCategory,
  IWorkspaceListGroup,
  IWorkspaceListItem,
  IWorkspacePosition,
  IWorkspacePositionProperty,
} from 'entities/Workspace/Workspace.models';

export const groupWorkspaceList = (list: IWorkspaceListItem[]) => {
  return list.reduce((acc: { subdivisionId: number; subdivisionName: string; dataSource: IWorkspaceListItem[] }[], item) => {
    const { subdivision } = item;
    const existingGroup = acc.find((group) => group.subdivisionId === subdivision?.id);

    if (existingGroup) {
      existingGroup.dataSource.push(item);
    } else {
      acc.push({
        subdivisionId: subdivision?.id,
        subdivisionName: subdivision?.name,
        dataSource: [item],
      });
    }

    return acc;
  }, []);
};

export const sortWorkspaceLowerLevelSubdivisions = (
  subdivisions: ISubdivision[],
  lowerLevelSubdivisions: ISubdivision[],
): ISubdivision[] => {
  const withChildren = lowerLevelSubdivisions.filter((subdivision) => {
    return !!subdivisions.filter((subdivisionItem) => subdivisionItem.parentId === subdivision.id).length;
  });
  const withoutChildren = lowerLevelSubdivisions.filter((subdivision) => {
    return !subdivisions.filter((subdivisionItem) => subdivisionItem.parentId === subdivision.id).length;
  });

  withChildren.sort((a, b) => a.name.localeCompare(b.name));
  withoutChildren.sort((a, b) => a.name.localeCompare(b.name));

  return [...withChildren, ...withoutChildren];
};

export const sortWorkspaceLowerLevelCategories = (
  lowerLevelCategories: IWorkspaceCatalogCategory[],
): IWorkspaceCatalogCategory[] => {
  const withChildren = lowerLevelCategories.filter((category) => !!category.children.length);
  const withoutChildren = lowerLevelCategories.filter((category) => !category.children.length);

  withChildren.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
  withoutChildren.sort((a, b) => a.categoryName.localeCompare(b.categoryName));

  return [...withChildren, ...withoutChildren];
};

export const updateWorkspaceLowerLevelCategories = (
  categories: IWorkspaceCatalogCategory[],
  catalog: IWorkspaceCatalogCategory[],
): IWorkspaceCatalogCategory[] => {
  if (!categories.length) {
    return [];
  }

  const positionsMap = new Map(
    catalog.flatMap(function traverse(item): any {
      return [[item.categoryId, item.positionsCount], ...item.children.flatMap(traverse)];
    }),
  );

  return categories.map((category) => ({
    ...category,
    positionsCount: (positionsMap.get(category.categoryId) as number) ?? category.positionsCount,
  }));
};

export const sortWorkspacePositionLowerLevelCategories = (
  categoryList: ICategory[],
  lowerLevelCategories: ICategory[],
): ICategory[] => {
  const withChildren = lowerLevelCategories.filter((category) => {
    return !!categoryList.filter((categoryItem) => categoryItem.parentId === category.id).length;
  });
  const withoutChildren = lowerLevelCategories.filter((category) => {
    return !categoryList.filter((categoryItem) => categoryItem.parentId === category.id).length;
  });

  return [...withChildren, ...withoutChildren];
};

export const getWorkspacePositionFormInitialValueEntries = (position: IWorkspacePosition) => {
  return position.properties.map((property) => {
    if (property.presetType === EPresetType.SingleValue) {
      return [numberToString(property.propertyId), property.values?.[0]];
    }

    if (property.presetType === EPresetType.Range) {
      return [numberToString(property.propertyId), [{ from: property.range?.from, to: property.range?.to }]];
    }

    return [numberToString(property.propertyId), property.values];
  });
};

export const mapFormValuesToWorkspacePositionPropertyListWithResult = (formValue: IFormValue, propertyList: IProperty[]) => {
  const [key, value] = Object.entries(formValue)[0];

  return propertyList.map((property) => {
    if (property.id === stringToNumber(key)) {
      const { isSingleValue, isMultipleValue, isCheckbox, isRange } = getPropertyPresetType(property);

      if (isSingleValue) {
        return { ...property, result: [value] };
      }

      if (isMultipleValue || isCheckbox) {
        return { ...property, result: value };
      }

      if (isRange) {
        const rangeValue = value[0];

        // eslint-disable-next-line
        if (rangeValue.hasOwnProperty('from')) {
          return { ...property, result: getPropertyRangeResult(rangeValue.from, property.result?.[1]) };
        }

        // eslint-disable-next-line
        if (rangeValue.hasOwnProperty('to')) {
          return { ...property, result: getPropertyRangeResult(property.result?.[0], rangeValue.to) };
        }

        return { ...property, result: [] };
      }
    }

    return property;
  });
};

export const filterGroupedWorkspaceList = (
  groupedWorkspaceList: IWorkspaceListGroup[],
  search: string,
  selectedSubdivisionId?: number,
) => {
  return groupedWorkspaceList
    .map((group) => ({
      ...group,
      dataSource: group.dataSource.filter((workspace) => workspace.name.toLowerCase().includes(search.toLowerCase())),
    }))
    .filter((group) => !!group.dataSource.length)
    .filter((group) => (selectedSubdivisionId ? group.subdivisionId === selectedSubdivisionId : true));
};

const findSelectedWorkspaceCategoryChildrenIds = (categories: IWorkspaceCatalogCategory[], targetId?: number): number[] => {
  for (const category of categories) {
    if (category.categoryId === targetId) {
      return [
        category.categoryId,
        ...category.children.flatMap((item) => findSelectedWorkspaceCategoryChildrenIds([item], item.categoryId)),
      ];
    }
    const ids = findSelectedWorkspaceCategoryChildrenIds(category.children, targetId);

    if (ids.length) {
      return ids;
    }
  }

  return [];
};

export const filterWorkspacePositions = (search: string, workspace: IGroupedWorkspace | null, selectedCategoryId?: number) => {
  if (!workspace) {
    return [];
  }

  const ids = findSelectedWorkspaceCategoryChildrenIds(workspace.catalog, selectedCategoryId);

  return workspace.categories
    ?.map((category) => {
      return {
        ...category,
        positions: category.positions.filter((position) => {
          return position.categoryName.toLowerCase().includes(search.toLowerCase());
        }),
      };
    })
    .filter((category) => !!category.positions.length)
    .filter((category) => (selectedCategoryId ? ids.includes(category.categoryId) : true));
};

export const increaseGroupedWorkspaceCatalogPositionsCountByCategoryId = (
  catalog: IWorkspaceCatalogCategory[],
  categoryId: number,
): IWorkspaceCatalogCategory[] => {
  return catalog.map((item) => ({
    ...item,
    positionsCount: item.categoryId === categoryId ? item.positionsCount + 1 : item.positionsCount,
    children: increaseGroupedWorkspaceCatalogPositionsCountByCategoryId(item.children, categoryId),
  }));
};

export const decreaseGroupedWorkspaceCatalogPositionsCountByCategoryId = (
  catalog: IWorkspaceCatalogCategory[],
  categoryId: number,
): IWorkspaceCatalogCategory[] => {
  return catalog.map((item) => ({
    ...item,
    positionsCount: item.categoryId === categoryId ? item.positionsCount - 1 : item.positionsCount,
    children: decreaseGroupedWorkspaceCatalogPositionsCountByCategoryId(item.children, categoryId),
  }));
};

export const getWorkspacePositionName = (position: IWorkspacePosition) => {
  let name = `${position.categoryName}`;

  position.properties.forEach(({ isCategory, propertyName, presetType, values, range, unitOfMeasurement }) => {
    const unit = unitOfMeasurement ? ` ${unitOfMeasurement}` : '';
    const isSingleValue = presetType === EPresetType.SingleValue;
    const isRange = presetType === EPresetType.Range;
    const isCheckbox = presetType === EPresetType.Checkbox;

    if (isCategory || (isCheckbox && values?.every((value) => value.toLowerCase() === 'нет'))) {
      return;
    }

    if (isSingleValue) {
      name += `, ${values?.[0]}${unit}`;
    } else if (isRange) {
      name += `, ${getPresetRangeValue(range)}${unit}`;
    } else if (isCheckbox) {
      if (values?.some((value) => value.toLowerCase() === 'да')) {
        name += `, ${propertyName}`;
      }
    } else {
      name += `, ${values?.join(', ')}${unit}`;
    }
  });

  return name;
};

export const showWorkspacePositionChipsClearAllBtn = (propertyList: IProperty[]) => {
  return (
    propertyList.filter(({ isCategory, result }) => !isCategory && !!result?.length).length > CHIP_LIST_LIMIT ||
    // @ts-ignore
    propertyList.some(({ isCategory, result }) => !isCategory && result?.length > CHIP_LIST_LIMIT)
  );
};

export const mapNewWorkspacePositionPropertyListToChips = (propertyList: IProperty[]) => {
  return propertyList.map((property) => {
    const { isSingleValue, isMultipleValue, isCheckbox, isRange } = getPropertyPresetType(property);
    const { id, isCategory, displayName, isRequiredForPresets, result, unitOfMeasurement } = property;
    const unit = unitOfMeasurement ? unitOfMeasurement : '';

    if (isCategory || !result?.length) {
      return null;
    }

    if (isSingleValue) {
      const value = result?.[0];

      return { id, name: displayName, value: value, unit, isRequiredForPresets };
    }

    if (isMultipleValue || isCheckbox) {
      return { id, name: displayName, value: result.join(', '), unit, isRequiredForPresets };
    }

    if (isRange) {
      const from = result[0];
      const to = result[1];
      let value = '';

      if (from) {
        value += `от ${from}`;
      }

      if (to) {
        value += ` до ${to}`;
      }

      return { id, name: displayName, value: value, unit, isRequiredForPresets };
    }

    return null;
  });
};

export const mapWorkspacePositionPropertyListToChips = (propertyList: IWorkspacePositionProperty[]) => {
  return propertyList.map((property) => {
    const { propertyId, propertyName, isCategory, presetType, isRequiredForPresets, unitOfMeasurement, values, range } = property;
    const isSingleValue = presetType === EPresetType.SingleValue;
    const isMultipleValue = presetType === EPresetType.MultipleValue;
    const isRange = presetType === EPresetType.Range;
    const isCheckbox = presetType === EPresetType.Checkbox;
    const unit = unitOfMeasurement ? unitOfMeasurement : '';

    if (isCategory) {
      return null;
    }

    if (isSingleValue) {
      return { id: propertyId, name: propertyName, value: `${values?.[0]} ${unit}`, isRequiredForPresets };
    }

    if (isMultipleValue || isCheckbox) {
      return { id: propertyId, name: propertyName, value: `${values?.join(', ')} ${unit}`, isRequiredForPresets };
    }

    if (isRange) {
      return { id: propertyId, name: propertyName, value: `${getPresetRangeValue(range)} ${unit}`, isRequiredForPresets };
    }

    return null;
  });
};

export const getWorkspacePositionRequiredPropertiesForView = (propertyList: IProperty[]) => {
  return propertyList.filter((property) => !property.isCategory && !property.hidden && !!property.isRequiredForPresets);
};

export const getWorkspacePositionOptionalPropertiesForView = (propertyList: IProperty[]) => {
  return propertyList.filter((property) => !property.isCategory && !property.hidden && !property.isRequiredForPresets);
};
