import { IAuthState, IToken } from 'entities/Auth/Auth.models';

export const authStateReducers = {
  setAuth: (state: IAuthState, payload: IToken | null) => ({ ...state, data: payload }),
  setLoading: (state: IAuthState, payload: boolean) => ({ ...state, loading: payload }),
  setError: (state: IAuthState, payload: string | null) => ({ ...state, error: payload }),
  setSuperAdminAsUser: (state: IAuthState, payload: boolean) => ({ ...state, superAdminAsUser: payload }),
  setUserCatalogId: (state: IAuthState, payload: number | null) => ({ ...state, userCatalogId: payload }),
  setXBasketId: (state: IAuthState, payload: number | null) => ({ ...state, xBasketId: payload }),
};
