import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { DRAWER_Z_INDEX_10000 } from 'common/config';

interface IComponentProps {
  open: boolean;
  subtitle: string;
  content: string;
  btnTitle?: string;
  onClose: () => void;
  onConfirm?: () => void;
}

export const SuccessCard: FC<IComponentProps> = ({ open, subtitle, content, btnTitle, onClose, onConfirm }) => {
  return (
    <Drawer
      rootClassName="redesign drawer success-card"
      open={open}
      onClose={onClose}
      width={720}
      destroyOnClose
      zIndex={DRAWER_Z_INDEX_10000}
    >
      <div className="drawer__body">
        <span className="text-h3 color-white-40 success-card__subtitle">{subtitle}</span>

        <div className="drawer__title color-white">{content}</div>
      </div>

      {onConfirm && (
        <div className="drawer__footer">
          <Button className="button-lg green" onClick={onConfirm}>
            {btnTitle}
          </Button>
        </div>
      )}
    </Drawer>
  );
};
