import { IBuyerCityListState, IDelivery, IDeliveryListState, IDeliveryState } from 'entities/Delivery/Delivery.models';

export const buyerCityListStateReducers = {
  setList: (state: IBuyerCityListState, payload: string[]) => ({ ...state, data: payload }),
  setLoading: (state: IBuyerCityListState, payload: boolean) => ({ ...state, loading: payload }),
};

export const deliveryListStateReducers = {
  setList: (state: IDeliveryListState, payload: IDelivery[]) => ({ ...state, data: payload }),
  setLoading: (state: IDeliveryListState, payload: boolean) => ({ ...state, loading: payload }),
  addDelivery: (state: IDeliveryListState, payload: IDelivery) => ({ ...state, data: [...state.data, payload] }),
  updateDelivery: (state: IDeliveryListState, payload: IDelivery) => ({
    ...state,
    data: state.data.map((stockCity) => (stockCity.id === payload.id ? payload : stockCity)),
  }),
  deleteDelivery: (state: IDeliveryListState, payload: number) => ({
    ...state,
    data: state.data.filter((stockCity) => stockCity.id !== payload),
  }),
};

export const deliveryStateReducers = {
  setLoading: (state: IDeliveryState, payload: boolean) => ({ ...state, loading: payload }),
};
