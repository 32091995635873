import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { ESettingsMenuTab, ESettingsTab } from 'common/const/settings.enum';
import { ERoute } from 'common/const/route.enum';
import { ReactComponent as SettingsIcon } from 'app/assets/images/redesign/settings.svg';
import { RootState } from 'app/store';
import { SettingsSidebarSubdivisionTree } from 'entities/Settings/components/SettingsSidebarSubdivisionTree';
import { SettingsSidebarBackBtn } from 'entities/Settings/components/SettingsSidebarBackBtn';
import { SettingsSidebarContractList } from 'entities/Settings/components/SettingsSidebarContractList';

interface IComponentProps {
  isSeller?: boolean;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const Component: FC<AllType> = ({ isSeller, isSidebarOpen }) => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const isAccountSettings = tab === ESettingsTab.Account;

  return (
    <div className={`settings-sidebar ${isSidebarOpen ? 'collapsed' : ''}`}>
      <InfiniteScrollContainer>
        <div className="mb-40">
          <SettingsSidebarBackBtn />
        </div>

        <div
          style={{ cursor: 'pointer' }}
          className={`settings-sidebar__btn ${isAccountSettings ? 'active' : ''}`}
          onClick={() =>
            navigate(
              `${ERoute.Settings}/${ESettingsTab.Account}/${isSeller ? ESettingsMenuTab.UserList : ESettingsMenuTab.Contracts}`,
            )
          }
        >
          <div className="settings-sidebar__btn-container">
            <SettingsIcon className="icon-settings-dark-grey" />

            <span className="text-h4-item-name color-black settings-sidebar__btn-text">Настройки аккаунта</span>
          </div>

          <span className="text-tag color-dark-grey settings-sidebar__btn-text">
            {isSeller ? 'Склады отгрузки, скидки и другие настройки' : 'Контракты, пользователи и другие настройки'}
          </span>
        </div>

        {isSeller ? (
          <SettingsSidebarContractList active={!isAccountSettings} />
        ) : (
          <SettingsSidebarSubdivisionTree active={!isAccountSettings} />
        )}
      </InfiniteScrollContainer>
    </div>
  );
};

const mapState = (state: RootState) => ({
  isSidebarOpen: state.UIState.isSidebarOpen,
});

export const SettingsSidebar = connect(mapState)(Component);
