export enum EScrollDirection {
  UP = 'up',
  DOWN = 'down',
}

export enum EOrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum EOrderField {
  CreatedAt = 'createdAt',
  DeliveryDate = 'deliveryDate',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  Name = 'name',
  LastName = 'lastName',
}

export enum EMode {
  Create = 'create',
  Edit = 'edit',
  Copy = 'copy',
}
