import { ISubdivisionShort, IUserShort } from 'common/models';
import {
  GroupedSupplyListStateDataItemType,
  IGroupedByDeliveryDateSupply,
  IGroupedSupplyListState,
  ISupply,
  ISupplyAddress,
  ISupplyLegal,
  ISupplyListState,
  ISupplyState,
} from 'entities/Supply/Supply.models';
import { sortGroupedSupplyListByDeliveryDate } from 'entities/Supply/Supply.helper';

export const supplyListStateReducers = {
  setList: (state: ISupplyListState, payload: ISupply[]) => ({ ...state, data: payload }),
  updateList: (state: ISupplyListState, payload: ISupply[]) => {
    return {
      ...state,
      data: state.data.map((supply) => {
        const item = payload.find((supplyItem) => supplyItem.id === supply.id);

        return item ? item : supply;
      }),
    };
  },
  setListPart: (state: ISupplyListState, payload: ISupply[]) => ({ ...state, data: [...state.data, ...payload] }),
  setCount: (state: ISupplyListState, payload: number) => ({ ...state, count: payload }),
  setLoading: (state: ISupplyListState, payload: boolean) => ({ ...state, loading: payload }),
  setUserList: (state: ISupplyListState, payload: IUserShort[]) => ({ ...state, userList: payload }),
  setSubdivisionList: (state: ISupplyListState, payload: ISubdivisionShort[]) => ({
    ...state,
    subdivisionList: payload,
  }),
  setLegalList: (state: ISupplyListState, payload: ISupplyLegal[]) => ({ ...state, legalList: payload }),
  setAddressList: (state: ISupplyListState, payload: ISupplyAddress[]) => ({ ...state, addressList: payload }),
  addSupply: (state: ISupplyListState, payload: ISupply) => {
    return {
      ...state,
      data: [...state.data, payload],
    };
  },
  deleteSupply: (state: ISupplyListState, payload: number) => {
    return {
      ...state,
      data: state.data.filter((supply) => supply.id !== payload),
    };
  },
};

export const groupedSupplyListStateReducers = {
  setList: (state: IGroupedSupplyListState, payload: GroupedSupplyListStateDataItemType[]) => {
    return { ...state, data: payload };
  },
  updateList: (state: IGroupedSupplyListState, payload: ISupply[]) => {
    return {
      ...state,
      data: state.data.map((group) => {
        return {
          ...group,
          supplies: group.supplies.map((supply) => {
            const newSupply = payload.find((payloadSupply) => payloadSupply.id === supply.id);

            return newSupply ? newSupply : supply;
          }),
        };
      }),
    };
  },
  filterList: (state: IGroupedSupplyListState, payload: number[]) => {
    return {
      ...state,
      data: state.data
        .map((group) => {
          return {
            ...group,
            supplies: group.supplies.filter((supply) => !payload.includes(supply.id)),
          };
        })
        .filter((group) => !!group.supplies.length),
    };
  },
  setListPart: (state: IGroupedSupplyListState, payload: GroupedSupplyListStateDataItemType[]) => {
    return { ...state, data: [...state.data, ...payload] };
  },
  setCount: (state: IGroupedSupplyListState, payload: number) => ({ ...state, count: payload }),
  setLoading: (state: IGroupedSupplyListState, payload: boolean) => ({ ...state, loading: payload }),
  updateDeliveryDate: (state: IGroupedSupplyListState, payload: ISupply[]) => {
    const payloadIds = payload.map((item) => item.id);
    const filteredState = state.data
      .map((group) => {
        return {
          ...group,
          supplies: group.supplies.filter((supply) => !payloadIds.includes(supply.id)),
        };
      })
      .filter((group) => !!group.supplies.length);

    const stateMap = new Map<string | null, GroupedSupplyListStateDataItemType>(
      filteredState.map((group) => [(group as IGroupedByDeliveryDateSupply).deliveryDate, group]),
    );

    payload.forEach((supply) => {
      const { deliveryDate } = supply;

      if (!stateMap.has(deliveryDate)) {
        const newGroup = { deliveryDate, supplies: [] };

        filteredState.push(newGroup);
        stateMap.set(deliveryDate, newGroup);
      }

      stateMap.get(deliveryDate)?.supplies.push(supply);
    });

    return { ...state, data: sortGroupedSupplyListByDeliveryDate(filteredState as IGroupedByDeliveryDateSupply[]) };
  },
  addSupply: (state: IGroupedSupplyListState, payload: ISupply) => {
    const groupExist = state.data.find((group) => (group as IGroupedByDeliveryDateSupply).deliveryDate === payload.deliveryDate);

    return {
      ...state,
      data: groupExist
        ? state.data.map((group) => {
            return (group as IGroupedByDeliveryDateSupply).deliveryDate === payload.deliveryDate
              ? { ...group, supplies: [...group.supplies, payload] }
              : group;
          })
        : [...state.data, { deliveryDate: payload.deliveryDate, supplies: [payload] }],
    };
  },
};

export const supplyStateReducers = {
  setSupply: (state: ISupplyState, payload: ISupply | null) => ({ ...state, data: payload }),
  setLoading: (state: ISupplyState, payload: boolean) => ({ ...state, loading: payload }),
};
