import React, { FC, ReactNode } from 'react';
import { EColor } from 'common/const/color.enum';

interface IComponentProps {
  content: ReactNode;
  color: EColor;
}

export const IconBackground: FC<IComponentProps> = ({ content, color }) => {
  return <div className={`icon-background ${color}`}>{content}</div>;
};
