import { IBrandDiscount, IBrandDiscountState, IBrandDiscountListState } from 'entities/Discount/Discount.models';

export const brandDiscountListStateReducers = {
  setList: (state: IBrandDiscountListState, payload: IBrandDiscount[]) => ({ ...state, data: payload }),
  setLoading: (state: IBrandDiscountListState, payload: boolean) => ({ ...state, loading: payload }),
  addDiscount: (state: IBrandDiscountListState, payload: IBrandDiscount) => ({ ...state, data: [...state.data, payload] }),
  updateDiscount: (state: IBrandDiscountListState, payload: IBrandDiscount) => {
    return {
      ...state,
      data: state.data.map((discount) => (discount.id === payload.id ? payload : discount)),
    };
  },
  deleteDiscount: (state: IBrandDiscountListState, payload: number) => ({
    ...state,
    data: state.data.filter((discount) => discount.id !== payload),
  }),
};

export const brandDiscountStateReducers = {
  setBrandDiscount: (state: IBrandDiscountState, payload: IBrandDiscount) => ({ ...state, data: payload }),
  setLoading: (state: IBrandDiscountState, payload: boolean) => ({ ...state, loading: payload }),
};
