import { createModel } from '@rematch/core';
import { DEFAULT_VALUE_0 } from 'common/config';
import { IRootModel } from 'app/store';
import { IBuyerListState } from 'entities/Buyer/Buyer.models';
import { buyerListStateReducers } from 'entities/Buyer/Buyer.reducers';
import { buyerListStateEffects } from 'entities/Buyer/Buyer.effects';

export const buyerListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: DEFAULT_VALUE_0,
    loading: false,
  } as IBuyerListState,
  reducers: buyerListStateReducers,
  effects: buyerListStateEffects,
});
