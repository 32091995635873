import React, { FC, useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ERequestsNavigationTab } from 'common/const/request.enum';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { TabNavigation } from 'common/components/TabNavigation';
import { ERoute } from 'common/const/route.enum';
import { EStorageKey } from 'common/const/axios.enum';
import { getStorageItem } from 'common/helpers/axios.helper';
import { RootDispatch } from 'app/store';
import { IncomingRequestsForSeller } from 'entities/Requests/components/IncomingRequestsForSeller';
import { SupplyListForSeller } from 'entities/Supply/components/Seller/SupplyListForSeller';

type AllType = ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ getCurrentBasket }) => {
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const { tab } = useParams();

  const isSupplyList = tab === ERequestsNavigationTab.SupplyList;
  const header = <div className="text-body color-dark-grey need-list__subtitle">Заявки на закупку</div>;
  const navigation = useMemo(() => {
    return (
      <TabNavigation
        items={[
          { label: 'Входящие заказы', path: ERoute.RequestsForSeller },
          { label: 'Поставки', path: `${ERoute.RequestsForSeller}/${ERequestsNavigationTab.SupplyList}` },
        ]}
      />
    );
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const xBasketId = getStorageItem<number>(EStorageKey.XBasketId);

      if (xBasketId) {
        await getCurrentBasket(xBasketId);
      }

      setFetchLoading(false);
    };

    fetch();
  }, []);

  if (fetchLoading) {
    return <Spin indicator={<SpinIndicator />} />;
  }

  return isSupplyList ? (
    <SupplyListForSeller header={header} navigation={navigation} />
  ) : (
    <IncomingRequestsForSeller header={header} navigation={navigation} />
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  getCurrentBasket: dispatch.basketState.getCurrentBasket,
});

export const RequestsForSeller = connect(null, mapDispatch)(Component);
