import React, { FC, useEffect, useState } from 'react';
import { AutoComplete, Button, Drawer, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { WarningCard } from 'common/components/WarningCard';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { EMode } from 'common/const/common.enum';
import { debounce } from 'common/helpers/common.helper';
import { rules } from 'common/helpers/form.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { SELECT_LIST_HEIGHT_320 } from 'common/config';
import { IFormValue } from 'common/models';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { RootDispatch, RootState } from 'app/store';
import { getAddressSuggestionOptions } from 'entities/Address/Address.helper';
import { IStockUpdatePayload } from 'entities/Stock/Stock.models';

interface IComponentProps {
  open: boolean;
  mode: EMode;
  onOpenChange: (value: boolean) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    open,
    mode,
    onOpenChange,
    // state
    addressSuggestionList,
    stock,
    stockLoading,
    // dispatch
    setAddressSuggestionList,
    getAddressSuggestionList,
    createStock,
    updateStock,
  } = props;

  const [payload, setPayload] = useState<IStockUpdatePayload | null>(null);
  const [formNotSaved, setFormNotSaved] = useState<boolean>(false);
  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const [form] = useForm();

  const isEditMode = mode === EMode.Edit;
  const addressSuggestionOptions = getAddressSuggestionOptions(addressSuggestionList);

  const debouncedSearch = debounce((value: string) => getAddressSuggestionList({ name: value }));

  const onAddressSelect = (value: string) => {
    const address = addressSuggestionList.find((item) => item.name === value);

    if (address) {
      setFormNotSaved(true);
      form.setFieldsValue({
        address: address.name,
        city: address.city,
      });
    }
  };

  const onFormValuesChange = (value: IFormValue) => {
    setFormNotSaved(true);
    setPayload((prev) => (prev ? { ...prev, ...value } : null));
  };

  const onClose = () => {
    setAddressSuggestionList([]);
    setOpenWarningCard(false);
    setFormNotSaved(false);
    onOpenChange(false);
    form.resetFields();
  };

  const handleClose = () => {
    if (stockLoading) {
      return;
    }

    if (formNotSaved) {
      onOpenChange(false);
      setOpenWarningCard(true);
      return;
    }

    onClose();
  };

  const onCreateSubmit = ({ name, address, city }: IFormValue) => {
    createStock({
      name,
      displayName: name,
      address: address.replaceAll('\n', ' '),
      city,
      onSuccess: () => {
        showSuccessMessage('Новый склад добавлен.');
        onClose();
      },
    });
  };

  const onEditSubmit = () => {
    if (payload) {
      updateStock({
        ...payload,
        address: payload.address?.replaceAll('\n', ' '),
        onSuccess: () => {
          showSuccessMessage('Склад успешно изменен.');
          onClose();
        },
      });
    }
  };

  useEffect(() => {
    if (open && isEditMode && stock) {
      setPayload({ id: stock.id });
      form.setFieldsValue({
        name: stock.name,
        address: stock.address,
        city: stock.city,
      });
    }
  }, [open, isEditMode, stock]);

  return (
    <>
      <Drawer
        rootClassName="redesign drawer stock-create-card"
        destroyOnClose
        width={720}
        open={open}
        onClose={handleClose}
        footer={
          <Button className="button-lg primary" onClick={form.submit} loading={stockLoading}>
            {isEditMode ? 'Сохранить изменения' : 'Добавить склад'}
          </Button>
        }
      >
        <div className="drawer__body">
          <div className="drawer__title mb-52">{isEditMode ? 'Редактировать склад' : 'Новый склад отгрузки'}</div>

          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onValuesChange={onFormValuesChange}
            onFinish={isEditMode ? onEditSubmit : onCreateSubmit}
          >
            <Form.Item className="mb-32" label="Название склада*" name="name" rules={[rules.required()]}>
              <Input
                placeholder={EPlaceholder.EnterName}
                allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
              />
            </Form.Item>

            <div className="input-with-label mb-32">
              <AutoComplete
                className="redesign autosize-autocomplete"
                rootClassName="redesign"
                popupClassName="redesign"
                virtual={false}
                listHeight={SELECT_LIST_HEIGHT_320}
                allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
                onChange={debouncedSearch}
                options={addressSuggestionOptions}
                onSelect={onAddressSelect}
              >
                <Input.TextArea placeholder={EPlaceholder.StartTypingAddress} autoSize />
              </AutoComplete>

              <div className="text-tag mb-8 input-label">Автоматический поиск</div>
            </div>

            <Form.Item className="mb-32" label="Адрес*" name="address" rules={[rules.required()]}>
              <Input.TextArea
                className="autosize-input"
                placeholder={EPlaceholder.SpecifyAddress}
                autoSize
                allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
              />
            </Form.Item>

            <Form.Item className="mb-32" label="Город*" name="city" rules={[rules.required()]}>
              <Input
                placeholder={EPlaceholder.SpecifyCity}
                allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
              />
            </Form.Item>
          </Form>
        </div>
      </Drawer>

      <WarningCard
        open={openWarningCard}
        content="Вы уверены, что не хотите сохранить изменения?"
        subtitle="Введенные данные будут потеряны."
        confirmBtnTitle="Не сохранять"
        onConfirm={onClose}
        onClose={() => {
          setOpenWarningCard(false);
          onOpenChange(true);
        }}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  addressSuggestionList: state.addressSuggestionListState.data,
  stock: state.stockState.data,
  stockLoading: state.stockState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setAddressSuggestionList: dispatch.addressSuggestionListState.setList,
  getAddressSuggestionList: dispatch.addressSuggestionListState.getAddressSuggestionList,
  createStock: dispatch.stockState.createStock,
  updateStock: dispatch.stockState.updateStock,
});

export const StockCreateCard = connect(mapState, mapDispatch)(Component);
