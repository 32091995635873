import axios from 'axios';
import { ISubdivisionsShortCollection } from 'common/models';
import {
  IBasketCollectionDto,
  IBasketListParams,
  IBasket,
  IBasketRequestCreatePayload,
  IBasketUpdatePayload,
} from 'entities/Basket/Basket.models';

const basePath = 'basket';

export const basketTransport = {
  getBasketById: (id: number): Promise<IBasket> => axios.get(`${basePath}/${id}`),
  updateBasket: (payload: IBasketUpdatePayload): Promise<IBasket> => axios.patch(`${basePath}`, payload),
  createBasketRequest: (payload: IBasketRequestCreatePayload): Promise<IBasket> => axios.post(`${basePath}/request`, payload),
  getBasketList: (params: IBasketListParams): Promise<IBasketCollectionDto> => axios.get(`${basePath}`, { params }),
  uploadBasketExcel: (id: number): Promise<ArrayBuffer> => axios.get(`${basePath}/file/${id}`, { responseType: 'arraybuffer' }),
  getBasketListSubdivisions: (): Promise<ISubdivisionsShortCollection> => axios.get(`${basePath}/subdivisions`),
};
