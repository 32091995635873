import { createModel } from '@rematch/core';
import { DEFAULT_VALUE_0 } from 'common/config';
import { IRootModel } from 'app/store';
import { ILegalEntityListState, ILegalEntityState } from 'entities/LegalEntity/LegalEntity.models';
import { legalEntityListStateReducers, legalEntityStateReducers } from 'entities/LegalEntity/LegalEntity.reducers';
import { legalEntityListStateEffects, legalEntityStateEffects } from 'entities/LegalEntity/LegalEntity.effects';

export const legalEntityListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: DEFAULT_VALUE_0,
    loading: false,
  } as ILegalEntityListState,
  reducers: legalEntityListStateReducers,
  effects: legalEntityListStateEffects,
});

export const legalEntityState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    error: null,
  } as ILegalEntityState,
  reducers: legalEntityStateReducers,
  effects: legalEntityStateEffects,
});
