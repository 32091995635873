import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { WarningCard } from 'common/components/WarningCard';
import { EMode } from 'common/const/common.enum';
import { showMessage } from 'common/helpers/message.helper';
import { useLegalEntityContext } from 'common/hooks/useLegalEntityContext';
import { LIST_LIMIT_0 } from 'common/config';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { LegalEntityCard } from 'entities/LegalEntity/components/LegalEntityCard';
import { EditLegalEntityCard } from 'entities/LegalEntity/components/EditLegalEntityCard';
import { ILegalEntity } from 'entities/LegalEntity/LegalEntity.models';
import { renderLegalEntityListSettingsRecords } from 'entities/Settings/Settings.helper';

interface IComponentProps {
  header: ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    // state
    legalEntityList,
    legalEntityListLoading,
    legalEntityLoading,
    // dispatch
    getLegalEntityList,
    getLegalEntityById,
    deleteLegalEntity,
  } = props;

  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const [selectedLegalEntity, setSelectedLegalEntity] = useState<ILegalEntity | null>(null);
  const { setEditLegalEntityCardMode, setOpenLegalEntityCard, setOpenEditLegalEntityCard } = useLegalEntityContext();

  const onCreateLegalEntityClick = async () => {
    setEditLegalEntityCardMode(EMode.Create);
    setOpenEditLegalEntityCard(true);
  };

  const onEditLegalEntityClick = async (id: number) => {
    getLegalEntityById({
      id,
      onSuccess: () => {
        setEditLegalEntityCardMode(EMode.Edit);
        setOpenEditLegalEntityCard(true);
      },
    });
  };

  const onDeleteLegalEntityClick = (record: ILegalEntity) => {
    setSelectedLegalEntity(record);
    setOpenWarningCard(true);
  };

  const onDeleteLegalEntityConfirmClick = () => {
    if (selectedLegalEntity) {
      deleteLegalEntity({
        id: selectedLegalEntity.id,
        onSuccess: () => {
          showMessage({
            content: `Юридическое лицо «${selectedLegalEntity.name}» удалено.`,
            icon: <BinIcon className="icon-bin-dark-grey" />,
          });
          setOpenWarningCard(false);
        },
      });
    }
  };

  useEffect(() => {
    getLegalEntityList({ limit: LIST_LIMIT_0 });
  }, []);

  return (
    <>
      <InfiniteScrollContainer>
        {header}

        <div className="mb-20">
          <Button
            className="button-sm primary"
            icon={<PlusIcon className="icon-plus-white" />}
            onClick={onCreateLegalEntityClick}
          >
            Добавить новое
          </Button>
        </div>

        <Table
          className={`table-hover ${!legalEntityList.length && 'table-empty'} mb-72 account-legal-entity-list-settings__table`}
          showHeader={false}
          pagination={false}
          size="middle"
          dataSource={legalEntityList.map((legalEntity) => ({ ...legalEntity, key: legalEntity.id }))}
          columns={renderLegalEntityListSettingsRecords(onEditLegalEntityClick, onDeleteLegalEntityClick)}
          locale={{ emptyText: <div className="text-body color-dark-grey">Юридические лица ещё не указаны.</div> }}
          loading={{ spinning: legalEntityListLoading || legalEntityLoading, indicator: <SpinIndicator /> }}
          onRow={({ id }) => ({
            onClick: () => {
              getLegalEntityById({
                id,
                onSuccess: () => setOpenLegalEntityCard(true),
              });
            },
          })}
        />
      </InfiniteScrollContainer>

      <LegalEntityCard />

      <EditLegalEntityCard />

      <WarningCard
        open={openWarningCard}
        content="Вы уверены, что хотите удалить юр. лицо?"
        subtitle="Юридическое лицо будет отвязано от подразделений и корзин пользователей."
        confirmBtnTitle="Удалить"
        loading={legalEntityLoading}
        onConfirm={onDeleteLegalEntityConfirmClick}
        onClose={() => setOpenWarningCard(false)}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  legalEntityList: state.legalEntityListState.data,
  legalEntityListLoading: state.legalEntityListState.loading,
  legalEntityLoading: state.legalEntityState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getLegalEntityList: dispatch.legalEntityListState.getLegalEntityList,
  getLegalEntityById: dispatch.legalEntityState.getLegalEntityById,
  deleteLegalEntity: dispatch.legalEntityState.deleteLegalEntity,
});

export const AccountLegalEntityListSettings = connect(mapState, mapDispatch)(Component);
