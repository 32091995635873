/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useState } from 'react';
import { EMode } from 'common/const/common.enum';

export interface ILegalEntityContext {
  editLegalEntityCardMode: EMode;
  setEditLegalEntityCardMode: (value: EMode) => void;
  openLegalEntityCard: boolean;
  setOpenLegalEntityCard: (value: boolean) => void;
  openEditLegalEntityCard: boolean;
  setOpenEditLegalEntityCard: (value: boolean) => void;
}

export const LegalEntityContext = createContext<ILegalEntityContext>({
  editLegalEntityCardMode: EMode.Create,
  setEditLegalEntityCardMode: () => {},
  openLegalEntityCard: false,
  setOpenLegalEntityCard: () => {},
  openEditLegalEntityCard: false,
  setOpenEditLegalEntityCard: () => {},
});

export const useLegalEntityContext = () => useContext(LegalEntityContext);

export interface IComponentProps {
  children: React.ReactNode;
}

export const LegalEntityProvider: FC<IComponentProps> = ({ children }) => {
  const [editLegalEntityCardMode, setEditLegalEntityCardMode] = useState<EMode>(EMode.Create);
  const [openLegalEntityCard, setOpenLegalEntityCard] = useState<boolean>(false);
  const [openEditLegalEntityCard, setOpenEditLegalEntityCard] = useState<boolean>(false);

  return (
    <LegalEntityContext.Provider
      value={{
        editLegalEntityCardMode,
        setEditLegalEntityCardMode,
        openLegalEntityCard,
        setOpenLegalEntityCard,
        openEditLegalEntityCard,
        setOpenEditLegalEntityCard,
      }}
    >
      {children}
    </LegalEntityContext.Provider>
  );
};
