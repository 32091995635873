import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Col, Form, Input, InputNumber, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { EAccountTariffPlanTitle } from 'common/const/settings.enum';
import { IFormValue } from 'common/models';
import { rules } from 'common/helpers/form.helper';
import { debounce } from 'common/helpers/common.helper';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { RootDispatch, RootState } from 'app/store';
import { IAccountUpdatePayload } from 'entities/Account/Account.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const AccountSettingsComponent: React.FC<AllType> = ({ authState, currentUser, accountState, getAccountById, updateAccount }) => {
  const { data: auth } = authState;
  const { data: account } = accountState;

  const [form] = useForm();

  const isSeller = auth?.access.isSeller;
  const accountId = currentUser?.accountId;

  const debouncedUpdateAccount = debounce((payload: IAccountUpdatePayload) => {
    updateAccount(payload);
  });

  const onFormValueChange = (values: IFormValue) => {
    if (account) {
      debouncedUpdateAccount({ id: account.id, ...values });
    }
  };

  useEffect(() => {
    if (account) {
      const { name, tariffPlan, priceRounding, maxGoodPrice } = account;

      form.setFieldsValue({
        name,
        tariffPlan: EAccountTariffPlanTitle[tariffPlan],
        priceRounding,
        maxGoodPrice,
      });
    }
  }, [account]);

  const { contentLoading } = useContentLoader(async () => {
    if (accountId) {
      await getAccountById(accountId);
    }
  }, [accountId]);

  if (contentLoading) {
    return <ContentLoader />;
  }

  return (
    <Row className="account-settings" style={{ padding: '0px 40px' }}>
      <Col xs={24} xl={17}>
        <Form layout="vertical" form={form} requiredMark={false} onValuesChange={onFormValueChange}>
          <Form.Item label="Название аккаунта" name="name" rules={[rules.required()]}>
            <Input placeholder="Введите название аккаунта" />
          </Form.Item>

          <Col xs={24} lg={10}>
            <Form.Item label="Тариф" name="tariffPlan">
              <Input disabled />
            </Form.Item>
          </Col>

          {!isSeller && (
            <>
              <Col xs={24} lg={10}>
                <Form.Item label="Лимит на цену товара" name="maxGoodPrice">
                  <InputNumber controls={false} />
                </Form.Item>
              </Col>

              <div className="account-settings__table-item">
                <Form.Item name="priceRounding" valuePropName="checked">
                  <Checkbox>Округлять итоговую сумму спецификации до рублей</Checkbox>
                </Form.Item>
              </div>
            </>
          )}
        </Form>
      </Col>
    </Row>
  );
};

const mapState = (state: RootState) => ({
  authState: state.authState,
  currentUser: state.userState.currentUser,
  accountState: state.accountState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getAccountById: dispatch.accountState.getAccountById,
  updateAccount: dispatch.accountState.updateAccount,
});

export const AccountSettings = connect(mapState, mapDispatch)(AccountSettingsComponent);
