import axios from 'axios';
import { ISubdivisionsShortCollection, IUserShortCollection } from 'common/models';
import {
  IInventoryAction,
  IInventoryActionCollectionDto,
  IInventoryActionCreatePayload,
  IInventoryActionDeletePayload,
  IInventoryActionListPayload,
  IInventoryActionListSubdivisionsParams,
  IInventoryActionUpdatePayload,
  IInventoryActionListUsersParams,
  IInventoryCollectionDto,
  IInventoryDetailed,
  IInventoryListPayload,
  IInventoryListSubdivisionsParams,
  IInventoryListUsersParams,
} from 'entities/Inventory/Inventory.models';

const basePath = 'inventory';

export const inventoryTransport = {
  getInventoryList: (payload: IInventoryListPayload): Promise<IInventoryCollectionDto> => axios.post(`${basePath}/list`, payload),
  getInventoryListSubdivisions: (params: IInventoryListSubdivisionsParams): Promise<ISubdivisionsShortCollection> =>
    axios.get(`inventory/subdivisions`, { params }),
  getInventoryListUsers: (params: IInventoryListUsersParams): Promise<IUserShortCollection> =>
    axios.get(`inventory/users`, { params }),
  getInventoryById: (id: number): Promise<IInventoryDetailed> => axios.get(`${basePath}/${id}`),
  getInventoryActionList: (payload: IInventoryActionListPayload): Promise<IInventoryActionCollectionDto> =>
    axios.post(`inventory-action/list`, payload),
  getInventoryActionListSubdivisions: (params: IInventoryActionListSubdivisionsParams): Promise<ISubdivisionsShortCollection> =>
    axios.get(`inventory-action/subdivisions`, { params }),
  getInventoryActionListUsers: (params: IInventoryActionListUsersParams): Promise<IUserShortCollection> =>
    axios.get(`inventory-action/users`, { params }),
  getInventoryActionById: (id: number): Promise<IInventoryAction> => axios.get(`inventory-action/${id}`),
  createInventoryAction: (payload: IInventoryActionCreatePayload): Promise<IInventoryAction> =>
    axios.post(`inventory-action`, payload),
  updateInventoryAction: (payload: IInventoryActionUpdatePayload): Promise<IInventoryAction> =>
    axios.patch(`inventory-action`, payload),
  deleteInventoryAction: (payload: IInventoryActionDeletePayload): Promise<IInventoryActionCollectionDto> =>
    axios.delete(`inventory-action`, { data: payload }),
};
