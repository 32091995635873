import { mapToMenuWithDivider } from 'common/helpers/common.helper';
import { DEFAULT_EMPTY_VALUE } from 'common/config';
import { ISubdivisionShort, IUserShort } from 'common/models';
import { IAddress } from 'entities/Address/Address.models';
import { IBuyer } from 'entities/Buyer/Buyer.models';
import { ILegalEntity } from 'entities/LegalEntity/LegalEntity.models';
import { getUserName } from 'entities/User/User.helper';

export const getFilterOptions = {
  subdivision: (list: ISubdivisionShort[]) =>
    mapToMenuWithDivider([
      { label: 'Все подразделения', value: DEFAULT_EMPTY_VALUE },
      ...list.filter((subdivision) => !!subdivision).map((subdivision) => ({ label: subdivision.name, value: subdivision.id })),
    ]),
  user: (list: IUserShort[]) =>
    mapToMenuWithDivider([
      { label: 'Все пользователи', value: DEFAULT_EMPTY_VALUE },
      ...list
        .filter((user) => !!user)
        .map((user) => ({ label: getUserName.fullName(user.lastName, user.firstName, user.patronymic), value: user.id })),
    ]),
  userWithNull: (list: IUserShort[]) =>
    mapToMenuWithDivider([
      { label: 'Все пользователи', value: DEFAULT_EMPTY_VALUE },
      { label: 'Сотрудник не назначен', value: null },
      ...list
        .filter((user) => !!user)
        .map((user) => ({ label: getUserName.fullName(user.lastName, user.firstName, user.patronymic), value: user.id })),
    ]),
  buyer: (list: IBuyer[]) =>
    mapToMenuWithDivider([
      { label: 'Все компании', value: DEFAULT_EMPTY_VALUE },
      ...list.filter((buyer) => !!buyer).map((buyer) => ({ label: buyer.name, value: buyer.id })),
    ]),
  legal: (list: ILegalEntity[]) =>
    mapToMenuWithDivider([
      { label: 'Все юридические лица', value: DEFAULT_EMPTY_VALUE },
      ...list.filter((legal) => !!legal).map((legal) => ({ label: legal.name, value: legal.id })),
    ]),
  address: (list: IAddress[]) =>
    mapToMenuWithDivider([
      { label: 'Все адреса отгрузки', value: DEFAULT_EMPTY_VALUE },
      ...list.filter((address) => !!address).map((address) => ({ label: address?.name, value: address?.id })),
    ]),
};
