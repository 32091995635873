import React, { FC, useEffect } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { rules } from 'common/helpers/form.helper';
import { IFormValue } from 'common/models';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  url?: string;
  onClose: () => void;
  onSave: (value: string, onSuccess: () => void) => void;
}

export const FeedCreateModal: FC<IComponentProps> = ({ open, loading, url, onClose, onSave }) => {
  const [form] = useForm();

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const onSubmit = ({ feedUrl }: IFormValue) => {
    onSave(feedUrl, handleClose);
  };

  useEffect(() => {
    if (open && url) {
      form.setFieldsValue({ feedUrl: url });
    }
  }, [open, url]);

  return (
    <Modal
      rootClassName="redesign modal"
      width={400}
      title="Настройка интеграции"
      centered
      open={open}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" className="button-sm secondary" onClick={handleClose} disabled={loading}>
          Отмена
        </Button>,
        <Button key="save" className="button-sm primary" loading={loading} onClick={form.submit}>
          Сохранить
        </Button>,
      ]}
    >
      <Form form={form} requiredMark={false} layout="vertical" onFinish={onSubmit}>
        <Form.Item label="Фид" name="feedUrl" rules={[rules.required()]}>
          <Input
            placeholder={EPlaceholder.AddFeedForExport}
            allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
